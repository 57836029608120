import React, { useState, useEffect, useRef } from "react";
import { Notifications } from "./Notifications";
import BellIcon from "../../images/bellIcon.svg";
import { useSelector } from "react-redux";

function useOutsideAlerter(ref, setDropdownOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        //   alert("You clicked outside of me!");
        setDropdownOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const NotificationDropdown = () => {
  const notifications = useSelector(
    (state) => state.primaryEmail.notifications.listOfNotifications
  );
  const [isDropdownOpend, setDropdownOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setDropdownOpen);

  const toggleDropDown = () => setDropdownOpen(!isDropdownOpend);

  return (
    <>
      <div ref={wrapperRef}>
        <button
          onMouseEnter={() => setDropdownOpen(true)}
          onClick={toggleDropDown}
          className="hideBellOnMobile"
          style={{ border: "none", background: "transparent", outline: "none" }}
        >
          <img
            src={
              "https://cdn.pegasus.imarticus.org/index/notifications_neutral.svg"
            }
            alt="NotificationIcon"
            style={{
              backgroundColor: isDropdownOpend
                ? "rgba(60,72,82,0.10)"
                : "white",
              borderRadius: "8px",
            }}
          />
          {notifications && countUnreadNots(notifications) !== 0 && (
            <span className="badge badge-danger">
              {notifications && countUnreadNots(notifications)}
            </span>
          )}
        </button>
        {isDropdownOpend && (
          <div className="user-notification-menu">
            <Notifications seeAll={true} />
          </div>
        )}
      </div>
    </>
  );
};
export default NotificationDropdown;

const countUnreadNots = (notifications) => {
  return notifications.filter((d) => d.status && d.status == true).length;
};
