import Axios from "axios";
import { withRouter, useHistory } from "react-router";
import small_left from "../../../images/left-arr-white.svg";
import { connect } from "react-redux";
import Loader from "../../utils/Loader.js";
import React, { useState, useRef, useEffect } from "react";
import { CONFIGS, GENDER_DROPDOWN } from "../../../constants/constants";
import ProfileDetailCard from "../../utils/ProfileDetailCard";
import { utils } from "../../../utility/utils";
import { Link, useLocation } from "react-router-dom";
import "./placementprofile.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormText,
  FormGroup,
  Col,
  Row,
} from "reactstrap";
import { CCODS, TABS } from "../../../constants/constants";
import {
  getPlacementProfile,
  getProfileInfo,
  getProfileInfoOnly,
  saveProfileDetails,
} from "../../../actions/profileAction";
import { fetchMyCourses } from "../../../actions/courseAction";
import { setCurrentTab } from "../../../actions/utilAction";
import { uploadUserProfilePic } from "../../../actions/profileAction";

const BasicDetails2 = (props) => {
  console.log({ props });
  const history = useHistory();
  let profile = props.profile;
  let courses = props.courses;
  let selected_course = props.selected_course;
  let fileInput = useRef(null);
  const location = useLocation();
  console.log({ selected_course });
  console.log({ profile });
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    ccod: "",
    name: "",
    email: "",
    num: "",
    aboutme: "",
    gender: "",
    uid: "",
    ppic: "",
  });
  const [percCompleted, setPercCompleted] = useState(0);
  const [profilePic, setProfilePic] = useState();
  const [crs_pg_id, setCrsPgId] = useState("");
  const [initialLoading, setIntitalLoading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [lastFilledBlockIndex, setLastFilledBlockIndex] = useState(0);
  const [errorMessageGender, setErrorMessageGender] = useState("");
  const [errorMessageAboutMe, setErrorMessageAboutMe] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  let anyInvalid = false;

  const handleFormChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };
  const searchParams = new URLSearchParams(location.search);

  const validateBasicDetails = () => {
    validateGender();
    validateAboutMe();
  };

  const validateGender = () => {
    if (formData.gender && formData.gender === "0") {
      setErrorMessageGender("Gender cant be blank");
      anyInvalid = true;
    } else {
      setErrorMessageGender("");
    }
  };
  const validateAboutMe = () => {
    if (formData.aboutme === "") {
      setErrorMessageAboutMe("About Me can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessageAboutMe("");
    }
  };

  const redirectToCourse = () => {
    const course = courses.find((course) => course.crs_pg_id === crs_pg_id);
    if (course) {
      history.push(`/view/?cid=${course._id}`);
    }
  };

  const handleSaveBasicDetails = () => {
    console.log("Inside handle save");
    console.log({ anyInvalid });
    validateBasicDetails();
    console.log({ anyInvalid });
    if (!anyInvalid) {
      console.log({ anyInvalid });
      saveBasicDetails();
    } else {
      console.log({ anyInvalid });
      window.alert("Please fill all the required details before saving.");
    }

    setTimeout(() => {
      console.log({ anyInvalid });
    }, 3000);
  };

  const saveBasicDetails = async () => {
    if (formData.ccod == "") {
      window.alert("Country code can't be blank");
      return;
    }
    if (formData.aboutme == "") {
      window.alert("About Me can't be blank");
      return;
    }
    if (formData.gender == "") {
      window.alert("Gender can't be blank");
      return;
    }
    saveProfileDetails(crs_pg_id, "basic-details", formData).then((res) => {
      if (res && res.data.success) {
        history.push(
          `/placement-module/education-details/?crs_pg_id=${crs_pg_id}`
        );
        // setOpenSuccessModal(true);
      }
    });
  };

  let chooseProfileImage = (ev) => {
    const file = ev.target.files[0];
    let res = validCompanyLogo(file);

    if (!res.valid) {
      window.alert(res.errorString);
    } else {
      setProfilePic(file);
      uploadUserProfilePic(file)
        .then(({ success, data, message }) => {
          // setIsLoading(false)
          console.log({ success: success });
          if (!success) {
            if (message) {
              window.alert(message);
            } else {
              window.alert("Something went wrong. Please try again.");
            }
          } else {
            setProfilePic(data.img_url);
            handleFormChange("ppic", data.img_url);
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          // Handle any errors that occurred during the async operation
        });
    }
  };

  let validCompanyLogo = (file) => {
    const supportedFileTypes = ["image/jpg", "image/jpeg", "image/png"];

    let errorList = [];

    if (file.size > 50 * 1024) {
      errorList.push("File Size exceeds limit");
    }

    if (supportedFileTypes.indexOf(file.type) == -1) {
      errorList.push("File type is unsupported");
    }

    let errorString = errorList.join(" and ");
    if (errorList.length > 0) {
      return {
        valid: false,
        errorString,
      };
    } else {
      return {
        valid: true,
      };
    }
  };

  const saveBasicDetailsNoNext = async () => {
    saveProfileDetails(crs_pg_id, "basic-details", formData).then((res) => {
      if (res && res.data.success) {
        window.alert("Details successfully updated");
      }
    });
  };

  useEffect(() => {
    props.setCurrentTab(TABS.BASIC_DETAILS);
    if (props.uid == "") {
      props.getProfileInfo();
    }
    // if(!props.course){
    //   // props.fetchMyCourses()
    // }

    setFormData((prevFormData) => ({
      ...prevFormData,
      ccod: props.profile.ccode,
      name: props.profile.p_name,
      email: props.profile.email,
      num: props.profile.contact,
      uid: props.profile.uid,
      ppic: props.profile.ppic,
    }));

    setProfilePic(props.profile.ppic);

    // const pathSegments = location.pathname.split('/');
    // const crs_pgid = pathSegments[pathSegments.length - 1];
    const crs_pgid = searchParams.get("crs_pg_id");
    setCrsPgId(crs_pgid);
    setIntitalLoading(true);
  }, []);

  useEffect(() => {
    console.log("useEffect ran");
    if (initialLoading) {
      getPlacementProfile("basic-details", crs_pg_id)
        .then(function (res) {
          setLoading(false);
          console.log(res, ":response data");
          if (res && res.learnerInfo) {
            setFormData({
              ccod: res.learnerInfo.ccod,
              name: res.learnerInfo.name,
              email: res.learnerInfo.email,
              num: res.learnerInfo.num,
              uid: res.learnerInfo.uid,
              ppic: res.learnerInfo.ppic,
              aboutme: res.learnerInfo.aboutme ? res.learnerInfo.aboutme : "",
              gender: res.learnerInfo.gender ? res.learnerInfo.gender : "",
            });
            setPercCompleted(res.perc_completed);
            setLastFilledBlockIndex(res.lastFilledBlockIndex);
          }
        })
        .catch(function (err) {
          setLoading(false);
          console.log(err);
        });
    }
  }, [initialLoading]);
  return (
    <>
      <div className="group-sidebar">
        <Link to="/dashboard">
          <div
            className="logo-container center-all "
            style={{
              backgroundColor: `${
                props.brand && props.brand.whiteBackground ? "white" : ""
              }`,
            }}
          >
            {!props.is_data_loading && (
              <img
                src={
                  props.brand
                    ? props.brand.pic
                    : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                }
                className="side-bar__logo-img"
                style={{
                  maxHeight: "50px",
                  margin: "unset",
                }}
              />
            )}
          </div>
        </Link>
        {/* <Link to="/dashboard"> */}
        <p className="back-btn" onClick={redirectToCourse}>
          <img className="back-img" src={small_left}></img>Back
        </p>
        {/* </Link> */}
        <div>
          <p className="grp-heading">Placement Profile</p>
          <Link
            to={`/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Profile Preview</p>
            </div>
          </Link>
          <Link to={`/placement-module/basic-details/?crs_pg_id=${crs_pg_id}`}>
            <div className={"g-name-wrapper active"}>
              <p className={"g-name"}>Basic Details</p>
            </div>
          </Link>
          <Link
            to={"#"}
            // to={`/placement-module/education-details/?crs_pg_id=${crs_pg_id}`}
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Education Details</p>
            </div>
          </Link>
          <Link
            to={"#"}
            // to={`/placement-module/professional-details/?crs_pg_id=${crs_pg_id}`}
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Professional Details</p>
            </div>
          </Link>
          <Link
            to={
              "#"
              // `/placement-module/resume-details/?crs_pg_id=${crs_pg_id}`
            }
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Resume</p>
            </div>
          </Link>
          {/* <Link
            to={`/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Profile Preview</p>
            </div>
          </Link> */}
        </div>
      </div>
      {!loading ? (
        <>
          <div className="group-db-content-wrapper-2">
            <div className="container-fluid dashboard-content">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row justify-content-left profile-bar">
                    <h5 className="profile-bar-heading col-md-12">
                      Tell us a little more about yourself
                    </h5>
                    <div className="profile-bar-detail col-md-12">
                      <p>Profile Progress</p>
                      <div
                        className="row"
                        style={{ height: "100%", marginLeft: "4px" }}
                      >
                        <div className="profile-bar-progress col-md-10">
                          <div
                            className="profile-bar-completed"
                            style={{ width: `${percCompleted}%` }}
                          ></div>
                        </div>
                        <div className="col-md-2" style={{ marginTop: "-5px" }}>
                          {percCompleted}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-db-content-wrapper-2">
            <div className="container-fluid ">
              <h4 className="profile-setting-container-3">Basic Details</h4>
            </div>
          </div>
          <div className="group-db-content-wrapper">
            <div className="container-fluid dashboard-content-2">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="justify-content-center">
                    <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                      <div className="edit-window-profile-details">
                        <li className="row">
                          <div className="col-md-12">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Name
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <Input
                                type="text"
                                name="text"
                                id="name"
                                value={formData.name}
                                // onChange={(event) => {
                                //   setName(event.target.value)
                                // }}
                                style={{ width: "98%" }}
                                className="profile-box"
                                disabled={formData.name != ""}
                              />
                            </FormGroup>
                            {/* <p>opportunity id already exist</p> */}
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              UID
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <Input
                                type="text"
                                name="text"
                                id="name"
                                value={formData.uid}
                                style={{ width: "98%" }}
                                className="profile-box"
                                disabled={formData.uid != ""}
                              />
                            </FormGroup>
                            {/* <p>opportunity id already exist</p> */}
                          </div>
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Primary Email
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <Input
                                type="text"
                                name="text"
                                id="name"
                                value={formData.email}
                                onChange={(event, oppID) => {
                                  // setOpportunityId(event.target.value)
                                }}
                                style={{ width: "98%" }}
                                className="profile-box"
                              />
                            </FormGroup>
                            {/* <p>opportunity id already exist</p> */}
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Primary Mobile No.
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <div className="row">
                                <div
                                  style={{
                                    width: "100px",
                                    paddingRight: "12px",
                                    paddingLeft: "12px",
                                  }}
                                >
                                  <Input
                                    name="mobileCountryCode"
                                    placeholder="Country Code"
                                    type="select"
                                    value={formData.ccod}
                                    disabled={formData.ccod != ""}
                                    // onChange={(event, oppID) => {
                                    //   setHrccod(event.target.value)
                                    // }}
                                  >
                                    {Object.keys(CCODS).map((cc) => {
                                      return (
                                        <option key={cc} value={cc}>
                                          +{cc}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                </div>
                                <Input
                                  type="text"
                                  name="text"
                                  id="companyHRMobile"
                                  value={formData.num}
                                  // onChange={(event, oppID) => {
                                  //   setHrmobile(event.target.value)
                                  // }}
                                  style={{ width: "68%" }}
                                  disabled={formData.num != ""}
                                />
                              </div>
                            </FormGroup>
                            {/* <p>opportunity id already exist</p> */}
                          </div>
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Gender
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <div style={{ display: "flex" }}>
                                <select
                                  style={{ width: "98%" }}
                                  className="form-control"
                                  onChange={(e) =>
                                    handleFormChange("gender", e.target.value)
                                  }
                                  value={formData.gender}
                                  // disabled={gender != ""}
                                >
                                  {GENDER_DROPDOWN.map((cc, i) => {
                                    return (
                                      <option
                                        // key={cc.code + String(i)}

                                        value={cc.value}
                                      >
                                        {cc.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errorMessageGender != "" && (
                                <FormText color="danger">
                                  {errorMessageGender}
                                </FormText>
                              )}
                            </FormGroup>
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-12">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Profile Picture (Optional)
                            </h5>
                            <FormGroup>
                              {/* <div
                            className={`upload-div drop-zone ${
                              dragging ? "dragging" : ""
                            }`}
                            onClick={() => fileInput.click()}
                          >
                            <h5 className="upload-text">Upload File</h5>

                            <input
                              type="file"
                              name="file"
                              id="fileInput"
                              ref={(input) => {
                                fileInput = input;
                              }}
                              style={{ display: "none" }}
                              onChange={(event) => {
                                // Handle file upload logic here
                                const file = event.target.files[0];
                                console.log("Uploaded file:", file);
                                // chooseCompanyLogo(event)
                              }}
                            />
                          </div>
                          <p>
                            Upload image 150x150 px in a format of PNG, JPG or
                            JPEG. Max Size 50KB
                          </p> */}
                              <div
                                className="upload-div-2 drop-zone"
                                onClick={() => fileInput.click()}
                              >
                                <div className="upload-div-profile ">
                                  <img
                                    src="https://webcdn.imarticus.org/Placement/material-symbols_upload1.svg"
                                    className="as-dl-logo-job-1"
                                  />
                                </div>
                                <div className="upload-div-profile-content">
                                  <div className="upload-div-txt">
                                    <h5>Upload File</h5>{" "}
                                    <input
                                      type="file"
                                      name="file"
                                      id="fileInput"
                                      ref={(input) => {
                                        fileInput = input;
                                      }}
                                      style={{ display: "none" }}
                                      onChange={(event) => {
                                        // Handle file upload logic here
                                        const file = event.target.files[0];
                                        console.log("Uploaded file:", file);
                                        chooseProfileImage(event);
                                      }}
                                    />
                                  </div>
                                  <div className="upload-div-txt-2">
                                    <p>
                                      Accepted format: PNG, JPG or JPEG | Max
                                      Size 50KB
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </FormGroup>
                            {profilePic && (
                              <a
                                className="file-name"
                                href={profilePic}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Uploaded Pic
                              </a>
                            )}
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-12">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              About Me
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <Input
                                type="textarea"
                                name="text"
                                id="name"
                                value={formData.aboutme}
                                onChange={(e) =>
                                  handleFormChange("aboutme", e.target.value)
                                }
                                style={{ width: "98%" }}
                                rows={7}
                              />
                              {errorMessageAboutMe != "" && (
                                <FormText color="danger">
                                  {errorMessageAboutMe}
                                </FormText>
                              )}
                            </FormGroup>
                            {/* <p>opportunity id already exist</p> */}
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>
                  <div className="addcrit-footer">
                    <Row className="adques-footer">
                      <Col md={2}>
                        {/* <Button
                    className="jobs-table-btn-5"
                    onClick={ () => {
                      // history.push(`/placement-module/add-job-criteria/${jobid}`)
                    }}
                >
                {"<"} Previous
                </Button> */}
                      </Col>
                      <Col md={3}></Col>
                      <Col md={1}></Col>
                      <Col md={2}></Col>
                      <Col md={2}>
                        <Link
                          to={`/placement-module/education-details/?crs_pg_id=${crs_pg_id}`}
                        >
                          <Button className="labs-table-btn-2">
                            Skip & Next {">"}
                          </Button>
                        </Link>
                      </Col>
                      <Col md={2}>
                        <Button
                          className="jobs-table-btn-5"
                          // onClick={() => saveBasicDetails()}
                          onClick={() => handleSaveBasicDetails()}
                        >
                          Save & Next
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={openSuccessModal}
            size={"xl"}
            className="apply-sucess-modal"
            centered
          >
            <ModalBody style={{ maxHeight: "800px", overflowY: "scroll" }}>
              <div>
                <p className="aply-success-txt ">Thank you for submitting!</p>
                <p className="prof-dtl-txt">Your responses have been saved.</p>
              </div>
              <div
                className="apply-success-div"
                style={{ justifyContent: "space-evenly" }}
              >
                <Button
                  className="apply-danger-btn"
                  onClick={() => {
                    setOpenSuccessModal(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  // color="success"
                  onClick={() => {
                    setOpenSuccessModal(false);
                    redirectToCourse();
                  }}
                  className="apply-success-btn-1"
                >
                  Go to My Course
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  profile: state.profile,
  course: state.course.courses[0],
});

const mapDispatchToProps = { getProfileInfo, fetchMyCourses, setCurrentTab };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BasicDetails2)
);
