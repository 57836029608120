import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { showSidebarMB, setSidebar } from "../../actions/utilAction";
import small_left from "../../images/left-arr-white.svg";
import { withRouter } from "react-router";
import queryString from "query-string";
import white_cross from "../../images/white-cross.svg";
import { getProfileInfo } from "../../actions/profileAction";
import { SIDEBARS, TABS } from "../../constants/constants";
import { Link } from "react-router-dom";
import {
  fetchGroups,
  setSelectedGroupDetails,
  setGSettingBack,
  resetGSettingBack,
} from "../../actions/groupAction";
import eck_logo from "../../images/imarticus-new-logo.svg";
import SidebarSS from "../skeletonscreens/SidebarSS";
import GroupSidebarSS from "../skeletonscreens/GroupSidebarSS";

class SettingSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.setSidebar(SIDEBARS.GROUP_SIDEBAR);
    var query_params = queryString.parse(this.props.location.search);
    this.setState({
      current_gid: query_params.gid,
    });
  }
  componentDidUpdate(prev_props) {
    if (
      this.props.location != prev_props.location &&
      this.props.gsetting_is_back
    ) {
      this.props.history.goBack();
    }
  }

  closeSidebar = () => {
    this.props.showSidebarMB(false);
  };
  goBack = () => {
    this.props.setGSettingBack();
    this.props.history.goBack();
  };
  goToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard/",
    });
  };

  render() {
    const GROUP_SETTING_TABS = [TABS.GROUP_EDIT, TABS.GROUP_DESC];
    if (
      GROUP_SETTING_TABS.includes(this.props.current_tab) &&
      !this.props.gsetting_is_back
    ) {
      /* GROUP SETTINGS */
      return (
        <Fragment>
          <div className="group-sidebar">
            <Link to="/dashboard">
              <div
                className="logo-container center-all side-bar__logo-img"
                style={{
                  backgroundColor: `${
                    this.props.brand && this.props.brand.whiteBackground
                      ? "white"
                      : ""
                  }`,
                }}
              >
                {!this.props.is_data_loading && (
                  <img
                    src={
                      this.props.brand
                        ? this.props.brand.pic
                        : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                    }
                    style={{
                      maxHeight: "50px",
                      margin: "unset",
                    }}
                  />
                )}
              </div>
            </Link>
            <p onClick={this.goBack} className="back-btn">
              <img className="back-img" src={small_left}></img>Back
            </p>
            <div>
              <p className="grp-heading">Group Settings</p>
              <Link to={"/dashboard/gedit?gid=" + this.state.current_gid}>
                <div
                  className={
                    this.props.current_tab == TABS.GROUP_EDIT
                      ? "g-name-wrapper active"
                      : "g-name-wrapper"
                  }
                >
                  <p
                    className={
                      this.props.current_tab == TABS.GROUP_EDIT
                        ? "g-name"
                        : "g-name"
                    }
                  >
                    Edit Group
                  </p>
                </div>
              </Link>
              <Link to={"/dashboard/gdesc?gid=" + this.state.current_gid}>
                <div
                  className={
                    this.props.current_tab == TABS.GROUP_DESC
                      ? "g-name-wrapper active"
                      : "g-name-wrapper"
                  }
                >
                  <p
                    className={
                      this.props.current_tab == TABS.GROUP_DESC
                        ? "g-name"
                        : "g-name"
                    }
                  >
                    Group Description
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div
            onClick={this.closeSidebar}
            className={this.props.show_sidebar_mb ? "demo" : "hide"}
          ></div>
          <div className="group-sidebar-mb">
            <div
              className={
                "left-sidebar-menu-mb " +
                (this.props.show_sidebar_mb ? "out" : "")
              }
            >
              <div className="sidebar-header">
                {!this.props.is_data_loading && (
                  <img
                    onClick={this.goToDashboard}
                    src={
                      this.props.brand
                        ? this.props.brand.pic
                        : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                    }
                    className="Logo"
                  ></img>
                )}

                <button
                  className={this.props.show_sidebar_mb ? "close-btn" : "hide"}
                  onClick={this.closeSidebar}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    enable-background="new 0 0 40 40"
                  >
                    <line
                      x1="5"
                      y1="5"
                      x2="35"
                      y2="35"
                      stroke="#fff"
                      stroke-width="5"
                      stroke-linecap="round"
                      stroke-miterlimit="10"
                    ></line>
                    <line
                      x1="35"
                      y1="5"
                      x2="5"
                      y2="35"
                      stroke="#fff"
                      stroke-width="5"
                      stroke-linecap="round"
                      stroke-miterlimit="10"
                    ></line>
                  </svg>
                </button>
              </div>
              <p onClick={this.goBack} className="back-btn">
                <img className="back-img" src={small_left}></img>Back
              </p>
              {/*<button className={this.props.show_sidebar_mb ? "close-btn" : "hide"} onClick={this.closeSidebar}><img src={white_cross}></img></button>*/}
              <div>
                <p className="grp-heading">Groups Settings</p>
                <Link to={"/dashboard/gedit?gid=" + this.state.current_gid}>
                  <div
                    className={
                      this.props.current_tab == TABS.GROUP_EDIT
                        ? "g-name-wrapper active"
                        : "g-name-wrapper"
                    }
                  >
                    <p
                      className={
                        this.props.current_tab == TABS.GROUP_EDIT
                          ? "g-name"
                          : "g-name"
                      }
                    >
                      Edit Group
                    </p>
                  </div>
                </Link>
                <Link to={"/dashboard/gdesc?gid=" + this.state.current_gid}>
                  <div
                    className={
                      this.state.current_gid == 0
                        ? "g-name-wrapper active"
                        : "g-name-wrapper"
                    }
                  >
                    <p
                      className={
                        this.state.current_gid == 0 ? "g-name" : "g-name"
                      }
                    >
                      Group Details
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else if (!this.props.gsetting_is_back) {
      /* PROFILE SETTINGS */
      return (
        <Fragment>
          <div className="group-sidebar">
            <Link to="/dashboard">
              <div
                className="logo-container center-all "
                style={{
                  backgroundColor: `${
                    this.props.brand && this.props.brand.whiteBackground
                      ? "white"
                      : ""
                  }`,
                }}
              >
                {!this.props.is_data_loading && (
                  <img
                    src={
                      this.props.brand
                        ? this.props.brand.pic
                        : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                    }
                    className="side-bar_logo-img"
                    alt="logo"
                    style={{
                      maxHeight: "50px",
                    }}
                  />
                )}
              </div>
            </Link>
            <Link to="/dashboard">
              <p className="back-btn">
                <img className="back-img" src={small_left}></img>Back
              </p>
            </Link>
            <div>
              <p className="grp-heading">Profile Settings</p>
              {(this.props.brand == undefined ||
                Object.keys(this.props.brand).length == 0 ||
                this.props.brand.editProfileEnabled == true) && (
                <Link to={"/dashboard/edit"}>
                  <div
                    className={
                      this.props.current_tab == TABS.PROFILE_EDIT
                        ? "g-name-wrapper active"
                        : "g-name-wrapper"
                    }
                  >
                    <p
                      className={
                        this.props.current_tab == TABS.PROFILE_EDIT
                          ? "g-name"
                          : "g-name"
                      }
                    >
                      Edit Profile
                    </p>
                  </div>
                </Link>
              )}

              <Link to={"/dashboard/pwd_edit"}>
                <div
                  className={
                    this.props.current_tab == TABS.PASSWORD_EDIT
                      ? "g-name-wrapper active"
                      : "g-name-wrapper"
                  }
                >
                  <p
                    className={
                      this.props.current_tab == TABS.PASSWORD_EDIT
                        ? "g-name"
                        : "g-name"
                    }
                  >
                    Change Password
                  </p>
                </div>
              </Link>

              <Link to={"/dashboard/manageDevices"}>
                <div
                  className={
                    this.props.current_tab == TABS.MANAGE_DEVICES
                      ? "g-name-wrapper active"
                      : "g-name-wrapper"
                  }
                >
                  <p
                    className={
                      this.props.current_tab == TABS.MANAGE_DEVICES
                        ? "g-name"
                        : "g-name"
                    }
                  >
                    Manage Devices
                  </p>
                </div>
              </Link>
              <Link to={"/dashboard/troubleshootNotifications"}>
                <div
                  className={
                    this.props.current_tab == TABS.TROUBLESHOOT_NOTIFICATIONS
                      ? "g-name-wrapper active"
                      : "g-name-wrapper"
                  }
                >
                  <p
                    className={
                      this.props.current_tab == TABS.TROUBLESHOOT_NOTIFICATIONS
                        ? "g-name"
                        : "g-name"
                    }
                  >
                    Troubleshoot Notifications
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div
            onClick={this.closeSidebar}
            className={this.props.show_sidebar_mb ? "demo" : "hide"}
          ></div>
          <div className="group-sidebar-mb">
            <div
              className={
                "left-sidebar-menu-mb " +
                (this.props.show_sidebar_mb ? "out" : "")
              }
            >
              <div className="sidebar-header">
                {!this.props.is_data_loading && (
                  <img
                    onClick={this.goToDashboard}
                    src={
                      this.props.brand
                        ? this.props.brand.pic
                        : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                    }
                    className="Logo"
                  ></img>
                )}

                <button
                  className={this.props.show_sidebar_mb ? "close-btn" : "hide"}
                  onClick={this.closeSidebar}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    enable-background="new 0 0 40 40"
                  >
                    <line
                      x1="5"
                      y1="5"
                      x2="35"
                      y2="35"
                      stroke="#fff"
                      stroke-width="5"
                      stroke-linecap="round"
                      stroke-miterlimit="10"
                    ></line>
                    <line
                      x1="35"
                      y1="5"
                      x2="5"
                      y2="35"
                      stroke="#fff"
                      stroke-width="5"
                      stroke-linecap="round"
                      stroke-miterlimit="10"
                    ></line>
                  </svg>
                </button>
              </div>
              <p onClick={this.goBack} className="back-btn">
                <img className="back-img" src={small_left}></img>Back
              </p>
              {/*<button className={this.props.show_sidebar_mb ? "close-btn" : "hide"} onClick={this.closeSidebar}><img src={white_cross}></img></button>*/}
              <div>
                <p className="grp-heading">Profile Settings</p>
                {(this.props.brand == undefined ||
                  Object.keys(this.props.brand).length == 0 ||
                  this.props.brand.editProfileEnabled == true) && (
                  <Link to={"/dashboard/edit"}>
                    <div
                      className={
                        this.props.current_tab == TABS.PROFILE_EDIT
                          ? "g-name-wrapper active"
                          : "g-name-wrapper"
                      }
                    >
                      <p
                        className={
                          this.props.current_tab == TABS.PROFILE_EDIT
                            ? "g-name"
                            : "g-name"
                        }
                      >
                        Edit Profile
                      </p>
                    </div>
                  </Link>
                )}
                <Link to={"/dashboard/pwd_edit"}>
                  <div
                    className={
                      this.props.current_tab == TABS.PASSWORD_EDIT
                        ? "g-name-wrapper active"
                        : "g-name-wrapper"
                    }
                  >
                    <p
                      className={
                        this.props.current_tab == TABS.PASSWORD_EDIT
                          ? "g-name"
                          : "g-name"
                      }
                    >
                      Change Password
                    </p>
                  </div>
                </Link>
                <Link to={"/dashboard/manageDevices"}>
                  <div
                    className={
                      this.props.current_tab == TABS.MANAGE_DEVICES
                        ? "g-name-wrapper active"
                        : "g-name-wrapper"
                    }
                  >
                    <p
                      className={
                        this.props.current_tab == TABS.MANAGE_DEVICES
                          ? "g-name"
                          : "g-name"
                      }
                    >
                      Manage Devices
                    </p>
                  </div>
                </Link>
                <Link to={"/dashboard/troubleshootNotifications"}>
                  <div
                    className={
                      this.props.current_tab == TABS.MANAGE_DEVICES
                        ? "g-name-wrapper active"
                        : "g-name-wrapper"
                    }
                  >
                    <p
                      className={
                        this.props.current_tab == TABS.MANAGE_DEVICES
                          ? "g-name"
                          : "g-name"
                      }
                    >
                      Troubleshoot Notifications
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <GroupSidebarSS />;
    }
  }
}

const mapStateToProps = (state) => ({
  show_sidebar_mb: state.utils.show_sidebar_mb,
  current_tab: state.utils.current_tab,
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  gsetting_is_back: state.j_grp.gsetting_is_back,
  brand: state.course.brand,
  is_data_loading: state.utils.is_data_loading,
});

const mapDispatchToProps = {
  setSidebar,
  showSidebarMB,
  getProfileInfo,
  fetchGroups,
  setSelectedGroupDetails,
  setGSettingBack,
  resetGSettingBack,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingSidebar)
);
