import Axios from "axios";
import { withRouter, useHistory } from "react-router";
import React, { useState, useRef, useEffect } from "react";
import Loader from "../../utils/Loader.js";
import { connect } from "react-redux";
import {
  CONFIGS,
  PASSING_YEAR,
  BOARD_DROPDOWN,
  BACHELORS_DROPDOWN,
  MASTERS_DROPDOWN,
} from "../../../constants/constants";
import ProfileDetailCard from "../../utils/ProfileDetailCard";
import { utils } from "../../../utility/utils";
import "./placementprofile.css";
import { Link, useLocation } from "react-router-dom";
import small_left from "../../../images/left-arr-white.svg";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { CCODS, TABS } from "../../../constants/constants";
import { setCurrentTab } from "../../../actions/utilAction";
import {
  getPlacementProfile,
  getProfileInfo,
  getProfileInfoOnly,
  saveProfileDetails,
} from "../../../actions/profileAction";
const EducationDetails2 = (props) => {
  const history = useHistory();
  let fileInput = useRef(null);
  let profile = props.profile;
  let courses = props.courses;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const [ifSubscribed, setIfSubscribed] = useState(profile.ifSubscribed);
  const [loading, setLoading] = useState(true);
  const [totalPositions, setTotalPositions] = useState(0);
  //states
  const [percCompleted, setPercCompleted] = useState(0);
  const [crs_pg_id, setCrsPgId] = useState("");
  const [initialLoading, setIntitalLoading] = useState(false);
  const [lastFilledBlockIndex, setLastFilledBlockIndex] = useState(0);
  const [educationDetails, setEducationDetails] = useState({
    matric: {
      school: "",
      board: "",
      score_type: "CGPA",
      perc: 0,
      year: "",
    },
    inter: {
      school: "",
      board: "",
      score_type: "CGPA",
      perc: 0,
      year: "",
    },
    grad: {
      college: "",
      degree: "",
      score_type: "CGPA",
      perc: 0,
      year: "",
    },
    post_grad_completed: false,
    post_grad: {
      college: "",
      degree: "",
      score_type: "CGPA",
      perc: 0,
      year: "",
    },
  });
  const [isPG, setIsPG] = useState(false);

  const [errorMessageMatricSchool, setErrorMessageMatricSchool] = useState("");
  const [errorMessageMatricBoard, setErrorMessageMatricBoard] = useState("");
  const [errorMessageMatricPerc, setErrorMessageMatricPerc] = useState("");
  const [errorMessageMatricYear, setErrorMessageMatricYear] = useState("");

  const [errorMessageInterSchool, setErrorMessageInterSchool] = useState("");
  const [errorMessageInterBoard, setErrorMessageInterBoard] = useState("");
  const [errorMessageInterPerc, setErrorMessageInterPerc] = useState("");
  const [errorMessageInterYear, setErrorMessageInterYear] = useState("");

  const [errorMessageGradCollege, setErrorMessageGradCollege] = useState("");
  const [errorMessageGradDeg, setErrorMessageGradDeg] = useState("");
  const [errorMessageGradPerc, setErrorMessageGradPerc] = useState("");
  const [errorMessageGradYear, setErrorMessageGradYear] = useState("");

  const [errorMessagePostGradCollege, setErrorMessagePostGradCollege] =
    useState("");
  const [errorMessagePostGradDeg, setErrorMessagePostGradDeg] = useState("");
  const [errorMessagePostGradPerc, setErrorMessagePostGradPerc] = useState("");
  const [errorMessagePostGradYear, setErrorMessagePostGradYear] = useState("");

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  //end states
  const [dragging, setDragging] = useState(false);
  console.log({ profile });
  let creationDate = new Date(profile.profile_map.cat).toString();
  let anyInvalid = false;
  useEffect(() => {
    props.setCurrentTab(TABS.EDUCATION_DETAILS);
    if (props.uid == "") {
      props.getProfileInfo();
    }
    const crs_pgid = searchParams.get("crs_pg_id");
    setCrsPgId(crs_pgid);
    setIntitalLoading(true);
  }, []);
  useEffect(() => {
    console.log("useEffect ran");
    if (initialLoading) {
      getPlacementProfile("educational-details", crs_pg_id)
        .then(function (res) {
          setLoading(false);
          console.log(res, ":response data");
          if (
            res &&
            res.learnerInfo &&
            Object.keys(res.learnerInfo).length > 0
          ) {
            setEducationDetails({
              matric: {
                school: res.learnerInfo.matric.school,
                board: res.learnerInfo.matric.board,
                score_type: res.learnerInfo.matric.score_type,
                perc: res.learnerInfo.matric.perc,
                year: res.learnerInfo.matric.year,
              },
              inter: {
                school: res.learnerInfo.inter.school,
                board: res.learnerInfo.inter.board,
                score_type: res.learnerInfo.inter.score_type,
                perc: res.learnerInfo.inter.perc,
                year: res.learnerInfo.inter.year,
              },
              grad: {
                college: res.learnerInfo.grad.college,
                degree: res.learnerInfo.grad.degree,
                score_type: res.learnerInfo.grad.score_type,
                perc: res.learnerInfo.grad.perc,
                year: res.learnerInfo.grad.year,
              },
              post_grad_completed: res.learnerInfo.post_grad_completed,
              post_grad: {
                college: res.learnerInfo.post_grad?.college,
                degree: res.learnerInfo.post_grad?.degree,
                score_type: res.learnerInfo.post_grad?.score_type,
                perc: res.learnerInfo.post_grad?.perc,
                year: res.learnerInfo.post_grad?.year,
              },
            });
            if (res.learnerInfo.post_grad_completed) {
              setIsPG(true);
            }
          }
          setPercCompleted(res.perc_completed);
          setLastFilledBlockIndex(res.lastFilledBlockIndex);
        })
        .catch(function (err) {
          setLoading(false);
          console.log(err);
        });
    }
  }, [initialLoading]);
  const handleAddingmastersDetails = () => {
    setIsPG(true);
    setEducationDetails((prevDetails) => ({
      ...prevDetails,
      post_grad_completed: true,
    }));
  };

  const handleRemoveMastersDetails = () => {
    setIsPG(false);
    setEducationDetails((prevDetails) => ({
      ...prevDetails,
      post_grad_completed: false,
      post_grad: {
        college: "",
        degree: "",
        score_type: "CGPA",
        perc: 0,
        year: "",
      },
    }));
  };

  const redirectToCourse = () => {
    const course = courses.find((course) => course.crs_pg_id === crs_pg_id);
    if (course) {
      history.push(`/view/?cid=${course._id}`);
    }
  };

  const handleSaveEducationDetails = () => {
    console.log("Inside handle save");
    console.log({ anyInvalid });
    validateEducationDetails();
    console.log({ anyInvalid });
    // setTimeout(() => {
    if (!anyInvalid) {
      console.log({ anyInvalid });
      saveEducationalDetails();
    } else {
      console.log({ anyInvalid });
      window.alert("Please fill all the required details before saving.");
    }
    // }, 200);

    setTimeout(() => {
      console.log({ anyInvalid });
    }, 3000);
  };

  const validateEducationDetails = () => {
    validateMatricSchool();
    validateMatricBoard();
    validateMatricPerc();
    validateMatricYear();

    validateInterSchool();
    validateInterBoard();
    validateInterPerc();
    validateInterYear();

    validateGradCollege();
    validateGradDegree();
    validateGradPerc();
    validateGradYear();

    if (isPG) {
      validatePostGradCollege();
      validatePostGradDegree();
      validatePostGradPerc();
      validatePostGradYear();
    }

    console.log({ anyInvalid });
  };

  const validateMatricSchool = () => {
    console.log("inside validate curr working");
    if (educationDetails.matric.school === "") {
      setErrorMessageMatricSchool("School cant be blank");
      anyInvalid = true;
    } else {
      setErrorMessageMatricSchool("");
    }
  };
  const validateMatricPerc = () => {
    if (
      educationDetails.matric.perc === "" ||
      isNaN(educationDetails.matric.perc)
    ) {
      setErrorMessageMatricPerc("CGPA/Percentage can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessageMatricPerc("");
    }
  };
  const validateMatricBoard = () => {
    if (
      educationDetails.matric.board === "" ||
      educationDetails.matric.board === "0"
    ) {
      setErrorMessageMatricBoard("Board can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessageMatricBoard("");
    }
  };
  const validateMatricYear = () => {
    if (educationDetails.matric.year === "") {
      setErrorMessageMatricYear("Year can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessageMatricYear("");
    }
  };

  const validateInterSchool = () => {
    console.log("inside validate curr working");
    if (educationDetails.inter.school === "") {
      setErrorMessageInterSchool("School cant be blank");
      anyInvalid = true;
    } else {
      setErrorMessageInterSchool("");
    }
  };
  const validateInterPerc = () => {
    if (
      educationDetails.inter.perc === "" ||
      isNaN(educationDetails.inter.perc)
    ) {
      setErrorMessageInterPerc("CGPA/Percentage can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessageInterPerc("");
    }
  };
  const validateInterBoard = () => {
    if (
      educationDetails.inter.board === "" ||
      educationDetails.inter.board === "0"
    ) {
      setErrorMessageInterBoard("Board can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessageInterBoard("");
    }
  };
  const validateInterYear = () => {
    if (educationDetails.inter.year === "") {
      setErrorMessageInterYear("Year can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessageInterYear("");
    }
  };

  const validateGradCollege = () => {
    if (educationDetails.grad.college === "") {
      setErrorMessageGradCollege("College cant be blank");
      anyInvalid = true;
    } else {
      setErrorMessageGradCollege("");
    }
  };
  const validateGradDegree = () => {
    if (
      educationDetails.grad.degree === "" ||
      educationDetails.grad.degree === "0"
    ) {
      setErrorMessageGradDeg("Degree can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessageGradDeg("");
    }
  };
  const validateGradPerc = () => {
    if (
      educationDetails.grad.perc === "" ||
      isNaN(educationDetails.grad.perc)
    ) {
      setErrorMessageGradPerc("CGPA/Percentage can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessageGradPerc("");
    }
  };
  const validateGradYear = () => {
    if (educationDetails.grad.year === "") {
      setErrorMessageGradYear("Year can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessageGradYear("");
    }
  };

  const validatePostGradCollege = () => {
    if (educationDetails.post_grad.college === "") {
      setErrorMessagePostGradCollege("College cant be blank");
      anyInvalid = true;
    } else {
      setErrorMessagePostGradCollege("");
    }
  };
  const validatePostGradDegree = () => {
    if (
      educationDetails.post_grad.degree === "" ||
      educationDetails.post_grad.degree === "0"
    ) {
      setErrorMessagePostGradDeg("Degree can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessagePostGradDeg("");
    }
  };
  const validatePostGradPerc = () => {
    if (
      educationDetails.post_grad.perc === "" ||
      isNaN(educationDetails.post_grad.perc)
    ) {
      setErrorMessagePostGradPerc("CGPA/Percentage can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessagePostGradPerc("");
    }
  };
  const validatePostGradYear = () => {
    if (educationDetails.post_grad.year === "") {
      setErrorMessagePostGradYear("Year can't be blank");
      anyInvalid = true;
    } else {
      setErrorMessagePostGradYear("");
    }
  };

  const saveEducationalDetails = async () => {
    if (educationDetails.matric.school === "") {
      window.alert("Class Xth school can't be blank");
      return;
    }
    if (educationDetails.matric.board === "") {
      window.alert("Class Xth board can't be blank");
      return;
    }
    if (educationDetails.matric.year === "") {
      window.alert("Class Xth year can't be blank");
      return;
    }

    if (educationDetails.inter.school === "") {
      window.alert("Class XIIth school can't be blank");
      return;
    }
    if (educationDetails.inter.board === "") {
      window.alert("Class XtIIh board can't be blank");
      return;
    }
    if (educationDetails.inter.year === "") {
      window.alert("Class XIIth year can't be blank");
      return;
    }

    if (educationDetails.grad.college === "") {
      window.alert("College Name can't be blank");
      return;
    }
    if (educationDetails.grad.degree === "") {
      window.alert("College Degree can't be blank");
      return;
    }
    if (educationDetails.grad.year === "") {
      window.alert("College Passing Year can't be blank");
      return;
    }

    if (isPG) {
      if (educationDetails.post_grad.college === "") {
        window.alert("Post graduation College Name can't be blank");
        return;
      }
      if (educationDetails.post_grad.degree === "") {
        window.alert(" Post graduation College Degree can't be blank");
        return;
      }
      if (educationDetails.post_grad.year === "") {
        window.alert("Post graduation College Passing Year can't be blank");
        return;
      }
    }

    saveProfileDetails(crs_pg_id, "educational-details", educationDetails).then(
      (res) => {
        if (res && res.data.success) {
          history.push(
            `/placement-module/professional-details/?crs_pg_id=${crs_pg_id}`
          );
          // setOpenSuccessModal(true);
        }
      }
    );
  };

  const saveEducationalDetailsNoNext = async () => {
    saveProfileDetails(crs_pg_id, "educational-details", educationDetails).then(
      (res) => {
        if (res && res.data.success) {
          window.alert("Details successfully updated");
        }
      }
    );
  };

  const handleMatricChange = (key, value) => {
    let newValue = value;
    if (key === "perc") {
      if (educationDetails.matric.score_type == "CGPA") {
        newValue = Math.max(0, Math.min(10, parseFloat(value)));
      } else {
        newValue = Math.max(0, Math.min(100, parseFloat(value)));
      }
    }
    if (key === "score_type") {
      setEducationDetails({
        ...educationDetails,
        matric: {
          ...educationDetails.matric,
          [key]: newValue,
          perc: 0,
        },
      });
      return;
    }
    setEducationDetails({
      ...educationDetails,
      matric: {
        ...educationDetails.matric,
        [key]: newValue,
      },
    });
  };
  const handleInterChange = (key, value) => {
    let newValue = value;
    if (key === "perc") {
      if (educationDetails.inter.score_type == "CGPA") {
        newValue = Math.max(0, Math.min(10, parseFloat(value)));
      } else {
        newValue = Math.max(0, Math.min(100, parseFloat(value)));
      }
    }
    if (key === "score_type") {
      setEducationDetails({
        ...educationDetails,
        inter: {
          ...educationDetails.inter,
          [key]: newValue,
          perc: 0,
        },
      });
      return;
    }
    setEducationDetails({
      ...educationDetails,
      inter: {
        ...educationDetails.inter,
        [key]: newValue,
      },
    });
  };
  const handleGradChange = (key, value) => {
    let newValue = value;
    if (key === "perc") {
      if (educationDetails.grad.score_type == "CGPA") {
        newValue = Math.max(0, Math.min(10, parseFloat(value)));
      } else {
        newValue = Math.max(0, Math.min(100, parseFloat(value)));
      }
    }

    if (key === "score_type") {
      setEducationDetails({
        ...educationDetails,
        grad: {
          ...educationDetails.grad,
          [key]: newValue,
          perc: 0,
        },
      });
      return;
    }

    setEducationDetails({
      ...educationDetails,
      grad: {
        ...educationDetails.grad,
        [key]: newValue,
      },
    });
  };
  const handlePostGradChange = (key, value) => {
    let newValue = value;
    if (key === "perc") {
      if (educationDetails.post_grad.score_type == "CGPA") {
        newValue = Math.max(0, Math.min(10, parseFloat(value)));
      } else {
        newValue = Math.max(0, Math.min(100, parseFloat(value)));
      }
    }
    if (key === "score_type") {
      setEducationDetails({
        ...educationDetails,
        post_grad: {
          ...educationDetails.post_grad,
          [key]: newValue,
          perc: 0,
        },
      });
      return;
    }
    setEducationDetails({
      ...educationDetails,
      post_grad: {
        ...educationDetails.post_grad,
        [key]: newValue,
      },
    });
  };

  return (
    <>
      <div className="group-sidebar">
        <Link to="/dashboard">
          <div
            className="logo-container center-all "
            style={{
              backgroundColor: `${
                props.brand && props.brand.whiteBackground ? "white" : ""
              }`,
            }}
          >
            {!props.is_data_loading && (
              <img
                src={
                  props.brand
                    ? props.brand.pic
                    : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                }
                className="side-bar__logo-img"
              />
            )}
          </div>
        </Link>
        {/* <Link to="/dashboard"> */}
        <p className="back-btn" onClick={redirectToCourse}>
          <img className="back-img" src={small_left}></img>Back
        </p>
        {/* </Link> */}
        <div>
          <p className="grp-heading">Placement Profile</p>
          <Link
            to={`/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Profile Preview</p>
            </div>
          </Link>
          <Link
            // to={"#"}
            to={`/placement-module/basic-details/?crs_pg_id=${crs_pg_id}`}
            // onClick={(e) => {
            //   e.preventDefault();
            // }}
            // style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Basic Details</p>
            </div>
          </Link>
          <Link
            to={`/placement-module/education-details/?crs_pg_id=${crs_pg_id}`}
          >
            <div className={"g-name-wrapper active"}>
              <p className={"g-name"}>Education Details</p>
            </div>
          </Link>
          <Link
            to={"#"}
            // to={`/placement-module/professional-details/?crs_pg_id=${crs_pg_id}`}
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Professional Details</p>
            </div>
          </Link>
          <Link
            to={
              "#"
              // `/placement-module/resume-details/?crs_pg_id=${crs_pg_id}`
            }
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Resume</p>
            </div>
          </Link>
          {/* <Link
            to={`/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Profile Preview</p>
            </div>
          </Link> */}
        </div>
      </div>
      {!loading ? (
        <>
          <div className="group-db-content-wrapper-2">
            <div className="container-fluid dashboard-content">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row justify-content-left profile-bar">
                    <h5 className="profile-bar-heading col-md-12">
                      Tell us a little more about yourself
                    </h5>
                    <div className="profile-bar-detail col-md-12">
                      <p>Profile Progress</p>
                      <div
                        className="row"
                        style={{ height: "100%", marginLeft: "4px" }}
                      >
                        <div className="profile-bar-progress col-md-10">
                          <div
                            className="profile-bar-completed"
                            style={{ width: `${percCompleted}%` }}
                          ></div>
                        </div>
                        <div className="col-md-2" style={{ marginTop: "-5px" }}>
                          {percCompleted}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-db-content-wrapper-2">
            <div className="container-fluid ">
              <h4 className="profile-setting-container-3">Education Details</h4>
            </div>
          </div>
          <div className="group-db-content-wrapper">
            <div className="container-fluid dashboard-content-2">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="justify-content-center">
                    <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                      <div className="edit-window-profile-details">
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Class X
                            </h5>
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              School Name
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <Input
                                type="text"
                                name="text"
                                id="name"
                                value={educationDetails.matric.school}
                                onChange={(e) =>
                                  handleMatricChange("school", e.target.value)
                                }
                                style={{ width: "98%" }}
                                // className="profile-box"
                              />
                              {errorMessageMatricSchool != "" && (
                                <FormText color="danger">
                                  {errorMessageMatricSchool}
                                </FormText>
                              )}
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              School Board
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              {/* <Input
                            type="text"
                            name="text"
                            id="name"
                            value={educationDetails.matric.board}
                            onChange={(e) =>
                              handleMatricChange("board", e.target.value)
                            }
                            style={{ width: "98%" }}
                            // className="profile-box"
                          /> */}
                              <div style={{ display: "flex" }}>
                                <select
                                  style={{ width: "98%" }}
                                  className="form-control"
                                  onChange={(e) =>
                                    handleMatricChange("board", e.target.value)
                                  }
                                  value={educationDetails.matric.board}
                                  // disabled={gender != ""}
                                >
                                  {BOARD_DROPDOWN.map((cc, i) => {
                                    return (
                                      <option
                                        // key={cc.code + String(i)}

                                        value={cc.value}
                                      >
                                        {cc.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errorMessageMatricBoard != "" && (
                                <FormText color="danger">
                                  {errorMessageMatricBoard}
                                </FormText>
                              )}
                            </FormGroup>
                            {/* <p>opportunity id already exist</p> */}
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Scoring Method
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <div
                                style={{ display: "flex", marginTop: "1rem" }}
                              >
                                <label style={{ marginRight: "1rem" }}>
                                  <input
                                    type="radio"
                                    name="score_type"
                                    value="CGPA"
                                    onChange={(e) =>
                                      handleMatricChange(
                                        "score_type",
                                        e.target.value
                                      )
                                    }
                                    checked={
                                      educationDetails.matric.score_type
                                        ? educationDetails.matric.score_type ===
                                          "CGPA"
                                        : false
                                    }
                                  />
                                  <span style={{ marginLeft: "8px" }}>
                                    CGPA
                                  </span>
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="score_type"
                                    value="Percentage"
                                    onChange={(e) =>
                                      handleMatricChange(
                                        "score_type",
                                        e.target.value
                                      )
                                    }
                                    checked={
                                      educationDetails.matric.score_type
                                        ? educationDetails.matric.score_type ===
                                          "Percentage"
                                        : false
                                    }
                                  />
                                  <span style={{ marginLeft: "8px" }}>
                                    Percentage
                                  </span>
                                </label>
                              </div>
                              {/* {errorMessageMatricYear != "" && (
                                <FormText color="danger">
                                  {errorMessageMatricYear}
                                </FormText>
                              )} */}
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading "
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Grade obtained
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <Input
                                type="number"
                                name="text"
                                id="courseShortCode"
                                max="100"
                                min="0"
                                value={educationDetails.matric.perc}
                                onChange={(e) =>
                                  handleMatricChange("perc", e.target.value)
                                }
                                style={{ width: "98%" }}
                                className={
                                  educationDetails.matric.perc != ""
                                    ? parseInt(educationDetails.matric.perc)
                                      ? ""
                                      : "invalid"
                                    : ""
                                }
                              />
                              {errorMessageMatricPerc != "" && (
                                <FormText color="danger">
                                  {errorMessageMatricPerc}
                                </FormText>
                              )}
                            </FormGroup>
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Select Passing Year
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <div style={{ display: "flex" }}>
                                <select
                                  style={{ width: "98%" }}
                                  className="form-control"
                                  value={educationDetails.matric.year}
                                  onChange={(e) =>
                                    handleMatricChange("year", e.target.value)
                                  }
                                  // disabled={this.state.isVerify}
                                >
                                  {PASSING_YEAR.map((cc, i) => {
                                    return (
                                      <option
                                        // key={cc.code + String(i)}
                                        // disabled={cc.value == "_"}
                                        value={cc.value}
                                      >
                                        {cc.value}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errorMessageMatricYear != "" && (
                                <FormText color="danger">
                                  {errorMessageMatricYear}
                                </FormText>
                              )}
                            </FormGroup>
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid dashboard-content-2">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="justify-content-center">
                    <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                      <div className="edit-window-profile-details">
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Class XII
                            </h5>
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              School Name
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <Input
                                type="text"
                                name="text"
                                id="name"
                                value={educationDetails.inter.school}
                                onChange={(e) =>
                                  handleInterChange("school", e.target.value)
                                }
                                style={{ width: "98%" }}
                                // className="profile-box"
                              />
                              {errorMessageInterSchool != "" && (
                                <FormText color="danger">
                                  {errorMessageInterSchool}
                                </FormText>
                              )}
                            </FormGroup>
                            {/* <p>opportunity id already exist</p> */}
                          </div>
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              School Board
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <div style={{ display: "flex" }}>
                                <select
                                  style={{ width: "98%" }}
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInterChange("board", e.target.value)
                                  }
                                  value={educationDetails.inter.board}
                                  // disabled={gender != ""}
                                >
                                  {BOARD_DROPDOWN.map((cc, i) => {
                                    return (
                                      <option
                                        // key={cc.code + String(i)}

                                        value={cc.value}
                                      >
                                        {cc.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errorMessageInterBoard != "" && (
                                <FormText color="danger">
                                  {errorMessageInterBoard}
                                </FormText>
                              )}
                            </FormGroup>
                            {/* <p>opportunity id already exist</p> */}
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Scoring Method
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <div
                                style={{ display: "flex", marginTop: "1rem" }}
                              >
                                <label style={{ marginRight: "1rem" }}>
                                  <input
                                    type="radio"
                                    name="score_type_inter"
                                    value="CGPA"
                                    onChange={(e) =>
                                      handleInterChange(
                                        "score_type",
                                        e.target.value
                                      )
                                    }
                                    checked={
                                      educationDetails.inter.score_type ===
                                      "CGPA"
                                    }
                                  />
                                  <span style={{ marginLeft: "8px" }}>
                                    CGPA
                                  </span>
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="score_type_inter"
                                    value="Percentage"
                                    onChange={(e) =>
                                      handleInterChange(
                                        "score_type",
                                        e.target.value
                                      )
                                    }
                                    checked={
                                      educationDetails.inter.score_type ===
                                      "Percentage"
                                    }
                                  />
                                  <span style={{ marginLeft: "8px" }}>
                                    Percentage
                                  </span>
                                </label>
                              </div>
                              {/* {errorMessageMatricYear != "" && (
                                <FormText color="danger">
                                  {errorMessageMatricYear}
                                </FormText>
                              )} */}
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Grade obtained
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <Input
                                type="number"
                                name="text"
                                id="courseShortCode"
                                max="100"
                                min="0"
                                value={educationDetails.inter.perc}
                                onChange={(e) =>
                                  handleInterChange("perc", e.target.value)
                                }
                                style={{ width: "98%" }}
                                className={
                                  educationDetails.inter.perc != ""
                                    ? parseInt(educationDetails.inter.perc)
                                      ? ""
                                      : "invalid"
                                    : ""
                                }
                                // disabled={companyName !== ""}
                              />
                              {errorMessageInterPerc != "" && (
                                <FormText color="danger">
                                  {errorMessageInterPerc}
                                </FormText>
                              )}
                            </FormGroup>
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Select Passing Year
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <div style={{ display: "flex" }}>
                                <select
                                  style={{ width: "98%" }}
                                  className="form-control"
                                  value={educationDetails.inter.year}
                                  onChange={(e) =>
                                    handleInterChange("year", e.target.value)
                                  }
                                  // disabled={this.state.isVerify}
                                >
                                  {PASSING_YEAR.map((cc, i) => {
                                    return (
                                      <option
                                        // key={cc.code + String(i)}
                                        // disabled={cc.value == "_"}
                                        value={cc.value}
                                      >
                                        {cc.value}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errorMessageInterYear != "" && (
                                <FormText color="danger">
                                  {errorMessageInterYear}
                                </FormText>
                              )}
                            </FormGroup>
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid dashboard-content-2">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="justify-content-center">
                    <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                      <div className="edit-window-profile-details">
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Graduation/Diploma
                            </h5>
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              College/University Name
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <Input
                                type="text"
                                name="text"
                                id="name"
                                value={educationDetails.grad.college}
                                onChange={(e) =>
                                  handleGradChange("college", e.target.value)
                                }
                                style={{ width: "98%" }}
                                // className="profile-box"
                              />
                              {errorMessageGradCollege != "" && (
                                <FormText color="danger">
                                  {errorMessageGradCollege}
                                </FormText>
                              )}
                            </FormGroup>
                            {/* <p>opportunity id already exist</p> */}
                          </div>
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Degree/Diploma Name
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <div style={{ display: "flex" }}>
                                <select
                                  style={{ width: "98%" }}
                                  className="form-control"
                                  onChange={(e) =>
                                    handleGradChange("degree", e.target.value)
                                  }
                                  value={educationDetails.grad.degree}
                                  // disabled={gender != ""}
                                >
                                  {BACHELORS_DROPDOWN.map((cc, i) => {
                                    return (
                                      <option
                                        // key={cc.code + String(i)}

                                        value={cc.value}
                                      >
                                        {cc.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errorMessageGradDeg != "" && (
                                <FormText color="danger">
                                  {errorMessageGradDeg}
                                </FormText>
                              )}
                            </FormGroup>
                            {/* <p>opportunity id already exist</p> */}
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Scoring Method
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <div
                                style={{ display: "flex", marginTop: "1rem" }}
                              >
                                <label style={{ marginRight: "1rem" }}>
                                  <input
                                    type="radio"
                                    name="score_type_grad"
                                    value="CGPA"
                                    onChange={(e) =>
                                      handleGradChange(
                                        "score_type",
                                        e.target.value
                                      )
                                    }
                                    checked={
                                      educationDetails.grad.score_type ===
                                      "CGPA"
                                    }
                                  />
                                  <span style={{ marginLeft: "8px" }}>
                                    CGPA
                                  </span>
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="score_type_grad"
                                    value="Percentage"
                                    onChange={(e) =>
                                      handleGradChange(
                                        "score_type",
                                        e.target.value
                                      )
                                    }
                                    checked={
                                      educationDetails.grad.score_type ===
                                      "Percentage"
                                    }
                                  />
                                  <span style={{ marginLeft: "8px" }}>
                                    Percentage
                                  </span>
                                </label>
                              </div>
                              {/* {errorMessageMatricYear != "" && (
                                <FormText color="danger">
                                  {errorMessageMatricYear}
                                </FormText>
                              )} */}
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Grade obtained
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <Input
                                type="number"
                                name="text"
                                id="courseShortCode"
                                max="100"
                                min="0"
                                value={educationDetails.grad.perc}
                                onChange={(e) =>
                                  handleGradChange("perc", e.target.value)
                                }
                                style={{ width: "98%" }}
                                className={
                                  educationDetails.grad.perc != ""
                                    ? parseInt(educationDetails.grad.perc)
                                      ? ""
                                      : "invalid"
                                    : ""
                                }
                                // disabled={companyName !== ""}
                              />
                              {errorMessageGradPerc != "" && (
                                <FormText color="danger">
                                  {errorMessageGradPerc}
                                </FormText>
                              )}
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Select Passing Year
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            <FormGroup>
                              <div style={{ display: "flex" }}>
                                <select
                                  style={{ width: "98%" }}
                                  className="form-control"
                                  value={educationDetails.grad.year}
                                  onChange={(e) =>
                                    handleGradChange("year", e.target.value)
                                  }
                                  // disabled={this.state.isVerify}
                                >
                                  {PASSING_YEAR.map((cc, i) => {
                                    return (
                                      <option
                                        // key={cc.code + String(i)}
                                        // disabled={cc.value == "_"}
                                        value={cc.value}
                                      >
                                        {cc.value}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errorMessageGradYear != "" && (
                                <FormText color="danger">
                                  {errorMessageGradYear}
                                </FormText>
                              )}
                            </FormGroup>
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-12">
                            {!isPG && (
                              <button
                                className="addques-newoption row"
                                onClick={handleAddingmastersDetails}
                                disabled={isPG}
                              >
                                <span className="">+</span>
                                <span className="">
                                  Add Post-Graduation/Masters
                                </span>
                              </button>
                            )}
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isPG && (
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="justify-content-center">
                      <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Post-Graduation/Masters
                              </h5>
                            </div>
                            <div style={{ width: "50%" }}>
                              <div
                                className="as-dl-sidebar-lg link"
                                onClick={() => handleRemoveMastersDetails()}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: "6px",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src="https://webcdn.imarticus.org/fsd-pro/Group938.svg"
                                  className="as-dl-logo-job"
                                  alt="Delete Post Grad"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                College/University Name
                                <span
                                  style={{ color: "red", fontSize: "18px" }}
                                >
                                  *
                                </span>
                              </h5>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="text"
                                  id="name"
                                  value={educationDetails.post_grad.college}
                                  onChange={(e) =>
                                    handlePostGradChange(
                                      "college",
                                      e.target.value
                                    )
                                  }
                                  style={{ width: "98%" }}
                                  // className="profile-box"
                                />
                                {errorMessagePostGradCollege != "" && (
                                  <FormText color="danger">
                                    {errorMessagePostGradCollege}
                                  </FormText>
                                )}
                              </FormGroup>
                              {/* <p>opportunity id already exist</p> */}
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Degree/Diploma Name
                                <span
                                  style={{ color: "red", fontSize: "18px" }}
                                >
                                  *
                                </span>
                              </h5>
                              <FormGroup>
                                <div style={{ display: "flex" }}>
                                  <select
                                    style={{ width: "98%" }}
                                    className="form-control"
                                    onChange={(e) =>
                                      handlePostGradChange(
                                        "degree",
                                        e.target.value
                                      )
                                    }
                                    value={educationDetails.post_grad.degree}
                                    // disabled={gender != ""}
                                  >
                                    {MASTERS_DROPDOWN.map((cc, i) => {
                                      return (
                                        <option
                                          // key={cc.code + String(i)}

                                          value={cc.value}
                                        >
                                          {cc.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errorMessagePostGradDeg != "" && (
                                  <FormText color="danger">
                                    {errorMessagePostGradDeg}
                                  </FormText>
                                )}
                              </FormGroup>
                              {/* <p>opportunity id already exist</p> */}
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Scoring Method
                                <span
                                  style={{ color: "red", fontSize: "18px" }}
                                >
                                  *
                                </span>
                              </h5>
                              <FormGroup>
                                <div
                                  style={{ display: "flex", marginTop: "1rem" }}
                                >
                                  <label style={{ marginRight: "1rem" }}>
                                    <input
                                      type="radio"
                                      name="score_type_post_grad"
                                      value="CGPA"
                                      onChange={(e) =>
                                        handlePostGradChange(
                                          "score_type",
                                          e.target.value
                                        )
                                      }
                                      checked={
                                        educationDetails.post_grad
                                          .score_type === "CGPA"
                                      }
                                    />
                                    <span style={{ marginLeft: "8px" }}>
                                      CGPA
                                    </span>
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      name="score_type_post_grad"
                                      value="Percentage"
                                      onChange={(e) =>
                                        handlePostGradChange(
                                          "score_type",
                                          e.target.value
                                        )
                                      }
                                      checked={
                                        educationDetails.post_grad
                                          .score_type === "Percentage"
                                      }
                                    />
                                    <span style={{ marginLeft: "8px" }}>
                                      Percentage
                                    </span>
                                  </label>
                                </div>
                                {/* {errorMessageMatricYear != "" && (
                                  <FormText color="danger">
                                    {errorMessageMatricYear}
                                  </FormText>
                                )} */}
                              </FormGroup>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Grade obtained
                                <span
                                  style={{ color: "red", fontSize: "18px" }}
                                >
                                  *
                                </span>
                              </h5>
                              <FormGroup>
                                <Input
                                  type="number"
                                  name="text"
                                  id="courseShortCode"
                                  max="100"
                                  min="0"
                                  value={educationDetails.post_grad.perc}
                                  onChange={(e) =>
                                    handlePostGradChange("perc", e.target.value)
                                  }
                                  style={{ width: "98%" }}
                                  className={
                                    educationDetails.post_grad.perc != ""
                                      ? parseInt(
                                          educationDetails.post_grad.perc
                                        )
                                        ? ""
                                        : "invalid"
                                      : ""
                                  }
                                  // disabled={companyName !== ""}
                                />
                                {errorMessagePostGradPerc != "" && (
                                  <FormText color="danger">
                                    {errorMessagePostGradPerc}
                                  </FormText>
                                )}
                              </FormGroup>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Select Passing Year
                                <span
                                  style={{ color: "red", fontSize: "18px" }}
                                >
                                  *
                                </span>
                              </h5>
                              <FormGroup>
                                <div style={{ display: "flex" }}>
                                  <select
                                    style={{ width: "98%" }}
                                    className="form-control"
                                    value={educationDetails.post_grad.year}
                                    onChange={(e) =>
                                      handlePostGradChange(
                                        "year",
                                        e.target.value
                                      )
                                    }
                                    // disabled={this.state.isVerify}
                                  >
                                    {PASSING_YEAR.map((cc, i) => {
                                      return (
                                        <option
                                          // key={cc.code + String(i)}
                                          // disabled={cc.value == "_"}
                                          value={cc.value}
                                        >
                                          {cc.value}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errorMessagePostGradYear != "" && (
                                  <FormText color="danger">
                                    {errorMessagePostGradYear}
                                  </FormText>
                                )}
                              </FormGroup>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="container-fluid dashboard-content-2">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 profile-next-btn">
                  <div className="addcrit-footer">
                    <Row className="adques-footer">
                      <Col md={2}>
                        <Link
                          to={`/placement-module/basic-details/?crs_pg_id=${crs_pg_id}`}
                        >
                          <Button className="jobs-table-btn-5">
                            {"<"} Previous
                          </Button>
                        </Link>
                      </Col>
                      <Col md={3}></Col>
                      <Col md={1}></Col>
                      <Col md={2}></Col>
                      <Col md={2}>
                        <Link
                          to={`/placement-module/professional-details/?crs_pg_id=${crs_pg_id}`}
                        >
                          <Button className="labs-table-btn-2">
                            Skip & Next {">"}
                          </Button>
                        </Link>
                      </Col>
                      <Col md={2}>
                        <Button
                          className="jobs-table-btn-5"
                          // onClick={() => saveEducationalDetails()}
                          onClick={() => handleSaveEducationDetails()}
                        >
                          Save & Next
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={openSuccessModal}
            size={"xl"}
            className="apply-sucess-modal"
            centered
          >
            <ModalBody style={{ maxHeight: "800px", overflowY: "scroll" }}>
              <div>
                <p className="aply-success-txt ">Thank you for submitting!</p>
                <p className="prof-dtl-txt">Your responses have been saved.</p>
              </div>
              <div
                className="apply-success-div"
                style={{ justifyContent: "space-evenly" }}
              >
                <Button
                  className="apply-danger-btn"
                  onClick={() => {
                    setOpenSuccessModal(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  // color="success"
                  onClick={() => {
                    setOpenSuccessModal(false);
                    redirectToCourse();
                  }}
                  className="apply-success-btn-1"
                >
                  Go to My Course
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  profile: state.profile,
  course: state.course.courses[0],
});

const mapDispatchToProps = { getProfileInfo, setCurrentTab };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EducationDetails2)
);
