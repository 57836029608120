import React, { Component } from "react";
import { Input } from "reactstrap";
import backArrow from "../../images/backArrow.svg";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CountryCodes from "../../constants/countryCodes";
import { ROUTES } from "../../constants/routes";
import Axios from "axios";
import SetPassword from "./SetPassword";
import { utils } from "../../utility/utils";
import Spinner from "../utils/Spinner";
import Loader from "../utils/Loader";
import Cookies from "universal-cookie";
import { CONFIGS } from "../../constants/constants";
import { dispatchIndividualBrand } from "../../actions/brandAction";
const cookies = new Cookies();

let errorMessages = {
  incorrectNumber:
    "No account is linked with this number. Kindly try another method.",
  incorrectEmail:
    "No account is linked with this mail Id. Kindly try another method.",
  invalidOTP: "Invalid OTP",
  invalidEmail: "Invalid Email ID, kindly check it again.",
  invalidNumber: "Invalid Mobile Number, kindly check it again.",
  bruteforce: "Try again later, you have entered too many wrong otps.",
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    let query = new URLSearchParams(this.props.location.search);
    let resetType = query.get("type") == "mobile" ? query.get("type") : "email";

    window.FreshworksWidget?.("hide");
    let brand = props.match.params.brand;
    console.log({ brand });
    // if (utils.getLoggedIn()) {
    //   if (brand) {
    //     this.props.history.push(`/${brand}/dashboard`);
    //   } else {
    //     this.props.history.push("/dashboard");
    //   }
    // }
    if (brand) {
      this.state = {
        email: query.get("email") != undefined ? query.get("email") : "",
        mobile: query.get("m") != undefined ? query.get("m") : "",
        ccod: query.get("ccod") != undefined ? query.get("ccod") : "91",
        isEmail: resetType == "email",
        incorrectEmail: false,
        incorrectNumber: false,
        invalidOTP: false,
        endTime: 5 * 60,
        otp: "",
        isVerify: false,
        isSetPassWord: false,
        isLoading: true,
        invalidEmail: false,
        invalidNumber: false,
      };
      Axios.get(ROUTES.FETCH_BRAND_DATA + `?brand_id=${brand}`)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          this.props.dispatchIndividualBrand(brandData);
          if (
            res.data.success &&
            (this.state.isEmail ||
              (!this.state.isEmail &&
                res.data?.data?.brand?.mobileAllowed == true))
          ) {
            console.log({ brandData });
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          } else {
            this.props.history.push(`/login`);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log({ err });
          this.props.history.push("/login");
          window.location.reload();
        });
      window.FreshworksWidget?.("hide");
      // if (utils.getLoggedIn()) this.props.history.push(`/${brand}/dashboard`);
    } else {
      if (cookies.get("brand")) {
        cookies.remove("brand", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
      }
      this.state = {
        email: query.get("email") != undefined ? query.get("email") : "",
        mobile: query.get("m") != undefined ? query.get("m") : "",
        ccod: query.get("ccod") != undefined ? query.get("ccod") : "91",
        isEmail: resetType == "email",
        incorrectEmail: false,
        incorrectNumber: false,
        invalidOTP: false,
        endTime: 5 * 60,
        otp: "",
        isVerify: false,
        isSetPassWord: false,
        isLoading: false,
        invalidEmail: false,
        invalidNumber: false,
      };
      this.setState({ isLoading: true });
      Axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          console.log({ brandData });
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success) {
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          }
        })
        .catch((err) => {
          console.log({ err });
          this.setState({ isLoading: false });
        });
      window.FreshworksWidget?.("hide");
      // if (utils.getLoggedIn()) this.props.history.push("/dashboard");
    }
  }

  emailMobileSwitch = () => {
    this.setState({
      isEmail: !this.state.isEmail,
      mobile: "",
      email: "",
      incorrectEmail: false,
      incorrectNumber: false,
      invalidOTP: false,
      invalidEmail: false,
      invalidNumber: false,
    });
  };

  resendMobileOTP = () => {
    if (this.state.endTime > 60 || this.state.isEmail) return;
    console.log("sent mobile otp");
    this.RequestOTPForMobile();
    this.setState({
      endTime: 5 * 60,
    });
  };

  resendEmailOTP = () => {
    if (this.state.endTime > 60 || !this.state.isEmail) return;
    console.log("sent email otp");
    this.RequestOTPForEmail();
    this.setState({
      endTime: 5 * 60,
    });
  };

  validateEmail = (email) => {
    return (
      email.length <= 50 &&
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  };

  validateMobile = (mobile) => {
    return mobile.length < 16 && mobile.length > 5;
    return mobile.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
  };

  validateOTP = () => {
    return this.state.aid && this.state.otp.length == 6;
  };

  formValidation = () => {
    return this.state.isVerify
      ? this.validateOTP()
      : this.state.isEmail
      ? this.validateEmail(this.state.email)
      : this.validateMobile(this.state.mobile);
  };

  OTPChange = (e) => {
    let otp = e.target.value;
    let regExp = new RegExp("^[0-9]*$");
    if (otp.length > 0 && !regExp.test(otp)) return;
    if (otp.length > 6) return;
    this.setState({
      otp,
      invalidOTP: false,
    });
  };

  emailChange = (e) => {
    let email = e.target.value;
    if (email.length > 50) return;
    this.setState({
      email,
      incorrectEmail: false,
      invalidEmail: false,
    });
  };

  ccodChange = (e) => {
    let ccod = e.target.value;
    this.setState({
      ccod,
      incorrectNumber: false,
      invalidNumber: false,
    });
  };

  mobileChange = (e) => {
    let mobile = e.target.value;
    if (mobile.length > 15) return;
    let regExp = new RegExp("^[0-9]*$");
    if (mobile != "" && !regExp.test(mobile)) {
      return;
    }
    this.setState({
      mobile,
      incorrectNumber: false,
      invalidNumber: false,
    });
  };

  getOTPTimeRemaining = () => {
    setTimeout(() => {
      this.setState({
        endTime: this.state.endTime > 0 ? this.state.endTime - 1 : 0,
      });
      this.getOTPTimeRemaining();
    }, 1000);
  };

  RequestOTPForEmail = () => {
    let email = this.state.email;

    if (!this.validateEmail(email)) return;
    let dataObj = {
      email,
    };
    this.setState({ isLoading: true });
    Axios.post(ROUTES.REQUEST_EMAIL_OTP, dataObj)
      .then((res) => {
        console.log(res.data.data);
        this.getOTPTimeRemaining();
        this.setState({
          aid: res.data.data.aid,
          isVerify: true,
          isLoading: false,

          invalidEmail: false,
          invalidNumber: false,
          incorrectEmail: false,
          incorrectNumber: false,
        });
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 100) {
          this.setState({
            incorrectEmail: true,
            incorrectNumber: false,
            invalidOTP: false,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: false,
            bruteforce: false,
          });
        } else if (ecode == 207000) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP: false,
            isLoading: false,
            invalidEmail: true,
            invalidNumber: false,
            bruteforce: false,
          });
        } else if (
          err.response.data.message ||
          err.response.data.data?.message
        ) {
          this.setState({
            customError:
              err.response.data.message || err.response.data.data?.message,
            isLoading: false,
          });
        }
      });
  };

  RequestOTPForMobile = () => {
    let mobile = this.state.mobile;
    let c_code = this.state.ccod;

    if (!this.validateMobile(mobile)) return;
    let dataObj = {
      mobile,
      c_code,
    };
    this.setState({ isLoading: true });
    Axios.post(ROUTES.REQUEST_MOBILE_OTP, dataObj)
      .then((res) => {
        console.log(res.data);
        this.getOTPTimeRemaining();
        this.setState({
          aid: res.data.data.aid,
          isVerify: true,
          isLoading: false,
          invalidEmail: false,
          invalidNumber: false,
          incorrectEmail: false,
          incorrectNumber: false,
        });
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 100) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: true,
            invalidOTP: false,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: false,
          });
        } else if (ecode == 206000) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP: false,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: true,
          });
        }
      });
  };

  verifyOTPs = () => {
    let dataObj = {
      aid: this.state.aid,
    };

    if (this.state.isEmail) {
      dataObj.e_otp = this.state.otp;
    } else {
      dataObj.m_otp = this.state.otp;
    }

    console.log(dataObj);
    this.setState({ isLoading: true });
    Axios.post(ROUTES.VERIFY_OTPS, dataObj)
      .then((res) => {
        this.setState({
          cookiesToSet: res.data.data,
        });

        this.setState({
          isSetPassWord: true,
          isLoading: false,
        });
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (
          ecode == 300 ||
          ecode == 301 ||
          ecode == 302 ||
          ecode == 206000 ||
          ecode == 207000
        ) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP: true,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: false,
            bruteforce: false,
          });
        } else if (ecode == 6100) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP: false,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: false,
            bruteforce: true,
          });
        }
      });
  };

  render() {
    return this.state.isLoading ? (
      <div className="loader loader-center">
        <div className="loader-msg-container">
          <Loader brand={this.state.brand} />
        </div>
      </div>
    ) : (
      <div
        className="auth-main"
        style={
          this.state.brand
            ? {
                background: `url(${this.state.brand.bgimg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }
            : {}
        }
      >
        <div
          className="auth-block"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            minHeight: "85%",
            justifyContent: "flex-start",
            overflow: "hidden",
          }}
        >
          <img
            className="auth-logo"
            width={200}
            height={100}
            alt="logo"
            src={
              this.state.brand
                ? this.state.brand.pic
                : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
            }
          ></img>

          {this.state.isSetPassWord ? (
            <SetPassword
              aid={this.state.aid}
              backRoute={
                (this.state.brand ? `/${this.state.brand.utag}` : "") +
                `/forgot?type=${
                  this.state.isEmail
                    ? `email&email=${this.state.email}`
                    : `mobile&m=${this.state.mobile}&ccod=${this.state.ccod}`
                }`
              }
              cookiesToSet={this.state.cookiesToSet}
            />
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <Link
                  to={
                    (this.state.brand ? `/${this.state.brand.utag}` : "") +
                    `/login/${this.state.isEmail ? "email" : "mobile"}`
                  }
                  className="reset-back"
                  style={{ cursor: "pointer", marginRight: "8px" }}
                >
                  <img src={backArrow} style={{ marginRight: "8px" }}></img>
                </Link>
                <div className="auth-h2">Reset Password</div>
              </div>
              <br />
              {this.state.isEmail ? (
                <div className="auth-box">
                  <div className="auth-ttl">Enter Email Id</div>
                  <div className="auth-container">
                    <Input
                      type="email"
                      name="email"
                      className="auth-inp"
                      onChange={this.emailChange}
                      value={this.state.email}
                      disabled={this.state.isVerify}
                    ></Input>
                    <div
                      className={`line ${
                        this.validateEmail(this.state.email) &&
                        !this.state.incorrectEmail
                          ? "lgreen"
                          : "lred"
                      }`}
                    ></div>
                  </div>
                  {this.state.incorrectEmail && (
                    <div className="auth-error">
                      {errorMessages["incorrectEmail"]}
                    </div>
                  )}
                  {this.state.invalidEmail && (
                    <div className="auth-error">
                      {errorMessages["invalidEmail"]}
                    </div>
                  )}
                  {this.state.customError && (
                    <div className="auth-error">{this.state.customError}</div>
                  )}
                </div>
              ) : (
                <div className="auth-box">
                  <div className="auth-ttl">Enter Mobile Number</div>
                  <div className="auth-container">
                    <div style={{ display: "flex" }}>
                      <select
                        style={{ width: "30%" }}
                        className="auth-inp dropdown"
                        onChange={this.ccodChange}
                        value={this.state.ccod}
                        disabled={this.state.isVerify}
                      >
                        {CountryCodes.map((cc, i) => {
                          return (
                            <option
                              key={cc.code + String(i)}
                              disabled={cc.value == "_"}
                              value={cc.value}
                            >
                              {cc.display}
                            </option>
                          );
                        })}
                      </select>
                      <input
                        type="tel"
                        name="tel"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        style={{ width: "70%" }}
                        className="auth-inp mobile"
                        onChange={this.mobileChange}
                        value={this.state.mobile}
                        disabled={this.state.isVerify}
                      ></input>
                    </div>
                    <div
                      className={`line ${
                        this.validateMobile(this.state.mobile)
                          ? "lgreen"
                          : "lred"
                      }`}
                    ></div>
                  </div>
                  {this.state.incorrectNumber && (
                    <div className="auth-error">
                      {errorMessages["incorrectNumber"]}
                    </div>
                  )}
                  {this.state.customError && (
                    <div className="auth-error">{this.state.customError}</div>
                  )}
                  {this.state.invalidNumber && (
                    <div className="auth-error">
                      {errorMessages["invalidNumber"]}
                    </div>
                  )}
                </div>
              )}
              {this.state.isVerify && (
                <div className="auth-box">
                  <div className="auth-ttl">
                    Enter OTP sent on{" "}
                    <p style={{ display: "inline" }} className="green">
                      {this.state.isEmail
                        ? this.state.email
                        : this.state.mobile}
                    </p>
                  </div>
                  <div className="auth-container">
                    <Input
                      type="text"
                      className="auth-inp"
                      onChange={this.OTPChange}
                      value={this.state.otp}
                    ></Input>
                    <div
                      className={`line ${
                        this.validateOTP() ? "lgreen" : "lred"
                      }`}
                    ></div>
                    {this.state.invalidOTP && (
                      <div className="auth-error">
                        {errorMessages["invalidOTP"]}
                      </div>
                    )}
                    {this.state.customError && (
                      <div className="auth-error">{this.state.customError}</div>
                    )}
                  </div>

                  <div className="resend-otp-block">
                    {this.state.endTime < 60 ? (
                      <div
                        style={{ cursor: "pointer" }}
                        className="auth-txt"
                        onClick={
                          this.state.isEmail
                            ? this.resendEmailOTP
                            : this.resendMobileOTP
                        }
                      >
                        Resend OTP
                      </div>
                    ) : (
                      <div className="auth-txt"></div>
                    )}
                    <div className="auth-timer">
                      {`Remaining Time: ${Math.floor(
                        this.state.endTime / 60
                      )}:${("0" + String(this.state.endTime % 60)).slice(-2)}`}
                    </div>
                  </div>
                </div>
              )}

              <div
                className={`${this.formValidation() ? "" : "disable"}  ${
                  this.state.brand && this.state.brand.themeColorIndex
                    ? "auth-btn-brand"
                    : "auth-btn"
                }`}
                onClick={
                  this.formValidation()
                    ? this.state.isVerify
                      ? this.verifyOTPs
                      : this.state.isEmail
                      ? this.RequestOTPForEmail
                      : this.RequestOTPForMobile
                    : undefined
                }
                style={{
                  background: `${
                    this.state.brand &&
                    this.state.brand.themeColorIndex &&
                    this.state.brand.themeColorIndex
                  }`,
                }}
              >
                {this.state.isVerify ? "Verify & Set Password" : "Send OTP"}
              </div>
              {this.state.bruteforce && (
                <div className="auth-error">{errorMessages["bruteforce"]}</div>
              )}
              <div>
                {!this.state.isVerify && (
                  <div
                    className="auth-ttl green right"
                    onClick={this.emailMobileSwitch}
                    style={{
                      cursor: "pointer",
                      margin: "12px",
                      color: `${
                        this.state.brand &&
                        this.state.brand.themeColorIndex &&
                        this.state.brand.themeColorIndex
                      }`,
                    }}
                  >
                    Reset with{" "}
                    {!this.state.isEmail ? "Email " : "Mobile number"}
                  </div>
                )}
              </div>
              {this.state.brand &&
                this.state.brand.poweredByEnabled == true &&
                this.state.brand.hyperlinkEnabled == true && (
                  <div
                    className="powered-by-container"
                    style={{ position: "absolute", bottom: "0px" }}
                  >
                    <a
                      className="powered-by"
                      href={"https://www.imarticus.org"}
                    >
                      Powered By Imarticus Learning
                    </a>
                  </div>
                )}
              {this.state.brand &&
                this.state.brand.poweredByEnabled == true &&
                this.state.brand.hyperlinkEnabled == false && (
                  <div
                    className="powered-by-container"
                    style={{ position: "absolute", bottom: "0px" }}
                  >
                    <div
                      className="powered-by"
                      href={"https://www.imarticus.org"}
                    >
                      Powered By Imarticus Learning
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

// export default ResetPassword;

const mapStateToProps = (state) => ({
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
