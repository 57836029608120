import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import default_dp from "../../images/default-profile-mb.svg";
import { connect } from "react-redux";
import { showMenu, logoutUser } from "../../actions/accountDropdownAction";
import { CONFIGS, ROLES } from "../../constants/constants";
import right_arrow from "../../images/right_arrow.svg";
import { switchUserProfile } from "../../actions/profileAction";
import { resetBackBtn } from "../../actions/utilAction";
import { URLS } from "../../constants/constants.js";
import { utils } from "../../utility/utils";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";

class AccountSettingDropdown extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      switch_profile: true,
      show_all: false,
    };
  }

  showMenu = (event) => {
    event.preventDefault();
    console.log("brandData", this.props.brand);
    this.props
      .showMenu(true)
      .then(
        function (res) {
          document.addEventListener("click", this.closeMenu);
        }.bind(this),
      )
      .catch(function (err) {}.bind(this));
  };

  closeMenu = (event) => {
    if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
      this.props
        .showMenu(false)
        .then(
          function (res) {
            document.removeEventListener("click", this.closeMenu);
          }.bind(this),
        )
        .catch(function (err) {}.bind(this));
    }
  };
  toggleSwitchProfile = () => {
    this.setState({
      switch_profile: !this.state.switch_profile,
    });
  };
  switchUserProfile = (pid) => {
    this.closeMenuExplictly();
    this.props.resetBackBtn();
    // alert(pid)
    if (utils.getPid() != pid) {
      this.props.switchUserProfile({
        p_id: pid,
        profile_map: this.props.profile_map,
        ex_gids: this.props.all_ex_gids,
        profiles: this.props.profiles,
        dispatchIndividualBrand: this.props.dispatchIndividualBrand,
        // pid,
        // this.props.profile_map,
        // this.props.all_ex_gids,
        // this.props.profiles,
      });
    }
  };
  closeMenuExplictly = () => {
    // if (!this.dropdownMenu.contains(event.target)) {
    this.props
      .showMenu(false)
      .then(
        function (res) {
          document.removeEventListener("click", this.closeMenu);
        }.bind(this),
      )
      .catch(function (err) {}.bind(this));
    // }
  };
  openHelpLearning = () => {
    window.open(URLS.HELP_LEARNING_URL, "_blank");
  };
  openFeedback = () => {
    window.location.href = URLS.SEND_FEEDBACK_URL;
  };
  toggleAllProfiles = () => {
    this.setState({
      show_all: !this.state.show_all,
    });
  };

  render() {
    return (
      <Fragment>
        <button className="username-link" onClick={this.showMenu}>
          <p className="user-name">
            {this.props.p_name ? this.props.p_name : ""}
          </p>{" "}
          <i className="fa fa-angle-down bold-angle-down"></i>
        </button>
        <button className="dp" onClick={this.showMenu}>
          <img className="profile-img" src={this.props.ppic}></img>
        </button>
        {this.props.show_menu ? (
          <div
            className="user-menu"
            ref={(element) => {
              this.dropdownMenu = element;
            }}
          >
            {this.state.switch_profile ? (
              <ul className="user-list">
                <li
                  className={
                    this.props.profiles && this.props.profiles.length > 1
                      ? "user-switch-profile"
                      : "hide"
                  }
                  onClick={this.toggleSwitchProfile}
                >
                  Switch Profile
                  <i className="fa fa-angle-right right-arrow"></i>{" "}
                </li>

                <hr className="hr" />
                {(this.props.brand == undefined ||
                  Object.keys(this.props.brand).length == 0 ||
                  this.props.brand.editProfileEnabled == true) && (
                  <Link to="/dashboard/edit">
                    <li
                      className="user-list-item"
                      onClick={this.closeMenuExplictly}
                    >
                      My Profile
                    </li>
                  </Link>
                )}
                {(this.props.brand == undefined ||
                  Object.keys(this.props.brand).length == 0) && (
                  <a href={CONFIGS.MY_APPLICATIONS}>
                    <li
                      className="user-list-item"
                      onClick={this.closeMenuExplictly}
                    >
                      My Applications
                    </li>
                  </a>
                )}
                {(this.props.brand == undefined ||
                  Object.keys(this.props.brand).length == 0) && (
                  <a href={CONFIGS.MY_TRANSACTIONS}>
                    <li
                      className="user-list-item"
                      onClick={this.closeMenuExplictly}
                    >
                      My Transactions
                    </li>
                  </a>
                )}
                {(this.props.brand == undefined ||
                  (this.props.brand &&
                    Object.keys(this.props.brand).length == 0) ||
                  this.props.brand.editProfileEnabled == true) && (
                  <a href={CONFIGS.CHANGE_PASSWORD}>
                    <li
                      className="user-list-item"
                      onClick={this.closeMenuExplictly}
                    >
                      Settings
                    </li>
                  </a>
                )}

                {/* <li className="user-list-item" onClick={this.openFeedback}>Send Feedback</li> */}
                <li
                  className="user-list-item mb-12"
                  onClick={() => {
                    this.props.logoutUser(
                      this.props.brand ? this.props.brand.utag : undefined,
                    );
                  }}
                >
                  Logout
                </li>
              </ul>
            ) : (
              <ul className="user-list">
                <li
                  className="user-add-switch"
                  onClick={this.toggleSwitchProfile}
                >
                  {" "}
                  <i className="fa fa-angle-left"></i>&nbsp; &nbsp; Back
                </li>
                <hr className="hr"></hr>
                {this.props.profiles.map(({ _id, nam, ppic, role }, index) => {
                  if (index > 1 && !this.state.show_all) {
                    return;
                  }
                  return (
                    <li
                      className="user-list-item"
                      onClick={() => this.switchUserProfile(_id)}
                    >
                      <div className="row user-list-row">
                        <div className="col-3 profile-pic-container">
                          {ppic != null && ppic != undefined && ppic != "" ? (
                            <img className="profile-img" src={ppic} />
                          ) : (
                            <img
                              className="profile-img"
                              src={URLS.DEFAULT_PPIC_URL}
                            />
                          )}
                          {/* <img className="profile-img" src="https://sample-videos.com/img/Sample-jpg-image-500kb.jpg" /> */}
                        </div>
                        <div className="col-9 profile-dtls">
                          <p className="profile-name">{nam}</p>
                          {role == ROLES.PARENT && (
                            <p className="profile-role">Parent</p>
                          )}
                          {role == ROLES.TEACHER && (
                            <p className="profile-role">Teacher</p>
                          )}
                          {role == ROLES.STUDENT && (
                            <p className="profile-role">Student</p>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
                {/* <li className="user-list-item">
													<div className="row user-list-row">
														<div className="col-3 profile-pic-container"><img className="profile-img" src="https://sample-videos.com/img/Sample-jpg-image-500kb.jpg" /></div>
														<div className="col-9 profile-dtls">
															<p className="profile-name">Kate Wilson</p>
															<p className="profile-role">Teacher</p>
														</div>
													</div>
												</li>


												<li className="user-list-item">
													<div className="row user-list-row">
														<div className="col-3 profile-pic-container"><img className="profile-img" src="https://sample-videos.com/img/Sample-jpg-image-500kb.jpg" /></div>
														<div className="col-9 profile-dtls">
															<p className="profile-name">James Williamson</p>
															<p className="profile-role">Student</p>
														</div>
													</div>
												</li> */}

                <li
                  className={
                    this.props.profiles.length > 2
                      ? "user-list-item blue-color see-all-profile"
                      : "hide"
                  }
                  onClick={this.toggleAllProfiles}
                >
                  {!this.state.show_all
                    ? "See all profiles"
                    : "See less profiles"}
                </li>
                <hr className="hr" />
                <Link to="/dashboard/profile">
                  <li
                    className="user-add-new"
                    onClick={this.closeMenuExplictly}
                  >
                    + Add New Profile
                  </li>
                </Link>
              </ul>
            )}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  show_menu: state.ac_dd.show_menu,
  profiles: state.profile.profiles,
  p_name: state.profile.p_name,
  ppic: state.profile.ppic,
  profile_map: state.profile.profile_map,
  all_ex_gids: state.explore.all_ex_gids,
  brand: state.course.brand || state.course.individualBrand,
});

const mapDispatchToProps = {
  showMenu,
  logoutUser,
  switchUserProfile,
  resetBackBtn,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettingDropdown);
