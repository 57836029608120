import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { TABS, SIDEBARS, CONFIGS } from "../../constants/constants";
import home_sidebar_active from "../../images/home-sidebar-ac.svg";
import home_sidebar from "../../images/home-sidebar.svg";
import create_grp_sidebar from "../../images/create-grp-sidebar.svg";
import create_grp_sidebar_ac from "../../images/create-grp-sidebar-ac.svg";
import join_grp_sidebar from "../../images/join-grp-sidebar.svg";
import join_grp_sidebar_active from "../../images/join-grp-sidebar-ac.svg";
import explore_sidebar from "../../images/explore-sidebar.svg";
import explore_sidebar_active from "../../images/explore-sidebar-ac.svg";
import group_sidebar from "../../images/group-sidebar.svg";
import course_sidebar from "../../images/course-sidebar.svg";
import course_sidebar_active from "../../images/course-sidebar-ac.svg";
import group_sidebar_active from "../../images/group-sidebar-ac.svg";
import { showSidebarMB } from "../../actions/utilAction";
import white_cross from "../../images/white-cross.svg";
import logo_sidebar from "../../images/eckovation-sidebar.png";
import { withRouter } from "react-router";
import eck_logo from "../../images/imarticusLogo.svg";
import eck_logo_wt from "../../images/Imarticus-logo-white.png";
import { resetGDBack } from "../../actions/groupAction";
import { showFreshDesk } from "../../actions/showFreshDesk";
import {
  setCurrentTab,
  setSidebar,
  resetLoadingData,
} from "../../actions/utilAction";
import SidebarSS from "../skeletonscreens/SidebarSS";
import { getPrimaryEmailsWithoutModal } from "../../actions/primaryEmailActions";
class LeftSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_crs: false,
      is_grp: false,
      loading: true,
    };
    console.log({ "this.props": this.props });
  }

  closeSidebar = () => {
    this.props.showSidebarMB(false);
  };
  componentDidMount() {
    this.props.setSidebar(SIDEBARS.HOME_SIDEBAR);
    if (this.props.groups) {
      this.checkGroupsAndCourses();
    }
    if (this.props.courses) {
      this.checkGroupsAndCourses();
    }
    if (this.props.gd_is_back) {
      this.props.resetGDBack();
    }

    // setTimeout(
    // 	function() {
    // 		this.props.resetLoadingData();
    // 	}
    // 	.bind(this),
    // 	1000
    // );
    // this.showFreshDesk=this.showFreshDesk.bind(this);
  }
  componentDidUpdate(prev_props) {
    console.log("-------component this update");
    console.log(this.props);
    if (this.props.groups != prev_props.groups) {
      this.checkGroupsAndCourses();
    }
    if (this.props.courses != prev_props.courses) {
      this.checkGroupsAndCourses();
    }
    if (
      this.props.is_data_loading != prev_props.is_data_loading &&
      this.props.is_data_loading
    ) {
      // setTimeout(
      // 	function() {
      // 		this.props.resetLoadingData();
      // 	}
      // 	.bind(this),
      // 	1000
      // );
    }
    var pathname = this.props.location.pathname;
    if (this.props.is_data_loading == false) {
      console.log("----------------redirect");
      if (pathname == "/dashboard/course" || pathname == "/dashboard/course/") {
        console.log("-------course");

        if (
          this.props.courses &&
          this.props.courses.length == 0 &&
          this.props.groups &&
          this.props.groups.length == 0
        ) {
          this.props.history.push({
            pathname: "/dashboard/",
          });
        }
      }
      if (pathname == "/dashboard/group" || pathname == "/dashboard/group/") {
        console.log("-------course");
        console.log(this.props.courses, this.props.groups);

        if (
          this.props.courses &&
          this.props.courses.length == 0 &&
          this.props.groups &&
          this.props.groups.length == 0
        ) {
          this.props.history.push({
            pathname: "/dashboard/",
          });
        }
      }
      if (pathname == "/dashboard/home" || pathname == "/dashboard/home/") {
        console.log("-------course");

        if (
          this.props.courses &&
          this.props.courses.length > 0 &&
          this.props.groups &&
          this.props.groups.length > 0
        ) {
          this.props.history.push({
            pathname: "/dashboard/",
          });
        }
      }
    }
  }

  checkGroupsAndCourses = () => {
    if (this.props.groups && this.props.groups.length > 0) {
      this.setState({
        is_grp: true,
      });
    } else {
      this.setState({
        is_grp: false,
      });
    }
    if (this.props.courses && this.props.courses.length > 0) {
      this.setState({
        is_crs: true,
      });
    } else {
      this.setState({
        is_crs: false,
      });
      var pathname = this.props.location.pathname;
    }
    if (pathname == "/dashboard" || pathname == "/dashboard/") {
      // alert("yes");
      if (
        this.props.courses &&
        this.props.courses.length > 0 &&
        this.props.groups &&
        this.props.groups.length > 0
      ) {
        // alert("1");
        this.props.setCurrentTab(TABS.MY_COURSES);
      } else if (
        (!this.props.courses || this.props.courses.length == 0) &&
        this.props.groups &&
        this.props.groups.length > 0
      ) {
        // alert("2");
        this.props.setCurrentTab(TABS.MY_GROUPS);
      } else {
        // alert("3");
        this.props.setCurrentTab(TABS.HOME);
      }
    }
  };

  goToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard/",
    });
  };

  render() {
    console.log({ "this.props": this.props });
    if (this.props.is_data_loading) {
      return <SidebarSS />;
    } else {
      return (
        <Fragment>
          <div className="home-left-sidebar">
            <div className="menu-list left-sidebar-menu">
              <Link to="/dashboard">
                <div
                  className="logo-container center-all"
                  style={{
                    backgroundColor: `${
                      this.props.brand && this.props.brand.whiteBackground
                        ? "white"
                        : ""
                    }`,
                  }}
                >
                  {!this.props.is_data_loading && (
                    <img
                      src={
                        this.props.brand?.pic ||
                        "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
                      }
                      alt="logo"
                    />
                  )}
                </div>
              </Link>
              <ul className="sidebar-list">
                <Link
                  to="/dashboard/home"
                  className={
                    !this.state.is_crs && !this.state.is_grp ? "" : "hide"
                  }
                >
                  <li
                    className={
                      "sidebar-list-item " +
                      (this.props.current_tab == TABS.HOME ? "active" : "")
                    }
                  >
                    My Courses
                  </li>
                </Link>
                <Link
                  to="/dashboard/course"
                  className={this.state.is_crs ? "" : "hide"}
                >
                  <li
                    className={
                      "sidebar-list-item " +
                      (this.props.current_tab == TABS.MY_COURSES
                        ? "active"
                        : "")
                    }
                  >
                    My Courses
                  </li>
                </Link>
                <Link
                  to="/dashboard/group"
                  className={this.state.is_grp ? "" : "hide"}
                >
                  <li
                    className={
                      "sidebar-list-item " +
                      (this.props.current_tab == TABS.MY_GROUPS ? "active" : "")
                    }
                  >
                    My Groups
                  </li>
                </Link>
                {(this.props.brand == undefined ||
                  this.props.brand.exploreEnabled == true) && (
                  <Link to="/dashboard/explore">
                    <li
                      className={
                        "sidebar-list-item " +
                        (this.props.current_tab == TABS.EXPLORE ? "active" : "")
                      }
                    >
                      Explore
                    </li>
                  </Link>
                )}

                {/* <Link to="/dashboard/jgroup">
                  <li
                    className={
                      "sidebar-list-item " +
                      (this.props.current_tab == TABS.JOIN_GROUP
                        ? "active"
                        : "")
                    }
                  >
                    Join Group
                  </li>
                </Link> */}

                {/* <Link to="/dashboard/cgroup"><li className={"sidebar-list-item " + (this.props.current_tab == TABS.CREATE_GROUP ? "active" : "")}>Create Group</li></Link> */}
              </ul>
              {!this.props.is_data_loading &&
                (this.props.brand == undefined ||
                  this.props.brand.getHelpEnabled == true) && (
                  <div className="side-bar-freshdesk-container">
                    <p className="side-bar-freshdesk-heading">
                      Facing problems?
                    </p>
                    <button
                      style={{ margin: "0 auto" }}
                      className="side-bar-freshdesk-button"
                      onClick={() => {
                        showFreshDesk(
                          this.props.courses,
                          this.props.contact,
                          this.props.profileName,
                          this.props.profile.email,
                        );
                      }}
                    >
                      Get help
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div
            onClick={this.closeSidebar}
            className={this.props.show_sidebar_mb ? "demo" : "hide"}
          ></div>
          <div className={"home-left-sidebar-mb "}>
            <div
              className={
                "left-sidebar-menu-mb " +
                (this.props.show_sidebar_mb ? "out" : "")
              }
            >
              <div className="logo-container">
                <button
                  className={this.props.show_sidebar_mb ? "close-btn" : "hide"}
                  onClick={this.closeSidebar}
                >
                  <img src={white_cross}></img>
                </button>
                {!this.props.is_data_loading && (
                  <img
                    onClick={this.goToDashboard}
                    src={
                      this.props.brand?.pic ||
                      `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                    }
                    alt="logo"
                  ></img>
                )}
              </div>
              <ul className="sidebar-list">
                <Link
                  to="/dashboard/home"
                  className={
                    !this.state.is_crs && !this.state.is_grp ? "" : "hide"
                  }
                >
                  <li
                    className={
                      "sidebar-list-item " +
                      (this.props.current_tab == TABS.HOME ? "active" : "")
                    }
                  >
                    My Courses
                  </li>
                </Link>
                <Link
                  to="/dashboard/course"
                  className={this.state.is_crs ? "" : "hide"}
                >
                  <li
                    className={
                      "sidebar-list-item " +
                      (this.props.current_tab == TABS.MY_COURSES
                        ? "active"
                        : "")
                    }
                  >
                    My Courses
                  </li>
                </Link>
                <Link
                  to="/dashboard/group"
                  className={this.state.is_grp ? "" : "hide"}
                >
                  <li
                    className={
                      "sidebar-list-item " +
                      (this.props.current_tab == TABS.MY_GROUPS ? "active" : "")
                    }
                  >
                    My Groups
                  </li>
                </Link>
                {(this.props.brand == undefined ||
                  this.props.brand.exploreEnabled == true) && (
                  <Link to="/dashboard/explore">
                    <li
                      className={
                        "sidebar-list-item " +
                        (this.props.current_tab == TABS.EXPLORE ? "active" : "")
                      }
                    >
                      Explore
                    </li>
                  </Link>
                )}

                {/* <Link to="/dashboard/jgroup">
                  <li
                    className={
                      "sidebar-list-item " +
                      (this.props.current_tab == TABS.JOIN_GROUP
                        ? "active"
                        : "")
                    }
                  >
                    Join Group
                  </li>
                </Link> */}

                {/* <Link to="/dashboard/cgroup"><li className={"sidebar-list-item " + (this.props.current_tab == TABS.CREATE_GROUP ? "active" : "")}>Create Group</li></Link> */}
              </ul>
              {!this.props.is_data_loading &&
                (this.props.brand == undefined ||
                  this.props.brand.getHelpEnabled == true) && (
                  <div className="side-bar-freshdesk-container">
                    <p className="side-bar-freshdesk-heading">
                      Facing problems?
                    </p>
                    <button
                      style={{ margin: "0 auto" }}
                      className="side-bar-freshdesk-button"
                      onClick={() => {
                        showFreshDesk(
                          this.props.courses,
                          this.props.contact,
                          this.props.profileName,
                          this.props.profile.email,
                        );
                      }}
                    >
                      Get help
                    </button>
                  </div>
                )}
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  show_sidebar_mb: state.utils.show_sidebar_mb,
  current_tab: state.utils.current_tab,
  show_my_crs: state.utils.show_my_crs,
  show_my_grp: state.utils.show_my_grp,
  show_home: state.utils.show_home,
  groups: state.j_grp.groups,
  courses: state.course.courses,
  is_data_loading: state.utils.is_data_loading,
  gd_is_back: state.j_grp.gd_is_back,
  profileName: state.profile.p_name,
  email: state.primaryEmail.primary_email,
  brand: state.brand?.brand || state.course.brand,
  contact: state.profile.contact,
  profile: state.profile,
  courses: state.course.courses,
});

const mapDispatchToProps = {
  showSidebarMB,
  setCurrentTab,
  setSidebar,
  resetLoadingData,
  resetGDBack,
  getPrimaryEmailsWithoutModal,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftSidebar),
);
