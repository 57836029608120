import React, { Component } from "react";
import facebookIcon from "../../images/facebook-icon.svg";
import googleIcon from "../../images/google-icon.svg";
import emailIcon from "../../images/email-icon.svg";
import mobileIcon from "../../images/mobile-icon.svg";
import usernameIcon from "../../images/username-icon.svg";
import { ROUTES } from "../../constants/routes";
import Axios from "axios";
import { Link, Redirect, withRouter } from "react-router-dom";
import { utils } from "../../utility/utils";
import Spinner from "../utils/Spinner";
import BrandSpinner from "../utils/BrandSpinner";
import { dispatchIndividualBrand } from "../../actions/brandAction";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { CONFIGS, LABS_REDIRECT_LINK } from "../../constants/constants";
import cookie from "react-cookies";
import LoginWithEmail from "./LoginWithEmail";
import { Button, Input } from "reactstrap";
import color from "@material-ui/core/colors/amber";
const cookies = new Cookies();

let errorMessages = {
  incorrectPassword: "Incorrect Password",
  incorrectNumber:
    "This number is not linked with any account. Kindly try another method.",
  incorrectEmail:
    "No account is linked with this mail Id. Kindly try another method.",
  noPass:
    "Your account doesn’t have a password set up, please use Forgot Password option to set up your password",
  bruteforce:
    "You've exceeded the maximum number of incorrect password attempts. Please reset your password or opt for logging in via OTP",
};

class Login extends Component {
  constructor(props) {
    super(props);
    console.log({ "this.state": this.state });
    var query = new URLSearchParams(this.props.location.search);
    this.redirectValue = query.get("redirect");

    var brand = props.match.params.brand;
    console.log({ query });
    if (brand) {
      this.state = {
        email: "",
        password: "",
        incorrectPassword: false,
        incorrectEmail: false,
        noPass: false,
        bruteforce: false,
        isLoading: true,
        invalidEmail: false,
      };
      Axios.get(ROUTES.FETCH_BRAND_DATA + `?brand_id=${brand}`)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success && brandData) {
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          } else {
            this.props.history.push(`/login`);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log({ err });
          this.props.history.push(`/login`);
          window.location.reload();
        });
      window.FreshworksWidget?.("hide");
      if (utils.getLoggedIn()) this.props.history.push(`/${brand}/dashboard`);
    } else {
      if (cookies.get("brand")) {
        cookies.remove("brand", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
      }
      this.state = {
        email: "",
        password: "",
        incorrectPassword: false,
        incorrectEmail: false,
        noPass: false,
        bruteforce: false,
        invalidEmail: false,
        isLoading: false,
      };
      let auth_cookies = query.get("auth_cookies");
      if (auth_cookies) {
        let {
          rt,
          secret,
          at: a_t,
          aid,
          pid,
          user,
          sid,
          Profile,
        } = JSON.parse(auth_cookies);
        // utils.setLoginCookies(
        //   a_t,
        //   aid,
        //   secret,
        //   rt,
        //   true,
        //   pid,
        //   sid,
        //   user,
        //   Profile
        // );
      }
      this.setState({ isLoading: true });
      Axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          console.log({ brandData });
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success) {
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData }, (brand) => {
              console.log({ "this.state": this.state });
            });
          }
        })
        .catch((err) => {
          console.log({ err });
          this.setState({ isLoading: false });
        });
      window.FreshworksWidget?.("hide");

      if (utils.getLoggedIn()) this.props.history.push("/dashboard");
    }
  }

  validateEmail = (email) => {
    console.log({ email });
    return (
      email &&
      email.length <= 50 &&
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  };

  validatePassword = (password) => {
    return password.length >= 8 && password.length <= 50;
  };

  formValidation = () => {
    return (
      this.validateEmail(this.state.email) &&
      this.validatePassword(this.state.password)
    );
  };

  emailChange = (e) => {
    let email = e.target.value;
    if (email.length > 50) return;
    this.setState({
      email,
      incorrectEmail: false,
      invalidEmail: false,
    });
  };

  passwordChange = (e) => {
    let password = e.target.value;
    if (password.length > 50) return;
    this.setState({
      password,
      incorrectPassword: false,
    });
  };

  loginResponseHandler = (res) => {
    let data = res.data.data;
    // utils.setLoginCookies(
    //   data.at,
    //   data.aid,
    //   data.secret,
    //   data.rt,
    //   true,
    //   data.pid,
    //   data.sid,
    //   data.user,
    //   data.Profile[0]
    // );

    this.setState({
      incorrectEmail: false,
      incorrectPassword: false,
      isLoading: false,
    });

    if (this.redirectValue === "labs")
      window.location.href = LABS_REDIRECT_LINK;

    if (this.state.brand) {
      this.props.history.push(`/${this.state.brand.utag}/dashboard`);
    } else {
      this.props.history.push("/dashboard");
    }
  };

  loginWithEmail = () => {
    this.setState({ isLoading: true });
    const headers = {
      ...Axios.defaults.headers.common,
      "User-Agent": navigator.userAgent,
      "Accept-Language": navigator.languages,
    };

    headers["resolution"] = `${window.screen.width}x${window.screen.height}`;
    Axios.post(
      ROUTES.LOGIN_ROUTE,
      {
        auth_method: "email",
        email: this.state.email,
        password: this.state.password,
        device_id: window.fingerprint.md5hash,
      },
      { headers }
    )
      .then((res) => {
        console.log(res);
        this.loginResponseHandler(res);
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 310) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 100) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 315) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: true,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 207000) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: true,
          });
        } else if (ecode == 320) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: true,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 5400) {
          this.setState({
            incorrectEmail: true,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (
          err.response.data.message ||
          err.response.data.data?.message
        ) {
          this.setState({
            customError:
              err.response.data.message || err.response.data.data?.message,
            isLoading: false,
          });
        }
      });
  };

  loginWithFacebook = () => {
    this.setState({ isLoading: true });
    Axios.post(ROUTES.OAUTH_LOGIN + "?auth_method=facebook").then((res) => {
      let url = res.data.data.url;
      window.location.href = url;
      //console.log(url);
    });
  };

  loginWithSaml = (utag) => {
    this.setState({ isLoading: true });
    Axios.get(ROUTES.SAML_LOGIN + "?utag=" + utag + "&redirect=false").then(
      (res) => {
        let url = res.data.data.url;
        window.location.href = url;
        //console.log(url);
      }
    );
  };

  loginWithGoogle = () => {
    this.setState({ isLoading: true });
    Axios.post(ROUTES.OAUTH_LOGIN + "?auth_method=google").then((res) => {
      let url = res.data.data.url;
      window.location.href = url;
      //console.log(url);
    });
  };
  getIfNativeDomain = () => {
    let domain = new URL(window?.location.href).hostname
      .split(".")
      .slice(0)
      .slice(-(window?.location.href.length === 4 ? 3 : 2))
      .join(".");
    console.log({ domain });
    if (domain == "imarticus.org" || domain == "localhost") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return this.state.isLoading ? (
      <div className="loader loader-center">
        <div className="loader-msg-container">
          <BrandSpinner brand={this.state.brand} />
        </div>
      </div>
    ) : (
      <div
        className="auth-main"
        style={
          this.state.brand
            ? {
                background: `url(${this.state.brand.bgimg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }
            : {}
        }
      >
        <div className="auth-block" style={{}}>
          <img
            className="auth-logo"
            alt="logo"
            width={200}
            height={100}
            src={
              this.state.brand
                ? this.state.brand.pic
                : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
            }
          ></img>
          <div className="auth-h">Login</div>
          <div className="auth-box">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="auth-ttl">Enter Email Id</div>
            </div>
            <div className="auth-container">
              <Input
                type="email"
                className="auth-inp"
                name="email"
                onChange={this.emailChange}
                value={this.state.email}
              ></Input>
              <div
                className={`line ${
                  this.validateEmail(this.state.email) &&
                  !this.state.incorrectEmail
                    ? "lgreen"
                    : "lred"
                }`}
              ></div>
            </div>
            {this.state.customError && (
              <div className="auth-error">{this.state.customError}</div>
            )}
            {this.state.incorrectEmail && (
              <div className="auth-error">
                {errorMessages["incorrectEmail"]}
              </div>
            )}
            {this.state.invalidEmail && (
              <div className="auth-error">{errorMessages["invalidEmail"]}</div>
            )}
          </div>

          <div className="auth-box">
            <div className="auth-ttl">Enter Password</div>
            <div className="auth-container">
              <Input
                type="password"
                className={`auth-inp ${
                  this.state.incorrectPassword ? "error-outline" : ""
                }`}
                name="password"
                onChange={this.passwordChange}
                value={this.state.password}
              ></Input>
              <div
                className={`line ${
                  this.validatePassword(this.state.password) &&
                  !this.state.incorrectPassword
                    ? "lgreen"
                    : "lred"
                }`}
              ></div>
            </div>
            {this.state.incorrectPassword && (
              <div className="auth-error">
                {errorMessages["incorrectPassword"]}
              </div>
            )}

            {this.state.noPass && (
              <div className="auth-error">{errorMessages["noPass"]}</div>
            )}
            {this.state.bruteforce && (
              <div className="auth-error">{errorMessages["bruteforce"]}</div>
            )}
          </div>

          <Button
            type="submit"
            className={`${this.formValidation() ? "" : "disable"} ${
              this.state.brand && this.state.brand.themeColorIndex
                ? "auth-btn-brand"
                : "auth-btn"
            }`}
            onClick={(event) => {
              event.preventDefault();
              if (this.formValidation()) this.loginWithEmail();
            }}
            style={{
              background: `${
                this.state.brand &&
                this.state.brand.themeColorIndex &&
                this.state.brand.themeColorIndex
              }`,
              color: "var(--textColor)",
            }}
          >
            Log in
          </Button>

          <div
            className="auth-txt"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Link
              to={
                (this.state.brand ? `/${this.state.brand.utag}` : "") +
                "/login/otp?type=email"
              }
              className="green right"
              style={{
                textDecoration: "underline",
                display: "inline",
                cursor: "pointer",
                // color: `${
                //   this.state.brand &&
                //   this.state.brand.themeColorIndex &&
                //   this.state.brand.themeColorIndex
                // }`,
              }}
            >
              Login With OTP
            </Link>
            <Link
              to={
                (this.state.brand ? `/${this.state.brand.utag}` : "") +
                "/forgot?type=email"
              }
              className="green right"
              style={{
                textDecoration: "underline",
                display: "inline",
                cursor: "pointer",
                // color: `${
                //   this.state.brand &&
                //   this.state.brand.themeColorIndex &&
                //   this.state.brand.themeColorIndex
                // }`,
              }}
            >
              Forgot Password
            </Link>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              minHeight: "85%",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <br />
            <div className="auth-name-mob-container">
              {/* <Link
                to={
                  (this.state.brand ? `/${this.state.brand.utag}` : "") +
                  "/login/email"
                }
                className="auth-login-btn"
              >
                <img src={emailIcon}></img>
                Login with Email
              </Link> */}
              {(!this.state.brand ||
                this.state.brand?.usernameEnabled == true) && (
                <Link
                  to={
                    (this.state.brand ? `/${this.state.brand.utag}` : "") +
                    "/login/username" +
                    (this.redirectValue
                      ? `?redirect=${this.redirectValue}`
                      : "")
                  }
                  className="auth-login-btn"
                >
                  <img
                    src={
                      "https://cdn.pegasus.imarticus.org/index/username_neutral.svg"
                    }
                  ></img>
                  Login with Username
                </Link>
              )}
              {(!this.state.brand ||
                this.state.brand?.mobileAllowed == true) && (
                <Link
                  to={
                    (this.state.brand ? `/${this.state.brand.utag}` : "") +
                    "/login/mobile" +
                    (this.redirectValue
                      ? `?redirect=${this.redirectValue}`
                      : "")
                  }
                  className="auth-login-btn"
                >
                  <img src={mobileIcon}></img>
                  Login with Mobile Number
                </Link>
              )}
              {this.getIfNativeDomain() &&
                (!this.state.brand ||
                  this.state.brand.googleAllowed == true) && (
                  <div
                    className="auth-login-btn"
                    onClick={this.loginWithGoogle}
                  >
                    <img src={googleIcon}></img>
                    Login with Google
                  </div>
                )}
              {(!this.state.brand ||
                this.state.brand.facebookAllowed == true) && (
                <div
                  className="auth-login-btn"
                  onClick={this.loginWithFacebook}
                >
                  <img src={facebookIcon}></img>
                  Login with Facebook
                </div>
              )}
            </div>
            {this.state.brand && this.state.brand.samlLoginEnabled == true && (
              <div
                className="auth-login-btn-full"
                onClick={() => {
                  this.loginWithSaml(this.state.brand.utag);
                }}
              >
                {this.state.brand && this.state.brand.pic && (
                  <img src={this.state.brand.pic} width={64} height={32}></img>
                )}
                Login with {this.state.brand.name}
              </div>
            )}
            {(!this.state.brand ||
              this.state.brand.createAccAllowed == true) && (
              <>
                <div className="OR">
                  <div className="line"></div>
                  OR
                  <div className="line"></div>
                </div>
                <div className="signup-block signupm-block">
                  <div className="auth-h2">Sign up</div>
                  <div className="auth-txt">Don't have an account?</div>
                  <br />
                  <Link
                    to={
                      (this.state.brand ? `/${this.state.brand.utag}` : "") +
                      "/signup"
                    }
                    className="create-ac-btn"
                  >
                    Create Account
                  </Link>
                </div>
              </>
            )}
            {this.state.brand &&
              this.state.brand.poweredByEnabled == true &&
              this.state.brand.hyperlinkEnabled == true && (
                <div
                  className="powered-by-container"
                  style={{ bottom: "24px" }}
                >
                  <a className="powered-by" href={"https://www.imarticus.org"}>
                    Powered By Imarticus Learning
                  </a>
                </div>
              )}
            {this.state.brand &&
              this.state.brand.poweredByEnabled == true &&
              this.state.brand.hyperlinkEnabled == false && (
                <div
                  className="powered-by-container"
                  style={{ bottom: "24px" }}
                >
                  <div className="powered-by">
                    Powered By Imarticus Learning
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

// export default Login;
const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  pendingInstallments: state.course.pendingInstallments,
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
