import axios from "axios";
import cookie from "react-cookies";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
import { CONFIGS, MAIL_BODY } from "../constants/constants";
import { BACKEND_ERRORS, BACKEND_ERROR_CODES } from "../constants/errors";
import { utils } from "../utility/utils";
import { renewAccessToken } from "./utilAction";

import { showPopover } from "../actions/utilAction";
import { POPOVER_TYPE } from "../constants/constants";
const cookies = new Cookies();
let serialize = (obj) => {
  // eslint-disable-next-line no-shadow
  var keyOrderArr = [
    "name",
    "mn",
    "email",
    "pwd",
    "role",
    "lang",
    "signature",
    "china",
  ];

  Array.intersect = function () {
    var result = new Array();
    var obj = {};
    for (var i = 0; i < arguments.length; i++) {
      for (var j = 0; j < arguments[i].length; j++) {
        var str = arguments[i][j];
        if (!obj[str]) {
          obj[str] = 1;
        } else {
          obj[str]++;
          if (obj[str] == arguments.length) {
            result.push(str);
          }
        }
      }
    }
    return result;
  };

  if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, "includes", {
      enumerable: false,
      value: function (obj) {
        var newArr = this.filter(function (el) {
          return el === obj;
        });
        return newArr.length > 0;
      },
    });
  }

  var tmpInterArr = Array.intersect(keyOrderArr, Object.keys(obj));
  var sortedObj = [];
  keyOrderArr.forEach(function (key) {
    if (tmpInterArr.includes(key)) {
      sortedObj.push([key, obj[key]]);
    }
  });
  Object.keys(obj)
    .sort()
    .forEach(function (key) {
      if (!tmpInterArr.includes(key)) {
        sortedObj.push([key, obj[key]]);
      }
    });
  var tmpSortResult = (function (sortedObj) {
    var str = [];
    for (var p in sortedObj) {
      if (typeof sortedObj[p][1] !== "undefined") {
        str.push(
          encodeURIComponent(sortedObj[p][0]) +
            "=" +
            encodeURIComponent(sortedObj[p][1])
        );
      }
    }
    return str.join("&");
  })(sortedObj);
  return tmpSortResult;
};

export const fetchZoomSignatureV3 = (liveLectureId) => {
  return new Promise((resolve, reject) => {
    let zoomRoute = ROUTES.ZOOM_SIGNATURE_V3;
    let pid = cookies.get("pid");
    let aid = cookies.get("aid");
    let crs_tkn = cookies.get("crs_tkn");
    let cl = "W";
    let data = {
      liv_lec_id: liveLectureId,
      p_id: pid,
      a_id: aid,
      cl: cl,
    };

    axios
      .post(zoomRoute, data, {
        headers: {
          "Content-Type": "application/json",
          "x-access-crs-token": crs_tkn,
        },
      })
      .then((res) => {
        const authArr = res.data.data.auth.split(",");
        const meeting_number = atob(authArr[0]);
        const meeting_password = atob(authArr[1]);

        var meetingConfig = {};
        meetingConfig["signature"] = res.data.data.signature;
        meetingConfig["meetingNumber"] = meeting_number;
        meetingConfig["password"] = meeting_password;
        meetingConfig["apiKey"] = res.data.data.api_key;
        meetingConfig["userName"] = res.data.data.user_name;
        meetingConfig["userEmail"] = res.data.data.user_email;
        meetingConfig["leaveUrl"] = window.location.href;
        meetingConfig["isSupportAV"] = true;
        let joinUrl =
          CONFIGS.WEB_DASHBOARD_ENDPOINT +
          "zoom_meeting?" +
          serialize(meetingConfig);
        resolve(joinUrl);
      })
      .catch((err) => {
        console.log({ err });
        reject(err.response.data);
      });
  });
};

export const fetchMyCourses =
  ({ pid, combo_id, dispatchIndividualBrand }) =>
  (dispatch) => {
    let my_crs_route = ROUTES.MY_CRS;
    let data = {
      headers: {
        "Content-Type": "application/json",
      },

      pid: pid,
      tokn: utils.getAt(),
      d_id: utils.getDid(),
      cl: "W",
    };
    axios
      .post(my_crs_route, data)
      .then(async (my_courses) => {
        var res = my_courses.data.data; //>>>>>>>> need to uncomment this & use api data
        // var res = JSON.parse(JSON.stringify(myCrs.data)) // >>>>> comment out this line
        var crs_pid = res.crs_pid != undefined ? res.crs_pid : null;
        // console.log("Prayas ka log!");
        // console.log(crs_pid, " >>>> crs_pid");
        var crsPidObj = {};
        crsPidObj.crs_pid = crs_pid;
        crsPidObj.type = "CRS_PID";
        dispatch(crsPidObj);
        console.log(res);
        // console.log("Notification : ", {
        //   notificationsInResponse: res.notifications,
        // });
        // if (res.notifications && Object.keys(res.notifications).length > 0) {
        //   console.log(" -- In CourseAction -- ");
        //   console.log("\n\n --- Sending In app notifications --- \n\n");
        //   let objtoDispatch = {
        //     type: "IN_APP",
        //     notification: {
        //       title:
        //         res.notifications.InAppMessage.Content[0].HeaderConfig.Header,
        //       body: res.notifications.InAppMessage.Content[0].BodyConfig.Body,
        //     },
        //   };
        //   dispatch(objtoDispatch);
        // }

        var courseObj = {};
        courseObj.brand = res.brand;
        let brand;
        if (!window?.location.href.includes("imarticus.org")) {
          let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);
          if (res.data?.data?.brand) brand = res.data?.data?.brand;
        } else {
          brand = res.brand;
        }
        dispatchIndividualBrand(brand);
        courseObj.courses = res.courses != undefined ? res.courses : [];
        courseObj.groups = res.groups != undefined ? res.groups : [];
        courseObj.combo_courses =
          res.combo_courses != undefined ? res.combo_courses : [];
        courseObj.resume_course =
          res.resume_course != undefined ? res.resume_course : [];
        courseObj.live_lectures =
          res.liveLectures != undefined ? res.liveLectures : [];
        courseObj.type = "MY_COURSES";
        console.log(courseObj.live_lectures, "live");
        //dispatch(courseObj);

        // to set the current combo child courses
        var ccid = combo_id;
        var cc_len = res.combo_courses.length;
        var cc = JSON.parse(JSON.stringify(res.combo_courses));
        var ic = JSON.parse(JSON.stringify(res.courses));
        var ic_len = ic.length;
        var child_arr = [];
        for (var i = 0; i < cc_len; i++) {
          if (cc[i]._id == ccid) {
            var ch_len = cc[i].child_courses.length;
            for (var j = 0; j < ch_len; j++) {
              child_arr.push(cc[i].child_courses[j]);
            }
          }
        }
        var ic_map = {};
        for (var i = 0; i < ic_len; i++) {
          if (!ic_map[ic[i]._id]) {
            ic_map[ic[i]._id] = "";
            ic_map[ic[i]._id] = ic[i];
          }
        }
        courseObj.courses_map = JSON.parse(JSON.stringify(ic_map));
        dispatch(courseObj);
        var ic_render_arr = [];
        for (var i = 0; i < child_arr.length; i++) {
          if (ic_map[child_arr[i]]) {
            ic_render_arr.push(ic_map[child_arr[i]]);
          }
        }
        var childCourseObj = {};
        childCourseObj.child_courses = ic_render_arr;
        childCourseObj.type = "CHILD_COURSES";
        dispatch(childCourseObj);
      })
      .catch((err) => {
        utils.handleErrors(err, dispatch);
      });
  };

export const authenticateCourse = (cid) => {
  return new Promise((resolve, reject) => {
    let d_id = utils.getDid();
    let pid = cookies.get("pid");
    let aid = cookies.get("aid");
    let cl = "W";
    let my_crs_route = ROUTES.AUTHENTICATE_COURSE;
    let data = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": cookies.get("crs_tkn"),
      },
      p_id: pid,
      crs_id: cid,
      a_id: aid,
      d_id: d_id,
      cl: cl,
      tokn: utils.getAt(),
    };
    return axios
      .post(my_crs_route, data, {})
      .then(async (response) => {
        console.log(response.data.data);
        let brand;
        if (!window?.location.href.includes("imarticus.org")) {
          let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);
          if (res.data?.data?.brand) brand = res.data?.data?.brand;
        } else {
          brand = response.data?.data?.brand;
        }
        if (!brand) {
          if (cookies.get("brand")) {
            cookies.remove("brand", {
              path: "/",
              domain: CONFIGS.DOMAIN,
            });
          }
        }
        return resolve(response.data.data);
      })
      .catch((err) => {
        console.log({ err });
        window.location.href = CONFIGS.DASHBOARD_URL;
        reject(err.response);
      });
  });
};

export const getLevelData = (cid, levelId) => {
  return new Promise((resolve, reject) => {
    let pid = cookies.get("pid");
    let aid = cookies.get("aid");
    let crs_tkn = cookies.get("crs_tkn");
    let query = "?";
    if (cid) query += "&cid=" + cid;
    if (levelId) query += "&levelId=" + levelId;
    if (pid) query += "&pid=" + pid;
    if (aid) query += "&aid=" + aid;
    query += "&cl=W";
    console.log("query is ", query);
    let my_crs_route = ROUTES.LEVELS_DATA;
    return axios
      .get(my_crs_route + query, {
        headers: {
          "Content-Type": "application/json",
          "x-access-crs-token": crs_tkn,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.data.url) {
          console.log(response.data.data.url);
          window.location.href = response.data.data.url;
        }
        return resolve(response.data.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const addChildCourses = (courseObj) => (dispatch) => {
  var childCrsObj = JSON.parse(JSON.stringify(courseObj));
  dispatch(childCrsObj);
};

export const setSelectedCourse = (course) => (dispatch) => {
  console.log("setting course in redux:", course);
  dispatch({
    type: "SET_SELECTED_COURSE",
    course,
  });
};

export const redirectToMailForNoGroupForCourse =
  (crs_name, st_name) => (dispatch) => {
    var mail_body =
      MAIL_BODY.GROUP_NOT_JOINED_START +
      crs_name +
      MAIL_BODY.GROUP_NOT_JOINED_END +
      st_name;
    window.location.href = mail_body;
    // var win = window.open(mail_body,'_blank');
    // win.focus();
  };

export const openLearnerCourseDashboard = (gid, course) => (dispatch) => {
  var url = "";
  // if(!(course && course["gids"] && course["gids"][0])){
  // 	return;
  // }
  if (course && course.adaptive_course && course._id) {
    // Adaptive Course

    var cid = course._id;
    url =
      CONFIGS.ADAPTIVE_WEB_ENDPOINT +
      "#/?pid=" +
      utils.getPid() +
      "&gid=" +
      gid +
      "&cid=" +
      cid;
  } else if (course && !course.adaptive_course) {
    // Normal Course
    url =
      CONFIGS.WEB_DASHBOARD_BASE_URL +
      "course_home/" +
      utils.getPid() +
      "/" +
      gid;
  } else {
    return;
  }
  var win = window.open(url, "_self");
  win.focus();
};

export const getParam = (param) => {
  let urlString = window.location.href;
  // console.log({urlString})
  let paramString = urlString.split("?")[1];
  if (paramString) {
    var params_arr = paramString.split("&");
  } else {
    return null;
  }
  let value = null;
  for (let i = 0; i < params_arr.length; i++) {
    let pair = params_arr[i].split("=");
    if (pair[0] == param) {
      value = pair[1];
    }
    // console.log("Key is:", pair[0]);
    // console.log("Value is:", pair[1]);
  }
  return value;
};
