import { connect } from "react-redux";
import React, { Component } from "react";
import { Switch, Redirect, withRouter } from "react-router";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { setCurrentTab } from "../../actions/utilAction";
import LeftSidebar from "./LeftSidebar";
import GroupSidebar from "./GroupSidebar";
import SettingSidebar from "./SettingSidebar";
import PlacementSidebar from "./PlacementSidebar";
import CourseSidebar from "./CourseSidebar";
import Notifications from "../notifications/Notifications";

class SidebarContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var loggedIn = false;
    return (
      <Switch>
        <Route exact path={"/dashboard"} component={LeftSidebar} />
        <Route exact path={"/dashboard/home"} component={LeftSidebar} />
        <Route exact path={"/dashboard/edit"} component={SettingSidebar} />
        <Route exact path="/dashboard/edit/email" component={SettingSidebar} />
        <Route exact path="/dashboard/edit/mobile" component={SettingSidebar} />
        <Route
          exact
          path="/dashboard/edit/timezone"
          component={SettingSidebar}
        />
        <Route path={"/placement-portal"} component={CourseSidebar} />
        <Route path={"/job-view"} />
        <Route
          exact
          path={"/dashboard/edit/profile"}
          component={SettingSidebar}
        />

        <Route path={"/view"} component={CourseSidebar} />
        <Route exact path={"/dashboard/pwd_edit"} component={SettingSidebar} />
        <Route
          exact
          path={"/placement-module/basic_details"}
          // component={PlacementSidebar}
        />
        <Route
          exact
          path={"/placement-module/education_details"}
          // component={PlacementSidebar}
        />
        <Route
          exact
          path={"/placement-module/professional_details"}
          // component={PlacementSidebar}
        />
        <Route
          exact
          path={"/placement-module/resume"}
          // component={PlacementSidebar}
        />
        <Route
          exact
          path={"/placement-module/basic-details"}
          // component={PlacementSidebar}
        />
        <Route
          exact
          path={"/placement-module/education-details"}
          // component={PlacementSidebar}
        />
        <Route
          exact
          path={"/placement-module/professional-details"}
          // component={PlacementSidebar}
        />
        <Route
          exact
          path={"/placement-module/resume-details"}
          // component={PlacementSidebar}
        />
        <Route
          exact
          path={"/placement-module/profile_preview"}
          // component={PlacementSidebar}
        />
        <Route exact path={"/dashboard/updt_mail"} component={SettingSidebar} />
        <Route exact path={"/dashboard/gedit"} component={SettingSidebar} />
        <Route exact path="/dashboard/profile" component={SettingSidebar} />
        <Route exact path="/dashboard/gdesc" component={SettingSidebar} />
        <Route exact path="/dashboard/course" component={LeftSidebar} />
        <Route exact path="/dashboard/explore" component={LeftSidebar} />
        <Route exact path="/dashboard/group" component={LeftSidebar} />
        <Route exact path="/dashboard/group/:gid" component={LeftSidebar} />
        <Route exact path="/dashboard/cgroup" component={LeftSidebar} />
        <Route exact path="/dashboard/jgroup" component={LeftSidebar} />
        <Route exact path="/dashboard/group-detail" component={GroupSidebar} />
        <Route exact path="/dashboard/combo" component={LeftSidebar} />
        <Route exact path="/dashboard/notifications" component={LeftSidebar} />
        <Route path={"/gradebook"} component={CourseSidebar} />
        <Route path={"/certificate"} component={CourseSidebar} />
        <Route path={"/dashboard/socketConnect"} component={CourseSidebar} />
        <Route
          exact
          path="/dashboard/manageDevices"
          component={SettingSidebar}
        />
        <Route
          exact
          path="/dashboard/troubleshootNotifications"
          component={SettingSidebar}
        />
        <Route
          exact
          path="*"
          render={() =>
            loggedIn ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/dashboard" />
            )
          }
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
});

const mapDispatchToProps = {
  setCurrentTab,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarContainer)
);
