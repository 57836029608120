import React, { Fragment, useEffect, useState } from "react";
import { ROUTES } from "../../constants/routes";
import { TIER_COLOR_CODE } from "../../constants/constants";
import { Link, useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import momentjs from "moment-timezone";
import {
  setSelectedCourse,
  authenticateCourse,
  getParam,
} from "../../actions/courseAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import { showFreshDesk } from "../../actions/showFreshDesk.js";
import "./placementportal.scss";
import ReactHtmlParser from "react-html-parser";
import Loader from "../utils/Loader.js";
import SidebarContainer from "../sidebars/SidebarContainer.js";
import PlacementHeader from "../headers/PlacementHeader.jsx";
import CourseHeader from "../headers/CourseHeader";
import { CONFIGS, STATIC_ROUNDS } from "../../constants/constants.js";
import TooltipItem from "../utils/GeneralToolTip.js";
import SkillTagTooltipItem from "../utils/SkillTagToolTip.js";
import { Progress, Tooltip } from "reactstrap";
import ContentContainer from "../homecontents/ContentContainer";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  FormGroup,
  Input,
} from "reactstrap";

import {
  applyCerti,
  atCrs,
  brandDataCourse,
  certificateStatus,
  gradebookData,
  tiersData,
  courseStatus,
} from "../../actions/gradebookAction.js";
import {
  placementProfileData,
  placementJobData,
  changeSaveStatus,
  addLearnerRemarks,
} from "../../actions/profileAction.js";
import { size } from "lodash";

var monthsArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

let query = window.location.href;
query = query.split("/");
const pid = query[4];
const gid = query[5];
const cid = query[6];
const levelId = query[7] != undefined && query[7] ? query[7] : null;

console.log({ query });
const cookies = new Cookies();

const data = [
  {
    company: "Alfreds Futterkiste",
    contact: "Maria Anders",
    country: "Germany",
  },
  {
    company: "Centro comercial Moctezuma",
    contact: "Maria Anders",
    country: "Mexico",
  },
];

const CareerServices = (props) => {
  const [brand, setBrand] = useState();
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [isPortalLocked, setIsPortalLocked] = useState(false);
  const [jobsApplied, setJobsApplied] = useState(0);
  const [jobsNotSelected, setJobsNotSelected] = useState(0);
  const [selfPaced, setSelfPaced] = useState();
  const [saveStatus, setSaveStatus] = useState(false);
  const [searchAllJobs, setSearchAllJobs] = useState([]);
  const [searchSavedJobs, setSearchSavedJobs] = useState([]);
  const [appliedSelected, setAppliedSelected] = useState(false);
  const [notAppliedSelected, setNotAppliedSelected] = useState(false);
  const [notSelected, setNotSelected] = useState(false);
  const [selectStaticStatus, setSelectStaticStatus] = useState(0);
  const [selectedPanel, setSelectedPanel] = useState(1);
  const [joining, setJoining] = useState();
  const [uid, setUid] = useState();
  const [courseName, setCourseName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [impartusEnabled, setImpartusEnabled] = useState();
  const [batchName, setBatchName] = useState();
  const [courseLocation, setCourseLocation] = useState();
  const [certiAutoPublish, setCertiAutoPublish] = useState();
  const [openCongratsModal, setOpenCongratsModal] = useState(false);
  const [openMissedModal, setOpenMissedModal] = useState(false);
  const [certiGenerated, setCertiGenerated] = useState();
  const [course, setCourse] = useState();
  const [status, setStatus] = useState();
  const [gstatus, setGstatus] = useState();
  const [crtfEligible, setCrtfEligible] = useState();
  const [certiAvailable, setCertiAvailable] = useState();
  const [certiLink, setCertiLink] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [tiersOpen, setTiersOpen] = useState({});
  const [dp, setDp] = useState();
  const [userName, setUserName] = useState();
  const [primaryEmail, setPrimaryEmail] = useState();
  const [loading, setLoading] = useState(true);
  const [gradebookLoaded, setGradebookLoaded] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [grdData, setGrdData] = useState({});
  const [studentGradebook, setStudentGradebook] = useState([]);
  const [collapseRow, setCollapseRow] = useState({});
  const [enableCategory, setEnableCategory] = useState(false);
  const [grdTotalPerc, setGrdTotalPerc] = useState({});
  const [grdTier, setGrdTier] = useState({});
  const [applyCert, setApplyCert] = useState({});
  const [certStatus, setCertStatus] = useState({});
  const [certiGeneratedStatus, setCertiGeneratedStatus] = useState({});
  const [crtfFileMap, setCrtfFileMap] = useState();

  const [oppShared, setOppShared] = useState(0);
  const [oppApplied, setOppApplied] = useState(0);
  const [oppNotSelected, setOppNotSelected] = useState(0);
  const [showWeightage, setShowWeightage] = useState(false);
  const [showPassMarks, setShowPassMarks] = useState(false);
  const [showPlcStatus, setShowPlcStatus] = useState(false);
  const [placementEnable, setPlacementEnable] = useState(false);
  const [columnSpan, setColumnSpan] = useState(false);
  const [gradebookType, setGradebookType] = useState(1);
  const [isElec, setIsElec] = useState(false);
  const [gradebookDataLoaded, setGradebookDataLoaded] = useState(false);
  const [breadCrumbsTemp, setBreadCrumbsTemp] = useState([]);
  const [individualBrand, setIndividualBrand] = useState({});
  const [skillTagPresent, setSkillTagPresent] = useState(false);
  const [allAssessmentPublished, setAllAssessmentPublished] = useState(false);
  const [learnerRemarks, setLearnerRemarks] = useState("");
  const [jobMissed, setJobMissed] = useState({});
  const [jobSelected, setJobSelected] = useState({});
  const [showPlcPassStatus, setShowPlcPassStatus] = useState(
    "Congratulations! You are eligible for Placement Criteria"
  );
  const [showPlcFailStatus, setShowPlcFailStatus] = useState(
    "You are not eligible for the Placement.  "
  );
  const [plcPassStatusText, setPlcPassStatusText] = useState("PASS");
  const [plcFailStatusText, setPlcFailStatusText] = useState("FAIL");
  const [showContentStatus, setShowContentStatus] = useState(false);
  const [searchString, setSearchString] = useState("");

  let ColorMap = {
    0: "sucess",
    1: "info",
    2: "warning",
    3: "danger",
    4: "primary",
    5: "secondary",
    6: "light",
    7: "dark",
  };
  useEffect(() => {
    (async () => {
      window.FreshworksWidget?.("hide", "launcher");
      //   setLoading(true);
      await authenticatCourse();
      await props.getProfileInfoOnly();
      await fetchBrandDataCrs();
      setDp(props.ppic);
      setUserName(props.profileName);
      setPrimaryEmail(props.primaryEmail);
      await getPlacementProfileData();
      await getPlacementJobData();
    })();
  }, []);
  useEffect(() => {
    (async () => {
      //   await getTiersData();
      //   await getCertificateStatus();
      //   await getCourseStatus();
      //   await getCerti();
    })();
  }, [gradebookDataLoaded]);

  useEffect(() => {
    console.log({ careerservicesprops: props });
  }, []);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.startsWith("/placement-portal/") &&
      location.pathname.length > "/placement-portal/".length
    ) {
      setOpenProfileModal(false);
    }
  }, [location]);

  const [state, setState] = useState(0);

  useEffect(() => {
    if (!placementEnable) {
      if (showContentStatus) {
        if (showPassMarks && showWeightage) {
          setColumnSpan(6);
        } else if (showPassMarks && !showWeightage) {
          setColumnSpan(4);
        } else if (!showPassMarks && showWeightage) {
          setColumnSpan(5);
        } else {
          setColumnSpan(3);
        }
      } else {
        if (showPassMarks && showWeightage) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage) {
          setColumnSpan(4);
        } else {
          setColumnSpan(2);
        }
      }
    } else {
      if (showContentStatus) {
        if (showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else if (showPassMarks && !showWeightage && !showPlcStatus) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (!showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(4);
        } else if (!showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else {
          setColumnSpan(3);
        }
      } else {
        if (showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else if (showPassMarks && !showWeightage && !showPlcStatus) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (!showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(4);
        } else if (!showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else {
          setColumnSpan(2);
        }
      }
    }
  }, [placementEnable, showWeightage, showPassMarks, showPlcStatus]);

  const handleClick = (index, data) => {
    let collapserow = collapseRow;

    if (collapserow[data]) {
      collapserow[data] = false;
      setCollapseRow({ ...collapserow });
    } else {
      collapserow[data] = true;
      setCollapseRow({ ...collapserow });
    }
  };

  const goToJobPreview = (job_id) => {
    // /job-view/:aid/:crs_pg_id/:job_id'
    console.log({ sc: props.selected_course });
    let pid = cookies.get("pid");
    let gid = props.selected_course.gids ? props.selected_course.gids[0] : null;
    let cid = props.selected_course ? props.selected_course._id : null;
    if (pid && gid && cid) {
      let url =
        CONFIGS.WEB_DASHBOARD_BASE_URL +
        "job-view" +
        "/" +
        pid +
        "/" +
        gid +
        "/" +
        cid +
        "/" +
        job_id;
      console.log({ url });
      window.location.href = url;
    }
  };

  const addJobMissedRemarks = async () => {
    if (learnerRemarks == "") {
      window.alert("Reason can't be empty");
      return;
    }
    addLearnerRemarks(
      jobMissed.crs_pg_id,
      jobMissed.job_id,
      jobMissed.aid,
      learnerRemarks
    )
      .then(function (res) {
        console.log(res, ":response data");
        let data = res.data.data;
        if (data && data.updatedApplication) {
          // setOpenApplicationModal(false);
          // setOpenSuccessModal(true);
          setOpenMissedModal(false);
        }
      })
      .catch(function (err) {
        setOpenMissedModal(false);
        console.log(err);
      });
  };

  const addJobSuccessRemarks = async () => {
    // if(learnerRemarks == ''){
    //   window.alert("Reason can't be empty")
    // }
    addLearnerRemarks(
      jobSelected.crs_pg_id,
      jobSelected.job_id,
      jobSelected.aid,
      "Selected"
    )
      .then(function (res) {
        console.log(res, ":response data");
        let data = res.data.data;
        if (data && data.updatedApplication) {
          // setOpenApplicationModal(false);
          // setOpenSuccessModal(true);
          setOpenCongratsModal(false);
          setOpenMissedModal(false);
        }
      })
      .catch(function (err) {
        setOpenMissedModal(false);
        console.log(err);
      });
  };

  const authenticatCourse = async () => {
    if (!cookies.get("isLoggedIn")) {
      return window.open(CONFIGS.WEB_DASHBOARD_BASE_URL, "_self");
    }
    atCrs({
      pid,
      gid,
      cid,
      dispatchIndividualBrand: props.dispatchIndividualBrand,
    })
      .then(function (res) {
        console.log(res);
        var expirydate = new Date();
        expirydate.setDate(expirydate.getDate() + 180);
        cookies.set("crs_tkn", res.data.data.at, {
          expires: expirydate,
          domain: CONFIGS.DOMAIN,
          path: "/",
        });

        if (res.data.data.joined) {
          setJoining(new Date(res.data.data.joined));
        }
        if (res.data.data.uid) {
          setUid(res.data.data.uid);
        }
        if (res.data.data.name) {
          setCourseName(res.data.data.name);
          let cname = res.data.data.name;
          setBreadCrumbsTemp([
            { cname, link: "/view/?cid=" + getParam("cid") },
          ]);
          console.log({ cname: res.data.data.name });
          console.log({ breadCrumbsTemp });
        }
        if (res.data.data && res.data.data.phoneno) {
          setPhoneNo(res.data.data.phoneno);
        }
        if (res.data.data.selfPaced) {
          setSelfPaced(res.data.data.selfPaced);
        }
        setImpartusEnabled(res.data.data.course.impartus_enabled);
        setShowPassMarks(res.data.data.course.show_pass_marks);
        setShowWeightage(res.data.data.course.show_weightage);
        if ("show_content_status" in res.data.data.course) {
          setShowContentStatus(res.data.data.course["show_content_status"]);
        }

        let passStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_text
          : "Congratulations! You are eligible for Placement Criteria";
        let failStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_text
          : "You are not eligible for the Placement.  ";
        let plcpassStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_status_text
          : "PASS";
        let plcfailStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_status_text
          : "FAIL";

        setPlcPassStatusText(plcpassStatus);
        setPlcFailStatusText(plcfailStatus);

        setShowPlcFailStatus(failStatus);
        setShowPlcPassStatus(passStatus);
        let grdtype = res.data.data.course.gradebook_type
          ? res.data.data.course.gradebook_type
          : 1;
        setGradebookType(grdtype);
        setPlacementEnable(res.data.data.course.plcmnt_enable);

        let showplc = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.gradebook_show_plcmnt_stts
          : false;
        setShowPlcStatus(showplc);
        setBatchName(res.data.data.course.btch_name);
        setCourseLocation(res.data.data.location);

        let courseFound = res.data.data.course;
        if (res.data.data.location) {
          courseFound.loc_name = res.data.data.location;
        }
        if (courseFound) {
          props.setSelectedCourse(courseFound);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getPlacementProfileData = async () => {
    placementProfileData(cid)
      .then(function (res) {
        setLoading(false);
        console.log(res, ":response data");
        let data = res.data.data;

        if (data && data.show_profile_prompt) {
          setOpenProfileModal(true);
          // setLoading(true);
        }

        if (data && data.isPortalLocked) {
          setIsPortalLocked(data.isPortalLocked);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getPlacementJobData = async () => {
    placementJobData(cid)
      .then(function (res) {
        console.log(res, ":response data");
        let data = res.data.data;
        setLoading(false);
        if (data && data.allJobs) {
          setAllJobs(data.allJobs);
          let appliedCount = data.allJobs.filter(
            (job) => job.job_app_static_status > 1
          ).length;
          console.log({ appliedCount });
          let joNotSeleced = data.allJobs.filter(
            (job) => job.job_app_static_status >= 6
          ).length;
          let oppSharedCount = data.allJobs.filter(
            (job) => job.job_status >= 3
          ).length;
          setJobsNotSelected(joNotSeleced);
          setJobsApplied(appliedCount);
          setOppShared(oppSharedCount);
          const missedJob = data.allJobs.find(
            (job) =>
              job.etim < Date.now() &&
              job.job_status >= 3 &&
              !job.learner_acknowledged
          );
          const selectedJob = data.allJobs.find(
            (job) =>
              job.job_app_static_status === 4 &&
              job.job_status >= 3 &&
              !job.learner_acknowledged
          );
          if (missedJob) {
            setJobMissed(missedJob);
            setOpenMissedModal(true);
          }
          if (selectedJob) {
            setJobSelected(selectedJob);
            setOpenCongratsModal(true);
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const saveJob = async (jobAppId) => {
    changeSaveStatus(jobAppId, true)
      .then(function (res) {
        console.log(res, ":response data");
        let data = res.data.data;
        if (data && data.updatedApplication) {
          const updatedJobId = data.updatedApplication._id;
          const updatedJobIndex = allJobs.findIndex(
            (job) => job._id === updatedJobId
          );

          if (updatedJobIndex !== -1) {
            const updatedJob = { ...allJobs[updatedJobIndex], is_saved: true };
            const updatedJobs = [...allJobs];
            updatedJobs[updatedJobIndex] = updatedJob;

            // Update the state with the updatedJobs array

            setAllJobs(updatedJobs);
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const unsaveJob = async (jobAppId) => {
    changeSaveStatus(jobAppId, false)
      .then(function (res) {
        console.log(res, ":response data");
        let data = res.data.data;
        if (data && data.updatedApplication) {
          const updatedJobId = data.updatedApplication._id;
          const updatedJobIndex = allJobs.findIndex(
            (job) => job._id === updatedJobId
          );

          if (updatedJobIndex !== -1) {
            const updatedJob = { ...allJobs[updatedJobIndex], is_saved: false };
            const updatedJobs = [...allJobs];
            updatedJobs[updatedJobIndex] = updatedJob;

            // Update the state with the updatedJobs array
            setAllJobs(updatedJobs);
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const fetchBrandDataCrs = async () => {
    brandDataCourse(cid)
      .then((response) => {
        var brand = response.data.data.brand;
        // props.dispatchIndividualBrand(brand);
        if (brand) {
          setBrand(brand);
        }
      })
      .catch((err) => {
        props.dispatchIndividualBrand();
        console.log({ err });
      });
  };

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const togglePopover2 = () => {
    setIsOpen2(!isOpen2);
  };

  const ensureHttps = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };
  const toggleTiersPopover = (index) => {
    console.log("tiertoggle", index, tiersOpen);
    let tiers_open = tiersOpen;
    tiers_open[index] = !tiers_open[index];
    console.log(tiers_open);
    setTiersOpen({ ...tiers_open });
  };

  return !loading ? (
    <div className="course-page gradebookPage">
      {/* <PlacementHeader
        breadCrumbs={[
          { name: "Dashboard", link: "/" },
          { name: "Course", link: `/view?cid=${cid}` },
        ]}
        individualBrand={brand}
      /> */}
      <CourseHeader
        breadCrumbs={[
          { name: courseName, link: `/view?cid=${cid}` },
          // { name: "Course", link: `/view?cid=${cid}` },
        ]}
        individualBrand={props.brand}
      />
      <SidebarContainer crs_pg_id={props.selected_course.crs_pg_id} />
      {/*Check Placement Access here */}

      <div className="col-xs-12 rvm-v1" id="content">
        <div className="breadCrumbsContainer">
          <div className="rvm-v1-header-brdsc-ctnr breadCrumbs-row">
            <div className="breadCrumbs">
              <a href="/dashboard">
                <span>My Courses</span>
              </a>
              <i
                style={{ fontSize: "small", marginLeft: "10px" }}
                className="fa fa-chevron-right"
              ></i>
              <a href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}view/?cid=${cid}`}>
                {courseName}
              </a>
              <i
                style={{
                  fontSize: "small",
                  marginLeft: "10px",
                }}
                className="fa fa-chevron-right"
              ></i>
              <a href={""} className="rvm-v1-header-brdsc-active">
                <span>Career Services</span>
              </a>
            </div>
          </div>
        </div>
        {!isPortalLocked && oppShared >= 0 ? (
          <div>
            <div className="rvm-v1-cont-ctnr-3">
              <div className="revenuemaincontainer">
                <div
                  className={`revenuecontainer ${
                    selectedPanel === 1 ? "selected-jobtype" : ""
                  }`}
                  onClick={() => {
                    setSelectedPanel(1);
                    setSearchString("");
                  }}
                >
                  <h4>Opportunities Shared</h4>
                  <h2> {allJobs ? allJobs.length : 0}</h2>
                </div>

                <div
                  className={`revenuecontainer ${
                    selectedPanel === 2 ? "selected-jobtype" : ""
                  }`}
                  onClick={() => {
                    setSelectedPanel(2);
                    setSearchString("");
                  }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <h4>Opportunities Applied</h4>
                    <h4 className="tooltipl"></h4>
                  </div>
                  <h2> {jobsApplied > 0 ? jobsApplied : 0}</h2>
                </div>

                <div
                  className={`revenuecontainer ${
                    selectedPanel === 3 ? "selected-jobtype" : ""
                  }`}
                  onClick={() => {
                    setSelectedPanel(3);
                    setSearchString("");
                  }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <h4>Opportunities Not Selected</h4>
                    <h4 className="tooltipl"></h4>
                  </div>
                  <h2 style={{ color: "#DC3545" }}>
                    {" "}
                    {allJobs ? jobsNotSelected : 0}
                  </h2>
                </div>
                <div
                  className={`revenuecontainer ${
                    selectedPanel === 4 ? "selected-jobtype" : ""
                  }`}
                  onClick={() => {
                    setSelectedPanel(4);
                    setSearchString("");
                  }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <h4>Opportunities Not Applied</h4>
                    <h4 className="tooltipl"></h4>
                  </div>
                  <h2 style={{ color: "#DC3545" }}>
                    {" "}
                    {allJobs ? allJobs.length - jobsApplied : 0}
                  </h2>
                </div>
              </div>

              {selectedPanel === 1 && (
                <div className="row revenuemaincontainer-2">
                  <div className="col-md-6">
                    <div className="row">
                      <div
                        className="revenuecontainer-2 job-button-container"
                        onClick={() => setSaveStatus(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <p className={saveStatus ? "" : "container-selected"}>
                          All Jobs {allJobs ? `(${allJobs.length})` : "(0)"}{" "}
                        </p>
                        {/* <h2> {leadData ? leadData.admsissionCount : 0}</h2> */}
                      </div>
                      <div
                        className="revenuecontainer-2"
                        onClick={() => setSaveStatus(true)}
                      >
                        <div
                          className="job-button-container"
                          style={{ cursor: "pointer" }}
                        >
                          <p
                            className={!saveStatus ? "" : "container-selected"}
                          >
                            Saved Jobs (
                            {allJobs.filter((job) => job.is_saved).length})
                          </p>
                        </div>
                        {/* <h2> {leadData ? leadData.admsissionCount : 0}</h2> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedPanel === 2 && (
                <div className="row revenuemaincontainer-2">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="revenuecontainer-2 job-button-container">
                        <p className={saveStatus ? "" : "container-selected"}>
                          Applied Jobs {allJobs ? `(${jobsApplied})` : "(0)"}{" "}
                        </p>
                        {/* <h2> {leadData ? leadData.admsissionCount : 0}</h2> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedPanel === 4 && (
                <div className="row revenuemaincontainer-2">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="revenuecontainer-2 job-button-container">
                        <p className={saveStatus ? "" : "container-selected"}>
                          Not Applied Jobs{" "}
                          {allJobs
                            ? `(${allJobs.length - jobsApplied})`
                            : "(0)"}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedPanel === 3 && (
                <div className="row revenuemaincontainer-2">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="revenuecontainer-2 job-button-container">
                        <p className={saveStatus ? "" : "container-selected"}>
                          Not Selected Jobs{" "}
                          {allJobs ? `(${jobsNotSelected})` : "(0)"}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {selectedPanel === 1 &&
              (!saveStatus ? (
                <div className="rvm-v1-cont-ctnr rvm-v1-part-2-otr-ctnr">
                  <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                    <FormGroup style={{ marginBottom: "0px !important" }}>
                      <Input
                        type="text"
                        name="text"
                        id="courseShortCode"
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        style={{ width: "98%" }}
                        // disabled={companyName !== ""}
                        placeholder="Search"
                      />
                    </FormGroup>
                  </div>
                </div>
              ) : (
                <div className="rvm-v1-cont-ctnr rvm-v1-part-2-otr-ctnr">
                  <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                    <FormGroup style={{ marginBottom: "0px !important" }}>
                      <Input
                        type="text"
                        name="text"
                        id="courseShortCode"
                        value={searchSavedJobs}
                        // onChange={(e) =>handleGradChange("perc", e.target.value)}
                        style={{ width: "98%" }}
                        // disabled={companyName !== ""}
                        placeholder="Search"
                      />
                    </FormGroup>
                  </div>
                </div>
              ))}

            {selectedPanel === 2 && (
              <div className="rvm-v1-cont-ctnr rvm-v1-part-2-otr-ctnr">
                <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                  <FormGroup style={{ marginBottom: "0px !important" }}>
                    <Input
                      type="text"
                      name="text"
                      id="courseShortCode"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      style={{ width: "98%" }}
                      // disabled={companyName !== ""}
                      placeholder="Search Applied Jobs"
                    />
                  </FormGroup>
                </div>
              </div>
            )}

            {selectedPanel === 3 && (
              <div className="rvm-v1-cont-ctnr rvm-v1-part-2-otr-ctnr">
                <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                  <FormGroup style={{ marginBottom: "0px !important" }}>
                    <Input
                      type="text"
                      name="text"
                      id="courseShortCode"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      style={{ width: "98%" }}
                      // disabled={companyName !== ""}
                      placeholder="Search Not Selected Jobs"
                    />
                  </FormGroup>
                </div>
              </div>
            )}

            {selectedPanel === 4 && (
              <div className="rvm-v1-cont-ctnr rvm-v1-part-2-otr-ctnr">
                <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                  <FormGroup style={{ marginBottom: "0px !important" }}>
                    <Input
                      type="text"
                      name="text"
                      id="courseShortCode"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      style={{ width: "98%" }}
                      // disabled={companyName !== ""}
                      placeholder="Search Not Applied Jobs"
                    />
                  </FormGroup>
                </div>
              </div>
            )}

            {selectedPanel === 1 &&
              allJobs
                // .filter(
                //   (job) =>
                //     (selectStaticStatus == 0 ||
                //       job.job_app_static_status === selectStaticStatus) &&
                //     job.job_status >= 3
                // )
                .filter(
                  (job) =>
                    job.job_status >= 3 &&
                    (searchString === "" ||
                      job.job_title
                        .toLowerCase()
                        .includes(searchString.toLowerCase()))
                )
                .map((job, index) => (
                  <div
                    key={index}
                    className="rvm-v1-cont-ctnr rvm-v1-part-2-otr-ctnr"
                    style={{
                      display: saveStatus && !job.is_saved ? "none" : "block",
                    }}
                  >
                    <div className="main rvm-v1-part-2-ctnr no-padding">
                      <div className="rvm-v1-certi-ctnr no-padding">
                        <div className="rvm-v1-part-2-inr-ctnr some-padding">
                          <div className="rvm-v1-part-2-logo-ctnr rvm-pc no-padding">
                            <div className="rvm-v1-part-2-certi-logo-1">
                              <img
                                src={
                                  job.company_logo
                                    ? job.company_logo
                                    : "https://webcdn.imarticus.org/Placement/Suitcase.svg"
                                }
                                alt="Company Logo"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div className="rvm-v1-part-2-info-ctnr">
                            <div className="with-100 ">
                              <div className="row no-margin">
                                <div className="col-md-8 job-info-ttl">
                                  {job.job_title}
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-2 save-job">
                                  <span>{job.timeAgo || "-"} </span>
                                  {!job.is_saved ? (
                                    <span
                                      style={{
                                        paddingRight: "0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => saveJob(job._id)}
                                    >
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/material-symbols_bookmark-outline.svg"
                                        height={30}
                                        width={30}
                                        alt="bookmark"
                                      />
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        paddingRight: "0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => unsaveJob(job._id)}
                                    >
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/material-symbols_bookmark-outline1.svg"
                                        height={30}
                                        width={30}
                                        alt="bookmark"
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="with-100 job-quick-info row no-margin">
                              <div className="col-xs-12 col-sm-8 col-md-9 col-lg-6 no-padding">
                                <span className="rvm-v1-part-2-logo-ctnr rvm-mobile no-padding">
                                  <span className="rvm-v1-part-2-certi-logo"></span>
                                </span>
                                <div
                                  className="companyTopRow-2"
                                  style={{
                                    paddingTop: "6px",
                                  }}
                                >
                                  <li>
                                    <span>{job.company_name}</span> |{" "}
                                    <span>{job.timeAgo || "-"}</span> |{" "}
                                    <span>
                                      Last Date:{" "}
                                      <span style={{ color: "red" }}>
                                        {momentjs(job.etim).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </span>
                                    </span>
                                  </li>
                                  <a
                                    href={ensureHttps(job.company_website)}
                                    className="companyWebsite"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {job.company_website}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rvm-v1-part-2-inr-ctnr some-padding">
                          <div className="job-info-ctnr">
                            <span
                              className="rvm-v1-chap-liv-lec-det-intr-icon-ctnr"
                              style={{ paddingBottom: "8px" }}
                            ></span>

                            <div className="row">
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-md-3">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/mingcute_suitcase-line.svg"
                                        height={20}
                                        width={20}
                                        alt="experience"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.work_experience.join(", ")}
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/tdesign_money.svg"
                                        height={20}
                                        width={20}
                                        alt="salary"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.ctc_type === 1
                                        ? `${job.ctc_range_min}-${job.ctc_range_max}`
                                        : `${job.ctc_amount}`}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/mingcute_location-line.svg"
                                        height={20}
                                        width={20}
                                        alt="location"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.job_location.join(", ")}
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    <span className="job-toppoints-2">
                                      Status:
                                    </span>
                                    <span
                                      className="job-toppoints-3"
                                      style={{
                                        color:
                                          job.job_app_static_status === 3
                                            ? "red"
                                            : job.job_app_static_status === 4
                                            ? "darkgreen"
                                            : undefined,
                                      }}
                                    >
                                      {job.etim < Date.now() &&
                                      job.job_app_static_status === 1
                                        ? "Job Expired"
                                        : job.job_app_static_status === 1
                                        ? "Active"
                                        : job.job_app_static_status === 2
                                        ? "Applied"
                                        : job.job_app_static_status === 3
                                        ? "In Progress"
                                        : job.job_app_static_status === 4
                                        ? "Selected"
                                        : job.job_app_static_status >= 6
                                        ? "Rejected"
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1"></div>
                              <div className="col-md-2">
                                <Button
                                  className="jobs-viewjob-btn"
                                  onClick={() => goToJobPreview(job.job_id)}
                                >
                                  View Job
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            {selectedPanel === 2 &&
              allJobs
                .filter(
                  (job) =>
                    job.job_app_static_status > 1 &&
                    job.job_status >= 3 &&
                    (searchString === "" ||
                      job.job_title
                        .toLowerCase()
                        .includes(searchString.toLowerCase()))
                )
                .map((job, index) => (
                  <div
                    key={index}
                    className="rvm-v1-cont-ctnr rvm-v1-part-2-otr-ctnr"
                    style={{
                      display: saveStatus && !job.is_saved ? "none" : "block",
                    }}
                  >
                    <div className="main rvm-v1-part-2-ctnr no-padding">
                      <div className="rvm-v1-certi-ctnr no-padding">
                        <div className="rvm-v1-part-2-inr-ctnr some-padding">
                          <div className="rvm-v1-part-2-logo-ctnr rvm-pc no-padding">
                            <div className="rvm-v1-part-2-certi-logo-1">
                              <img
                                src={
                                  job.company_logo
                                    ? job.company_logo
                                    : "https://webcdn.imarticus.org/Placement/Suitcase.svg"
                                }
                                alt="Company Logo"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div className="rvm-v1-part-2-info-ctnr">
                            <div className="with-100 ">
                              <div className="row no-margin">
                                <div className="col-md-8 job-info-ttl">
                                  {job.job_title}
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-2 save-job">
                                  <span>{job.timeAgo || "-"} </span>
                                  {!job.is_saved ? (
                                    <span
                                      style={{
                                        paddingRight: "0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => saveJob(job._id)}
                                    >
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/material-symbols_bookmark-outline.svg"
                                        height={30}
                                        width={30}
                                        alt="bookmark"
                                      />
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        paddingRight: "0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => unsaveJob(job._id)}
                                    >
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/material-symbols_bookmark-outline1.svg"
                                        height={30}
                                        width={30}
                                        alt="bookmark"
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="with-100 job-quick-info row no-margin">
                              <div className="col-xs-12 col-sm-8 col-md-9 col-lg-6 no-padding">
                                <span className="rvm-v1-part-2-logo-ctnr rvm-mobile no-padding">
                                  <span className="rvm-v1-part-2-certi-logo"></span>
                                </span>
                                <div
                                  className="companyTopRow-2"
                                  style={{ paddingTop: "6px" }}
                                >
                                  <li>
                                    <span>{job.company_name}</span> |{" "}
                                    <span>{job.timeAgo || "-"}</span> |{" "}
                                    <span>
                                      Last Date:{" "}
                                      <span style={{ color: "red" }}>
                                        {momentjs(job.etim).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </span>
                                    </span>
                                  </li>
                                  <a
                                    href={ensureHttps(job.company_website)}
                                    className="companyWebsite"
                                  >
                                    {job.company_website}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rvm-v1-part-2-inr-ctnr some-padding">
                          <div className="job-info-ctnr">
                            <span
                              className="rvm-v1-chap-liv-lec-det-intr-icon-ctnr"
                              style={{ paddingBottom: "8px" }}
                            ></span>

                            <div className="row">
                              <div className="col-md-8">
                                <div className="row">
                                  <div className="col-md-4">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/mingcute_suitcase-line.svg"
                                        height={20}
                                        width={20}
                                        alt="experience"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.work_experience.join(", ")}
                                    </span>
                                  </div>
                                  <div className="col-md-4">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/tdesign_money.svg"
                                        height={20}
                                        width={20}
                                        alt="salary"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.ctc_type === 1
                                        ? `${job.ctc_range_min}-${job.ctc_range_max}`
                                        : `${job.ctc_amount}`}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/mingcute_location-line.svg"
                                        height={20}
                                        width={20}
                                        alt="location"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.job_location.join(", ")}
                                    </span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-4">
                                    <span className="job-toppoints-4">
                                      Applied On:
                                    </span>
                                    <span className="job-toppoints-3">
                                      {momentjs(job.etim).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div className="col-md-4">
                                    <span className="job-toppoints-4">
                                      Round:
                                    </span>
                                    <span className="job-toppoints-3">
                                      {job.job_app_static_status === 3
                                        ? job.rounds_order[
                                            job.job_app_dynamic_status
                                          ].label
                                        : STATIC_ROUNDS[
                                            job.job_app_static_status
                                          ]}
                                    </span>
                                  </div>
                                  <div className="col-md-4">
                                    <span className="job-toppoints-4">
                                      Status:
                                    </span>
                                    <span
                                      className="job-toppoints-3"
                                      style={{
                                        color:
                                          job.job_app_static_status === 3
                                            ? "red"
                                            : job.job_app_static_status === 4
                                            ? "darkgreen"
                                            : undefined,
                                      }}
                                    >
                                      {job.job_app_static_status === 1
                                        ? "Active"
                                        : job.job_app_static_status === 2
                                        ? "Applied"
                                        : job.job_app_static_status === 3
                                        ? "In Progress"
                                        : job.job_app_static_status === 4
                                        ? "Selected"
                                        : job.job_app_static_status >= 6
                                        ? "Rejected"
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2"></div>
                              <div className="col-md-2">
                                <Button
                                  className="jobs-viewjob-btn"
                                  onClick={() => goToJobPreview(job.job_id)}
                                >
                                  Track Progress
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            {selectedPanel === 3 &&
              allJobs
                .filter(
                  (job) =>
                    (job.job_app_static_status === 6 ||
                      job.job_app_static_status === 7) &&
                    job.job_status >= 3 &&
                    (searchString === "" ||
                      job.job_title
                        .toLowerCase()
                        .includes(searchString.toLowerCase()))
                )
                .map((job, index) => (
                  <div
                    key={index}
                    className="rvm-v1-cont-ctnr rvm-v1-part-2-otr-ctnr"
                    style={{
                      display: saveStatus && !job.is_saved ? "none" : "block",
                    }}
                  >
                    <div className="main rvm-v1-part-2-ctnr no-padding">
                      <div className="rvm-v1-certi-ctnr no-padding">
                        <div className="rvm-v1-part-2-inr-ctnr some-padding">
                          <div className="rvm-v1-part-2-logo-ctnr rvm-pc no-padding">
                            <div className="rvm-v1-part-2-certi-logo-1">
                              <img
                                src={
                                  job.company_logo
                                    ? job.company_logo
                                    : "https://webcdn.imarticus.org/Placement/Suitcase.svg"
                                }
                                alt="Company Logo"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div className="rvm-v1-part-2-info-ctnr">
                            <div className="with-100 ">
                              <div className="row no-margin">
                                <div className="col-md-8 job-info-ttl">
                                  {job.job_title}
                                </div>
                                <div className="col-md-2"></div>
                                <div
                                  className="col-md-2 save-job"
                                  style={{ paddingRight: "0px" }}
                                >
                                  <span>{job.timeAgo || "-"} </span>
                                  {!job.is_saved ? (
                                    <span
                                      // style={{ paddingRight: "15px" }}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => saveJob(job._id)}
                                    >
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/material-symbols_bookmark-outline.svg"
                                        height={30}
                                        width={30}
                                        alt="bookmark"
                                      />
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        paddingRight: "0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => unsaveJob(job._id)}
                                    >
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/material-symbols_bookmark-outline1.svg"
                                        height={30}
                                        width={30}
                                        alt="bookmark"
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="with-100 job-quick-info row no-margin">
                              <div className="col-xs-12 col-sm-8 col-md-9 col-lg-6 no-padding">
                                <span className="rvm-v1-part-2-logo-ctnr rvm-mobile no-padding">
                                  <span className="rvm-v1-part-2-certi-logo"></span>
                                </span>
                                <div
                                  className="companyTopRow-2"
                                  style={{ paddingTop: "6px" }}
                                >
                                  <li>
                                    <span>{job.company_name}</span> |{" "}
                                    <span>{job.timeAgo || "-"}</span> |{" "}
                                    <span>
                                      Last Date:{" "}
                                      <span style={{ color: "red" }}>
                                        {momentjs(job.etim).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </span>
                                    </span>
                                  </li>
                                  <a
                                    href={ensureHttps(job.company_website)}
                                    className="companyWebsite"
                                  >
                                    {job.company_website}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rvm-v1-part-2-inr-ctnr some-padding">
                          <div className="job-info-ctnr">
                            <span
                              className="rvm-v1-chap-liv-lec-det-intr-icon-ctnr"
                              style={{ paddingBottom: "8px" }}
                            ></span>

                            <div className="row">
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-md-3">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/mingcute_suitcase-line.svg"
                                        height={20}
                                        width={20}
                                        alt="experience"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.work_experience.join(", ")}
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/tdesign_money.svg"
                                        height={20}
                                        width={20}
                                        alt="salary"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.ctc_type === 1
                                        ? `${job.ctc_range_min}-${job.ctc_range_max}`
                                        : `${job.ctc_amount}`}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/mingcute_location-line.svg"
                                        height={20}
                                        width={20}
                                        alt="location"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.job_location.join(", ")}
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    <span className="job-toppoints-2">
                                      Status:
                                    </span>
                                    <span
                                      className="job-toppoints-3"
                                      style={{
                                        color:
                                          job.job_app_static_status === 3
                                            ? "red"
                                            : job.job_app_static_status === 4
                                            ? "darkgreen"
                                            : undefined,
                                      }}
                                    >
                                      {job.etim < Date.now() &&
                                      job.job_app_static_status === 1
                                        ? "Job Expired"
                                        : job.job_app_static_status === 1
                                        ? "Active"
                                        : job.job_app_static_status === 2
                                        ? "Applied"
                                        : job.job_app_static_status === 3
                                        ? "In Progress"
                                        : job.job_app_static_status === 4
                                        ? "Selected"
                                        : job.job_app_static_status >= 6
                                        ? "Rejected"
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1"></div>
                              <div className="col-md-2">
                                <Button
                                  className="jobs-viewjob-btn"
                                  onClick={() => goToJobPreview(job.job_id)}
                                >
                                  View Job
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            {selectedPanel === 4 &&
              allJobs
                .filter(
                  (job) =>
                    job.job_app_static_status === 1 &&
                    job.job_status >= 3 &&
                    (searchString === "" ||
                      job.job_title
                        .toLowerCase()
                        .includes(searchString.toLowerCase()))
                )
                .map((job, index) => (
                  <div
                    key={index}
                    className="rvm-v1-cont-ctnr rvm-v1-part-2-otr-ctnr"
                    style={{
                      display: saveStatus && !job.is_saved ? "none" : "block",
                    }}
                  >
                    <div className="main rvm-v1-part-2-ctnr no-padding">
                      <div className="rvm-v1-certi-ctnr no-padding">
                        <div className="rvm-v1-part-2-inr-ctnr some-padding">
                          <div className="rvm-v1-part-2-logo-ctnr rvm-pc no-padding">
                            <div className="rvm-v1-part-2-certi-logo-1">
                              <img
                                src={
                                  job.company_logo
                                    ? job.company_logo
                                    : "https://webcdn.imarticus.org/Placement/Suitcase.svg"
                                }
                                alt="Company Logo"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div className="rvm-v1-part-2-info-ctnr">
                            <div className="with-100 ">
                              <div className="row no-margin">
                                <div className="col-md-8 job-info-ttl">
                                  {job.job_title}
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-2 save-job">
                                  <span>{job.timeAgo || "-"}</span>
                                  {!job.is_saved ? (
                                    <span
                                      style={{
                                        paddingRight: "0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => saveJob(job._id)}
                                    >
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/material-symbols_bookmark-outline.svg"
                                        height={30}
                                        width={30}
                                        alt="bookmark"
                                      />
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        paddingRight: "0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => unsaveJob(job._id)}
                                    >
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/material-symbols_bookmark-outline1.svg"
                                        height={30}
                                        width={30}
                                        alt="bookmark"
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="with-100 job-quick-info row no-margin">
                              <div className="col-xs-12 col-sm-8 col-md-9 col-lg-6 no-padding">
                                <span className="rvm-v1-part-2-logo-ctnr rvm-mobile no-padding">
                                  <span className="rvm-v1-part-2-certi-logo"></span>
                                </span>
                                <div
                                  className="companyTopRow-2"
                                  style={{ paddingTop: "6px" }}
                                >
                                  <li>
                                    <span>{job.company_name}</span> |{" "}
                                    <span>{job.timeAgo || "-"}</span> |{" "}
                                    <span>
                                      Last Date:{" "}
                                      <span style={{ color: "red" }}>
                                        {momentjs(job.etim).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </span>
                                    </span>
                                  </li>
                                  <a
                                    href={ensureHttps(job.company_website)}
                                    className="companyWebsite"
                                  >
                                    {job.company_website}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rvm-v1-part-2-inr-ctnr some-padding">
                          <div className="job-info-ctnr">
                            <span
                              className="rvm-v1-chap-liv-lec-det-intr-icon-ctnr"
                              style={{ paddingBottom: "8px" }}
                            ></span>

                            <div className="row">
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-md-3">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/mingcute_suitcase-line.svg"
                                        height={20}
                                        width={20}
                                        alt="experience"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.work_experience.join(", ")}
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/tdesign_money.svg"
                                        height={20}
                                        width={20}
                                        alt="salary"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.ctc_type === 1
                                        ? `${job.ctc_range_min}-${job.ctc_range_max}`
                                        : `${job.ctc_amount}`}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    <span>
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/mingcute_location-line.svg"
                                        height={20}
                                        width={20}
                                        alt="location"
                                      />
                                    </span>
                                    <span className="job-toppoints">
                                      {job.job_location.join(", ")}
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    <span className="job-toppoints-2">
                                      Status:
                                    </span>
                                    <span
                                      className="job-toppoints-3"
                                      style={{
                                        color:
                                          job.job_app_static_status === 3
                                            ? "red"
                                            : job.job_app_static_status === 4
                                            ? "darkgreen"
                                            : undefined,
                                      }}
                                    >
                                      {job.etim < Date.now() &&
                                      job.job_app_static_status === 1
                                        ? "Job Expired"
                                        : job.job_app_static_status === 1
                                        ? "Active"
                                        : job.job_app_static_status === 2
                                        ? "Applied"
                                        : job.job_app_static_status === 3
                                        ? "In Progress"
                                        : job.job_app_static_status === 4
                                        ? "Selected"
                                        : job.job_app_static_status >= 6
                                        ? "Rejected"
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1"></div>
                              <div className="col-md-2">
                                <Button
                                  className="jobs-viewjob-btn"
                                  onClick={() => goToJobPreview(job.job_id)}
                                >
                                  View Job
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        ) : (
          !openProfileModal && (
            <div className="job-not-found">
              <img src="https://webcdn.imarticus.org/Placement/portal-locked.svg"></img>
              <div style={{ marginBottom: "1rem" }}>
                <br></br>
                <h5>Your Placement Access is Currently Locked,</h5>
                <h5>Please Complete the Course to Unlock</h5>
              </div>
              {/* <div>
              <p style={{ marginLeft: '1rem' }}>First create a job by clicking on</p>
              <p>"Create a Job" to view jobs here</p>
            </div> */}
            </div>
          )
        )}
      </div>

      <Modal
        isOpen={openProfileModal}
        //   toggle={toggleShowSettingsHandler}
        size={"xl"}
        className="prof-dtl-modal"
        centered
      >
        <ModalBody
          style={{
            maxHeight: "420px",
            paddingBottom: "0px",
            paddingTop: "0px",
            marginBottom: "48px",
            marginTop: "48px",
          }}
        >
          <div>
            <h2 className="prof-dtl-header">Welcome to Career Services!</h2>
            <div className="prof-dtl-img-4">
              <img
                src="https://webcdn.imarticus.org/Placement/973113912.png"
                alt="cross"
                style={{ height: "20%" }}
                // onClick={closeRefer}
              />
            </div>
            <p className="prof-dtl-txt">
              Before exploring jobs, Kindly create your job profile and upload
              your resume
            </p>
          </div>
          <div className="btn-section-3">
            <Link
              to={`/placement-module/basic_details/?crs_pg_id=${props.selected_course.crs_pg_id}`}
              className="crs-resume-btn-3"
            >
              Complete Your Profile &nbsp;
              <img src="https://webcdn.imarticus.org/Placement/ph_arrow-up-bold.svg"></img>
            </Link>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openCongratsModal}
        //   toggle={toggleShowSettingsHandler}
        size={"xl"}
        className="prof-dtl-modal"
        style={{ maxWidth: "600px" }}
        centered
      >
        <ModalHeader className="apply-job">
          <div>
            <h5 className="job-aply-header">
              Congratulations! You are selected
            </h5>
          </div>
        </ModalHeader>
        <ModalBody
          className="apply-body"
          style={{
            maxHeight: "400px",
            overflowY: "scroll",
            paddingTop: "13px",
          }}
        >
          <div className="congrats-wrap-txt">
            <p className="row highlight-name">Hi {jobSelected.nam},</p>
            <p className="row highlight-text">
              Congratulations! 🎉 You've been selected! Let’s embark on your
              exciting journey ahead. Let's make great things happen together!
              🚀
            </p>
          </div>
          <div className="adques-all" style={{ display: "flex" }}>
            <div className="congrats-div-job ">
              <img
                src={
                  jobSelected.company_logo
                    ? jobSelected.company_logo
                    : "https://webcdn.imarticus.org/Placement/Suitcase.svg"
                }
                className="as-dl-logo-job-2"
                // onClick={() =>
                //   previewResumeClick(file.resume_url)
                // }
              />
            </div>
            <div className="congrats-div-job-content">
              <div className="congrats-div-job-txt">
                <h5 className="congrats-div-job-txt">
                  {jobSelected.job_title}
                </h5>{" "}
              </div>
              <div className="congrats-div-job-txt-2">
                <p className="congrats-div-job-txt-2 ">
                  <span>{jobSelected.company_name}</span>
                </p>
              </div>
              <div className="congrats-div-job-txt-3">
                <p className="congrats-div-job-txt-3">
                  <span>
                    Job Status:{" "}
                    <span style={{ color: "#00A400" }}>Selected</span>
                  </span>{" "}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              addJobSuccessRemarks();
            }}
            className="apply-success-btn"
          >
            Done
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openMissedModal}
        //   toggle={toggleShowSettingsHandler}
        size={"xl"}
        className="prof-dtl-modal"
        style={{ maxWidth: "600px" }}
        centered
      >
        <ModalHeader className="apply-job">
          <div>
            <h5 className="job-aply-header">
              You have missed some job opportunities
            </h5>
          </div>
        </ModalHeader>
        <ModalBody
          className="apply-body"
          style={{
            maxHeight: "500px",
            // overflowY: "scroll",
            paddingTop: "13px",
          }}
        >
          <div className="congrats-wrap-txt">
            <p className="row highlight-text">
              Before exploring more jobs, share the reason that you didnt apply
              for the below job
            </p>
          </div>
          <div className="adques-all" style={{ display: "flex" }}>
            <div className="congrats-div-job ">
              <img
                src={
                  jobMissed.company_logo
                    ? jobMissed.company_logo
                    : "https://webcdn.imarticus.org/Placement/Suitcase.svg"
                }
                className="as-dl-logo-job-2"
              />
            </div>
            <div className="congrats-div-job-content">
              <div className="congrats-div-job-txt">
                <h5 className="congrats-div-job-txt">{jobMissed.job_title}</h5>{" "}
              </div>
              <div className="congrats-div-job-txt-2">
                <p className="congrats-div-job-txt-2 ">
                  <span>{jobMissed.company_name}</span> |{" "}
                  <span>{jobMissed.timeAgo}</span> |{" "}
                  <span>
                    Last Date:{" "}
                    <span style={{ color: "red" }}>
                      {momentjs(jobMissed.etim).format("DD MMMM YYYY")}
                    </span>
                  </span>
                </p>
              </div>
              <div className="congrats-div-job-txt-3">
                <p className="congrats-div-job-txt-3">
                  <span>
                    Job Status:{" "}
                    <span style={{ color: "#DC3545" }}>Expired</span>
                  </span>{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="missed-reason-heading">
            Enter your Reason* (max 300 Words)
          </div>
          <div className="missed-txt-div">
            <textarea
              className="form-control"
              rows="3"
              placeholder="Enter your answer here"
              style={{ width: "100%" }}
              value={learnerRemarks}
              onChange={(e) => setLearnerRemarks(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            // color="success"
            onClick={() => {
              addJobMissedRemarks();
            }}
            className="apply-success-btn"
          >
            Send
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  primaryEmail: state.profile.email,
  ppic: state.profile.ppic,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
  getProfileInfoOnly,
  setSelectedCourse,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CareerServices)
);
