import React, { Component } from "react";
import { Input } from "reactstrap";
import backArrow from "../../images/backArrow.svg";
import CountryCodes from "../../constants/countryCodes";
import { ROUTES } from "../../constants/routes";
import Axios from "axios";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { utils } from "../../utility/utils";
import Spinner from "../utils/Spinner";
import Loader from "../utils/Loader";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Cookies from "universal-cookie";
import { CONFIGS } from "../../constants/constants";
import { dispatchIndividualBrand } from "../../actions/brandAction";
const cookies = new Cookies();

let errorMessages = {
  incorrectNumber:
    "No account is linked with this number. Kindly try another method.",
  incorrectEmail:
    "No account is linked with this mail Id. Kindly try another method.",
  invalidOTP: "Invalid OTP",
  invalidEmail: "Invalid Email ID, kindly check it again.",
  invalidNumber: "Invalid Mobile Number, kindly check it again.",
  bruteforce: "Try again later, you have entered too many wrong otps.",
};

class LoginWithOtp extends Component {
  constructor(props) {
    super(props);

    this.captcha = React.createRef();
    let query = new URLSearchParams(this.props.location.search);
    let resetType = query.get("type") || "mobile";
    this.redirectValue = query.get("redirect");
    let brand = props.match.params.brand;
    console.log({ brand });
    if (brand) {
      this.state = {
        email: "",
        mobile: "",
        ccod: "91",
        isEmail: resetType == "email",
        incorrectEmail: false,
        incorrectNumber: false,
        invalidOTP: false,
        endTime: 5 * 60,
        otp: "",
        isVerify: false,
        isLoading: true,
        invalidEmail: false,
        invalidNumber: false,
        bruteforce: false,
        captchaVerified: false,
        customError: "",
        token: "",
        captchaSiteKey: "",
        brand: this.props.brand,
      };
      Axios.get(ROUTES.FETCH_BRAND_DATA + `?brand_id=${brand}`)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          console.log({ brandData });
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success && brandData) {
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          } else {
            this.props.history.push(`/login/otp?type=${resetType}`);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log({ err });
          this.props.history.push(`/login/otp?type=${resetType}`);
          window.location.reload();
        });
    } else {
      this.state = {
        email: "",
        mobile: "",
        ccod: "91",
        isEmail: resetType == "email",
        incorrectEmail: false,
        incorrectNumber: false,
        invalidOTP: false,
        endTime: 5 * 60,
        otp: "",
        isVerify: false,
        isLoading: false,
        invalidEmail: false,
        invalidNumber: false,
        bruteforce: false,
      };
      this.setState({ isLoading: true });
      Axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          console.log({ brandData });
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success) {
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          }
        })
        .catch((err) => {
          console.log({ err });
          this.setState({ isLoading: false });
        });
      window.FreshworksWidget?.("hide");
      if (utils.getLoggedIn()) this.props.history.push("/dashboard");
    }
  }

  handleVerification = (token) => {
    this.setState({ token: token, captchaVerified: true }, () => {
      console.log("Captcha token set successfully ", token);
    });
  };

  emailMobileSwitch = () => {
    this.setState({
      isEmail: !this.state.isEmail,
      mobile: "",
      ccod: "91",
      email: "",
    });
  };

  resendMobileOTP = () => {
    if (this.state.endTime > 60 || this.state.isEmail) return;
    console.log("sent mobile otp");
    this.RequestOTPForMobile();
    this.setState({
      endTime: 5 * 60,
    });
  };

  resendEmailOTP = () => {
    if (this.state.endTime > 60 || !this.state.isEmail) return;
    console.log("sent email otp");
    this.RequestOTPForEmail();
    this.setState({
      endTime: 5 * 60,
    });
  };

  validateEmail = (email) => {
    return (
      email.length <= 50 &&
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  };

  validateMobile = (mobile) => {
    return mobile.length < 16 && mobile.length > 0;
    return mobile.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
  };

  validateOTP = () => {
    return this.state.aid && this.state.otp.length == 6;
  };

  formValidation = () => {
    return this.state.isVerify
      ? this.validateOTP()
      : this.state.isEmail
      ? this.validateEmail(this.state.email)
      : this.validateMobile(this.state.mobile);
  };

  OTPChange = (e) => {
    let otp = e.target.value;
    if (otp.length > 6) return;
    let regExp = new RegExp("^[0-9]*$");
    if (otp != "" && !regExp.test(otp)) {
      return;
    }
    this.setState({
      otp,
      invalidOTP: false,
    });
  };

  emailChange = (e) => {
    let email = e.target.value;
    if (email.length > 50) return;
    this.setState({
      email,
      incorrectEmail: false,
      invalidEmail: false,
    });
  };

  ccodChange = (e) => {
    let ccod = e.target.value;
    this.setState(
      {
        ccod,
        incorrectNumber: false,
        invalidNumber: false,
      },
      () => {
        if (Number(this.state.ccod) !== 91) {
          this.setState({ isLoading: true });
          Axios.get(ROUTES.HCAPTCHA_KEY_FETCH)
            .then((res) => {
              if (res.data.data.siteKey) {
                this.setState(
                  { captchaSiteKey: res.data.data.siteKey, isLoading: false },
                  () => {
                    console.log(
                      "Captcha site key fetched",
                      this.state.captchaSiteKey
                    );
                  }
                );
              } else {
                window.alert("Captcha did not load properly refresh again.");
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log(err);
              window.alert("Captcha did not load properly refresh again.");
              return;
            });
        }
      }
    );
  };

  mobileChange = (e) => {
    let mobile = e.target.value;
    if (mobile.length > 15) return;
    let regExp = new RegExp("^[0-9]*$");
    if (mobile != "" && !regExp.test(mobile)) {
      return;
    }
    this.setState({
      mobile,
      incorrectNumber: false,
      invalidNumber: false,
    });
  };

  getOTPTimeRemaining = () => {
    setTimeout(() => {
      this.setState({
        endTime: this.state.endTime > 0 ? this.state.endTime - 1 : 0,
      });
      this.getOTPTimeRemaining();
    }, 1000);
  };

  RequestOTPForEmail = () => {
    let email = this.state.email;

    if (!this.validateEmail(email)) return;
    let dataObj = {
      email,
    };
    this.setState({ isLoading: true });
    Axios.post(ROUTES.REQUEST_EMAIL_OTP, dataObj)
      .then((res) => {
        console.log(res.data.data);
        this.getOTPTimeRemaining();
        this.setState({
          aid: res.data.data.aid,
          isVerify: true,
          incorrectEmail: false,
          incorrectNumber: false,
          invalidOTP: false,
          isLoading: false,
          invalidEmail: false,
          invalidNumber: false,
        });
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 100) {
          this.setState({
            incorrectEmail: true,
            incorrectNumber: false,
            invalidOTP: false,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: false,
          });
        } else if (ecode == 207000) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP: false,
            isLoading: false,
            invalidEmail: true,
            invalidNumber: false,
          });
        } else if (
          err.response.data.message ||
          err.response.data.data?.message
        ) {
          this.setState({
            customError:
              err.response.data.message || err.response.data.data?.message,
            isLoading: false,
          });
        }
      });
  };

  RequestOTPForMobile = () => {
    let mobile = this.state.mobile;
    let c_code = this.state.ccod;

    if (!this.validateMobile(mobile)) return;
    let dataObj = {
      mobile,
      c_code,
    };
    if (
      Number(c_code) !== 91 &&
      this.state.token !== "" &&
      this.state.captchaVerified
    ) {
      dataObj["token"] = this.state.token;
    }
    this.setState({ isLoading: true });
    Axios.post(ROUTES.REQUEST_MOBILE_OTP, dataObj)
      .then((res) => {
        console.log(res.data);
        this.getOTPTimeRemaining();
        this.setState({
          aid: res.data.data.aid,
          isVerify: true,
          incorrectEmail: false,
          incorrectNumber: false,
          invalidOTP: false,
          isLoading: false,
          invalidEmail: false,
          invalidNumber: false,
        });
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 100) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: true,
            invalidOTP: false,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: false,
          });
        } else if (ecode == 206000) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP: false,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: true,
          });
        } else if (
          err.response.data.message ||
          err.response.data.data?.message
        ) {
          this.setState({
            customError:
              err.response.data.message || err.response.data.data?.message,
            isLoading: false,
          });
        }
      });
  };

  verifyOTPs = () => {
    let dataObj = {
      aid: this.state.aid,
    };

    if (this.state.isEmail) {
      dataObj.e_otp = this.state.otp;
    } else {
      dataObj.m_otp = this.state.otp;
    }

    const headers = {
      ...Axios.defaults.headers.common,
      "User-Agent": navigator.userAgent,
      "Accept-Language": navigator.languages,
    };

    headers["resolution"] = `${window.screen.width}x${window.screen.height}`;

    console.log("Screen height is ", window.screen.availHeight);
    console.log("Screen width is ", window.screen.availHeight);
    console.log("Browser language is ", window.navigator.languages);
    console.log("User agent is ", window.navigator.userAgent);

    console.log(dataObj);
    this.setState({ isLoading: true });
    Axios.post(ROUTES.VERIFY_OTPS, dataObj, { headers })
      .then((res) => {
        this.setState({ isLoading: false });
        let {
          rt,
          secret,
          at: a_t,
          aid,
          pid,
          user,
          sid,
          Profile,
        } = res.data.data;
        // utils.setLoginCookies(
        //   a_t,
        //   aid,
        //   secret,
        //   rt,
        //   true,
        //   pid,
        //   sid,
        //   user,
        //   Profile[0]
        // );
        window.FreshworksWidget?.("show");
        console.log({ "utils.getLoggedIn()": utils.getLoggedIn() });
        if (utils.getLoggedIn()) {
          let brand = this.props.match.params.brand || this.props.brand;
          if (brand) {
            this.props.history.push(`/${brand}/dashboard`);
          } else {
            this.props.history.push("/dashboard");
          }
        }
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 206000) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP: true,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: false,
            bruteforce: false,
          });
        } else if (ecode == 207000) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP: true,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: false,
            bruteforce: false,
          });
        } else if (ecode == 6100) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP: false,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: false,
            bruteforce: true,
          });
        } else if (ecode == 301 || ecode == 302) {
          this.setState({
            incorrectEmail: false,
            incorrectNumber: false,
            invalidOTP: true,
            isLoading: false,
            invalidEmail: false,
            invalidNumber: false,
            bruteforce: false,
          });
        } else if (
          err.response.data.message ||
          err.response.data.data?.message
        ) {
          this.setState({
            customError:
              err.response.data.message || err.response.data.data?.message,
            isLoading: false,
          });
        }
      });
  };

  render() {
    return this.state.isLoading ? (
      <div className="loader loader-center">
        <div className="loader-msg-container">
          <Loader brand={this.state.brand} />
        </div>
      </div>
    ) : (
      <div
        className="auth-main"
        style={
          this.state.brand
            ? {
                background: `url(${this.state.brand.bgimg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }
            : {}
        }
      >
        <div
          className="auth-block"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            minHeight: "85%",
            justifyContent: "flex-start",
            overflow: "hidden",
          }}
        >
          <img
            className="auth-logo"
            width={200}
            height={100}
            alt="logo"
            src={
              this.state.brand
                ? this.state.brand.pic
                : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
            }
          ></img>

          <div>
            <div style={{ display: "flex" }}>
              <Link
                to={
                  (this.state.brand ? `/${this.state.brand.utag}` : "") +
                  `/login/${this.state.isEmail ? "" : "mobile"}` +
                  (this.redirectValue ? `?redirect=${this.redirectValue}` : "")
                }
                className="reset-back"
                style={{ cursor: "pointer", marginRight: "8px" }}
              >
                <img src={backArrow} style={{ marginRight: "8px" }}></img>
              </Link>
              <div className="auth-h2">Login With OTP</div>
            </div>
            <br />
            {this.state.isEmail ? (
              <div className="auth-box">
                <div className="auth-ttl">Enter Email Id</div>
                <div className="auth-container">
                  <Input
                    type="email"
                    className="auth-inp"
                    onChange={this.emailChange}
                    value={this.state.email}
                  ></Input>
                  <div
                    className={`line ${
                      this.validateEmail(this.state.email) &&
                      !this.state.incorrectEmail
                        ? "lgreen"
                        : "lred"
                    }`}
                  ></div>
                </div>
                {this.state.customError && (
                  <div className="auth-error">{this.state.customError}</div>
                )}
                {this.state.incorrectEmail && (
                  <div className="auth-error">
                    {errorMessages["incorrectEmail"]}
                  </div>
                )}
                {this.state.invalidEmail && (
                  <div className="auth-error">
                    {errorMessages["invalidEmail"]}
                  </div>
                )}
              </div>
            ) : (
              <div className="auth-box">
                <div className="auth-ttl">Enter Mobile Number</div>
                <div className="auth-container">
                  <div style={{ display: "flex" }}>
                    <select
                      style={{ width: "30%" }}
                      className="auth-inp dropdown"
                      onChange={this.ccodChange}
                      value={this.state.ccod}
                    >
                      {CountryCodes.map((cc, i) => {
                        return (
                          <option
                            key={cc.code + String(i)}
                            disabled={cc.value == "_"}
                            value={cc.value}
                          >
                            {cc.display}
                          </option>
                        );
                      })}
                    </select>
                    <input
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      style={{ width: "70%" }}
                      className="auth-inp mobile"
                      onChange={this.mobileChange}
                      value={this.state.mobile}
                    ></input>
                  </div>
                  <div
                    className={`line ${
                      this.validateMobile(this.state.mobile) &&
                      !this.state.incorrectNumber
                        ? "lgreen"
                        : "lred"
                    }`}
                  ></div>
                </div>
                {this.state.incorrectNumber && (
                  <div className="auth-error">
                    {errorMessages["incorrectNumber"]}
                  </div>
                )}
                {this.state.invalidNumber && (
                  <div className="auth-error">
                    {errorMessages["invalidNumber"]}
                  </div>
                )}
                {this.state.customError && (
                  <div className="auth-error">{this.state.customError}</div>
                )}

                {Number(this.state.ccod) !== 91 && !this.state.isVerify ? (
                  <div
                    style={{
                      paddingTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <HCaptcha
                      ref={this.captcha}
                      sitekey={this.state.captchaSiteKey}
                      type="image"
                      onVerify={this.handleVerification}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
            {this.state.isVerify && (
              <div className="auth-box">
                <div className="auth-ttl">
                  Enter OTP sent on{" "}
                  <p style={{ display: "inline" }} className="green">
                    {this.state.isEmail ? this.state.email : this.state.mobile}
                  </p>
                </div>
                <div className="auth-container">
                  <Input
                    type="number"
                    className="auth-inp"
                    onChange={this.OTPChange}
                    value={this.state.otp}
                  ></Input>
                  <div
                    className={`line ${
                      this.validateOTP() && !this.state.invalidOTP
                        ? "lgreen"
                        : "lred"
                    }`}
                  ></div>
                  {this.state.invalidOTP && (
                    <div className="auth-error">
                      {errorMessages["invalidOTP"]}
                    </div>
                  )}
                  {this.state.customError && (
                    <div className="auth-error">{this.state.customError}</div>
                  )}
                </div>

                <div className="resend-otp-block">
                  {this.state.endTime < 60 ? (
                    <div
                      style={{ cursor: "pointer" }}
                      className="auth-txt"
                      onClick={
                        this.state.isEmail
                          ? this.resendEmailOTP
                          : this.resendMobileOTP
                      }
                    >
                      Resend OTP
                    </div>
                  ) : (
                    <div className="auth-txt"></div>
                  )}
                  <div className="auth-timer">
                    {`Remaining Time: ${Math.floor(this.state.endTime / 60)}:${(
                      "0" + String(this.state.endTime % 60)
                    ).slice(-2)}`}
                  </div>
                </div>
              </div>
            )}

            <div
              className={`${this.formValidation() ? "" : "disable"} ${
                this.state.brand && this.state.brand.themeColorIndex
                  ? "auth-btn-brand"
                  : "auth-btn"
              }`}
              onClick={
                this.formValidation()
                  ? this.state.isVerify
                    ? this.verifyOTPs
                    : this.state.isEmail
                    ? this.RequestOTPForEmail
                    : this.RequestOTPForMobile
                  : undefined
              }
              style={{
                background: `${
                  this.state.brand &&
                  this.state.brand.themeColorIndex &&
                  this.state.brand.themeColorIndex
                }`,
              }}
            >
              {this.state.isVerify ? "Verify & Login" : "Send OTP"}
            </div>
            {this.state.bruteforce && (
              <div className="auth-error">{errorMessages["bruteforce"]}</div>
            )}
            <div>
              {false && !this.state.isVerify && (
                <div
                  className="auth-ttl green right"
                  onClick={this.emailMobileSwitch}
                  style={{ cursor: "pointer", margin: "12px" }}
                >
                  Reset with {!this.state.isEmail ? "Email " : "Mobile number"}
                </div>
              )}
            </div>

            {this.state.brand &&
              this.state.brand.poweredByEnabled == true &&
              this.state.brand.hyperlinkEnabled == true && (
                <div
                  className="powered-by-container"
                  style={{ position: "absolute", bottom: "24px" }}
                >
                  <a className="powered-by" href={"https://www.imarticus.org"}>
                    Powered By Imarticus Learning
                  </a>
                </div>
              )}
            {this.state.brand &&
              this.state.brand.poweredByEnabled == true &&
              this.state.brand.hyperlinkEnabled == false && (
                <div
                  className="powered-by-container"
                  style={{ position: "absolute", bottom: "0px" }}
                >
                  <div className="powered-by">
                    Powered By Imarticus Learning
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  pendingInstallments: state.course.pendingInstallments,
  brand: state.brand?.brand || state.course.brand,
});
const mapDispatchToProps = {
  dispatchIndividualBrand,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginWithOtp)
);
