import Cookies from "universal-cookie";
import {
  AWS_ERROR_CODES,
  BACKEND_ERROR_CODES,
  FRONTEND_ERRORS,
  ERROR_MESSAGES,
  ERROR_BLOCK,
} from "../constants/errors";
import { authenticateCourse } from "../actions/courseAction";
import { renewAccessToken } from "../actions/utilAction";
import {
  CONFIGS,
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
  COOKIE_EXPIRY_PATHS,
} from "../constants/constants";
import XMLParser from "../lib/xml-parser";
import axios from "axios";
import cookie from "react-cookies";
import { logoutUser } from "../actions/accountDropdownAction";

const cookies = new Cookies();

let display = () => {
  var screen = window.screen || screen || {};
  return JSON.stringify(screen);
};
var SEP = "|",
  ua,
  opera,
  ie;
let userAgent = () => window.navigator.userAgent || "unknown user agent";
let stringToMD5 = (s) => {
  /*get a MD5Hash out of a string provided*/ var hexcase = 0,
    chrsz = 8,
    cmc5 = function (x, e) {
      x[e >> 5] |= 0x80 << e % 32;
      x[(((e + 64) >>> 9) << 4) + 14] = e;
      var a = 1732584193;
      var b = -271733879;
      var c = -1732584194;
      var d = 271733878;
      for (var i = 0; i < x.length; i += 16) {
        var f = a;
        var g = b;
        var h = c;
        var j = d;
        a = md5_ff(a, b, c, d, x[i + 0], 7, -680876936);
        d = md5_ff(d, a, b, c, x[i + 1], 12, -389564586);
        c = md5_ff(c, d, a, b, x[i + 2], 17, 606105819);
        b = md5_ff(b, c, d, a, x[i + 3], 22, -1044525330);
        a = md5_ff(a, b, c, d, x[i + 4], 7, -176418897);
        d = md5_ff(d, a, b, c, x[i + 5], 12, 1200080426);
        c = md5_ff(c, d, a, b, x[i + 6], 17, -1473231341);
        b = md5_ff(b, c, d, a, x[i + 7], 22, -45705983);
        a = md5_ff(a, b, c, d, x[i + 8], 7, 1770035416);
        d = md5_ff(d, a, b, c, x[i + 9], 12, -1958414417);
        c = md5_ff(c, d, a, b, x[i + 10], 17, -42063);
        b = md5_ff(b, c, d, a, x[i + 11], 22, -1990404162);
        a = md5_ff(a, b, c, d, x[i + 12], 7, 1804603682);
        d = md5_ff(d, a, b, c, x[i + 13], 12, -40341101);
        c = md5_ff(c, d, a, b, x[i + 14], 17, -1502002290);
        b = md5_ff(b, c, d, a, x[i + 15], 22, 1236535329);
        a = md5_gg(a, b, c, d, x[i + 1], 5, -165796510);
        d = md5_gg(d, a, b, c, x[i + 6], 9, -1069501632);
        c = md5_gg(c, d, a, b, x[i + 11], 14, 643717713);
        b = md5_gg(b, c, d, a, x[i + 0], 20, -373897302);
        a = md5_gg(a, b, c, d, x[i + 5], 5, -701558691);
        d = md5_gg(d, a, b, c, x[i + 10], 9, 38016083);
        c = md5_gg(c, d, a, b, x[i + 15], 14, -660478335);
        b = md5_gg(b, c, d, a, x[i + 4], 20, -405537848);
        a = md5_gg(a, b, c, d, x[i + 9], 5, 568446438);
        d = md5_gg(d, a, b, c, x[i + 14], 9, -1019803690);
        c = md5_gg(c, d, a, b, x[i + 3], 14, -187363961);
        b = md5_gg(b, c, d, a, x[i + 8], 20, 1163531501);
        a = md5_gg(a, b, c, d, x[i + 13], 5, -1444681467);
        d = md5_gg(d, a, b, c, x[i + 2], 9, -51403784);
        c = md5_gg(c, d, a, b, x[i + 7], 14, 1735328473);
        b = md5_gg(b, c, d, a, x[i + 12], 20, -1926607734);
        a = md5_hh(a, b, c, d, x[i + 5], 4, -378558);
        d = md5_hh(d, a, b, c, x[i + 8], 11, -2022574463);
        c = md5_hh(c, d, a, b, x[i + 11], 16, 1839030562);
        b = md5_hh(b, c, d, a, x[i + 14], 23, -35309556);
        a = md5_hh(a, b, c, d, x[i + 1], 4, -1530992060);
        d = md5_hh(d, a, b, c, x[i + 4], 11, 1272893353);
        c = md5_hh(c, d, a, b, x[i + 7], 16, -155497632);
        b = md5_hh(b, c, d, a, x[i + 10], 23, -1094730640);
        a = md5_hh(a, b, c, d, x[i + 13], 4, 681279174);
        d = md5_hh(d, a, b, c, x[i + 0], 11, -358537222);
        c = md5_hh(c, d, a, b, x[i + 3], 16, -722521979);
        b = md5_hh(b, c, d, a, x[i + 6], 23, 76029189);
        a = md5_hh(a, b, c, d, x[i + 9], 4, -640364487);
        d = md5_hh(d, a, b, c, x[i + 12], 11, -421815835);
        c = md5_hh(c, d, a, b, x[i + 15], 16, 530742520);
        b = md5_hh(b, c, d, a, x[i + 2], 23, -995338651);
        a = md5_ii(a, b, c, d, x[i + 0], 6, -198630844);
        d = md5_ii(d, a, b, c, x[i + 7], 10, 1126891415);
        c = md5_ii(c, d, a, b, x[i + 14], 15, -1416354905);
        b = md5_ii(b, c, d, a, x[i + 5], 21, -57434055);
        a = md5_ii(a, b, c, d, x[i + 12], 6, 1700485571);
        d = md5_ii(d, a, b, c, x[i + 3], 10, -1894986606);
        c = md5_ii(c, d, a, b, x[i + 10], 15, -1051523);
        b = md5_ii(b, c, d, a, x[i + 1], 21, -2054922799);
        a = md5_ii(a, b, c, d, x[i + 8], 6, 1873313359);
        d = md5_ii(d, a, b, c, x[i + 15], 10, -30611744);
        c = md5_ii(c, d, a, b, x[i + 6], 15, -1560198380);
        b = md5_ii(b, c, d, a, x[i + 13], 21, 1309151649);
        a = md5_ii(a, b, c, d, x[i + 4], 6, -145523070);
        d = md5_ii(d, a, b, c, x[i + 11], 10, -1120210379);
        c = md5_ii(c, d, a, b, x[i + 2], 15, 718787259);
        b = md5_ii(b, c, d, a, x[i + 9], 21, -343485551);
        a = safe_add(a, f);
        b = safe_add(b, g);
        c = safe_add(c, h);
        d = safe_add(d, j);
      }
      return Array(a, b, c, d);
    },
    md5_cmn = function (q, a, b, x, s, t) {
      return safe_add(bit_rol(safe_add(safe_add(a, q), safe_add(x, t)), s), b);
    },
    md5_ff = function (a, b, c, d, x, s, t) {
      return md5_cmn((b & c) | (~b & d), a, b, x, s, t);
    },
    md5_gg = function (a, b, c, d, x, s, t) {
      return md5_cmn((b & d) | (c & ~d), a, b, x, s, t);
    },
    md5_hh = function (a, b, c, d, x, s, t) {
      return md5_cmn(b ^ c ^ d, a, b, x, s, t);
    },
    md5_ii = function (a, b, c, d, x, s, t) {
      return md5_cmn(c ^ (b | ~d), a, b, x, s, t);
    },
    safe_add = function (x, y) {
      var a = (x & 0xffff) + (y & 0xffff);
      var b = (x >> 16) + (y >> 16) + (a >> 16);
      return (b << 16) | (a & 0xffff);
    },
    bit_rol = function (a, b) {
      return (a << b) | (a >>> (32 - b));
    },
    s2b = function (a) {
      var b = Array();
      var c = (1 << chrsz) - 1;
      for (var i = 0; i < a.length * chrsz; i += chrsz)
        b[i >> 5] |= (a.charCodeAt(i / chrsz) & c) << i % 32;
      return b;
    },
    b2h = function (a) {
      var b = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
      var c = "";
      for (var i = 0; i < a.length * 4; i++) {
        c +=
          b.charAt((a[i >> 2] >> ((i % 4) * 8 + 4)) & 0xf) +
          b.charAt((a[i >> 2] >> ((i % 4) * 8)) & 0xf);
      }
      return c;
    };
  return b2h(cmc5(s2b(s), s.length * chrsz));
};
let software = () => {
  var i,
    t = "",
    isFirst = true,
    temp = "",
    lastDir = "Plugins",
    plugin,
    mimeType,
    components,
    ver;

  if (window.navigator.plugins.length > 0) {
    if (opera) {
      temp = "";
      lastDir = "Plugins";

      for (i = 0; i < window.navigator.plugins.length; i++) {
        plugin = window.navigator.plugins[i];
        console.log("plugins opera \n" + plugin);
        if (isFirst === true) {
          temp += this.stripFullPath(plugin.filename, lastDir);
          isFirst = false;
        } else {
          temp += SEP + this.stripFullPath(plugin.filename, lastDir);
        }
      }
      console.log(temp);
      t = this.stripIllegalChars(temp);
    } else {
      for (i = 0; i < window.navigator.plugins.length; i++) {
        plugin = window.navigator.plugins[i];
        console.log("plugins  \n" + plugin);
        if (isFirst === true) {
          t += plugin.filename;
          isFirst = false;
        } else {
          t += SEP + plugin.filename;
        }
      }
      console.log(t);
      // t = this.stripIllegalChars(temp);
    }
  } else if (window.navigator.mimeTypes.length > 0) {
    for (i = 0; i < window.navigator.mimeTypes.length; i++) {
      mimeType = window.navigator.mimeTypes[i];
      console.log("mimeType  \n" + mimeType);
      if (isFirst === true) {
        t += mimeType.type;
        isFirst = false;
      } else {
        t += SEP + mimeType.type;
      }
    }
    console.log(t);
  } else if (ie) {
    components = new Array(
      "7790769C-0471-11D2-AF11-00C04FA35D02",
      "89820200-ECBD-11CF-8B85-00AA005B4340",
      "283807B5-2C60-11D0-A31D-00AA00B92C03",
      "4F216970-C90C-11D1-B5C7-0000F8051515",
      "44BBA848-CC51-11CF-AAFA-00AA00B6015C",
      "9381D8F2-0288-11D0-9501-00AA00B911A5",
      "4F216970-C90C-11D1-B5C7-0000F8051515",
      "5A8D6EE0-3E18-11D0-821E-444553540000",
      "89820200-ECBD-11CF-8B85-00AA005B4383",
      "08B0E5C0-4FCB-11CF-AAA5-00401C608555",
      "45EA75A0-A269-11D1-B5BF-0000F8051515",
      "DE5AED00-A4BF-11D1-9948-00C04F98BBC9",
      "22D6F312-B0F6-11D0-94AB-0080C74C7E95",
      "44BBA842-CC51-11CF-AAFA-00AA00B6015B",
      "3AF36230-A269-11D1-B5BF-0000F8051515",
      "44BBA840-CC51-11CF-AAFA-00AA00B6015C",
      "CC2A9BA0-3BDD-11D0-821E-444553540000",
      "08B0E5C0-4FCB-11CF-AAA5-00401C608500",
      "D27CDB6E-AE6D-11CF-96B8-444553540000",
      "2A202491-F00D-11CF-87CC-0020AFEECF20",
    );
    document.body.addBehavior("#default#clientCaps");
    for (i = 0; i < components.length; i++) {
      ver = this.activeXDetect(components[i]);
      console.log("ver  \n" + ver);
      if (ver) {
        if (isFirst === true) {
          t += ver;
          isFirst = false;
        } else {
          t += SEP + ver;
        }
      } else {
        t += SEP + "null";
      }
    }
    console.log(t);
  }

  return t;
};
let randomString = (length) => {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const utils = {
  //converts 1000 to 1K and 100000 to 1M
  convertMemberCountToWords: function (total_members) {
    var string_val;
    var numeric_count, decimal_pos;

    if (total_members < 1000) {
      return total_members;
    } else if (total_members >= 1000 && total_members <= 99999) {
      numeric_count = total_members / 1000;
      string_val = numeric_count.toString();
      decimal_pos = string_val.indexOf(".");
      if (decimal_pos >= 0) {
        return string_val.substring(0, decimal_pos + 2) + "K";
      } else {
        return numeric_count + "k";
      }
    } else if (total_members >= 100000 && total_members <= 9999999) {
      numeric_count = total_members / 100000;
      string_val = numeric_count.toString();
      decimal_pos = string_val.indexOf(".");
      if (decimal_pos >= 0) {
        return string_val.substring(0, decimal_pos + 2) + "M";
      } else {
        return numeric_count + "m";
      }
    }
  },

  setLoginCookies: function (
    at,
    aid,
    secret,
    rt,
    isLoggedIn,
    pid,
    sid,
    user,
    Profile,
  ) {
    let expirydate = new Date();
    expirydate.setDate(expirydate.getDate() + 180);
    let cookies = new Cookies();

    // cookies.set("at", at, {
    //   expires: expirydate,
    //   domain: CONFIGS.DOMAIN,
    //   path: "/",
    // });
    // cookies.set("aid", aid, {
    //   expires: expirydate,
    //   domain: CONFIGS.DOMAIN,
    //   path: "/",
    // });
    // cookies.set("secret", secret, {
    //   expires: expirydate,
    //   domain: CONFIGS.DOMAIN,
    //   path: "/",
    // });
    // cookies.set("rt", rt, {
    //   expires: expirydate,
    //   domain: CONFIGS.DOMAIN,
    //   path: "/",
    // });
    // cookies.set("isLoggedIn", isLoggedIn, {
    //   expires: expirydate,
    //   domain: CONFIGS.DOMAIN,
    //   path: "/",
    // });
    // cookies.set("pid", pid, {
    //   expires: expirydate,
    //   domain: CONFIGS.DOMAIN,
    //   path: "/",
    // });
    // cookies.set("s_id", sid, {
    //   expires: expirydate,
    //   domain: CONFIGS.DOMAIN,
    //   path: "/",
    // });
    // cookies.set("user", user, {
    //   expires: expirydate,
    //   domain: CONFIGS.DOMAIN,
    //   path: "/",
    // });
    // cookies.set("Profile", Profile, {
    //   expires: expirydate,
    //   domain: CONFIGS.DOMAIN,
    //   path: "/",
    // });
  },

  getDid: function () {
    try {
      return stringToMD5(userAgent() + display() + software());
    } catch (err) {
      console.error(
        "aid_" + cookies.get("aid") + "_myDeviceHash_error_\n" + err,
      );
      return stringToMD5(randomString(15));
    }
  },

  getAt: function () {
    // fetch access-token
    return cookies.get("at", { domain: CONFIGS.DOMAIN, path: "/" });
  },

  getAid: function () {
    // fetch account id
    return cookies.get("aid", { domain: CONFIGS.DOMAIN, path: "/" });
  },
  getSid: function () {
    // fetch device id
    return cookies.get("s_id", { domain: CONFIGS.DOMAIN, path: "/" });
  },

  // setPid: function (pid) {
  //   var expirydate = new Date();
  //   expirydate.setDate(expirydate.getDate() + 180);
  //   cookies.set("pid", pid, { domain: CONFIGS.DOMAIN, path: "/" });
  // },

  getPid: function () {
    return cookies.get("pid", { domain: CONFIGS.DOMAIN, path: "/" });
  },
  getSecret: function () {
    return cookies.get("secret", { domain: CONFIGS.DOMAIN, path: "/" });
  },
  getRefreshToken: function () {
    return cookies.get("rt", { domain: CONFIGS.DOMAIN, path: "/" });
  },
  getLoggedIn: function () {
    let isLoggedIn = cookies.get("isLoggedIn", {
      domain: CONFIGS.DOMAIN,
      path: "/",
    });
    console.log({ isLoggedIn });
    if (isLoggedIn == "true") return true;
    return false;
    // return true;
  },
  centralizeModalForHomeSidebar: function () {
    var e = document.getElementsByClassName("modal");
    if (e) {
      e = e[0];
      e.style.marginLeft = "300px";
      e.style.width = "calc( 100% - 300px)";
    }
  },
  centralizeModalForGroupSidebar: function () {
    var e = document.getElementsByClassName("modal");
    if (e) {
      e = e[0];
      e.style.marginLeft = "300px";
      e.style.width = "calc( 100% - 300px)";
    }
  },
  setModalDefaults: function () {
    var e = document.getElementsByClassName("modal");
    if (e) {
      e = e[0];
      e.style.marginLeft = "0 auto";
      e.style.width = "calc( 100% )";
    }
  },
  removeAuthCookies: async function () {
    await logoutUser();

    console.log("\n:::::::: Removing Auth Cookies :::::::: ");
    for (let i = 0; i < CONFIGS.AUTHENTICATION_COOKIES.length; i++) {
      let path = CONFIGS.PATH;
      if (CONFIGS.AUTHENTICATION_COOKIES[i] == "ifTokenSent") {
        path = "/dashboard";
      }
      cookies.remove(CONFIGS.AUTHENTICATION_COOKIES[i], {
        path: path,
        domain: CONFIGS.DOMAIN,
      });
      console.log(
        "\t Removed - Cookie : ",
        CONFIGS.AUTHENTICATION_COOKIES[i],
        ", Domain : ",
        CONFIGS.DOMAIN,
        ", Path : ",
        CONFIGS.PATH,
      );
      for (let j = 0; j < COOKIE_EXPIRY_PATHS.length; j++) {
        cookie.remove(
          CONFIGS.AUTHENTICATION_COOKIES[i],
          COOKIE_EXPIRY_PATHS[j],
        );
      }
    }
    console.log(":::::::: Removed Auth Cookies ::::::::\n");
  },
  handleErrors: function (err, dispatch, err_block) {
    console.log("Handle errors -- ", err, err_block);
    var err_obj = {};
    if (
      err.response &&
      err.response.data.code === BACKEND_ERROR_CODES.ACCOUNT_NOT_FOUND
    ) {
      logoutUser();
      utils.removeAuthCookies();
      window.location.href = CONFIGS.ECKOVATION_WEB_ENDPOINT;
    } else if (
      err.response &&
      err.response.data.code === BACKEND_ERROR_CODES.TOO_MANY_REQUEST
    ) {
      logoutUser();

      utils.removeAuthCookies();
      console.log("here");
      window.location.href = CONFIGS.ECKOVATION_WEB_ENDPOINT;
    } else if (
      err.response &&
      err.response.data.code === BACKEND_ERROR_CODES.NO_ACCESS_TOKEN
    ) {
      logoutUser();

      utils.removeAuthCookies();
      window.location.href = CONFIGS.ECKOVATION_WEB_ENDPOINT;
    } else if (!err.response && err.message === ERROR_MESSAGES.NETWORK_ERROR) {
      err_obj.type = "ERROR_OCCURED";
      err_obj.err_msg = FRONTEND_ERRORS.F0005;
      err_obj.val = true;
      dispatch(err_obj);
    } else if (err_block === ERROR_BLOCK.RAZORPAY_TRAN) {
      if (
        err.response &&
        err.response.status === BACKEND_ERROR_CODES.INTERNAL_SERVER_ERROR
      ) {
        utils.showPopover(
          POPOVER_HEADINGS.SUCCESS,
          POPOVER_CONTENT.RAZORPAY_TEMP_ERROR,
          POPOVER_TYPE.SUCCESS,
          dispatch,
        );
      } else {
        utils.showPopover(
          POPOVER_HEADINGS.SUCCESS,
          POPOVER_CONTENT.RAZORPAY_TEMP_ERROR,
          POPOVER_TYPE.SUCCESS,
          dispatch,
        );
      }
    } else if (
      err_block === ERROR_BLOCK.PROFILE_PIC_UPLOAD &&
      err.response &&
      err.response.status === BACKEND_ERROR_CODES.BAD_REQUEST
    ) {
      try {
        var xml = new XMLParser().parseFromString(err.response.data);
        var err_code = xml.getElementsByTagName("Code");
        err_code = err_code[0] ? err_code[0].value : null;
        if (err_code === AWS_ERROR_CODES.ENTITY_TOO_LARGE) {
          utils.showPopover(
            POPOVER_HEADINGS.PIC_SIZE_MAX,
            POPOVER_CONTENT.PIC_SIZE_MAX,
            POPOVER_TYPE.ERROR,
            dispatch,
          );
        } else if (err_code === AWS_ERROR_CODES.ENTITY_TOO_SMALL) {
          utils.showPopover(
            POPOVER_HEADINGS.PIC_SIZE_MIN,
            POPOVER_CONTENT.PIC_SIZE_MIN,
            POPOVER_TYPE.ERROR,
            dispatch,
          );
        } else if (err_block === ERROR_BLOCK.PROFILE_PIC_UPLOAD) {
          utils.showPopover(
            POPOVER_HEADINGS.PPIC_UPLOAD_ERR,
            POPOVER_CONTENT.PPIC_UPLOAD_ERR,
            POPOVER_TYPE.ERROR,
            dispatch,
          );
        }
      } catch (err) {
        utils.showPopover(
          POPOVER_HEADINGS.PPIC_UPLOAD_ERR,
          POPOVER_CONTENT.PPIC_UPLOAD_ERR,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      }
    } else if (err_block === ERROR_BLOCK.PROFILE_PIC_UPLOAD) {
      utils.showPopover(
        POPOVER_HEADINGS.PPIC_UPLOAD_ERR,
        POPOVER_CONTENT.PPIC_UPLOAD_ERR,
        POPOVER_TYPE.ERROR,
        dispatch,
      );
    } else if (err_block === ERROR_BLOCK.GROUP_PIC_UPLOAD) {
      if (
        err.response &&
        err.response.status === BACKEND_ERROR_CODES.BAD_REQUEST
      ) {
        try {
          xml = new XMLParser().parseFromString(err.response.data);
          err_code = xml.getElementsByTagName("Code");
          err_code = err_code[0] ? err_code[0].value : null;
          utils.showPopover(
            POPOVER_HEADINGS.PIC_SIZE_MAX,
            POPOVER_CONTENT.PIC_SIZE_MAX,
            POPOVER_TYPE.ERROR,
            dispatch,
          );
          if (err_code === AWS_ERROR_CODES.ENTITY_TOO_LARGE) {
          } else if (err_code === AWS_ERROR_CODES.ENTITY_TOO_SMALL) {
            utils.showPopover(
              POPOVER_HEADINGS.PIC_SIZE_MIN,
              POPOVER_CONTENT.PIC_SIZE_MIN,
              POPOVER_TYPE.ERROR,
              dispatch,
            );
          } else if (err_block === ERROR_BLOCK.GROUP_PIC_UPLOAD) {
            utils.showPopover(
              POPOVER_HEADINGS.GPIC_UPLOAD_ERR,
              POPOVER_CONTENT.GPIC_UPLOAD_ERR,
              POPOVER_TYPE.ERROR,
              dispatch,
            );
          }
        } catch (err) {
          utils.showPopover(
            POPOVER_HEADINGS.GPIC_UPLOAD_ERR,
            POPOVER_CONTENT.GPIC_UPLOAD_ERR,
            POPOVER_TYPE.ERROR,
            dispatch,
          );
        }
      } else {
        utils.showPopover(
          POPOVER_HEADINGS.GPIC_UPLOAD_ERR,
          POPOVER_CONTENT.GPIC_UPLOAD_ERR,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      }
    } else if (err_block === ERROR_BLOCK.DELETE_PPIC) {
      utils.showPopover(
        POPOVER_HEADINGS.DEL_PPIC_FAILURE,
        POPOVER_CONTENT.DEL_PPIC_FAILURE,
        POPOVER_TYPE.ERROR,
        dispatch,
      );
    } else if (err_block === ERROR_BLOCK.DELETE_GPIC) {
      utils.showPopover(
        POPOVER_HEADINGS.DEL_GPIC_FAILURE,
        POPOVER_CONTENT.DEL_GPIC_FAILURE,
        POPOVER_TYPE.ERROR,
        dispatch,
      );
    } else if (
      err_block === ERROR_BLOCK.DELETE_PROFILE &&
      err.response &&
      err.response.data.code === BACKEND_ERROR_CODES.PROFILE_GROUPS_EXIST
    ) {
      utils.showPopover(
        POPOVER_HEADINGS.PROFILE_GROUP_EXISTS,
        POPOVER_CONTENT.PROFILE_GROUP_EXISTS,
        POPOVER_TYPE.ERROR,
        dispatch,
      );
    } else if (
      err_block === ERROR_BLOCK.DELETE_PROFILE &&
      err.response &&
      err.response.data.code === BACKEND_ERROR_CODES.ONLY_ONE_PROFILE
    ) {
      utils.showPopover(
        POPOVER_HEADINGS.ONLY_ONE_PROFILE,
        POPOVER_CONTENT.ONLY_ONE_PROFILE,
        POPOVER_TYPE.ERROR,
        dispatch,
      );
    } else if (err_block === ERROR_BLOCK.DELETE_PROFILE) {
      utils.showPopover(
        POPOVER_HEADINGS.DEL_PROFILE_FAILURE,
        POPOVER_CONTENT.DEL_PROFILE_FAILURE,
        POPOVER_TYPE.ERROR,
        dispatch,
      );
    } else if (err_block === ERROR_BLOCK.DELETE_GROUP && err.response) {
      if (err.response.data.code === BACKEND_ERROR_CODES.GROUP_NOT_FOUND) {
        utils.showPopover(
          POPOVER_HEADINGS.ERROR,
          POPOVER_CONTENT.GROUP_NOT_FOUND,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      } else if (
        err.response.data.code === BACKEND_ERROR_CODES.NOT_A_GROUP_MEMBER
      ) {
        utils.showPopover(
          POPOVER_HEADINGS.ERROR,
          POPOVER_CONTENT.NOT_A_GROUP_MEMBER,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      } else if (
        err.response.data.code === BACKEND_ERROR_CODES.AUTHENTICATION_ERROR
      ) {
        utils.showPopover(
          POPOVER_HEADINGS.ERROR,
          POPOVER_CONTENT.AUTHENTICATION_ERROR,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      } else if (
        err.response.data.code === BACKEND_ERROR_CODES.GROUP_MEMBERS_ALIVE
      ) {
        utils.showPopover(
          POPOVER_HEADINGS.ERROR,
          POPOVER_CONTENT.GROUP_MEMBERS_ALIVE,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      } else {
        utils.showPopover(
          POPOVER_HEADINGS.ERROR,
          POPOVER_CONTENT.DEL_GROUP_FAILURE,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      }
    } else if (err_block === ERROR_BLOCK.DUPLICATE_PROFILE) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.code === BACKEND_ERROR_CODES.DUPLICATE_PROFILE
      ) {
        utils.showPopover(
          POPOVER_HEADINGS.ONLY_ONE_PROFILE,
          POPOVER_CONTENT.PROFILE_ALREADY_EXISTS,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      } else {
        utils.showPopover(
          POPOVER_HEADINGS.ERROR,
          POPOVER_CONTENT.ADD_PROFILE_ERR,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      }
    } else if (err_block === ERROR_BLOCK.INITIALIZE_TRAN) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.code === BACKEND_ERROR_CODES.USER_ALREADY_SUBSCRIBED
      ) {
        utils.showPopover(
          POPOVER_HEADINGS.ERROR,
          POPOVER_CONTENT.USER_ALREADY_SUBSCRIBED,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      } else {
        utils.showPopover(
          POPOVER_HEADINGS.ERROR,
          POPOVER_CONTENT.INITIALIZE_TRAN_ERR,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      }
    } else if (err_block === ERROR_BLOCK.APPLY_COUPON) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.code === BACKEND_ERROR_CODES.COUPON_CODE_EXPIRED
      ) {
        utils.showPopover(
          POPOVER_HEADINGS.ERROR,
          POPOVER_CONTENT.COUPON_CODE_EXPIRED,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      } else {
        utils.showPopover(
          POPOVER_HEADINGS.ERROR,
          POPOVER_CONTENT.APPLY_COUPON_ERR,
          POPOVER_TYPE.ERROR,
          dispatch,
        );
      }
    } else if (
      err.response &&
      err.response.status === BACKEND_ERROR_CODES.INTERNAL_SERVER_ERROR
    ) {
      err_obj.type = "ERROR_OCCURED";
      err_obj.err_msg = FRONTEND_ERRORS.F0005;
      err_obj.val = true;
      dispatch(err_obj);
    } else {
      logoutUser();

      utils.removeAuthCookies();
      window.location.href = CONFIGS.ECKOVATION_WEB_ENDPOINT;
    }
  },
  showPopover: function (heading, msg, type, dispatch) {
    var action = {};
    action.type = "SHOW_POPOVER";
    action.heading = heading;
    action.msg = msg;
    action.pover_type = type;
    dispatch(action);
    setTimeout(function () {
      utils.hidePopover(dispatch);
    }, POPOVER_HIDETIME);
  },
  hidePopover: function (dispatch) {
    var action = {};
    action.type = "CLOSE_POPOVER";
    dispatch(action);
  },
};

export function image64toCanvasRef(canvasRef, image64, pixelCrop) {
  const canvas = canvasRef; // document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");
  const image = new Image();
  image.src = image64;
  image.onload = function () {
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );
  };
}

export function downloadBase64File(base64Data, filename) {
  var element = document.createElement("a");
  element.setAttribute("href", base64Data);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function extractImageFileExtensionFromBase64(base64Data) {
  return base64Data.substring(
    "data:image/".length,
    base64Data.indexOf(";base64"),
  );
}

export function base64StringtoFile(base64String, filename) {
  var arr = base64String.split(",");
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function expireOlderCookies(name, paths) {
  cookies.remove(name, {
    path: "/",
    domain: CONFIGS.DOMAIN,
  });
}

export function updateURLParameter(url, param, paramVal) {
  var newAdditionalURL = "";
  var tempArray = url.split("?");
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] != param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  var tokn = temp + "" + param + "=" + paramVal;
  return baseURL + "?" + newAdditionalURL + tokn;
}
