import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { authenticateCourse } from "../../actions/courseAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import "./homeCtrl.scss";
import Loader from "../utils/Loader.js";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import { getCourses } from "../../actions/homeAction.js";
import { brandDataCourse } from "../../actions/gradebookAction.js";
import Cookies from "universal-cookie";
import { CONFIGS } from "../../constants/constants.js";
import { setLoading } from "../../actions/utilAction.js";
import { ROUTES } from "../../constants/routes.js";

const HomeCtrl = (props) => {
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const gid = query[5];
  console.log({ query });

  const cookies = new Cookies();

  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [status, setStatus] = useState({});
  const [courseLinks, setCourseLinks] = useState({});
  const [joining, setJoining] = useState();
  const [uid, setUid] = useState();
  const [courseName, setCourseName] = useState();
  const [selfPaced, setSelfPaced] = useState();
  const [impartusEnabled, setImpartusEnabled] = useState();
  const [batchName, setBatchName] = useState();
  const [courseLocation, setCourseLocation] = useState();
  const [brand, setBrand] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const getCourseData = await getCourses(pid, gid);
      if (getCourseData.success) {
        setCourses(getCourseData.data.groupcourse);
        setGroupName(getCourseData.data.group_name);
        setStatus(getCourseData.data.ucstatus);
        setCourseLinks(getCourseData.data.courselinks);
        const atCrsData = await authenticateCourse(
          getCourseData.data.groupcourse[0]._id,
        );
        if (atCrsData) {
          if (atCrsData.joined) {
            setJoining(atCrsData.joined);
          }
          if (atCrsData.uid) {
            setUid(atCrsData.uid);
          }
          if (atCrsData.name) {
            setCourseName(atCrsData.name);
          }
          if (atCrsData.selfPaced) {
            setSelfPaced(atCrsData.selfPaced);
          }
          setImpartusEnabled(atCrsData.course.impartus_enabled);
          setBatchName(atCrsData.course.btch_name);
          setCourseLocation(atCrsData.course.location);
        }
        const getBrandData = await brandDataCourse(
          getCourseData.data.groupcourse[0]._id,
        );
        if (getBrandData.data.success) {
          var brand = getBrandData.data.data.brand;
          // props.dispatchIndividualBrand(brand);
          if (getBrandData.data.data.brand) {
            setBrand(getBrandData.data.data.brand);
          }
        }
      }
    })();
    setIsLoading(false);
  }, []);

  const processRatings = function (rtg) {
    var rating = parseFloat(rtg);
    var ratingsArr = [];
    for (var i = 0; i < 5; i++) {
      if (i < rating) {
        if (i == Math.floor(rating)) {
          ratingsArr.push(Math.round((rating % i) * 10));
          continue;
        }
        ratingsArr.push(10);
      } else {
        ratingsArr.push(0);
      }
    }
    return ratingsArr;
  };

  const getStar = function (val) {
    return "https://cdn.pegasus.imarticus.org/courses/images/4." + val + ".svg";
  };

  const backToGroup = function () {
    console.log(
      "redirect url::",
      CONFIGS.DASHBOARD_BASE_URL + "/group-detail?gid=" + gid,
    );
    window.location.href =
      CONFIGS.DASHBOARD_BASE_URL + "/group-detail?gid=" + gid;
  };

  const redirectTo = function (type, cid) {
    if (type == 1) {
      // window.location.replace($scope.my_course_url);
      // window.history.back();
      console.log("cid:::", cid);
      // var url = constants.my_course_url + '?cid=' + cid
      // console.log('url:::', url)
      // window.location.href = url
      var course = {};
      for (var i = 0; i < courses; i++) {
        if (courses[i]._id == cid) {
          course = courses[i];
        }
      }
      if (course && course.level_labels && course.level_labels.length > 1) {
        window.location.href = CONFIGS.API_ENDPOINT + "view/" + "?cid=" + cid;
      } else {
        var link =
          CONFIGS.WEB_DASHBOARD_BASE_URL +
          "batch/" +
          pid +
          "/" +
          gid +
          "/" +
          cid +
          "/undefined";
        console.log("link::", link);
        window.location.href = link;
        // window.location.replace(link)
      }
    } else {
      window.location.replace(CONFIGS.DASHBOARD_BASE_URL);
    }
  };

  return !isLoading ? (
    <div className="homeControlScss">
      <nav id="sidebar" className={sidebarOpen ? "show" : ""}>
        <div
          className="sidebar-header"
          style={{
            backgroundColor: brand && brand.whiteBackground ? "white" : "",
            cursor: "pointer",
          }}
        >
          <img
            style={{ cursor: "pointer" }}
            src={
              brand
                ? brand.pic
                : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
            }
            className="Logo"
            onClick={redirectTo}
          />
        </div>
        <p className="back-btn" onClick={backToGroup}>
          <i
            className="icon fa fa-angle-left"
            style={{ paddingRight: "3px" }}
          ></i>
          Back
        </p>
      </nav>

      {/* Sidebar for mobile */}
      <nav id="sidebar-mobile" className={sidebarOpen ? "show" : ""}>
        <div id="dismiss" onClick={() => setSidebarOpen(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
            style={{ height: "18px", width: "18px", margin: "12.5px auto" }}
          >
            <line
              x1="35"
              y1="5"
              x2="5"
              y2="35"
              stroke="#fff"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <line
              x1="5"
              y1="5"
              x2="35"
              y2="35"
              stroke="#fff"
              strokeWidth="5"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div
          className="sidebar-header"
          style={{
            backgroundColor: brand && brand.whiteBackground ? "white" : "",
            cursor: "pointer",
          }}
        >
          <img
            style={{ cursor: "pointer" }}
            src={
              brand
                ? brand.pic
                : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
            }
            className="Logo"
            onClick={redirectTo}
          />
        </div>
        <p className="back-btn" onClick={backToGroup}>
          <i
            className="icon fa fa-angle-left"
            style={{ paddingRight: "3px" }}
          ></i>
          Back
        </p>
      </nav>

      {/* Content */}
      <div id="content">
        <div className="header">
          <div
            className="hamBurger"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <img
              src="http://cdn.pegasus.imarticus.org/homepage/homepagesidebaricon.svg"
              alt="hamburger"
            />
          </div>
          <h2>{groupName}</h2>
        </div>
        <div className="main">
          <div className="row">
            {courses.map((course) => (
              <div className="col-md-4 col-sm-6 col-12" key={course._id}>
                <div className="card">
                  <div
                    className="card-image"
                    style={{
                      background:
                        course.crs_thumb_url !== ""
                          ? `url(${course.crs_thumb_url}) center`
                          : `url(https://pegasus.imarticus.org/dashboard/static/media/course-default.svg) center`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <p
                    className="title"
                    style={{ wordWrap: "break-word !important" }}
                  >
                    {course.name}
                  </p>
                  {course.duration && (
                    <p className="instructors">{course.duration}</p>
                  )}
                  <div className="row">
                    {course.rtg && (
                      <div className="col-sm-6">
                        <ul
                          style={{ display: "inline-block" }}
                          className="rating"
                        >
                          {processRatings(course.rtg).map((rating, index) => (
                            <li key={index}>
                              <img
                                src={
                                  rating > 1 && rating < 10
                                    ? getStar(rating)
                                    : rating === 10
                                      ? "https://cdn.pegasus.imarticus.org/courses/images/star-yellow.svg"
                                      : "https://cdn.pegasus.imarticus.org/courses/images/star-gray.svg"
                                }
                              />
                            </li>
                          ))}
                        </ul>
                        <p>{course.rtg}</p>
                      </div>
                    )}
                    {course.nenl && (
                      <div className="col-sm-6">
                        <img
                          src="https://cdn.pegasus.imarticus.org/home-controller/enrollments.svg"
                          style={{ marginBottom: "10px" }}
                        />
                        <p>{course.nenl} Students</p>
                      </div>
                    )}
                  </div>
                  {!course.i_paid || status[course._id] ? (
                    <a
                      className="btn enroll-btn hanging-btn"
                      style={{ color: "#fff" }}
                      onClick={() => redirectTo(1, course._id)}
                    >
                      Start Course
                    </a>
                  ) : (
                    <a
                      className="btn enroll-btn hanging-btn"
                      href={courseLinks[course._id]}
                      target="_blank"
                    >
                      Subscribe
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = { dispatchIndividualBrand, getProfileInfoOnly };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeCtrl),
);

// return !isLoading ? <div><div className="width-100" id="rvm-v1-main-nav-sidebar-otr-ctnr">
//   <nav className="rvm-v1 rvm-v1-main-nav-ctnr" id="sidebar" sidebar>
//     <div id="dismiss-mobile" sidebar-closer onClick={clsoeSideNav}>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 40 40"
//         enable-background="new 0 0 40 40"
//       >
//         <line
//           x1="5"
//           y1="5"
//           x2="35"
//           y2="35"
//           stroke="#fff"
//           stroke-width="5"
//           stroke-linecap="round"
//           stroke-miterlimit="10"
//         ></line>
//         <line
//           x1="35"
//           y1="5"
//           x2="5"
//           y2="35"
//           stroke="#fff"
//           stroke-width="5"
//           stroke-linecap="round"
//           stroke-miterlimit="10"
//         ></line>
//       </svg>
//     </div>
//     <div
//       className="rvm-v1-sidebar-logo-ctnr center-all"
//       style={{
//         backgroundColor: brand && brand.whiteBackground ? "white" : "",
//       }}
//     >
//       <img
//         style={{ cursor: "pointer" }}
//         onClick={redirectTo}
//         src={
//           brand
//             ? brand.pic
//             : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
//         }
//         alt="Imarticus"
//       />
//     </div>
//     <p className="back-btn sidebar-header" onClick={backToGroup}>
//     <img
//       class="back-img"
//       src="https://pegasus.imarticus.org/dashboard/static/media/left-arr-white.fa8e4b0b.svg"
//     />Back
//   </p>
//   </nav>
// </div>

// {/* Content */}
// <div id="content">
//   <div className="header">
//     <div className="hamBurger" onClick={() => openSideNav() }>
//       <img src="http://cdn.pegasus.imarticus.org/homepage/homepagesidebaricon.svg" alt="hamburger" />
//     </div>
//     <h2>{groupName}</h2>
//   </div>
//   <div className="main">
//     <div className="row">
//       {courses.map(course => (
//         <div className="col-md-4 col-sm-6 col-12" key={course._id}>
//           <div className="card">
//             <div className="card-image" style={{ background: course.crs_thumb_url !== '' ? `url(${course.crs_thumb_url}) center` : 'url(https://cdn.pegasus.imarticus.org/images/imarticus-course-default.png) center', backgroundSize: 'cover' }}></div>
//             <p className="title" style={{ wordWrap: 'break-word !important' }}>{course.name}</p>
//             {course.duration && <p className="instructors">{course.duration}</p>}
//             <div className="row">
//               {course.rtg && (
//                 <div className="col-sm-6">
//                   <ul style={{ display: 'inline-block' }} className="rating">
//                     {processRatings(course.rtg).map((rating, index) => (
//                       <li key={index}>
//                         <img src={rating > 1 && rating < 10 ? getStar(rating) : rating === 10 ? 'https://cdn.pegasus.imarticus.org/courses/images/star-yellow.svg' : 'https://cdn.pegasus.imarticus.org/courses/images/star-gray.svg'} />
//                       </li>
//                     ))}
//                   </ul>
//                   <p>{course.rtg}</p>
//                 </div>
//               )}
//               {course.nenl && (
//                 <div className="col-sm-6">
//                   <img src="images/enrollments.svg" style={{ marginBottom: '10px' }} />
//                   <p>{course.nenl} Students</p>
//                 </div>
//               )}
//             </div>
//             {!course.i_paid || status[course._id] ? (
//               <a className="btn enroll-btn hanging-btn" style={{color:"#fff"}} onClick={() => redirectTo(1, course._id)}>Start Course</a>
//             ) : (
//               <a className="btn enroll-btn hanging-btn" href={courseLinks[course._id]} target="_blank">Subscribe</a>
//             )}
//           </div>
//         </div>
//       ))}
//     </div>
//   </div>
// </div></div> : <Loader />
