import React, { Fragment, useEffect, useState } from "react";
import { ROUTES } from "../../constants/routes";
import { TIER_COLOR_CODE } from "../../constants/constants";
import { Link, useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import {
  getProfileInfoOnly,
  changeSaveStatus,
} from "../../actions/profileAction.js";
import {
  setSelectedCourse,
  authenticateCourse,
} from "../../actions/courseAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import { showFreshDesk } from "../../actions/showFreshDesk.js";
import "./placementportal.scss";
import "./gradebook2.scss";
import ReactHtmlParser from "react-html-parser";
import Loader from "../utils/Loader.js";
import SidebarContainer from "../sidebars/SidebarContainer.js";
import JobHeader from "../headers/JobHeader.jsx";
import { CONFIGS, STATIC_ROUNDS } from "../../constants/constants.js";
import TooltipItem from "../utils/GeneralToolTip.js";
import SkillTagTooltipItem from "../utils/SkillTagToolTip.js";
import { Progress, Tooltip, Col, Row } from "reactstrap";
import ContentContainer from "../homecontents/ContentContainer";
import momentjs from "moment-timezone";
import PDFReader from "../PDFReader/PDFReader";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  FormGroup,
  Input,
} from "reactstrap";

import {
  applyCerti,
  atCrs,
  brandDataCourse,
  certificateStatus,
  gradebookData,
  tiersData,
  courseStatus,
} from "../../actions/gradebookAction.js";
import {
  placementProfileData,
  singleJobData,
  applyToJob,
} from "../../actions/profileAction.js";
import { size } from "lodash";

var monthsArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const nav = ["Step 1", "Step 2", "Step 3", "Step 4"];
const HeaderMap = {
  "Step 1": "Introduction",
  "Step 2": "Profile Setup",
  "Step 3": "Details",
  "Step 4": "Confirmation",
};

let query = window.location.href;
query = query.split("/");
const pid = query[4];
const gid = query[5];
const cid = query[6];
const job_id = query[7] != undefined && query[7] ? query[7] : null;
// const levelId = query[7] != undefined && query[7] ? query[7] : null;

console.log({ query });
const cookies = new Cookies();

const data = [
  {
    company: "Alfreds Futterkiste",
    contact: "Maria Anders",
    country: "Germany",
  },
  {
    company: "Centro comercial Moctezuma",
    contact: "Maria Anders",
    country: "Mexico",
  },
];

const SingleJobView = (props) => {
  const [brand, setBrand] = useState();
  const [step, setStep] = useState(2);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openApplicationModal, setOpenApplicationModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [isPortalLocked, setIsPortalLocked] = useState(false);
  const [jobDetail, setJobDetail] = useState({});
  const [selectedResume, setSelectedResume] = useState("");
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [resumeURL, setResumeURL] = useState("");
  const [jobWidth, setJobWidth] = useState(0);
  const [totalWeightAssignedP, setTotalWeightAssignedP] = useState();
  const [selfPaced, setSelfPaced] = useState();
  const [assignmentData, setAssignmentData] = useState();
  const [quizData, setQuizData] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [tierRange, setTierRange] = useState();
  const [joining, setJoining] = useState();
  const [uid, setUid] = useState();
  const [courseName, setCourseName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [impartusEnabled, setImpartusEnabled] = useState();
  const [batchName, setBatchName] = useState();
  const [courseLocation, setCourseLocation] = useState();
  const [certiEnabled, setCertiEnabled] = useState();
  const [certiAutoPublish, setCertiAutoPublish] = useState();
  const [certiPublished, setCertiPublished] = useState();
  const [certiGenerated, setCertiGenerated] = useState();
  const [course, setCourse] = useState();
  const [status, setStatus] = useState();
  const [gstatus, setGstatus] = useState();
  const [crtfEligible, setCrtfEligible] = useState();
  const [certiAvailable, setCertiAvailable] = useState();
  const [certiLink, setCertiLink] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [tiersOpen, setTiersOpen] = useState({});
  const [dp, setDp] = useState();
  const [userName, setUserName] = useState();
  const [primaryEmail, setPrimaryEmail] = useState();
  const [loading, setLoading] = useState(true);
  const [gradebookLoaded, setGradebookLoaded] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [grdData, setGrdData] = useState({});
  const [studentGradebook, setStudentGradebook] = useState([]);
  const [collapseRow, setCollapseRow] = useState({});
  const [enableCategory, setEnableCategory] = useState(false);
  const [grdTotalPerc, setGrdTotalPerc] = useState({});
  const [grdTier, setGrdTier] = useState({});
  const [applyCert, setApplyCert] = useState({});
  const [certStatus, setCertStatus] = useState({});
  const [certiGeneratedStatus, setCertiGeneratedStatus] = useState({});
  const [crtfFileMap, setCrtfFileMap] = useState();

  const [grdPlsStatus, setGrdPlsStatus] = useState({});
  const [elecNameMap, setElecNameMap] = useState({});
  const [showWeightage, setShowWeightage] = useState(false);
  const [showPassMarks, setShowPassMarks] = useState(false);
  const [showPlcStatus, setShowPlcStatus] = useState(false);
  const [placementEnable, setPlacementEnable] = useState(false);
  const [columnSpan, setColumnSpan] = useState(false);
  const [gradebookType, setGradebookType] = useState(1);
  const [isElec, setIsElec] = useState(false);
  const [gradebookDataLoaded, setGradebookDataLoaded] = useState(false);
  const [gradebookSkillTags, setGradebookSkillTags] = useState({});
  const [skillNameMap, setskillNameMap] = useState({});
  const [skillTagPresent, setSkillTagPresent] = useState(false);
  const [allAssessmentPublished, setAllAssessmentPublished] = useState(false);

  const [showPlcPassStatus, setShowPlcPassStatus] = useState(
    "Congratulations! You are eligible for Placement Criteria"
  );
  const [showPlcFailStatus, setShowPlcFailStatus] = useState(
    "You are not eligible for the Placement.  "
  );
  const [plcPassStatusText, setPlcPassStatusText] = useState("PASS");
  const [plcFailStatusText, setPlcFailStatusText] = useState("FAIL");
  const [showContentStatus, setShowContentStatus] = useState(false);
  const [ctcOffered, setCtcOffered] = useState(0);
  const [jobSaved, setJobSaved] = useState(false);
  const [createdJobDescData, setCreatedJobDescData] = useState({});
  const [createdJob, setCreatedJob] = useState({});
  const [createdJobCriteriaData, setCreatedJobCriteriaData] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [addedSkills, setAddedSkills] = useState([]);
  const [jobDomain, setJobDomain] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobMode, setJobMode] = useState("");
  const [workex, setWorkex] = useState([]);
  const [interviewMode, setInterviewMode] = useState("");
  const [jobLocationNames, setJobLocationNames] = useState([]);
  const [rolesData, setRolesData] = useState("");
  const [addInfoData, setAddInfoData] = useState("");
  let ColorMap = {
    0: "sucess",
    1: "info",
    2: "warning",
    3: "danger",
    4: "primary",
    5: "secondary",
    6: "light",
    7: "dark",
  };
  const quesNum = 1;
  let notSelectedCount = 0;
  useEffect(() => {
    (async () => {
      window.FreshworksWidget?.("hide", "launcher");
      //   setLoading(true);
      await authenticatCourse();
      await props.getProfileInfoOnly();
      await fetchBrandDataCrs();
      setDp(props.ppic);
      setUserName(props.profileName);
      setPrimaryEmail(props.primaryEmail);
      await getPlacementProfileData();
      await getSingleJobData();
    })();
  }, []);
  useEffect(() => {
    (async () => {
      //   await getTiersData();
      //   await getCertificateStatus();
      //   await getCourseStatus();
      //   await getCerti();
    })();
  }, [gradebookDataLoaded]);

  useEffect(() => {
    console.log({ careerservicesprops: props });
    const paragraph = document.querySelector('p[data-f-id="pbf"]');
    if (paragraph) {
      paragraph.style.opacity = "0";
    }
  }, []);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.startsWith("/placement-portal/") &&
      location.pathname.length > "/placement-portal/".length
    ) {
      setOpenProfileModal(false);
    }
  }, [location]);

  const [state, setState] = useState(0);

  useEffect(() => {
    if (!placementEnable) {
      if (showContentStatus) {
        if (showPassMarks && showWeightage) {
          setColumnSpan(6);
        } else if (showPassMarks && !showWeightage) {
          setColumnSpan(4);
        } else if (!showPassMarks && showWeightage) {
          setColumnSpan(5);
        } else {
          setColumnSpan(3);
        }
      } else {
        if (showPassMarks && showWeightage) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage) {
          setColumnSpan(4);
        } else {
          setColumnSpan(2);
        }
      }
    } else {
      if (showContentStatus) {
        if (showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else if (showPassMarks && !showWeightage && !showPlcStatus) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (!showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(4);
        } else if (!showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else {
          setColumnSpan(3);
        }
      } else {
        if (showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else if (showPassMarks && !showWeightage && !showPlcStatus) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (!showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(4);
        } else if (!showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else {
          setColumnSpan(2);
        }
      }
    }
  }, [placementEnable, showWeightage, showPassMarks, showPlcStatus]);

  const handleClick = (index, data) => {
    let collapserow = collapseRow;

    if (collapserow[data]) {
      collapserow[data] = false;
      setCollapseRow({ ...collapserow });
    } else {
      collapserow[data] = true;
      setCollapseRow({ ...collapserow });
    }
  };

  const saveJob = async (jobAppId) => {
    changeSaveStatus(jobAppId, true)
      .then(function (res) {
        console.log(res, ":response data");
        let data = res.data.data;
        if (data && data.updatedApplication) {
          setJobSaved(data.updatedApplication.is_saved);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const unsaveJob = async (jobAppId) => {
    changeSaveStatus(jobAppId, false)
      .then(function (res) {
        console.log(res, ":response data");
        let data = res.data.data;
        if (data && data.updatedApplication) {
          setJobSaved(data.updatedApplication.is_saved);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const previewResumeClick = (url) => {
    setResumeURL(url);
    setTogglePDFDoc(true);
  };

  const handleButtonClick = (questionIndex, optionIndex) => {
    const updatedQuestions = [...jobDetail.additionalQuestions];
    updatedQuestions[questionIndex].ans_options.forEach((option, index) => {
      option.selected = index === optionIndex;
    });
    setJobDetail({ ...jobDetail, additionalQuestions: updatedQuestions });
  };

  const handleAdditionalQuestionResponseChange = (e, index) => {
    const { value } = e.target;
    setJobDetail((prevJobDetail) => {
      const updatedQuestions = [...prevJobDetail.additionalQuestions];
      updatedQuestions[index].response = value;
      return { ...prevJobDetail, additionalQuestions: updatedQuestions };
    });
  };

  const handleResumeSelection = (resumeId) => {
    if (resumeId === selectedResume) {
      // If the clicked resume is already selected, deselect it
      setSelectedResume("");
    } else {
      // Otherwise, select the clicked resume
      setSelectedResume(resumeId);
    }
  };

  const authenticatCourse = async () => {
    if (!cookies.get("isLoggedIn")) {
      return window.open(CONFIGS.WEB_DASHBOARD_BASE_URL, "_self");
    }
    atCrs({
      pid,
      gid,
      cid,
      dispatchIndividualBrand: props.dispatchIndividualBrand,
    })
      .then(function (res) {
        console.log(res);
        var expirydate = new Date();
        expirydate.setDate(expirydate.getDate() + 180);
        cookies.set("crs_tkn", res.data.data.at, {
          expires: expirydate,
          domain: CONFIGS.DOMAIN,
          path: "/",
        });

        if (res.data.data.joined) {
          setJoining(new Date(res.data.data.joined));
        }
        if (res.data.data.uid) {
          setUid(res.data.data.uid);
        }
        if (res.data.data.name) {
          setCourseName(res.data.data.name);
        }
        if (res.data.data && res.data.data.phoneno) {
          setPhoneNo(res.data.data.phoneno);
        }
        if (res.data.data.selfPaced) {
          setSelfPaced(res.data.data.selfPaced);
        }
        setImpartusEnabled(res.data.data.course.impartus_enabled);
        setShowPassMarks(res.data.data.course.show_pass_marks);
        setShowWeightage(res.data.data.course.show_weightage);
        if ("show_content_status" in res.data.data.course) {
          setShowContentStatus(res.data.data.course["show_content_status"]);
        }

        let passStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_text
          : "Congratulations! You are eligible for Placement Criteria";
        let failStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_text
          : "You are not eligible for the Placement.  ";
        let plcpassStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_status_text
          : "PASS";
        let plcfailStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_status_text
          : "FAIL";

        setPlcPassStatusText(plcpassStatus);
        setPlcFailStatusText(plcfailStatus);

        setShowPlcFailStatus(failStatus);
        setShowPlcPassStatus(passStatus);
        let grdtype = res.data.data.course.gradebook_type
          ? res.data.data.course.gradebook_type
          : 1;
        setGradebookType(grdtype);
        setPlacementEnable(res.data.data.course.plcmnt_enable);

        let showplc = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.gradebook_show_plcmnt_stts
          : false;
        setShowPlcStatus(showplc);
        setBatchName(res.data.data.course.btch_name);
        setCourseLocation(res.data.data.location);

        let courseFound = res.data.data.course;
        if (res.data.data.location) {
          courseFound.loc_name = res.data.data.location;
        }
        if (courseFound) {
          props.setSelectedCourse(courseFound);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getPlacementProfileData = async () => {
    setLoading(true);
    placementProfileData(cid)
      .then(function (res) {
        console.log(res, ":response data");
        let data = res.data.data;

        if (data && data.show_profile_prompt) {
          setOpenProfileModal(true);
        }
        setLoading(false);
      })
      .catch(function (err) {
        console.log(err);
        setLoading(false);
      });
  };

  const getSingleJobData = async () => {
    setLoading(true);
    singleJobData(cid, job_id)
      .then(function (res) {
        console.log(res, ":response data");
        let data = res.data.data;
        if (data && data.jobDetail) {
          setJobDetail(data.jobDetail);
          let jwid = 100 / data.jobDetail.rounds_order.length;
          setJobWidth(jwid);
          if (data.jobDetail && data.jobDetail.ctc_offered) {
            setCtcOffered(data.jobDetail.ctc_offered);
          }
          if (data.jobDetail && data.jobDetail.is_saved) {
            setJobSaved(data.jobDetail.is_saved);
          }
        }
        setLoading(false);
      })
      .catch(function (err) {
        console.log(err);
        setLoading(false);
      });
  };

  const applyJob = async () => {
    if (selectedResume == "") {
      // window.alert("No resume selected, PLease select a resume");
      setOpenSelectModal(true);
      return;
    }
    applyToJob(
      jobDetail.crs_pg_id,
      job_id,
      selectedResume,
      jobDetail.additionalQuestions
    )
      .then(function (res) {
        console.log(res, ":response data");
        let data = res.data.data;
        if (data && data.updatedApplication) {
          // let appliedCount = allJobs.filter(job => job.is_applied).length
          // setJobsApplied(appliedCount)

          setOpenApplicationModal(false);
          setOpenSuccessModal(true);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const fetchBrandDataCrs = async () => {
    brandDataCourse(cid)
      .then((response) => {
        var brand = response.data.data.brand;
        // props.dispatchIndividualBrand(brand);
        if (brand) {
          setBrand(brand);
        }
      })
      .catch((err) => {
        props.dispatchIndividualBrand();
        console.log({ err });
      });
  };

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const ensureHttps = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  const togglePopover2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleTiersPopover = (index) => {
    console.log("tiertoggle", index, tiersOpen);
    let tiers_open = tiersOpen;
    tiers_open[index] = !tiers_open[index];
    console.log(tiers_open);
    setTiersOpen({ ...tiers_open });
  };

  return !loading ? (
    <div className="course-page gradebookPage-2">
      <JobHeader
        breadCrumbs={[
          { name: "Dashboard", link: "/" },
          { name: "Course", link: `/view?cid=${cid}` },
        ]}
        individualBrand={brand}
      />
      <SidebarContainer crs_pg_id={props.selected_course.crs_pg_id} />
      {/*Check Placement Access here */}

      <div className="col-xs-12 rvm-v1 no-padding" id="content">
        <div className="breadCrumbsContainer-2">
          <div className="rvm-v1-header-brdsc-ctnr breadCrumbs-row-2">
            <div
              className="breadCrumbs"
              style={{ color: "rgba(33, 42, 57, 0.75) !important" }}
            >
              <a href="/dashboard">
                <span>My Courses</span>
              </a>
              <i
                style={{ fontSize: "small", marginLeft: "10px" }}
                className="fa fa-chevron-right"
              ></i>
              <a href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}view/?cid=${cid}`}>
                {courseName}
              </a>
              <i
                style={{
                  fontSize: "small",
                  marginLeft: "10px",
                }}
                className="fa fa-chevron-right"
              ></i>
              <a href={`/placement-portal/${pid}/${gid}/${cid}`} className="">
                <span>Career Services</span>
              </a>
              <i
                style={{
                  fontSize: "small",
                  marginLeft: "10px",
                }}
                className="fa fa-chevron-right"
              ></i>
              <a href={""} className="rvm-v1-header-brdsc-active">
                <span>{jobDetail.company_name}</span>
              </a>
            </div>
          </div>
        </div>
        {!isPortalLocked &&
          Object.keys(jobDetail).length > 0 &&
          jobDetail.job_app_static_status > 1 && (
            <div>
              <div
                className="rvm-v1-cont-ctnr-2 rvm-v1-part-2-otr-ctnr job-prev-padding-2"
                style={{ display: "block" }}
              >
                <div
                  className="main rvm-v1-part-2-ctnr no-padding"
                  style={{ marginLeft: "4%" }}
                >
                  <div className="jobs-content">
                    <div className="adques-all">
                      <li
                        className="row centr-content"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="previewTag-2">
                          <p>Job Status :</p>
                        </div>
                        <div className="previewTagResponse">
                          <div style={{ display: "flex" }}>
                            {STATIC_ROUNDS[jobDetail.job_app_static_status]}
                          </div>
                        </div>
                      </li>
                      <li className="row centr-content">
                        <div
                          className=""
                          // key={pendingInstallment._id}
                          style={{ marginBottom: "1rem", width: "80%" }}
                        >
                          <div className="actionRow">
                            <div className="installmentRow-2">
                              <div className="installmentRow-2 ">
                                <div
                                  className="installment-2 "
                                  style={{
                                    position: "relative",
                                    top: "0px",
                                    width: "100%",
                                  }}
                                >
                                  <React.Fragment>
                                    <div
                                      style={{
                                        width: `${jobWidth}%`,
                                      }}
                                    >
                                      <img
                                        src="https://webcdn.imarticus.org/Placement/Group3775.svg"
                                        alt="right"
                                        height="24"
                                        width="24"
                                        style={{
                                          background: "#097252;",
                                          padding: "0",
                                          zIndex: 150,
                                          position: "relative",
                                        }}
                                      />
                                    </div>
                                    {jobDetail.rounds_order &&
                                    jobDetail.rounds_order.length > 0
                                      ? jobDetail.rounds_order.map(
                                          (round, index) =>
                                            jobDetail.job_app_static_status >
                                              2 &&
                                            jobDetail.job_app_static_status <
                                              5 ? (
                                              index <=
                                              jobDetail.job_app_dynamic_status ? (
                                                <div
                                                  style={{
                                                    width: `${jobWidth}%`,
                                                  }}
                                                >
                                                  <img
                                                    src="https://webcdn.imarticus.org/Placement/Group3775.svg"
                                                    alt="right"
                                                    height="24px"
                                                    width="24px"
                                                    style={{
                                                      background: "white",
                                                      padding: "0",
                                                      zIndex: 150,
                                                      position: "relative",
                                                    }}
                                                  />
                                                </div>
                                              ) : jobDetail.job_app_static_status >=
                                                6 ? (
                                                <div
                                                  style={{
                                                    width: `${jobWidth}%`,
                                                  }}
                                                >
                                                  <img
                                                    src="https://webcdn.imarticus.org/Placement/Group5482.svg"
                                                    alt="right"
                                                    height="24px"
                                                    width="24px"
                                                    style={{
                                                      background: "white",
                                                      padding: "0",
                                                      zIndex: 150,
                                                      position: "relative",
                                                    }}
                                                  />
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    width: `${jobWidth}%`,
                                                  }}
                                                >
                                                  <img
                                                    src="https://webcdn.imarticus.org/Placement/Group5480.svg"
                                                    alt="right"
                                                    height="24px"
                                                    width="24px"
                                                    style={{
                                                      background: "white",
                                                      padding: "0",
                                                      zIndex: 150,
                                                      position: "relative",
                                                    }}
                                                  />
                                                </div>
                                              )
                                            ) : index <
                                              jobDetail.job_app_dynamic_status ? (
                                              <div
                                                style={{
                                                  width: `${jobWidth}%`,
                                                }}
                                              >
                                                <img
                                                  src="https://webcdn.imarticus.org/Placement/Group3775.svg"
                                                  alt="right"
                                                  height="24px"
                                                  width="24px"
                                                  style={{
                                                    background: "white",
                                                    padding: "0",
                                                    zIndex: 150,
                                                    position: "relative",
                                                  }}
                                                />
                                              </div>
                                            ) : jobDetail.job_app_static_status >=
                                              6 ? (
                                              <div
                                                style={{
                                                  width: `${jobWidth}%`,
                                                }}
                                              >
                                                <img
                                                  src="https://webcdn.imarticus.org/Placement/Group5482.svg"
                                                  alt="right"
                                                  height="24px"
                                                  width="24px"
                                                  style={{
                                                    background: "white",
                                                    padding: "0",
                                                    zIndex: 150,
                                                    position: "relative",
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  width: `${jobWidth}%`,
                                                }}
                                              >
                                                <img
                                                  src="https://webcdn.imarticus.org/Placement/Group5480.svg"
                                                  alt="right"
                                                  height="24px"
                                                  width="24px"
                                                  style={{
                                                    background: "white",
                                                    padding: "0",
                                                    zIndex: 150,
                                                    position: "relative",
                                                  }}
                                                />
                                              </div>
                                            )
                                        )
                                      : ""}
                                    {jobDetail.job_app_static_status == 4 ? (
                                      <div>
                                        <img
                                          src="https://webcdn.imarticus.org/Placement/Group3775.svg"
                                          alt="right"
                                          height="24px"
                                          width="24px"
                                          style={{
                                            background: "white",
                                            padding: "0",
                                            zIndex: 150,
                                            position: "relative",
                                          }}
                                        />
                                      </div>
                                    ) : jobDetail.job_app_static_status >= 6 ? (
                                      <div>
                                        <img
                                          src="https://webcdn.imarticus.org/Placement/Group5482.svg"
                                          alt="right"
                                          height="24px"
                                          width="24px"
                                          style={{
                                            background: "white",
                                            padding: "0",
                                            zIndex: 150,
                                            position: "relative",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <img
                                          src="https://webcdn.imarticus.org/Placement/Group5480.svg"
                                          alt="right"
                                          height="24px"
                                          width="24px"
                                          style={{
                                            background: "white",
                                            padding: "0",
                                            zIndex: 150,
                                            position: "relative",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </React.Fragment>
                                  {/* <div
                                  className="bar-1"
                                  style={{
                                    width: `${80}%`,
                                  }}
                                >
                                </div> */}
                                  <div className="profile-bar-progress-1">
                                    <div
                                      className="profile-bar-completed-1"
                                      style={{
                                        width:
                                          jobDetail.job_app_static_status > 2
                                            ? `${
                                                ((jobDetail.job_app_dynamic_status +
                                                  (jobDetail.job_app_static_status ===
                                                  4
                                                    ? 2
                                                    : 1)) /
                                                  (jobDetail.rounds_order
                                                    .length +
                                                    1)) *
                                                100
                                              }%`
                                            : "0%",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="actionRow">
                            <div className="installmentRow-2">
                              {/* <div className="installmentRow-2"> */}
                              <div
                                className="installment-2"
                                style={{
                                  position: "relative",
                                  top: "-5px",
                                  width: "100%",
                                }}
                              >
                                <React.Fragment>
                                  {/* <div className="row"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: `${jobWidth}%`,
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "var(--212A39, #212A39)",
                                        fontFamily: "Source Sans Pro",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "normal",
                                        background: "white",
                                        padding: "2px",
                                        display: "inline-block",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Shortlisted
                                    </span>
                                  </div>
                                  {/* </div> */}
                                  {jobDetail.rounds_order &&
                                  jobDetail.rounds_order.length > 0
                                    ? jobDetail.rounds_order.map(
                                        (round, index) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: `${jobWidth}%`,
                                            }}
                                          >
                                            <span
                                              key={index}
                                              style={{
                                                color: "var(--212A39, #212A39)",
                                                fontFamily: "Source Sans Pro",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                lineHeight: "normal",
                                                background: "white",
                                                padding: "2px",
                                                display: "inline-block",
                                                marginRight: "10px",
                                              }}
                                            >
                                              {round}
                                            </span>
                                            <div
                                              className=""
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: `${jobWidth}%`,
                                              }}
                                            >
                                              {(jobDetail.job_app_dynamic_status >
                                                index &&
                                                jobDetail.job_app_static_status >
                                                  2) ||
                                              jobDetail.job_app_static_status ==
                                                4 ? (
                                                <span
                                                  style={{
                                                    color:
                                                      "var(--212A39, #212A39)",
                                                    fontFamily:
                                                      "Source Sans Pro",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "normal",
                                                    background: "white",
                                                    padding: "2px",
                                                    display: "inline-block",
                                                    marginRight: "10px",
                                                    paddingLeft: "7px",
                                                  }}
                                                >
                                                  Cleared
                                                </span>
                                              ) : (
                                                jobDetail.job_app_static_status >=
                                                  6 && (
                                                  <>
                                                    <span
                                                      style={{
                                                        color:
                                                          "var(--212A39, #212A39)",
                                                        fontFamily:
                                                          "Source Sans Pro",
                                                        fontSize: "14px",
                                                        fontStyle: "normal",
                                                        fontWeight: "400",
                                                        lineHeight: "normal",
                                                        background: "white",
                                                        padding: "2px",
                                                        display: "inline-block",
                                                        marginRight: "10px",
                                                        paddingLeft: "5px",
                                                      }}
                                                    >
                                                      Not Selected
                                                    </span>
                                                    <span
                                                      style={{
                                                        color:
                                                          "var(--212A39, #212A39)",
                                                        fontFamily:
                                                          "Source Sans Pro",
                                                        fontSize: "14px",
                                                        fontStyle: "normal",
                                                        fontWeight: "400",
                                                        lineHeight: "normal",
                                                        background: "white",
                                                        padding: "2px",
                                                        display: "inline-block",
                                                        marginRight: "10px",
                                                      }}
                                                    >
                                                      {momentjs(
                                                        jobDetail.updatedAt
                                                      ).format("DD MMMM YYYY")}
                                                    </span>
                                                  </>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )
                                    : ""}
                                  {jobDetail.job_app_static_status < 6 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "var(--212A39, #212A39)",
                                          fontFamily: "Source Sans Pro",
                                          fontSize: "16px",
                                          fontStyle: "normal",
                                          fontWeight: "600",
                                          lineHeight: "normal",
                                          background: "white",
                                          padding: "2px",
                                          display: "inline-block",
                                          // marginRight: "10px",
                                        }}
                                      >
                                        Selected
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "var(--212A39, #212A39)",
                                          fontFamily: "Source Sans Pro",
                                          fontSize: "16px",
                                          fontStyle: "normal",
                                          fontWeight: "600",
                                          lineHeight: "normal",
                                          background: "white",
                                          padding: "2px",
                                          display: "inline-block",
                                          // marginRight: "10px",
                                        }}
                                      >
                                        Not Selected
                                      </span>
                                      <div
                                        className=""
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {jobDetail.job_app_static_status >=
                                          6 && (
                                          <>
                                            <span
                                              style={{
                                                color: "var(--212A39, #212A39)",
                                                fontFamily: "Source Sans Pro",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                                background: "white",
                                                padding: "2px",
                                                display: "inline-block",
                                                marginRight: "10px",
                                              }}
                                            >
                                              Not Selected
                                            </span>
                                            <span
                                              style={{
                                                color: "var(--212A39, #212A39)",
                                                fontFamily: "Source Sans Pro",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                                background: "white",
                                                padding: "2px",
                                                display: "inline-block",
                                                marginRight: "10px",
                                              }}
                                            >
                                              {momentjs(
                                                jobDetail.updatedAt
                                              ).format("DD MMMM YYYY")}
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {!isPortalLocked &&
          Object.keys(jobDetail).length > 0 &&
          jobDetail.job_app_static_status < 6 &&
          jobDetail.job_app_static_status > 3 &&
          jobDetail.offer_status === 1 && (
            <div>
              <div
                className="job-prev-padding-2 rvm-v1-cont-ctnr-3 rvm-v1-part-2-otr-ctnr job-prev-padding-2"
                style={{ display: "block" }}
              >
                <div
                  className="main rvm-v1-part-2-ctnr no-padding"
                  style={{ marginLeft: "4%" }}
                >
                  <div className="jobs-content">
                    <div className="adques-all">
                      <div className="row">
                        <div className="col-md-1">
                          <div className="prof-dtl-img-2 ">
                            <img
                              src="https://webcdn.imarticus.org/Placement/lets-icons_done-ring-round-fill.svg"
                              alt="cross"
                              // onClick={closeRefer}
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <p className="row highlight-name">
                            Hi {props.profileName},
                          </p>
                          <p className="row highlight-text">
                            Congratulations! 🎉 You've been selected! Let’s
                            embark on your exciting journey ahead. Let's make
                            great things happen together! 🚀
                          </p>
                          <p className="row">
                            {" "}
                            {/* <span className="highlight-text">
                              CTC Accepted:{"  "}
                            </span>{" "}
                            <span className="highlight-text">
                              ₹ {ctcOffered}
                            </span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {!isPortalLocked &&
          Object.keys(jobDetail).length > 0 &&
          jobDetail.job_app_static_status >= 6 && (
            <div>
              <div
                className="rvm-v1-cont-ctnr-3 rvm-v1-part-2-otr-ctnr job-prev-padding-2"
                style={{ display: "block" }}
              >
                <div
                  className="main rvm-v1-part-2-ctnr no-padding"
                  style={{ marginLeft: "4%" }}
                >
                  <div className="jobs-content">
                    <div
                      className="adques-all"
                      style={{
                        paddingTop: "44px",
                        paddingBottom: "46px",
                        paddingLeft: "14px",
                      }}
                    >
                      <div className="row">
                        <div className="col-md-1">
                          <div className="prof-dtl-img-3 ">
                            <img
                              src="https://webcdn.imarticus.org/Placement/ic_baseline-cancel.svg"
                              alt="cross"
                              // onClick={closeRefer}
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <p className="row highlight-name">Not Selected</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {!isPortalLocked && Object.keys(jobDetail).length > 0 ? (
          <div>
            <div
              className="rvm-v1-cont-ctnr-2 rvm-v1-part-2-otr-ctnr-2"
              style={{ display: "block" }}
            >
              <div
                className="main rvm-v1-part-2-ctnr no-padding"
                style={{ marginLeft: "4%" }}
              >
                <div className="jobs-content">
                  <div className="adques-all" style={{ paddingBottom: "48px" }}>
                    <div
                      className="adques-header-2"
                      style={{ paddingTop: "10px", paddingBottom: "5px" }}
                    >
                      <Col md={9} className="jobs-table-header-3">
                        <p style={{ marginBottom: "10px" }}>Job Preview</p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={1}>
                        {jobDetail.job_app_static_status >= 1 &&
                          (!jobSaved ? (
                            <span
                              style={{
                                paddingRight: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => saveJob(jobDetail._id)}
                            >
                              <img
                                src="https://webcdn.imarticus.org/Placement/material-symbols_bookmark-outline.svg"
                                height={30}
                                width={30}
                                alt="bookmark"
                              />
                            </span>
                          ) : (
                            <span
                              style={{
                                paddingRight: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => unsaveJob(jobDetail._id)}
                            >
                              <img
                                src="https://webcdn.imarticus.org/Placement/material-symbols_bookmark-outline1.svg"
                                height={30}
                                width={30}
                                alt="bookmark"
                              />
                            </span>
                          ))}
                      </Col>
                    </div>
                    <li className="row companyTopRow-4">
                      <div className="companyTopRow-4">
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            border: "1px solid #ddd",
                            marginLeft: "30px",
                            marginBottom: "1rem",
                            borderRadius: "8px",
                          }}
                        >
                          <img
                            src={
                              jobDetail.company_logo
                                ? jobDetail.company_logo
                                : "https://webcdn.imarticus.org/Placement/Suitcase.svg"
                            }
                            alt="Company Logo"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        {/* </div> */}
                        <div className="companyNameRow">
                          <h5
                            className="modal-title-2"
                            htmlFor="thumbImg"
                            style={{ marginTop: "8px" }}
                          >
                            {jobDetail.job_title}
                          </h5>
                          <p className="job-dtl" style={{ marginTop: "16px" }}>
                            {jobDetail.company_name}
                          </p>
                          <a
                            className="companyWebsite job-dtl"
                            href={ensureHttps(jobDetail.company_website)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {jobDetail.company_website}
                          </a>
                        </div>
                      </div>

                      <div className="companyTopRow-3 ">
                        {jobDetail.job_app_static_status < 2 ? (
                          Date.now() < jobDetail.stim ? (
                            <div>
                              You can apply after{" "}
                              {momentjs(jobDetail.stim).format("DD MMMM YYYY")}
                            </div>
                          ) : // <Button
                          //   className="jobs-table-btn-66"
                          //   onClick={() => setOpenApplicationModal(true)}
                          // >
                          //   Apply
                          // </Button>
                          Date.now() > jobDetail.etim ? (
                            <div>
                              Job application deadline expired on{" "}
                              {momentjs(jobDetail.etim).format("DD MMMM YYYY")}
                            </div>
                          ) : (
                            <Button
                              className="jobs-table-btn-66"
                              onClick={() => setOpenApplicationModal(true)}
                            >
                              Apply
                            </Button>
                          )
                        ) : (
                          <span>
                            Applied On:{" "}
                            {momentjs(jobDetail.applied_on).format(
                              "DD MMMM YYYY"
                            )}
                          </span>
                        )}
                      </div>
                    </li>
                    <li className="row">
                      <div className="previewTag">
                        <p style={{ paddingTop: "6px", marginRight: "16px" }}>
                          Skills :
                        </p>
                      </div>
                      <div className="previewTagResponse">
                        <div style={{ display: "flex" }}>
                          {jobDetail.addedSkillTags.length > 0 ? (
                            jobDetail.addedSkillTags.map((skill, index) => (
                              <p
                                key={index}
                                className="skillTagDisplay"
                                style={{ marginRight: "10px" }}
                              >
                                {skill.name}
                              </p>
                            ))
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                    </li>
                    {jobDetail.ctc_type === 1 ? (
                      <li className="row">
                        <div className="previewTag">
                          <p>Salary Range :</p>
                        </div>
                        <div className="previewTagResponse">
                          <div className="row" style={{ marginLeft: "0px" }}>
                            <span>&#8377;</span>
                            <p>{jobDetail.ctc_range_min}</p>
                            <p>-</p>
                            <span>&#8377;</span>
                            <p>{jobDetail.ctc_range_max}</p>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li className="row">
                        <div className="previewTag">
                          <p>Salary Package :</p>
                        </div>
                        <div className="previewTagResponse">
                          <div className="row" style={{ marginLeft: "0px" }}>
                            <span>&#8377;</span>
                            <p>{jobDetail.ctc_amount}</p>
                          </div>
                        </div>
                      </li>
                    )}

                    <li className="row">
                      <div className="previewTag">
                        <p>Domain :</p>
                      </div>
                      <div className="previewTagResponse">
                        <p>{jobDetail.job_domain}</p>
                      </div>
                    </li>
                    <li className="row">
                      <div className="previewTag">
                        <p>Job Type :</p>
                      </div>
                      <div className="previewTagResponse">
                        <p>{jobDetail.job_type.join(", ")}</p>
                      </div>
                    </li>
                    <li className="row">
                      <div className="previewTag">
                        <p>Job Mode :</p>
                      </div>
                      <div className="previewTagResponse">
                        <p>{jobDetail.job_mode.join(", ")}</p>
                      </div>
                    </li>
                    <li className="row">
                      <div className="previewTag">
                        <p>Job Location :</p>
                      </div>
                      <div className="previewTagResponse">
                        <div className="row" style={{ marginLeft: "0px" }}>
                          {jobDetail.job_location.join(", ")}
                        </div>
                      </div>
                    </li>
                    <li className="row">
                      <div className="previewTag">
                        <p>Work Experience :</p>
                      </div>
                      <div className="previewTagResponse">
                        {jobDetail.work_experience.join(", ")}
                      </div>
                    </li>
                    <li className="row">
                      <div className="previewTag">
                        <p>Mode of Interview :</p>
                      </div>
                      <div className="previewTagResponse">
                        <p>{jobDetail.job_interview_mode.join(", ")}</p>
                      </div>
                    </li>
                    <li className="row">
                      <div className="previewTag">
                        <p>Roles & Responsibilities :</p>
                      </div>
                      <div className="previewTagResponse2 resizeHTML">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: jobDetail.roles_info,
                          }}
                        ></p>
                      </div>
                    </li>
                    <li className="row">
                      <div className="previewTag">
                        <p>Additional Information :</p>
                      </div>
                    </li>
                    <li className="row">
                      <div className="previewTagResponse2 resizeHTML">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: jobDetail.additional_info,
                          }}
                        ></p>
                      </div>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Modal
        isOpen={openSuccessModal}
        //   toggle={toggleShowSettingsHandler}
        size={"xl"}
        className="apply-sucess-modal"
        centered
      >
        <ModalBody style={{ maxHeight: "800px" }}>
          <div>
            <div className="prof-dtl-img ">
              <img
                src="https://webcdn.imarticus.org/Placement/lets-icons_done-ring-round-fill.svg"
                alt="cross"
                // onClick={closeRefer}
              />
            </div>
            <p className="aply-success-txt ">
              Your have successfully applied to this job!
            </p>
            <p className="prof-dtl-txt">
              The status will be changed as per updated by the employer
            </p>
          </div>
          <div className="apply-success-div">
            <Button
              // color="success"
              onClick={() => {
                setOpenSuccessModal(false);
                getSingleJobData();
              }}
              className="apply-success-btn-1"
            >
              Done
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={openCancelModal}
        //   toggle={toggleShowSettingsHandler}
        size={"xl"}
        className="apply-sucess-modal"
        centered
      >
        <ModalBody style={{ maxHeight: "400px" }}>
          <div>
            <p className="aply-success-txt ">
              We recommend you to apply as the Application ends soon. Are you
              sure you want to cancel?
            </p>
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            // color="success"
            onClick={() => {
              setOpenCancelModal(false);
              setOpenApplicationModal(false);
            }}
            className="apply-success-btn"
          >
            Yes
          </Button>
          <Button
            // color="success"
            onClick={() => setOpenCancelModal(false)}
            className="apply-danger-btn"
          >
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={openSelectModal}
        //   toggle={toggleShowSettingsHandler}
        size={"xl"}
        className="apply-sucess-modal"
        centered
      >
        <ModalBody style={{ maxHeight: "400px" }}>
          <div>
            <p className="aply-success-txt ">
              Please select a Resume before applying
            </p>
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            onClick={() => {
              setOpenSelectModal(false);
            }}
            className="apply-success-btn"
          >
            Done
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={openApplicationModal}
        //   toggle={toggleShowSettingsHandler}
        size={"xl"}
        className="prof-dtl-modal"
        style={{ maxWidth: "600px" }}
        centered
      >
        <ModalHeader className="apply-job">
          <div
            style={{
              width: "40px",
              height: "40px",
              border: "1px solid #ddd",
              marginLeft: "14px",
              marginRight: "16px",
              // marginBottom: "16px",
              borderRadius: "8px",
              background: "#FFF",
            }}
          >
            <img
              src={
                jobDetail.company_logo
                  ? jobDetail.company_logo
                  : "https://webcdn.imarticus.org/Placement/Suitcase.svg"
              }
              // alt="Company Logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <div>
            <h5 className="job-aply-header">
              Apply to{" "}
              {Object.keys("jobDetail").length > 0
                ? jobDetail.company_name
                : ""}
            </h5>
          </div>
        </ModalHeader>
        <ModalBody
          className="apply-body"
          style={{
            maxHeight: "600px",
            marginRight: "16px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div>
            <p className="job-dtl-txt">Answer few Questions before applying</p>
          </div>

          <div className="ques-div">
            {Object.keys(jobDetail).length > 0 &&
              jobDetail.additionalQuestions.map((question, index) => (
                <li key={index} className="row">
                  <div className="col-md-12 previewques">
                    <h5
                      className="job-dtl-txt2"
                      htmlFor="thumbImg"
                      style={{ marginTop: "1rem" }}
                    >
                      {index + 1}.&nbsp;{question.text}
                    </h5>
                    {question.type === 2 ? (
                      <textarea
                        className="form-control missed-txt-div"
                        rows="3"
                        placeholder="Enter your answer here"
                        style={{ width: "70%" }}
                        value={question.response}
                        onChange={(e) =>
                          handleAdditionalQuestionResponseChange(e, index)
                        }
                      />
                    ) : (
                      <div>
                        {question.ans_options.map((option, optIndex) => (
                          <div
                            key={optIndex}
                            className={
                              option.selected
                                ? "selected add-ques-opt"
                                : "add-ques-opt"
                            }
                            onClick={() => handleButtonClick(index, optIndex)}
                          >
                            <span>{option.optiondata}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </li>
              ))}
          </div>
          <div className="">
            <li className="row">
              <div className="col-md-12 previewques">
                <h5
                  className="job-dtl-txt2"
                  htmlFor="thumbImg"
                  style={{ marginTop: "1rem" }}
                >
                  Select Your Resume
                </h5>
              </div>
            </li>
            {Object.keys(jobDetail).length > 0 &&
              jobDetail.learner_resume
                .filter((file) => file.resume_type === 2)
                .map((file, index) => (
                  <div key={index}>
                    <div
                      className={`resume-div-2 drop-zone previewques ${
                        file._id === selectedResume ? "selected" : ""
                      }`}
                      onClick={() => handleResumeSelection(file._id)}
                    >
                      <div
                        className="row"
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "48px",
                            height: "48px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                          }}
                        >
                          <img
                            className="resume-icon"
                            src="https://webcdn.imarticus.org/Placement/fluent_document-pdf-24-filled.svg"
                            alt="Company Logo"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <div className="resume-wrap">
                          <div className="resume-txt">
                            <h5 className="resume-heading">
                              Resume {index + 1}
                            </h5>
                            <p>
                              {momentjs(file.createdAt).format(
                                "DD MMM 'YY, hh:mm A"
                              )}
                            </p>
                          </div>
                          <div>
                            <img
                              src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                              className="as-dl-logo-job"
                              onClick={() =>
                                previewResumeClick(file.resume_url)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                ))}

            {Object.keys(jobDetail).length > 0 &&
              jobDetail.learner_resume
                .filter((file) => file.resume_type === 1)
                .map((file, index) => (
                  <div key={index}>
                    <div
                      className={`resume-div-2 drop-zone previewques ${
                        file._id === selectedResume ? "selected" : ""
                      }`}
                      onClick={() => handleResumeSelection(file._id)}
                    >
                      <div
                        className="row"
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "48px",
                            height: "48px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                          }}
                        >
                          <img
                            className="resume-icon"
                            src="https://webcdn.imarticus.org/Placement/mingcute_video-fill.svg"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <div className="resume-wrap">
                          <div className="resume-txt">
                            <h5 className="resume-heading">
                              Video Resume {index + 1}
                            </h5>
                            <p>
                              {momentjs(file.createdAt).format(
                                "DD MMM 'YY, hh:mm A"
                              )}
                            </p>
                          </div>
                          <div>
                            <img
                              src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                              className="as-dl-logo-job"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            // color="danger"
            onClick={() => setOpenCancelModal(true)}
            className="apply-danger-btn"
          >
            Cancel
          </Button>
          <Button
            // color="success"
            onClick={() => applyJob()}
            // onClick={() => setOpenSuccessModal(true)}
            className="apply-success-btn"
          >
            Apply
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={togglePDFDoc}
        size={"md"}
        style={{ minwidth: "400px" }}
        centered={true}
      >
        <ModalHeader
          className="resume-prev"
          style={{ paddingRight: "7px", width: "100%" }}
        >
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div>Resume Preview </div>
            <div>
              <img
                src="https://webcdn.imarticus.org/Placement/Group926.svg"
                style={{ paddingRight: "7px" }}
                onClick={() => setTogglePDFDoc(false)}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: "500px",
            // overflowY: "scroll",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {/* <PDFReader url={resumeURL}></PDFReader> */}
          <iframe
            src={resumeURL}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              minHeight: "500px",
            }}
            frameBorder="0"
          />
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
      </Modal>
    </div>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  primaryEmail: state.profile.email,
  ppic: state.profile.ppic,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
  getProfileInfoOnly,
  setSelectedCourse,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleJobView)
);
