import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
import { CONFIGS } from "../constants/constants";
const cookies = new Cookies();

export const atCrs = ({ pid, gid, cid, dispatchIndividualBrand }) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.AUTHENTICATE_COURSE, {
        crs_id: cid,
        p_id: pid,
        g_id: gid,
        tokn: cookies.get("at"),
        a_id: cookies.get("aid"),
        cl: "W",
        d_id: window.fingerprint.md5hash,
      })
      .then(async (response) => {
        console.log(response.data);
        let brand;
        if (!window?.location.href.includes("imarticus.org")) {
          let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);

          if (res.data.success) {
            let brandData = res.data?.data?.brand;
            console.log({ brandData });
            if (brandData) brand = brandData;
          }
        } else {
          brand = response.data?.data?.brand;
        }
        console.log({ brand });
        dispatchIndividualBrand(brand);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const gradebookData = (pid, cid) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.GET_GRADEBOOK_DATA,
        {
          crs_id: cid,
          p_id: pid,
          a_id: cookies.get("aid"),
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const tiersData = (pid, cid) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    axios
      .post(
        ROUTES.GET_GRADEBOOK_TIERS,
        {
          crs_id: cid,
          p_id: pid,
          a_id: cookies.get("aid"),
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const certificateStatus = (pid, cid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.CERTIFICATE_STATUS,
        {
          crs_id: cid,
          a_id: cookies.get("aid"),
          p_id: pid,
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const courseStatus = (pid, cid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.G_STATUS,
        {
          crs_id: cid,
          p_id: pid,
          a_id: cookies.get("aid"),
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const applyCerti = (pid, cid, elec_id) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.APPLY_CERTIFICATE,
        {
          crs_id: cid,
          p_id: pid,
          a_id: cookies.get("aid"),
          cl: "W",
          elec_id: elec_id ? elec_id : undefined,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const brandDataCourse = (cid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .get(ROUTES.FETCH_BRAND_DATA_CRS + `?cid=${cid}`, configs)
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};
