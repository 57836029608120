import React, { Component } from "react";
import { Input } from "reactstrap";
import facebookIcon from "../../images/facebook-icon.svg";
import googleIcon from "../../images/google-icon.svg";
import CountryCodes from "../../constants/countryCodes";
import { ROUTES } from "../../constants/routes";
import Axios from "axios";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Verify from "./Verify";
import { utils } from "../../utility/utils";
import SetPassword from "./SetPassword";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { CONFIGS } from "../../constants/constants";
import tick from "../../images/tick.svg";
import greenTick from "../../images/green-tick.svg";
import eye from "../../images/eye.svg";
import noEye from "../../images/noEye.svg";
import Spinner from "../utils/Spinner";
import Loader from "../utils/Loader";
import { Flex } from "@aws-amplify/ui-react";
import Cookies from "universal-cookie";
import { dispatchIndividualBrand } from "../../actions/brandAction";
const cookies = new Cookies();

let errorMessages = {
  incorrectNumber: "Invalid mobile number, kindly check it again.",
  incorrectEmail: "Invalid Email ID, kindly check it again.",
  usedNumber: "Mobile number already exists.",
  usedEmail:
    "Email already exists. Kindly try to login with this mail ID or enter a different mail ID.",
  failedSignup:
    "Invalid Information provided in the form, kindly check it again.",
};

class Signup extends Component {
  constructor(props) {
    super(props);
    this.captcha = React.createRef();
    let brand = props.match.params.brand;
    console.log({ brand });
    if (brand) {
      this.state = {
        name: "",
        email: "",
        password: "",
        mobile: "",
        ccod: "91",
        incorrectEmail: false,
        incorrectNumber: false,
        usedEmail: false,
        usedNumber: false,
        isSignup: true,
        password: "",
        cnfPass: "",
        validPassword: false,
        incorrectPassword: false,
        password: "",
        cnfPassword: "",
        incorrectCnf: false,
        showPass: false,
        showCnf: false,
        aid: null,
        signupFailed: false,
        isLoading: true,
        captchaVerified: false,
        token: "",
        captchaSiteKey: "",
      };

      Axios.get(ROUTES.HCAPTCHA_KEY_FETCH)
        .then((res) => {
          if (res.data.data.siteKey) {
            this.setState({ captchaSiteKey: res.data.data.siteKey }, () => {
              console.log(
                "Captcha site key fetched",
                this.state.captchaSiteKey
              );
            });
          } else {
            window.alert("Captcha did not load properly refresh again.");
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
          window.alert("Captcha did not load properly refresh again.");
          return;
        });

      Axios.get(ROUTES.FETCH_BRAND_DATA + `?brand_id=${brand}`)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          this.props.dispatchIndividualBrand(brandData);
          if (
            res.data.success &&
            res.data?.data?.brand?.createAccAllowed == true
          ) {
            console.log({ brandData });
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          } else {
            this.props.history.push(`/signup`);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log({ err });
          this.props.history.push(`/signup`);
          window.location.reload();
        });
      window.FreshworksWidget?.("hide");
      if (utils.getLoggedIn()) this.props.history.push(`/${brand}/dashboard`);
    } else {
      if (cookies.get("brand")) {
        cookies.remove("brand", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
      }
      this.state = {
        name: "",
        email: "",
        password: "",
        mobile: "",
        ccod: "91",
        incorrectEmail: false,
        incorrectNumber: false,
        usedEmail: false,
        usedNumber: false,
        isSignup: true,
        password: "",
        cnfPass: "",
        validPassword: false,
        incorrectPassword: false,
        password: "",
        cnfPassword: "",
        incorrectCnf: false,
        showPass: false,
        showCnf: false,
        aid: null,
        signupFailed: false,
        isLoading: false,
        captchaVerified: false,
        token: "",
        captchaSiteKey: "",
      };
      Axios.get(ROUTES.HCAPTCHA_KEY_FETCH)
        .then((res) => {
          if (res.data.data.siteKey) {
            this.setState({ captchaSiteKey: res.data.data.siteKey }, () => {
              console.log(
                "Captcha site key fetched",
                this.state.captchaSiteKey
              );
            });
          } else {
            window.alert("Captcha did not load properly refresh again.");
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
          window.alert("Captcha did not load properly refresh again.");
          return;
        });
      this.setState({ isLoading: true });
      Axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL)
        .then((res) => {
          this.setState({ isLoading: false });
          let brandData = res.data?.data?.brand;
          console.log({ brandData });
          this.props.dispatchIndividualBrand(brandData);
          if (res.data.success) {
            let expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            this.setState({ brand: brandData });
          }
        })
        .catch((err) => {
          console.log({ err });
          this.setState({ isLoading: false });
        });
      window.FreshworksWidget?.("hide");
      if (utils.getLoggedIn()) this.props.history.push("/dashboard");
    }
  }

  validateEmail = (email) => {
    return (
      email.length <= 50 &&
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  };

  validateMobile = (mobile) => {
    return mobile.length < 16 || mobile.length == 0;
  };

  validateName = (name) => {
    return name.trim().length >= 1;
  };

  switchSignupVerify = () => {
    this.setState({
      isSignup: !this.state.isSignup,
    });
  };

  handleVerification = (token) => {
    this.setState({ token: token, captchaVerified: true }, () => {
      console.log("Captcha token set successfully ", token);
    });
  };

  signupPressed = () => {
    let name = this.state.name.trim();
    let email = this.state.email.trim();
    let mobile = this.state.mobile.trim();
    let ccod = this.state.ccod;
    let passwrd = this.state.password.trim();
    let cnfPass = this.state.cnfPassword.trim();
    let token = this.state.token;

    if (name.length == 0 || !this.validateEmail(email)) return;
    let dataObj = {
      name,
      email,
      passwrd,
      cnfPass,
      token,
    };
    if (this.validateMobile(mobile)) {
      dataObj.mobile = mobile.trim();
      dataObj.ccod = ccod;
    }
    this.setState({
      isLoading: true,
    });
    Axios.post(ROUTES.VERIFY_E_M, dataObj)
      .then((res) => {
        console.log(res);
        this.setState(
          {
            aid: res.data.data.data,
            isLoading: false,
            captchaVerified: res.data.data.isCaptchaSuccess,
          },
          () => {
            console.log("Successfully set state");
          }
        );
        if (this.state.captchaVerified) {
          this.switchSignupVerify();
        } else {
          window.alert(
            "Captcha Verification not successful, Please Try Again."
          );
          return;
        }
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        this.setState({
          signupFailed: true,
        });
        if (ecode == 207000) {
          //invalid_email
          this.setState(
            {
              incorrectEmail: true,
              incorrectNumber: false,
              incorrectPassword: false,
              usedEmail: false,
              usedNumber: false,
              isLoading: false,
              captchaVerified: false,
            },
            () => {
              console.log("captcha Verified", this.state.captchaVerified);
            }
          );
        } else if (ecode == 207575) {
          //invalid_parameter (both email + phone_number)
          this.setState(
            {
              incorrectEmail: false,
              incorrectNumber: false,
              incorrectPassword: false,
              usedEmail: true,
              usedNumber: true,
              isLoading: false,
              captchaVerified: false,
            },
            () => {}
          );
        } else if (ecode == 206000) {
          //invalid_phone_number
          this.setState(
            {
              incorrectEmail: false,
              incorrectNumber: true,
              incorrectPassword: false,
              usedEmail: false,
              usedNumber: false,
              isLoading: false,
              captchaVerified: false,
            },
            () => {}
          );
        } else if (ecode == 207500 || ecode == 315) {
          //email_already_exists
          this.setState(
            {
              incorrectEmail: false,
              incorrectNumber: false,
              incorrectPassword: false,
              usedEmail: true,
              usedNumber: false,
              isLoading: false,
              captchaVerified: false,
            },
            () => {}
          );
        } else if (ecode == 207550) {
          //mobile_no_already_exist
          this.setState(
            {
              incorrectEmail: false,
              incorrectNumber: false,
              incorrectPassword: false,
              usedEmail: false,
              usedNumber: true,
              isLoading: false,
              captchaVerified: false,
            },
            () => {}
          );
        } else if (
          err.response.data.message ||
          err.response.data.data?.message
        ) {
          this.setState({
            customError:
              err.response.data.message || err.response.data.data?.message,
            isLoading: false,
          });
        }
      });
  };

  formValidation = () => {
    return (
      this.validateEmail(this.state.email) &&
      (this.validateMobile(this.state.mobile) ||
        this.state.mobile.length == 0) &&
      this.validateName(this.state.name) &&
      this.validatePassword() &&
      this.validateCnfPassword() &&
      this.state.captchaVerified
    );
  };

  nameChange = (e) => {
    let name = e.target.value;
    if (name.length > 50) return;
    this.setState({
      signupFailed: false,
      name,
    });
  };

  emailChange = (e) => {
    let email = e.target.value;
    if (email.length > 50) return;
    this.setState({
      signupFailed: false,
      incorrectEmail: false,
      usedEmail: false,
      email,
    });
  };

  ccodChange = (e) => {
    let ccod = e.target.value;
    this.setState({
      signupFailed: false,
      incorrectNumber: false,
      usedEmail: false,
      ccod,
    });
  };

  mobileChange = (e) => {
    let mobile = e.target.value;
    if (mobile.length > 15) return;
    let regExp = new RegExp("^[0-9]*$");
    if (mobile != "" && !regExp.test(mobile)) {
      return;
    }
    this.setState({
      signupFailed: false,
      incorrectNumber: false,
      usedNumber: false,
      mobile,
    });
  };

  loginWithFacebook = () => {
    this.setState({
      isLoading: true,
    });
    Axios.post(ROUTES.OAUTH_LOGIN + "?auth_method=facebook").then((res) => {
      let url = res.data.data.url;
      window.location.href = url;
      this.setState({
        isLoading: false,
      });
      //console.log(url);
    });
  };

  loginWithGoogle = () => {
    this.setState({
      isLoading: true,
    });
    Axios.post(ROUTES.OAUTH_LOGIN + "?auth_method=google").then((res) => {
      let url = res.data.data.url;
      window.location.href = url;
      this.setState({
        isLoading: false,
      });
      //console.log(url);
    });
  };

  //Password Helpers
  togglePass = () => {
    this.setState({
      showPass: !this.state.showPass,
    });
  };

  toggleCnf = () => {
    this.setState({
      showCnf: !this.state.showCnf,
    });
  };

  validatePasswordLength = (passwrd) => {
    const re = /[^-\s]{8,}/;
    return re.test(passwrd);
  };

  validatePasswordNum = (passwrd) => {
    const re = /[0-9]/;
    return re.test(passwrd);
  };

  validatePasswordSymbol = (passwrd) => {
    const re = /[#?!@$%^&*-]/;
    return re.test(passwrd);
  };

  validatePassword = () => {
    return (
      this.validatePasswordLength(this.state.password) &&
      this.validatePasswordNum(this.state.password) &&
      this.validatePasswordSymbol(this.state.password)
    );
  };

  validateCnfPassword = () => {
    return this.state.password == this.state.cnfPassword;
  };

  changePass = (e) => {
    let password = e.target.value;
    if (password.length > 50) return;
    this.setState({
      password,
      incorrectPassword: false,
    });
  };

  changeCnf = (e) => {
    let cnfPassword = e.target.value;
    if (cnfPassword.length > 50) return;
    this.setState({
      cnfPassword,
    });
    if (this.state.incorrectCnf && cnfPassword == this.state.password) {
      this.setState({
        incorrectCnf: false,
      });
    }
  };

  cnfFocusOut = () => {
    this.setState({
      incorrectCnf: !this.validateCnfPassword(),
    });
  };

  getStrength = () => {
    return (
      ((this.validatePasswordLength(this.state.password) +
        this.validatePasswordNum(this.state.password) +
        this.validatePasswordSymbol(this.state.password)) *
        5) /
      3
    );
  };

  getStrengthText = () => {
    return [
      "Password Strength",
      "Weak",
      "Weak",
      "Moderate",
      "Strong",
      "Strong",
    ][Math.floor(this.getStrength())];
  };

  getStrengthStyle = () => {
    return ["none", "weak", "weak", "moderate", "strong", "strong"][
      Math.floor(this.getStrength())
    ];
  };

  render() {
    return this.state.isLoading ? (
      <div className="loader loader-center">
        <div className="loader-msg-container">
          <Loader brand={this.state.brand} />
        </div>
      </div>
    ) : (
      <div
        className="auth-main"
        style={
          this.state.brand
            ? {
                background: `url(${this.state.brand.bgimg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }
            : {}
        }
      >
        <div className="auth-block" style={{ overflowX: "hidden" }}>
          <img
            className="auth-logo"
            width={200}
            height={100}
            alt="logo"
            src={
              this.state.brand
                ? this.state.brand.pic
                : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
            }
          ></img>
          {this.state.isSignup ? (
            <div>
              <div className="auth-h">Sign Up</div>
              <div className="auth-txt">Enter your credentials</div>
              <div className="auth-name-mob-container">
                <div className="auth-box auth-name-box">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="auth-ttl">Enter Name</div>
                  </div>
                  <div className="auth-container">
                    <Input
                      className="auth-inp"
                      name="name"
                      onChange={this.nameChange}
                      value={this.state.name}
                    ></Input>
                    <div
                      className={`line ${
                        this.validateName(this.state.name) ? "lgreen" : "lred"
                      }`}
                    ></div>
                  </div>
                </div>
                <div className="auth-box auth-mobile-box">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="auth-ttl">
                      Enter Mobile Number (Optional)
                    </div>
                  </div>
                  <div className="auth-container">
                    <div style={{ display: "flex" }}>
                      <select
                        style={{ width: "30%" }}
                        className="auth-inp dropdown"
                        onChange={this.ccodChange}
                        value={this.state.ccod}
                      >
                        {CountryCodes.map((cc, i) => {
                          return (
                            <option
                              key={cc.code + String(i)}
                              disabled={cc.value == "_"}
                              value={cc.value}
                            >
                              {cc.display}
                            </option>
                          );
                        })}
                      </select>
                      <input
                        type="tel"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        name="tel"
                        style={{ width: "70%" }}
                        className="auth-inp mobile"
                        onChange={this.mobileChange}
                        value={this.state.mobile}
                      ></input>
                    </div>
                    <div
                      className={`line ${
                        this.state.mobile.length != 0 &&
                        this.validateMobile(this.state.mobile) &&
                        !this.state.incorrectNumber
                          ? "lgreen"
                          : "lred"
                      }`}
                    ></div>
                  </div>
                  {this.state.incorrectNumber && (
                    <div className="auth-error">
                      {errorMessages["incorrectNumber"]}
                    </div>
                  )}
                  {this.state.usedNumber && (
                    <div className="auth-error">
                      {errorMessages["usedNumber"]}
                    </div>
                  )}
                  {this.state.customError && (
                    <div className="auth-error">{this.state.customError}</div>
                  )}
                </div>
              </div>
              <div className="auth-box">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="auth-ttl">Enter Email ID</div>
                </div>
                <div className="auth-container">
                  <Input
                    type="email"
                    name="email"
                    className="auth-inp"
                    onChange={this.emailChange}
                    value={this.state.email}
                  ></Input>
                  <div
                    className={`line ${
                      this.validateEmail(this.state.email) &&
                      !this.state.incorrectEmail
                        ? "lgreen"
                        : "lred"
                    }`}
                  ></div>
                </div>
                {this.state.incorrectEmail && (
                  <div className="auth-error">
                    {errorMessages["incorrectEmail"]}
                  </div>
                )}
                {this.state.usedEmail && (
                  <div className="auth-error">{errorMessages["usedEmail"]}</div>
                )}
                {this.state.customError && (
                  <div className="auth-error">{this.state.customError}</div>
                )}
              </div>
              <br />
              <div className="auth-box">
                <div className="auth-ttl">Create Password</div>
                <div style={{ display: "flex" }}>
                  <div className="pass-container">
                    <input
                      type={!this.state.showPass ? "password" : "text"}
                      className="auth-inp auth-pass"
                      onChange={this.changePass}
                      value={this.state.password}
                    ></input>
                    <div
                      className={`line ${
                        !this.state.incorrectPassword && this.validatePassword()
                          ? "lgreen"
                          : "lred"
                      }`}
                    ></div>
                  </div>
                  <div className="auth-inp show-btn" onClick={this.togglePass}>
                    <img src={this.state.showPass ? noEye : eye}></img>
                    {this.state.showPass ? "Hide" : "Show"}
                  </div>
                </div>
                {this.state.incorrectPassword && (
                  <div className="auth-error">Password must be valid</div>
                )}
              </div>
              <div className="password-strength">
                <div style={{ width: "75%", display: "flex" }}>
                  <div
                    className={`strength-dash ${
                      this.getStrength() > 0 ? "weak" : "none"
                    }`}
                  ></div>
                  <div
                    className={`strength-dash ${
                      this.getStrength() > 1 ? "weak" : "none"
                    }`}
                  ></div>
                  <div
                    className={`strength-dash ${
                      this.getStrength() > 2 ? "moderate" : "none"
                    }`}
                  ></div>
                  <div
                    className={`strength-dash ${
                      this.getStrength() > 3 ? "strong" : "none"
                    }`}
                  ></div>
                  <div
                    className={`strength-dash ${
                      this.getStrength() > 4 ? "strong" : "none"
                    }`}
                  ></div>
                </div>
                <div className={`strength-text ${this.getStrengthStyle()}`}>
                  {this.getStrengthText()}
                </div>
              </div>
              <div className="check-pass">
                <img
                  src={
                    this.validatePasswordLength(this.state.password)
                      ? greenTick
                      : tick
                  }
                ></img>
                Atleast 8 characters
              </div>
              <div className="check-pass">
                <img
                  src={
                    this.validatePasswordNum(this.state.password)
                      ? greenTick
                      : tick
                  }
                ></img>
                One Number
              </div>
              <div className="check-pass">
                <img
                  src={
                    this.validatePasswordSymbol(this.state.password)
                      ? greenTick
                      : tick
                  }
                ></img>
                One Symbol
              </div>

              <div className="auth-box">
                <div className="auth-ttl">Confirm Password</div>
                <div style={{ display: "flex" }}>
                  <div className="pass-container">
                    <input
                      type={!this.state.showCnf ? "password" : "text"}
                      className="auth-inp auth-pass"
                      onChange={this.changeCnf}
                      onBlur={this.cnfFocusOut}
                      value={this.state.cnfPassword}
                    ></input>
                    <div
                      className={`line ${
                        this.validateCnfPassword() &&
                        this.validatePassword() &&
                        !this.state.incorrectCnf
                          ? "lgreen"
                          : "lred"
                      }`}
                    ></div>
                  </div>
                  <div className="auth-inp show-btn" onClick={this.toggleCnf}>
                    <img src={this.state.showCnf ? noEye : eye}></img>
                    {this.state.showCnf ? "Hide" : "Show"}
                  </div>
                </div>
                {this.state.incorrectCnf && (
                  <div className="auth-error">Password Does not match</div>
                )}
              </div>
              {this.state.captchaSiteKey && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "6%",
                  }}
                >
                  <HCaptcha
                    ref={this.captcha}
                    sitekey={this.state.captchaSiteKey}
                    type="text"
                    onVerify={this.handleVerification}
                  />
                </div>
              )}
              <div
                className={`${this.formValidation() ? "" : "disable"} ${
                  this.state.brand && this.state.brand.themeColorIndex
                    ? "auth-btn-brand"
                    : "auth-btn"
                }`}
                onClick={
                  this.formValidation()
                    ? this.signupPressed
                    : () => {
                        this.setState({
                          signupFailed: true,
                        });
                      }
                }
                style={{
                  background: `${
                    this.state.brand &&
                    this.state.brand.themeColorIndex &&
                    this.state.brand.themeColorIndex
                  }`,
                }}
              >
                Next
              </div>
              {this.state.signupFailed && (
                <div className="auth-error">
                  {errorMessages["failedSignup"]}
                </div>
              )}
              {this.state.signupFailed && this.state.customError && (
                <div className="auth-error">{this.state.customError}</div>
              )}
              <div className="OR">
                <div className="line"></div>
                OR
                <div className="line"></div>
              </div>
              <div className="oauth">
                <div className="oauth-btn" onClick={this.loginWithFacebook}>
                  <img src={facebookIcon}></img>
                  Sign up with Facebook
                </div>
                <div className="oauth-btn" onClick={this.loginWithGoogle}>
                  <img src={googleIcon}></img>
                  Sign up with Google
                </div>
              </div>

              <div className="signup-block">
                <div className="auth-h2">Log In</div>
                <div className="auth-txt">Already have an account?</div>
                <Link
                  to={
                    (this.state.brand ? `/${this.state.brand.utag}` : "") +
                    "/login"
                  }
                  className="create-ac-btn"
                >
                  Login
                </Link>
              </div>
            </div>
          ) : (
            <Verify
              email={this.state.email}
              mobile={
                this.validateMobile(this.state.mobile)
                  ? this.state.mobile
                  : null
              }
              backFunction={this.switchSignupVerify}
              isEmail={true}
              isMobile={
                this.validateMobile(this.state.mobile) &&
                this.state.mobile.length != 0
              }
              ccod={
                this.validateMobile(this.state.mobile) ? this.state.ccod : null
              }
              aid={this.state.aid}
            />
          )}
          {this.state.brand &&
            this.state.brand.poweredByEnabled == true &&
            this.state.brand.hyperlinkEnabled == true && (
              <div
                className="powered-by-container"
                style={{ position: "relative" }}
              >
                <a className="powered-by" href={"https://www.imarticus.org"}>
                  Powered By Imarticus Learning
                </a>
              </div>
            )}
          {this.state.brand &&
            this.state.brand.poweredByEnabled == true &&
            this.state.brand.hyperlinkEnabled == false && (
              <div
                className="powered-by-container"
                style={{ position: "relative" }}
              >
                <div className="powered-by" href={"https://www.imarticus.org"}>
                  Powered By Imarticus Learning
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  pendingInstallments: state.course.pendingInstallments,
});
const mapDispatchToProps = {
  dispatchIndividualBrand,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
