import React, { Fragment, useEffect, useState } from "react";
import { ROUTES } from "../../constants/routes";
import { TIER_COLOR_CODE } from "../../constants/constants";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import {
  setSelectedCourse,
  authenticateCourse,
} from "../../actions/courseAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import { showFreshDesk } from "../../actions/showFreshDesk.js";
import "./gradebook.scss";
import ReactHtmlParser from "react-html-parser";
import Loader from "../utils/Loader.js";
import SidebarContainer from "../sidebars/SidebarContainer.js";
import GradebookHeader from "../headers/GradebookHeader.jsx";
import { CONFIGS } from "../../constants/constants.js";
import TooltipItem from "../utils/GeneralToolTip.js";
import SkillTagTooltipItem from "../utils/SkillTagToolTip.js";
import { Progress, Tooltip } from "reactstrap";
import { Modal } from "reactstrap";
import { getParam } from "../../actions/courseAction";

import {
  applyCerti,
  atCrs,
  brandDataCourse,
  certificateStatus,
  gradebookData,
  tiersData,
  courseStatus,
} from "../../actions/gradebookAction.js";
import { size } from "lodash";

var monthsArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

let query = window.location.href;
query = query.split("/");
const pid = query[4];
const gid = query[5];
const cid = query[6];
const levelId = query[7] != undefined && query[7] ? query[7] : null;

// console.log({ query });
const cookies = new Cookies();

const data = [
  {
    company: "Alfreds Futterkiste",
    contact: "Maria Anders",
    country: "Germany",
  },
  {
    company: "Centro comercial Moctezuma",
    contact: "Maria Anders",
    country: "Mexico",
  },
];

const Gradebook = (props) => {
  const [brand, setBrand] = useState();
  const [grades, setGrades] = useState();
  const [totalP, setTotalP] = useState();
  const [totalPView, setTotalPView] = useState();
  const [totalWeightAssignedP, setTotalWeightAssignedP] = useState();
  const [selfPaced, setSelfPaced] = useState();
  const [assignmentData, setAssignmentData] = useState();
  const [quizData, setQuizData] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [tierRange, setTierRange] = useState();
  const [joining, setJoining] = useState();
  const [uid, setUid] = useState();
  const [courseName, setCourseName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [impartusEnabled, setImpartusEnabled] = useState();
  const [batchName, setBatchName] = useState();
  const [courseLocation, setCourseLocation] = useState();
  const [certiEnabled, setCertiEnabled] = useState();
  const [certiAutoPublish, setCertiAutoPublish] = useState();
  const [certiPublished, setCertiPublished] = useState();
  const [certiGenerated, setCertiGenerated] = useState();
  const [course, setCourse] = useState();
  const [status, setStatus] = useState();
  const [gstatus, setGstatus] = useState();
  const [crtfEligible, setCrtfEligible] = useState();
  const [certiAvailable, setCertiAvailable] = useState();
  const [certiLink, setCertiLink] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [tiersOpen, setTiersOpen] = useState({});
  const [dp, setDp] = useState();
  const [userName, setUserName] = useState();
  const [primaryEmail, setPrimaryEmail] = useState();
  const [loading, setLoading] = useState();
  const [gradebookLoaded, setGradebookLoaded] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [grdData, setGrdData] = useState({});
  const [studentGradebook, setStudentGradebook] = useState([]);
  const [collapseRow, setCollapseRow] = useState({});
  const [enableCategory, setEnableCategory] = useState(false);
  const [grdTotalPerc, setGrdTotalPerc] = useState({});
  const [grdTier, setGrdTier] = useState({});
  const [applyCert, setApplyCert] = useState({});
  const [certStatus, setCertStatus] = useState({});
  const [certiGeneratedStatus, setCertiGeneratedStatus] = useState({});
  const [crtfFileMap, setCrtfFileMap] = useState({});

  const [grdPlsStatus, setGrdPlsStatus] = useState({});
  const [elecNameMap, setElecNameMap] = useState({});
  const [showWeightage, setShowWeightage] = useState(false);
  const [showPassMarks, setShowPassMarks] = useState(false);
  const [showPlcStatus, setShowPlcStatus] = useState(false);
  const [placementEnable, setPlacementEnable] = useState(false);
  const [columnSpan, setColumnSpan] = useState(false);
  const [gradebookType, setGradebookType] = useState(1);
  const [isElec, setIsElec] = useState(false);
  const [gradebookDataLoaded, setGradebookDataLoaded] = useState(false);
  const [gradebookSkillTags, setGradebookSkillTags] = useState({});
  const [skillNameMap, setskillNameMap] = useState({});
  const [skillTagPresent, setSkillTagPresent] = useState(false);
  const [allAssessmentPublished, setAllAssessmentPublished] = useState(false);

  const [showPlcPassStatus, setShowPlcPassStatus] = useState(
    "Congratulations! You are eligible for Placement Criteria"
  );
  const [showPlcFailStatus, setShowPlcFailStatus] = useState(
    "You are not eligible for the Placement.  "
  );
  const [plcPassStatusText, setPlcPassStatusText] = useState("PASS");
  const [plcFailStatusText, setPlcFailStatusText] = useState("FAIL");
  const [showContentStatus, setShowContentStatus] = useState(false);
  const [elecLevelIndex, setElecLevelIndex] = useState();

  const [certiElectivePublishStatus, setCertiElectivePublishStatus] = useState(
    {}
  );
  const [crtfEligibleMap, setCrtfEligibleMap] = useState({});
  const [tierEnabled, setTierEnabled] = useState(false);

  let ColorMap = {
    0: "sucess",
    1: "info",
    2: "warning",
    3: "danger",
    4: "primary",
    5: "secondary",
    6: "light",
    7: "dark",
  };
  useEffect(() => {
    (async () => {
      window.FreshworksWidget?.("hide", "launcher");
      setLoading(true);
      await authenticatCourse();
      await props.getProfileInfoOnly();
      await fetchBrandDataCrs();
      setDp(props.ppic);
      setUserName(props.profileName);
      setPrimaryEmail(props.primaryEmail);
      await getGradebookData();
    })();
  }, []);
  useEffect(() => {
    (async () => {
      await getTiersData();
      await getCertificateStatus();
      await getCourseStatus();
      await getCerti();
    })();
  }, [gradebookDataLoaded]);

  const [state, setState] = useState(0);

  useEffect(() => {
    if (!placementEnable) {
      if (showContentStatus) {
        if (showPassMarks && showWeightage) {
          setColumnSpan(6);
        } else if (showPassMarks && !showWeightage) {
          setColumnSpan(4);
        } else if (!showPassMarks && showWeightage) {
          setColumnSpan(5);
        } else {
          setColumnSpan(3);
        }
      } else {
        if (showPassMarks && showWeightage) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage) {
          setColumnSpan(4);
        } else {
          setColumnSpan(2);
        }
      }
    } else {
      if (showContentStatus) {
        if (showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else if (showPassMarks && !showWeightage && !showPlcStatus) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (!showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(4);
        } else if (!showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else {
          setColumnSpan(3);
        }
      } else {
        if (showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else if (showPassMarks && !showWeightage && !showPlcStatus) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (!showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(4);
        } else if (!showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else {
          setColumnSpan(2);
        }
      }
    }
  }, [placementEnable, showWeightage, showPassMarks, showPlcStatus]);

  const handleClick = (index, data) => {
    let collapserow = collapseRow;

    if (collapserow[data]) {
      collapserow[data] = false;
      setCollapseRow({ ...collapserow });
    } else {
      collapserow[data] = true;
      setCollapseRow({ ...collapserow });
    }
  };

  const authenticatCourse = async () => {
    if (!cookies.get("isLoggedIn")) {
      return window.open(CONFIGS.WEB_DASHBOARD_BASE_URL, "_self");
    }
    atCrs({
      pid,
      gid,
      cid,
      dispatchIndividualBrand: props.dispatchIndividualBrand,
    })
      .then(function (res) {
        console.log(res);
        var expirydate = new Date();
        expirydate.setDate(expirydate.getDate() + 180);
        cookies.set("crs_tkn", res.data.data.at, {
          expires: expirydate,
          domain: CONFIGS.DOMAIN,
          path: "/",
        });

        if (res.data.data.joined) {
          setJoining(new Date(res.data.data.joined));
        }
        if (res.data.data.uid) {
          setUid(res.data.data.uid);
        }
        if (res.data.data.name) {
          setCourseName(res.data.data.name);
        }
        if (res.data.data && res.data.data.phoneno) {
          setPhoneNo(res.data.data.phoneno);
        }
        if (res.data.data.selfPaced) {
          setSelfPaced(res.data.data.selfPaced);
        }
        setImpartusEnabled(res.data.data.course.impartus_enabled);
        setShowPassMarks(res.data.data.course.show_pass_marks);
        setShowWeightage(res.data.data.course.show_weightage);
        if ("show_content_status" in res.data.data.course) {
          setShowContentStatus(res.data.data.course["show_content_status"]);
        }

        if ("elec_certi_publish_status" in res.data.data.course) {
          setCertiElectivePublishStatus(
            res.data.data.course["elec_certi_publish_status"]
          );
        }
        if ("elec_level_index" in res.data.data.course) {
          setElecLevelIndex(res.data.data.course["elec_level_index"]);
        }

        let passStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_text
          : "Congratulations! You are eligible for Placement Criteria";
        let failStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_text
          : "You are not eligible for the Placement.  ";
        let plcpassStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_status_text
          : "PASS";
        let plcfailStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_status_text
          : "FAIL";

        setPlcPassStatusText(plcpassStatus);
        setPlcFailStatusText(plcfailStatus);

        setShowPlcFailStatus(failStatus);
        setShowPlcPassStatus(passStatus);
        let grdtype = res.data.data.course.gradebook_type
          ? res.data.data.course.gradebook_type
          : 1;
        setGradebookType(grdtype);
        setPlacementEnable(res.data.data.course.plcmnt_enable);

        let showplc = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.gradebook_show_plcmnt_stts
          : false;
        setShowPlcStatus(showplc);
        setBatchName(res.data.data.course.btch_name);
        setCourseLocation(res.data.data.location);
        setTierEnabled(res.data.data.course.tierEnabled);

        let courseFound = res.data.data.course;
        if (res.data.data.location) {
          courseFound.loc_name = res.data.data.location;
        }
        if (courseFound) {
          props.setSelectedCourse(courseFound);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getGradebookData = async () => {
    gradebookData(pid, cid)
      .then(function (res) {
        console.log(res, ":response data");
        let grades = res.data.data.controlled_data || [];

        setGrades(res.data.data.controlled_data);
        setAllAssessmentPublished(res.data.data.all_assessment_publish);
        setTotalP(res.data.data.totalGrade);
        setTotalPView(res.data.data.totalGradeView);
        setTotalWeightAssignedP(res.data.data.totalWeightAssignedP);
        setElecNameMap({ ...res.data.data.grddata.elec_name_map });
        setSelfPaced(res.data.data.gradebook_auto_publish);
        setGrdData(res.data.data.grddata.contentElecMap);
        setGrdTotalPerc(res.data.data.grddata.grd_total_perc);
        let skillTagNameMap = res.data.data.grddata.skillTagNameMap || {};
        let gradebook_skill_tag =
          res.data.data.grddata.gradebook_skill_tag || {};
        setskillNameMap({ ...skillTagNameMap });
        setGradebookSkillTags({ ...gradebook_skill_tag });
        setGrdTier(res.data.data.grddata.grd_tier);
        let plc = res.data.data.grddata.plcStaus || {};
        setGrdPlsStatus(plc);
        setIsElec(res.data.data.grddata.elec_enable);
        setStudentGradebook([
          ...res.data.data.grddata.placementGradebookCategory,
        ]);
        setSkillTagPresent(res.data.data.grddata.skilltagPresent);
        setEnableCategory(res.data.data.enableCategory);
        let assignmentData = [];
        let quizData = [];
        let len = 0;
        if (res.data.data.grddata.contentElecMap) {
          len = Object.keys(res.data.data.grddata.contentElecMap).length;
        }
        let tiersOpen = {};
        for (let i = 0; i < len; i++) {
          tiersOpen[i] = false;
        }
        setTiersOpen({ ...tiersOpen });

        for (var i = 0; i < grades.length; i++) {
          if (grades[i]["type"] == 1) {
            assignmentData.push(grades[i]);
          } else if (grades[i]["type"] == 2) {
            quizData.push(grades[i]);
          }
        }
        setAssignmentData(() => {
          return [...assignmentData];
        });
        setQuizData(() => {
          return [...quizData];
        });
        setGradebookLoaded(true);
        setGradebookDataLoaded(true);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getTiersData = async () => {
    tiersData(pid, cid)
      .then(function (res) {
        console.log({ tiersRes: res });
        let tierRange = [];

        if (res.data.data.tiers.length > 0) {
          let tiers = res.data.data.tiers;
          setTiers(() => {
            return [...res.data.data.tiers];
          });
          for (var i = 0; i < tiers.length; i++) {
            tierRange.push({
              tier_start: tiers[i].tier_start,
              tier_end: tiers[i].tier_end,
              name: tiers[i].name,
              desc: tiers[i].desc,
            });
          }
          setTierRange(() => {
            return [...tierRange];
          });
        }

        if (tierRange[0].end == 0) {
          let tempTierRange = [
            {
              tier_start: 90,
              tier_end: 100,
              name: "Grade 1",
              desc: "Certificate of Excellence",
            },
            {
              tier_start: 70,
              tier_end: 90,
              name: "Grade 2",
              desc: "Certificate of Achievement",
            },
            {
              tier_start: 50,
              tier_end: 70,
              name: "Certificate of Completion",
              desc: tiers[i].desc,
            },
            {
              tier_start: 0,
              tier_end: 50,
              name: "Certificate of Participation",
              desc: tiers[i].desc,
            },
          ];
          setTierRange(() => {
            return [...tempTierRange];
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getCertificateStatus = async () => {
    certificateStatus(pid, cid)
      .then(function (res) {
        console.log(res);
        setCertiEnabled(res.data.data.certiEnabled);
        setCertiAutoPublish(res.data.data.auto_publish);
        setCertiPublished(res.data.data.published);
        setCertiGenerated(res.data.data.generated);
        setCertiGeneratedStatus(res.data.data.certgeneratedMap);
        setCourse(res.data.data.course);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getCourseStatus = async () => {
    courseStatus(pid, cid)
      .then(function (res) {
        console.log(res);
        setStatus(res.data.data);
        setGstatus(res.data.data.status);
        setCrtfEligible(res.data.data.crtf_eligible);
        setCrtfEligibleMap(res.data.data.crtf_eligibleMap); // this logic only happen when gradebooktype ==1 i.e, individual gradebook in case of lective
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getCerti = async () => {
    applyCerti(pid, cid)
      .then(function (res) {
        console.log(res, ":response data");
        // setCertiLink(res.data.data.crtf_file);
        let certfileMap = {};

        if (res.data.data) {
          let cert = res.data.data;
          Object.keys(cert).forEach((id) => {
            if (id != "FOUNDATION") {
              if (id && cert[id] && cert[id].status) {
                let obj = cert[id].data;
                certfileMap[id] = obj.crtf_file;
              }
            } else {
              if (id && cert[id] && cert[id].status) {
                let obj = cert[id].data;
                certfileMap[id] = obj.crtf_file;
              }
            }
          });
          console.log({ certfileMap });
          setCrtfFileMap({ ...certfileMap });
        }
        setLoading(false);
      })
      .catch(function (err) {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchBrandDataCrs = async () => {
    brandDataCourse(cid)
      .then((response) => {
        var brand = response.data?.data?.brand;
        // props.dispatchIndividualBrand(brand);
        if (brand) {
          setBrand(brand);
          var expirydate = new Date();
          expirydate.setDate(expirydate.getDate() + 180);
        } else {
        }
      })
      .catch((err) => {
        props.dispatchIndividualBrand();
        console.log({ err });
      });
  };

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const togglePopover2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleTiersPopover = (index) => {
    console.log("tiertoggle", index, tiersOpen);
    let tiers_open = tiersOpen;
    tiers_open[index] = !tiers_open[index];
    console.log(tiers_open);
    setTiersOpen({ ...tiers_open });
  };
  let getRedirectLink = () => {
    console.log("\n\n\n--- In get redirect to ourse link --- \n\n\n");
    console.log("course", props.selected_course);
    console.log("cid::", props.selected_course._id);
    var expiredate = new Date();
    expiredate.setDate(expiredate.getDate() + 180);
    cookies.set("cid", props.selected_course._id, {
      expires: expiredate,
      domain: CONFIGS.DOMAIN,
      path: "/",
    });
    // window.history.pushState([], "Dashboard", window.location.href);
    // let courseSelected = props.courses.find((el) => el._id == course._id);
    if (
      "level_labels" in props.selected_course &&
      props.selected_course.level_labels.length == 1
    ) {
      console.log("direct::::::::");
      let pid = cookies.get("pid");
      let gid = props.selected_course.gids
        ? props.selected_course.gids[0]
        : null;
      let cid = props.selected_course ? props.selected_course._id : null;
      console.log({ pid, gid, cid });
      if (gid && cid) {
        console.log(
          "checking for user version",
          props.profiles?.[0]?.user_version
        );
        var url = "/batch" + "/" + pid + "/" + gid + "/" + cid + "/undefined";
        // return;
      }
    } else {
      let cid = props.selected_course
        ? props.selected_course._id
        : getParam("cid");
      url = +"/view?cid=" + cid;
    }
    console.log({ url });
    return url;
  };
  return !loading ? (
    <div className="course-page gradebookPage">
      <GradebookHeader
        breadCrumbs={[
          { name: "Dashboard", link: "/" },
          { name: "Course", link: getRedirectLink() },
        ]}
        individualBrand={brand}
      />
      <SidebarContainer />
      <div className="col-xs-12 rvm-v1" id="content">
        <div
          className="info-row"
          style={{
            display:
              (assignmentData && assignmentData.length !== 0) ||
              (quizData && quizData.length !== 0)
                ? "flex"
                : "none",
            marginTop: "60px",
            paddingLeft: "35px",
          }}
        >
          <div className="profile">
            <img
              style={{ borderRadius: "4px" }}
              src={
                props.ppic && props.ppic != ""
                  ? props.ppic
                  : "https://cdn.pegasus.imarticus.org/index/user_neutral.svg"
              }
              alt="DP"
            />
            <div className="user-details">
              <h6>{props.profileName}</h6>
              {courseName && <p className="course-name">{courseName}</p>}
              {uid && <p className="uid">UID: {uid}</p>}
            </div>

            <div className="timeline">
              {joining && (
                <div className="started">
                  <img
                    src="https://cdn.pegasus.imarticus.org/gradebook/date.svg"
                    alt="Started on"
                  />
                  <div className="text">
                    <p className="head">Started on</p>
                    <p className="value">
                      {monthsArr[joining.getMonth()]} {joining.getDate()},{" "}
                      {joining.getFullYear()}
                    </p>
                  </div>
                </div>
              )}

              <div className="grades">
                <img
                  src="https://cdn.pegasus.imarticus.org/gradebook/grades.svg"
                  alt="Started on"
                />
                <div className="text" style={{ position: "relative" }}>
                  <p className="head">Total Grade</p>
                  <p className="value">
                    {assignmentData &&
                    assignmentData.length === 0 &&
                    quizData &&
                    quizData.length === 0
                      ? "To be calculated"
                      : `${totalP}%`}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {studentGradebook &&
            ((assignmentData && assignmentData.length !== 0) ||
              (quizData && quizData.length !== 0)) &&
            enableCategory &&
            studentGradebook.map((grd, index) => {
              return (
                <div key={index}>
                  {grd.length == 0 ? (
                    <div className="rvm-v1-cont-ctnr bottomRowGradebook">
                      <div
                        className="row no-margin gradeBlock"
                        style={{
                          display: "block",
                          width: "100vw",
                          paddingTop: "8vh",
                          flex: "0 1 100vw",
                          maxWidth: "100vw",
                        }}
                      >
                        <div className="col-12" style={{ padding: 0 }}>
                          <h2
                            style={{
                              color: "#055646",
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "24px",
                            }}
                          >
                            <span style={{ borderBottom: "2px solid #055646" }}>
                              {isElec &&
                                (gradebookType == 1
                                  ? elecNameMap[grd.elec_id]
                                    ? elecNameMap[grd.elec_id]
                                    : courseName
                                  : grd.elec_id
                                  ? courseName +
                                    " & " +
                                    elecNameMap[grd.elec_id]
                                  : courseName)}
                            </span>
                          </h2>

                          <span className="my-grade">
                            {!grd.elec_id && "My Grades"}
                          </span>
                        </div>
                        <hr />
                        <div style={{ textAlign: "center" }}>
                          <img
                            src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                            alt="Empty Gradebook"
                          />
                          <br />
                          <br />
                          <span>
                            There are no quizzes and assignments to show at this
                            moment.
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={`rvm-v1-cont-ctnr bottomRowGradebook`}>
                      <Modal
                        className="custom-modal-body"
                        isOpen={tiersOpen[index]}
                        centered={true}
                        style={{ size: "xl" }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "space-around",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                backgroundColor: "#cdcdcd82",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <div></div>
                              <div
                                className="text-info-tier"
                                style={{
                                  paddingTop: "20px",
                                  height: "56px",
                                  // background: "rgba(33,42,57,0.08)",
                                  color: "rgba(33,42,57,0.75) !important",
                                  fontWeight: 700,
                                  color: "black",
                                  fontSize: "18px",
                                  fontFamily: "Source Sans Pro",
                                }}
                              >
                                All Grades
                              </div>
                              <div>
                                <button
                                  className="close"
                                  style={{
                                    color: "black",
                                    marginRight: "15px",
                                  }}
                                  onClick={() => toggleTiersPopover(index)}
                                >
                                  ×
                                </button>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                width: "100%",
                              }}
                            >
                              {tierRange && tierRange.length > 0 ? (
                                <>
                                  {tierRange.map((tier, index) => {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="tier"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            border:
                                              " 1px solid rgba(33, 42, 57, 0.25)",
                                            padding: "10px",
                                            fontWeight: 800,
                                            gap: "20px",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              color:
                                                TIER_COLOR_CODE[
                                                  index > 3
                                                    ? TIER_COLOR_CODE.length -
                                                      index
                                                    : index
                                                ],
                                              textAlign: "center",
                                              fontSize: "20px",
                                              fontStyle: "normal",
                                              fontWeight: 700,
                                              lineHeight: "normal",
                                              fontFamily: "Source Sans Pro",
                                              flex: 0.2,
                                            }}
                                          >
                                            {tier.name
                                              ? tier.name
                                              : "GRADE " + "" + (index + 1)}
                                          </div>
                                          <div
                                            style={{
                                              color: "#212A39",
                                              textAlign: "center",
                                              fontSize: "14px",
                                              fontStyle: "normal",
                                              fontWeight: 400,
                                              lineHeight: "normal",
                                              fontFamily: "Source Sans Pro",
                                              flex: 0.6,
                                            }}
                                          >
                                            {tier.desc}
                                          </div>
                                          <div
                                            style={{
                                              color: "#212A39",
                                              textAlign: "center",
                                              fontSize: "14px",
                                              fontStyle: "normal",
                                              fontWeight: 400,
                                              lineHeight: "normal",
                                              fontFamily: "Source Sans Pro",
                                              flex: 0.2,
                                            }}
                                          >
                                            Marks: {tierRange[index].tier_start}
                                            % - {tierRange[index].tier_end}%
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <div className="tier">
                                    <h2 style={{ color: "#3E86A5" }}>Tier 1</h2>
                                    <p>Certificate of Excellence</p>
                                    <span className="tier1">
                                      Marks: 90% - 100%
                                    </span>
                                  </div>
                                  <div className="tier">
                                    <h2 style={{ color: "#6F87BF" }}>Tier 2</h2>
                                    <p>Certificate of Excellence</p>
                                    <span className="tier1">
                                      Marks: 70% - 90%
                                    </span>
                                  </div>
                                  <div className="tier">
                                    <h2 style={{ color: "#9D7075" }}>Tier 3</h2>
                                    <p>Certificate of Excellence</p>
                                    <span className="tier1">
                                      Marks: 50% - 70%
                                    </span>
                                  </div>
                                  <div className="tier">
                                    <h2 style={{ color: "#E27770" }}>Tier 4</h2>
                                    <p>Certificate of Excellence</p>
                                    <span className="tier1">
                                      Marks: 0% - 50%
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>

                            <div
                              style={{
                                padding: "15px",
                              }}
                            >
                              {" "}
                              <p
                                style={{
                                  marginBottom: "20px",
                                  marginLeft: "4px",
                                  marginRight: "4px",
                                  textAlign: "center",
                                  marginBottom: 0,
                                  fontFamily: "Source Sans Pro",
                                }}
                              >
                                *Note: The final grades will be calculated after
                                the final Grade score is published.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Modal>

                      <div
                        className="row no-margin gradeBlock gradeBlockPadding"
                        style={{ display: "block" }}
                      >
                        {/* <div className="col-12">
                        <span className="my-grade">My Grades</span>
                      </div> */}
                        <div className="col-12" style={{ padding: 0 }}>
                          <h2
                            style={{
                              color: "#055646",
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "24px",
                            }}
                          >
                            <span style={{ borderBottom: "2px solid #055646" }}>
                              {isElec &&
                                (gradebookType == 1
                                  ? elecNameMap[grd.elec_id]
                                    ? elecNameMap[grd.elec_id]
                                    : courseName
                                  : grd.elec_id
                                  ? courseName +
                                    " & " +
                                    elecNameMap[grd.elec_id]
                                  : courseName)}
                            </span>
                            {/* <span style={{ borderBottom: "2px solid #055646" }}>
                            {elecNameMap[grd.elec_id] &&
                              elecNameMap[grd.elec_id]}
                          </span> */}
                          </h2>

                          <span className="my-grade">
                            {!isElec && !grd.elec_id && "My Grades"}
                          </span>
                        </div>
                        <hr />

                        <div className="flexRow">
                          <table
                            className="table table-bordered gradeTooltip"
                            style={{ minWidth: "700px" }}
                          >
                            <thead className="th-bg-clr">
                              <tr>
                                <th className="t-center th-fnt">Category</th>
                                <th className="t-center th-fnt">
                                  Marks Scored
                                </th>
                                <th className="t-center th-fnt">
                                  Total Marks{" "}
                                </th>
                                {showPlcStatus && placementEnable && (
                                  <th className="t-center th-fnt">
                                    Placement Eligibility Cutoff &nbsp;
                                    <TooltipItem
                                      style={{ backgroundColor: "none" }}
                                      id={"namePopup1" + index}
                                      batch="Sum of scores of all the assessment  should be greater than or equal to the Placement cut off score to be eligible for placement."
                                    />
                                  </th>
                                )}
                                {showPassMarks && !placementEnable && (
                                  <th className="t-center th-fnt">
                                    Passing Marks
                                  </th>
                                )}
                                {showPassMarks &&
                                  placementEnable &&
                                  !showPlcStatus && (
                                    <th className="t-center th-fnt">
                                      Passing Marks
                                    </th>
                                  )}
                                {showWeightage && (
                                  <th className="t-center th-fnt">
                                    Assigned Weightage %
                                  </th>
                                )}
                                {showWeightage && (
                                  <th className="t-center th-fnt">
                                    Weighted % Scored
                                  </th>
                                )}
                                {placementEnable && showPlcStatus && (
                                  <th className="t-center th-fnt">
                                    Placement Status
                                  </th>
                                )}
                                {!placementEnable && showContentStatus && (
                                  <th className="t-center th-fnt">Status</th>
                                )}
                                {placementEnable &&
                                  showContentStatus &&
                                  !showPlcStatus && (
                                    <th className="t-center th-fnt">Status</th>
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {grd.catg &&
                                grd.catg.map((category, index) => {
                                  return (
                                    <Fragment key={`${index}${category.name}`}>
                                      {category.dids &&
                                        category.dids.length > 0 && (
                                          <>
                                            {" "}
                                            <tr
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                handleClick(
                                                  index,
                                                  `${index}${category.name}`
                                                )
                                              }
                                            >
                                              <td className="bg-white-clr tr-fnt">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  {" "}
                                                  <div>
                                                    {category.name
                                                      ? category.name
                                                      : "-"}
                                                  </div>{" "}
                                                  <div>
                                                    {" "}
                                                    <img
                                                      src={
                                                        collapseRow[
                                                          `${index}${category.name}`
                                                        ]
                                                          ? "https://webcdn.imarticus.org/Pegasus/uparrow.svg"
                                                          : "https://cdn.pegasus.imarticus.org/Gradebook/dropdown.svg"
                                                      }
                                                    ></img>
                                                  </div>
                                                </div>

                                                {/* <span>
                                                  {category.name
                                                    ? category.name
                                                    : "-"}
                                                    <img
                                                      src={
                                                        collapseRow[
                                                          `${index}${category.name}`
                                                        ]
                                                          ? "https://webcdn.imarticus.org/Pegasus/uparrow.svg"
                                                          : "https://cdn.pegasus.imarticus.org/Gradebook/dropdown.svg"
                                                      }
                                                    ></img>
                                                  </span> */}
                                              </td>

                                              <td className="bg-white-clr tr-fnt">
                                                {category.marks_scored ||
                                                category.marks_scored == 0
                                                  ? category.marks_scored
                                                  : "-"}
                                              </td>
                                              <td className="bg-white-clr tr-fnt">
                                                {category.total_marks ||
                                                category.total_marks == 0
                                                  ? category.total_marks
                                                  : "-"}
                                              </td>
                                              {showPlcStatus &&
                                                placementEnable && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.plac_cutoff ||
                                                    category.plac_cutoff == 0
                                                      ? category.plac_cutoff
                                                      : "-"}
                                                  </td>
                                                )}
                                              {showPassMarks &&
                                                !placementEnable && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.pass_marks ||
                                                    category.pass_marks == 0
                                                      ? category.pass_marks
                                                      : "-"}
                                                  </td>
                                                )}
                                              {showPassMarks &&
                                                placementEnable &&
                                                !showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    -
                                                  </td>
                                                )}
                                              {showWeightage && (
                                                <td className="bg-white-clr tr-fnt">
                                                  {category.weight ||
                                                  category.weight == 0
                                                    ? category.weight
                                                    : "-"}
                                                </td>
                                              )}
                                              {showWeightage && (
                                                <td className="bg-white-clr tr-fnt">
                                                  {category.weightedPView ||
                                                  category.weightedPView == 0
                                                    ? category.weightedPView
                                                    : "-"}
                                                </td>
                                              )}

                                              {placementEnable &&
                                                showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.status
                                                      ? category.status
                                                      : "-"}
                                                  </td>
                                                )}
                                              {!placementEnable &&
                                                showContentStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.all_cagetory_content_pass_status
                                                      ? category.all_cagetory_content_pass_status
                                                      : "-"}
                                                  </td>
                                                )}
                                              {placementEnable &&
                                                showContentStatus &&
                                                !showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.all_cagetory_content_pass_status
                                                      ? category.all_cagetory_content_pass_status
                                                      : "-"}
                                                  </td>
                                                )}
                                            </tr>
                                            {collapseRow[
                                              `${index}${category.name}`
                                            ] && category.dids ? (
                                              <>
                                                <tr>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    My Assessments
                                                  </td>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Marks Scored
                                                  </td>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Total Marks
                                                  </td>
                                                  {showPassMarks && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Pass Marks
                                                    </td>
                                                  )}
                                                  {showWeightage && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Assigned Weight%
                                                    </td>
                                                  )}
                                                  {showWeightage && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Weighted % Scored
                                                    </td>
                                                  )}

                                                  {showContentStatus && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Assessment Status
                                                    </td>
                                                  )}
                                                </tr>
                                                {category.dids.map((did) => {
                                                  return (
                                                    <tr>
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {did.name
                                                          ? did.name
                                                          : "-"}
                                                      </td>
                                                      {/* <td className="bg-blue-clr tr-fnt">
                                                  {did.marks ? did.marks:'-'}
                                                </td> */}
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {/* {did.status
                                                          ? did.status === 1
                                                            ? `${did.marks}`
                                                            : did.status === 2
                                                              ? "Under Evaluation"
                                                              : "Absent"
                                                          : did.marks !== "-"
                                                            ? `${did.marks}`
                                                            : "Absent"} */}
                                                        {did.marks ||
                                                        did.marks == 0
                                                          ? did.marks
                                                          : "-"}
                                                      </td>
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {did.total_marks ||
                                                        did.total_marks == 0
                                                          ? did.total_marks
                                                          : "-"}
                                                      </td>

                                                      {showPassMarks && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.pasmrk ||
                                                          did.pasmrk == 0
                                                            ? did.pasmrk
                                                            : "-"}
                                                        </td>
                                                      )}
                                                      {showWeightage && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.weight ||
                                                          did.weight == 0
                                                            ? did.weight
                                                            : "-"}
                                                        </td>
                                                      )}
                                                      {showWeightage && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.weight ||
                                                          did.weight == 0
                                                            ? did.weightedPView
                                                            : "-"}
                                                        </td>
                                                      )}

                                                      {showContentStatus && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.showStatus
                                                            ? did.showStatus
                                                            : "-"}
                                                        </td>
                                                      )}
                                                    </tr>
                                                  );
                                                })}
                                              </>
                                            ) : null}
                                          </>
                                        )}
                                    </Fragment>
                                  );
                                })}
                              {/* Render assignment data */}

                              <tr>
                                <th
                                  className="t-center th-fnt"
                                  colSpan={columnSpan} // ----------------------
                                  style={{ textAlign: "left" }}
                                >
                                  Total Grade (%)
                                </th>
                                <th
                                  className="t-center th-fnt total"
                                  colSpan="1"
                                  style={{ position: "relative" }}
                                >
                                  {grdTotalPerc[grd.elec_id] ||
                                  grdTotalPerc[grd.elec_id] == 0
                                    ? grdTotalPerc[grd.elec_id].toFixed(2)
                                    : grdTotalPerc["FOUNDATION"]
                                    ? grdTotalPerc["FOUNDATION"].toFixed(2)
                                    : "-"}
                                  % &nbsp;&nbsp;
                                  {totalWeightAssignedP < 100 && (
                                    <TooltipItem
                                      id="namePopupGrade2"
                                      batch={
                                        <div>
                                          <div>Total weightage = 100%</div>
                                          <div>
                                            Sum of Assigned Weightage of
                                            assessment completed = X%
                                          </div>
                                          <div>
                                            Sum of weighted score of assessment
                                            completed = Y%
                                          </div>
                                          <div>
                                            Multiplying factor = 100% / Sum of
                                            Weightage for completed assessments
                                          </div>
                                          <div>
                                            Total Grades = Sum of weighted score
                                            of assessment completed *
                                            Multiplying Factor
                                          </div>
                                        </div>
                                      }
                                    />
                                  )}
                                </th>
                              </tr>
                              {showPlcStatus &&
                                placementEnable &&
                                allAssessmentPublished &&
                                (gradebookType == 2 && !grd.elec_id
                                  ? false
                                  : true) && (
                                  <>
                                    {" "}
                                    <tr style={{ backgroundColor: "white" }}>
                                      <th
                                        className="t-center th-fnt"
                                        colSpan={
                                          // showWeightage &&
                                          // showPassMarks &&
                                          // placementEnable
                                          //   ? 5
                                          //   : (!showWeightage && showPassMarks) ||
                                          //     (showWeightage && !showPassMarks)
                                          //   ? 4
                                          //   : placementEnable
                                          //   ? 3
                                          //   : 2
                                          columnSpan
                                        } // ----------------------
                                        style={{
                                          textAlign: "left",
                                          weight: 60,
                                          size: "20px",
                                        }}
                                      >
                                        Placement Status
                                      </th>
                                      <th
                                        className="t-center th-fnt total"
                                        colSpan="1"
                                        style={{ position: "relative" }}
                                      >
                                        {/* {grdPlsStatus[grd.elec_id]
                                    ? (grdPlsStatus[grd.elec_id] ? 'PASS' :'FAIL')
                                    : grdPlsStatus["FOUNDATION"]
                                    ? (grdPlsStatus["FOUNDATION"]?'PASS' :'FAIL')
                                    : "-"} */}
                                        {grd.elec_id &&
                                          (grdPlsStatus[grd.elec_id]
                                            ? plcPassStatusText
                                            : plcFailStatusText)}
                                        {!grd.elec_id &&
                                          (grdPlsStatus["FOUNDATION"]
                                            ? plcPassStatusText
                                            : plcFailStatusText)}{" "}
                                      </th>
                                    </tr>
                                    <tr style={{ backgroundColor: "white" }}>
                                      <th
                                        className="t-center th-fnt"
                                        colSpan={
                                          // showWeightage && showPassMarks
                                          //   ? 6
                                          //   : (!showWeightage && showPassMarks) ||
                                          //     (showWeightage && !showPassMarks)
                                          //   ? 5
                                          //   : 4
                                          columnSpan + 1
                                        } // ----------------------
                                        style={{ textAlign: "left" }}
                                      >
                                        {" "}
                                        {grd.elec_id &&
                                          (grdPlsStatus[grd.elec_id]
                                            ? `${showPlcPassStatus}`
                                            : `${showPlcFailStatus}`)}
                                        {!grd.elec_id &&
                                          (grdPlsStatus["FOUNDATION"]
                                            ? `${showPlcPassStatus}`
                                            : `${showPlcFailStatus}`)}
                                      </th>
                                    </tr>
                                  </>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div>
                        <div className="skill-cert-tier">
                          {skillTagPresent && (
                            <div
                              className="skilltagbody"
                              style={{ display: "block", minWidth: "300px" }}
                            >
                              <div
                                className="tier-header gradeTooltip"
                                style={{ padding: "10px", minHeight: "50px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div></div>
                                  <div
                                    style={{
                                      color: "black",
                                      fontSize: "20px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {" "}
                                    My Skills
                                  </div>

                                  <div>
                                    {" "}
                                    <TooltipItem
                                      id={"myskill1" + index}
                                      batch=" The proportion of skill assessment is influenced by the scores obtained in quizzes and assignments that pertain to the skill."
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="my-skill"
                                // style={{ height: "130px", overflowY: "auto" }}
                              >
                                {grd.elec_id &&
                                  gradebookSkillTags[grd.elec_id] &&
                                  Object.keys(
                                    gradebookSkillTags[grd.elec_id]
                                  ).map((skilltag_id, ind) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-around",
                                          margin: "10px",
                                          gap: "20px",
                                        }}
                                      >
                                        <div
                                          className="skill-tag"
                                          style={{
                                            flex: 3,
                                            maxWidth: "50px",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {skillNameMap[skilltag_id] ? (
                                            skillNameMap[skilltag_id].length >
                                            5 ? (
                                              <>
                                                <SkillTagTooltipItem
                                                  id={"skillnamr" + index + ind}
                                                  text={skillNameMap[
                                                    skilltag_id
                                                  ].substring(0, 5)}
                                                  batch={
                                                    skillNameMap[skilltag_id]
                                                  }
                                                />
                                              </>
                                            ) : (
                                              skillNameMap[skilltag_id]
                                            )
                                          ) : (
                                            "-"
                                          )}
                                        </div>
                                        <Progress
                                          style={{ flex: 8 }}
                                          className="my-3"
                                          color={ColorMap[ind % 8]}
                                          value={
                                            gradebookSkillTags[grd.elec_id][
                                              skilltag_id
                                            ]
                                          }
                                        />
                                        <div
                                          className="skill-tag"
                                          style={{ flex: 1 }}
                                        >
                                          {
                                            gradebookSkillTags[grd.elec_id][
                                              skilltag_id
                                            ]
                                          }
                                          %
                                        </div>
                                      </div>
                                    );
                                  })}
                                {!grd.elec_id &&
                                  gradebookSkillTags["FOUNDATION"] &&
                                  Object.keys(
                                    gradebookSkillTags["FOUNDATION"]
                                  ).map((skilltag_id, ind) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-around",
                                          margin: "10px",
                                          gap: "20px",
                                        }}
                                      >
                                        {" "}
                                        <div
                                          className="skill-tag"
                                          style={{
                                            flex: 3,
                                            maxWidth: "50px",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {skillNameMap[skilltag_id] ? (
                                            skillNameMap[skilltag_id].length >
                                            5 ? (
                                              <>
                                                <SkillTagTooltipItem
                                                  id={
                                                    "skillnamr4" + index + ind
                                                  }
                                                  text={skillNameMap[
                                                    skilltag_id
                                                  ].substring(0, 5)}
                                                  batch={
                                                    skillNameMap[skilltag_id]
                                                  }
                                                />
                                              </>
                                            ) : (
                                              skillNameMap[skilltag_id]
                                            )
                                          ) : (
                                            "-"
                                          )}
                                        </div>{" "}
                                        <Progress
                                          style={{ flex: 6 }}
                                          className="my-3"
                                          color={ColorMap[ind % 8]}
                                          value={
                                            gradebookSkillTags["FOUNDATION"][
                                              skilltag_id
                                            ]
                                          }
                                        />
                                        <div
                                          className="skill-tag"
                                          style={{ flex: 1 }}
                                        >
                                          {
                                            gradebookSkillTags["FOUNDATION"][
                                              skilltag_id
                                            ]
                                          }
                                          %
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                          {(gradebookType == 1 || grd.elec_id) && (
                            <div className="tier-cert">
                              {tierEnabled && (
                                <div
                                  className="tiers"
                                  style={{
                                    background:
                                      "radial-gradient(184.43% 76.76% at 53.59% 57.29%, rgba(0, 191, 178, 0.15) 0%, rgba(2, 128, 144, 0.15) 100%), #FFF",
                                    borderRadius: 4,
                                    border: "none",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <div
                                    className="tier-header gradeTooltip"
                                    style={{
                                      padding: "10px",
                                      minHeight: "50px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div></div>
                                      <div
                                        style={{
                                          color: "black",
                                          fontSize: "20px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {" "}
                                        My Grades
                                      </div>

                                      <div>
                                        {" "}
                                        <img
                                          style={{ cursor: "pointer" }}
                                          className="tierInfo"
                                          src="https://cdn.pegasus.imarticus.org/gradebook/Info.svg"
                                          alt="info"
                                          onClick={() =>
                                            toggleTiersPopover(index)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flexcontainer">
                                    <div className="centered-div">
                                      {" "}
                                      <img src="https://cdn.pegasus.imarticus.org/Gradebook/tierLabel.svg"></img>
                                    </div>
                                    <div
                                      className="tierLabel centered-div"
                                      style={{
                                        background:
                                          "radial-gradient(184.43% 76.76% at 53.59% 57.29%, rgba(0, 191, 178, 0.25) 0%, rgba(2, 128, 144, 0.25) 100%), #FFF",
                                        height: "50px",
                                        width: "190px",
                                        borderRadius: "40px",
                                      }}
                                    >
                                      <span className="tierGrade">
                                        <img src="https://cdn.pegasus.imarticus.org/Gradebook/Group.svg"></img>
                                        {grdTier[grd.elec_id]
                                          ? grdTier[grd.elec_id]
                                          : grdTier["FOUNDATION"]
                                          ? grdTier["FOUNDATION"]
                                          : "-"}
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div
                                className="row no-margin certiBlock"
                                style={{ display: "block" }}
                              >
                                <div className="certiContainer">
                                  <div className="emptyCerti">
                                    {!certiEnabled && (
                                      <p>
                                        This course does not have a certificate
                                      </p>
                                    )}
                                    {certiEnabled && (
                                      <div>
                                        {certiAutoPublish ||
                                        (isElec && elecLevelIndex
                                          ? certiElectivePublishStatus[
                                              grd.elec_id
                                                ? grd.elec_id
                                                : "CORE_TRACK"
                                            ]
                                          : certiPublished) ? (
                                          <>
                                            {grd.elec_id && (
                                              <div>
                                                {(certiAutoPublish ||
                                                  certiElectivePublishStatus[
                                                    grd.elec_id
                                                  ]) &&
                                                  !certiGeneratedStatus[
                                                    grd.elec_id
                                                  ] &&
                                                  !(gradebookType == 1
                                                    ? crtfEligibleMap[
                                                        grd.elec_id
                                                      ]
                                                    : crtfEligible) && (
                                                    <button
                                                      style={{
                                                        cursor: "not-allowed",
                                                      }}
                                                      className="btn btn-primary rvm-v1-part-2-info-btn center-all disabled"
                                                    >
                                                      Get Certificate
                                                    </button>
                                                  )}
                                                {(certiAutoPublish ||
                                                  certiElectivePublishStatus[
                                                    grd.elec_id
                                                  ]) &&
                                                  certiGeneratedStatus[
                                                    grd.elec_id
                                                  ] &&
                                                  crtfFileMap[grd.elec_id] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificateView/${pid}/${cid}/${grd.elec_id}`}
                                                    >
                                                      <img
                                                        className="view-cert"
                                                        src="https://cdn.pegasus.imarticus.org/gradebook/certi-sample.svg"
                                                        alt="Sample certificate"
                                                      />
                                                    </a>
                                                  )}
                                                {(certiAutoPublish ||
                                                  certiElectivePublishStatus[
                                                    grd.elec_id
                                                  ]) &&
                                                  (gradebookType == 1
                                                    ? crtfEligibleMap[
                                                        grd.elec_id
                                                      ]
                                                    : crtfEligible) &&
                                                  !certiGeneratedStatus[
                                                    grd.elec_id
                                                  ] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${grd.elec_id}`}
                                                    >
                                                      <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                        Generate Certificate
                                                      </button>
                                                    </a>
                                                  )}
                                                {(certiAutoPublish ||
                                                  certiElectivePublishStatus[
                                                    grd.elec_id
                                                  ]) &&
                                                  certiGeneratedStatus[
                                                    grd.elec_id
                                                  ] &&
                                                  !crtfFileMap[grd.elec_id] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${grd.elec_id}`}
                                                    >
                                                      <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                        Get Certificate
                                                      </button>
                                                    </a>
                                                  )}
                                              </div>
                                            )}

                                            {!grd.elec_id && (
                                              <div>
                                                {(certiAutoPublish ||
                                                  (isElec && elecLevelIndex
                                                    ? certiElectivePublishStatus[
                                                        "CORE_TRACK"
                                                      ]
                                                    : certiPublished)) &&
                                                  !certiGeneratedStatus[
                                                    "FOUNDATION"
                                                  ] &&
                                                  !(gradebookType == 1 && isElec
                                                    ? crtfEligibleMap[
                                                        "FOUNDATION"
                                                      ]
                                                    : crtfEligible) && (
                                                    <button
                                                      style={{
                                                        cursor: "not-allowed",
                                                      }}
                                                      className="btn btn-primary rvm-v1-part-2-info-btn center-all disabled"
                                                    >
                                                      Get Certificate
                                                    </button>
                                                  )}
                                                {(certiAutoPublish ||
                                                  (isElec && elecLevelIndex
                                                    ? certiElectivePublishStatus[
                                                        "CORE_TRACK"
                                                      ]
                                                    : certiPublished)) &&
                                                  (gradebookType == 1 && isElec
                                                    ? crtfEligibleMap[
                                                        "FOUNDATION"
                                                      ]
                                                    : crtfEligible) &&
                                                  !certiGeneratedStatus[
                                                    "FOUNDATION"
                                                  ] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${grd.elec_id}`}
                                                    >
                                                      <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                        Generate Certificate
                                                      </button>
                                                    </a>
                                                  )}
                                                {(certiAutoPublish ||
                                                  (isElec && elecLevelIndex
                                                    ? certiElectivePublishStatus[
                                                        "CORE_TRACK"
                                                      ]
                                                    : certiPublished)) &&
                                                  certiGeneratedStatus[
                                                    "FOUNDATION"
                                                  ] &&
                                                  !crtfFileMap[
                                                    "FOUNDATION"
                                                  ] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${grd.elec_id}`}
                                                    >
                                                      <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                        Get Certificate
                                                      </button>
                                                    </a>
                                                  )}
                                                {(certiAutoPublish ||
                                                  (isElec && elecLevelIndex
                                                    ? certiElectivePublishStatus[
                                                        "CORE_TRACK"
                                                      ]
                                                    : certiPublished)) &&
                                                  certiGeneratedStatus[
                                                    "FOUNDATION"
                                                  ] &&
                                                  crtfFileMap["FOUNDATION"] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificateView/${pid}/${cid}/${grd.elec_id}`}
                                                    >
                                                      <img
                                                        className="view-cert"
                                                        src="https://cdn.pegasus.imarticus.org/gradebook/certi-sample.svg"
                                                        alt="Sample certificate"
                                                      />
                                                    </a>
                                                  )}
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <p>
                                            The Certificate for this course is
                                            not yet available
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

          {grdData &&
            !enableCategory &&
            Object.keys(grdData).map((grd, index) => {
              return (
                <div key={index}>
                  {grdData[grd] && grdData[grd].length == 0 ? (
                    <div className="rvm-v1-cont-ctnr bottomRowGradebook">
                      <div
                        className="row no-margin gradeBlock"
                        style={{
                          display: "block",
                          width: "100vw",
                          paddingTop: "8vh",
                          flex: "0 1 100vw",
                          maxWidth: "100vw",
                        }}
                      >
                        <div className="col-12" style={{ padding: 0 }}>
                          <h2
                            style={{
                              color: "#055646",
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "24px",
                            }}
                          >
                            {/* {elecNameMap[grd] &&  <> <span style={{ borderBottom: "2px solid #055646" }}>
                           
                           </span>
                           </>} */}
                            <span className="my-grade">{"My Grades"}</span>
                          </h2>
                        </div>
                        <hr />
                        <div style={{ textAlign: "center" }}>
                          <img
                            src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                            alt="Empty Gradebook"
                          />
                          <br />
                          <br />
                          <span>
                            There are no quizzes and assignments to show at this
                            moment.
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={`rvm-v1-cont-ctnr bottomRowGradebook`}>
                        <Modal
                          isOpen={tiersOpen[index]}
                          className="custom-modal-body"
                          centered={true}
                        >
                          {tiersOpen[index] && (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  // justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    backgroundColor: "#cdcdcd82",
                                    width: "100%",
                                    alignItems: "center",
                                  }}
                                >
                                  <div></div>
                                  <div
                                    style={{
                                      paddingTop: "20px",
                                      height: "56px",
                                      // background: "rgba(33,42,57,0.08)",
                                      color: "rgba(33,42,57,0.75) !important",
                                      fontWeight: 700,
                                      color: "black",
                                      fontSize: "18px",
                                      fontFamily: "Source Sans Pro",
                                    }}
                                  >
                                    All Grades
                                  </div>
                                  <div>
                                    <button
                                      className="close"
                                      style={{
                                        color: "black",
                                        marginRight: "15px",
                                      }}
                                      onClick={() => toggleTiersPopover(index)}
                                    >
                                      ×
                                    </button>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    width: "100%",
                                  }}
                                >
                                  {tierRange && tierRange.length > 0 ? (
                                    <>
                                      {tierRange.map((tier, index) => {
                                        return (
                                          <>
                                            <div
                                              key={index}
                                              className="tier"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                border:
                                                  " 1px solid rgba(33, 42, 57, 0.25)",
                                                padding: "10px",
                                                fontWeight: 800,
                                                gap: "20px",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  color:
                                                    TIER_COLOR_CODE[
                                                      index > 3
                                                        ? TIER_COLOR_CODE.length -
                                                          index
                                                        : index
                                                    ],
                                                  textAlign: "center",
                                                  fontSize: "20px",
                                                  fontStyle: "normal",
                                                  fontWeight: 700,
                                                  lineHeight: "normal",
                                                  fontFamily: "Source Sans Pro",
                                                  flex: 0.2,
                                                }}
                                              >
                                                {tier.name
                                                  ? tier.name
                                                  : "GRADE " + "" + (index + 1)}
                                              </div>
                                              <div
                                                style={{
                                                  color: "#212A39",
                                                  textAlign: "center",
                                                  fontSize: "14px",
                                                  fontStyle: "normal",
                                                  fontWeight: 400,
                                                  lineHeight: "normal",
                                                  fontFamily: "Source Sans Pro",
                                                  flex: 0.6,
                                                }}
                                              >
                                                {tier.desc}
                                              </div>
                                              <div
                                                style={{
                                                  color: "#212A39",
                                                  textAlign: "center",
                                                  fontSize: "14px",
                                                  fontStyle: "normal",
                                                  fontWeight: 400,
                                                  lineHeight: "normal",
                                                  fontFamily: "Source Sans Pro",
                                                  flex: 0.2,
                                                }}
                                              >
                                                Marks:{" "}
                                                {tierRange[index].tier_start}% -{" "}
                                                {tierRange[index].tier_end}%
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <>
                                      <div className="tier">
                                        <h2 style={{ color: "#3E86A5" }}>
                                          Tier 1
                                        </h2>
                                        <p>Certificate of Excellence</p>
                                        <span className="tier1">
                                          Marks: 90% - 100%
                                        </span>
                                      </div>
                                      <div className="tier">
                                        <h2 style={{ color: "#6F87BF" }}>
                                          Tier 2
                                        </h2>
                                        <p>Certificate of Excellence</p>
                                        <span className="tier1">
                                          Marks: 70% - 90%
                                        </span>
                                      </div>
                                      <div className="tier">
                                        <h2 style={{ color: "#9D7075" }}>
                                          Tier 3
                                        </h2>
                                        <p>Certificate of Excellence</p>
                                        <span className="tier1">
                                          Marks: 50% - 70%
                                        </span>
                                      </div>
                                      <div className="tier">
                                        <h2 style={{ color: "#E27770" }}>
                                          Tier 4
                                        </h2>
                                        <p>Certificate of Excellence</p>
                                        <span className="tier1">
                                          Marks: 0% - 50%
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </div>

                                <div
                                  style={{
                                    padding: "15px",
                                  }}
                                >
                                  {" "}
                                  <p
                                    style={{
                                      marginBottom: "20px",
                                      marginLeft: "4px",
                                      marginRight: "4px",
                                      textAlign: "center",
                                      marginBottom: 0,
                                      fontFamily: "Source Sans Pro",
                                    }}
                                  >
                                    *Note: The final grades will be calculated
                                    after the final Grade score is published.
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </Modal>
                        <div
                          className="row no-margin gradeBlock gradeBlockPadding"
                          style={{ display: "block" }}
                        >
                          <div className="col-12" style={{ padding: 0 }}>
                            <h2
                              style={{
                                color: "#055646",
                                fontWeight: 600,
                                fontSize: "20px",
                                lineHeight: "24px",
                              }}
                            >
                              <span
                                style={{ borderBottom: "2px solid #055646" }}
                              >
                                {isElec &&
                                  (gradebookType == 1
                                    ? elecNameMap[grd]
                                      ? elecNameMap[grd]
                                      : courseName
                                    : grd != "FOUNDATION"
                                    ? courseName + " & " + elecNameMap[grd]
                                    : courseName)}
                              </span>
                            </h2>

                            {!isElec && (
                              <span className="my-grade">{"My Grades"}</span>
                            )}
                          </div>
                          <hr />

                          <div className="flexRow">
                            <table
                              className="table table-bordered gradeTooltip"
                              style={{ minWidth: "700px" }}
                            >
                              <thead className="th-bg-clr">
                                <tr>
                                  <th className="t-center th-fnt">
                                    Assessment/Quiz
                                  </th>
                                  <th className="t-center th-fnt">
                                    Actual Marks
                                  </th>
                                  <th className="t-center th-fnt">
                                    Total Marks
                                  </th>

                                  {showWeightage && (
                                    <th className="t-center th-fnt">
                                      Assigned Weightage%
                                    </th>
                                  )}
                                  {showPassMarks && (
                                    <th className="t-center th-fnt">
                                      Passing Marks
                                    </th>
                                  )}
                                  {showWeightage && (
                                    <th className="t-center th-fnt">
                                      Weighted % Scored
                                    </th>
                                  )}
                                  {showContentStatus && (
                                    <th className="t-center th-fnt">
                                      {" "}
                                      Assessment Status
                                    </th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {/* Render assignment data */}
                                {grdData[grd].map((ag) => (
                                  <tr key={ag.name}>
                                    <td className="bg-white-clr tr-fnt">
                                      {ag.name ? ag.name : "-"}
                                    </td>
                                    <td className="bg-white-clr tr-fnt">
                                      {/* {ag.status
                                        ? ag.status === 1
                                          ? `${ag.marks}`
                                          : ag.status === 2
                                          ? "Under Evaluation"
                                          : "Absent"
                                        : !ag.status && ag.marks !== "-"
                                        ? `${ag.marks}`
                                        : "Absent"} */}
                                      {ag.marks || ag.marks == 0
                                        ? ag.marks
                                        : "-"}
                                    </td>
                                    <td className="bg-white-clr tr-fnt">
                                      {ag.total_marks || ag.total_marks == 0
                                        ? ag.total_marks
                                        : "-"}
                                    </td>

                                    {showWeightage && (
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.weight || ag.weight == 0
                                          ? ag.weight
                                          : "-"}
                                        %
                                      </td>
                                    )}
                                    {showPassMarks && (
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.pasmrk || ag.pasmrk == 0
                                          ? ag.pasmrk
                                          : "-"}
                                      </td>
                                    )}
                                    {showWeightage && (
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.weightedPView ||
                                        ag.weightedPView == 0
                                          ? ag.weightedPView
                                          : "-"}
                                        %
                                      </td>
                                    )}
                                    {showContentStatus && (
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.showStatus ? ag.showStatus : "-"}
                                      </td>
                                    )}
                                  </tr>
                                ))}

                                <tr>
                                  <th
                                    className="t-center th-fnt"
                                    // colSpan={showWeightage ? "3" : "2"}
                                    colSpan={
                                      showContentStatus
                                        ? showWeightage && showPassMarks
                                          ? 6
                                          : showWeightage && !showPassMarks
                                          ? 5
                                          : !showWeightage && showPassMarks
                                          ? 4
                                          : 3
                                        : showWeightage && showPassMarks
                                        ? 5
                                        : showWeightage && !showPassMarks
                                        ? 4
                                        : !showWeightage && showPassMarks
                                        ? 3
                                        : 2
                                    } // ----------------------
                                    style={{ textAlign: "left" }}
                                  >
                                    Total Grade (%)
                                  </th>
                                  <th
                                    className="t-center th-fnt total"
                                    colSpan="1"
                                    style={{ position: "relative" }}
                                  >
                                    {grdTotalPerc[grd] || grdTotalPerc[grd] == 0
                                      ? grdTotalPerc[grd].toFixed(2)
                                      : "-"}
                                    %&nbsp;&nbsp;
                                    {totalWeightAssignedP < 100 && (
                                      <TooltipItem
                                        id="namePopupGrade25"
                                        batch={
                                          <div>
                                            <div>Total weightage = 100%</div>
                                            <div>
                                              Sum of Assigned Weightage of
                                              assessment completed = X%
                                            </div>
                                            <div>
                                              Sum of weighted score of
                                              assessment completed = Y%
                                            </div>
                                            <div>
                                              Multiplying factor = 100% / Sum of
                                              Weightage for completed
                                              assessments
                                            </div>
                                            <div>
                                              Total Grades = Sum of weighted
                                              score of assessment completed *
                                              Multiplying Factor
                                            </div>
                                          </div>
                                        }
                                      />
                                    )}
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="skill-cert-tier">
                          {skillTagPresent && (
                            <div
                              className="skilltagbody"
                              style={{ display: "block", minWidth: "300px" }}
                            >
                              <div
                                className="tier-header gradeTooltip"
                                style={{ padding: "10px", minHeight: "50px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div></div>
                                  <div
                                    style={{
                                      color: "black",
                                      fontSize: "20px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {" "}
                                    My Skills
                                  </div>
                                  <div>
                                    {" "}
                                    <TooltipItem
                                      id={"myskill2" + index}
                                      batch=" The proportion of skill assessment is influenced by the scores obtained in quizzes and assignments that pertain to the skill."
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="my-skill">
                                {gradebookSkillTags[grd] &&
                                  Object.keys(gradebookSkillTags[grd]).map(
                                    (skilltag_id, ind) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            margin: "10px",
                                            gap: "20px",
                                          }}
                                        >
                                          <div
                                            className="skill-tag"
                                            style={{
                                              flex: 3,
                                              maxWidth: "50px",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {skillNameMap[skilltag_id] ? (
                                              skillNameMap[skilltag_id].length >
                                              5 ? (
                                                <>
                                                  <SkillTagTooltipItem
                                                    id={
                                                      "skillnamr1" + index + ind
                                                    }
                                                    text={skillNameMap[
                                                      skilltag_id
                                                    ].substring(0, 5)}
                                                    batch={
                                                      skillNameMap[skilltag_id]
                                                    }
                                                  />
                                                </>
                                              ) : (
                                                skillNameMap[skilltag_id]
                                              )
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                          <Progress
                                            style={{ flex: 8 }}
                                            className="my-3"
                                            color={ColorMap[ind % 8]}
                                            value={
                                              gradebookSkillTags[grd][
                                                skilltag_id
                                              ]
                                            }
                                          />
                                          <div
                                            className="skill-tag"
                                            style={{ flex: 1 }}
                                          >
                                            {
                                              gradebookSkillTags[grd][
                                                skilltag_id
                                              ]
                                            }
                                            %
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          )}
                          {(gradebookType == 1 ||
                            (grd && grd != "FOUNDATION")) && (
                            <div className="tier-cert">
                              {tierEnabled && (
                                <div
                                  className="tiers"
                                  style={{
                                    background:
                                      "radial-gradient(184.43% 76.76% at 53.59% 57.29%, rgba(0, 191, 178, 0.15) 0%, rgba(2, 128, 144, 0.15) 100%), #FFF",
                                    borderRadius: 4,
                                    border: "none",
                                    borderRadius: "4px",
                                    marginBottom: "9px",
                                  }}
                                >
                                  <div
                                    className="tier-header gradeTooltip"
                                    style={{
                                      padding: "10px",
                                      minHeight: "50px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div></div>
                                      <div
                                        style={{
                                          color: "black",
                                          fontSize: "20px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {" "}
                                        My Grades
                                      </div>
                                      <div>
                                        <img
                                          style={{ cursor: "pointer" }}
                                          className="tierInfo"
                                          src="https://cdn.pegasus.imarticus.org/gradebook/Info.svg"
                                          alt="info"
                                          onClick={() =>
                                            toggleTiersPopover(index)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flexcontainer">
                                    <div className="centered-div">
                                      {" "}
                                      <img src="https://cdn.pegasus.imarticus.org/Gradebook/tierLabel.svg"></img>
                                    </div>
                                    <div
                                      className="tierLabel centered-div"
                                      style={{
                                        background:
                                          "radial-gradient(184.43% 76.76% at 53.59% 57.29%, rgba(0, 191, 178, 0.25) 0%, rgba(2, 128, 144, 0.25) 100%), #FFF",
                                        height: "50px",
                                        width: "190px",
                                        borderRadius: "40px",
                                      }}
                                    >
                                      <span className="tierGrade">
                                        <img src="https://cdn.pegasus.imarticus.org/Gradebook/Group.svg"></img>
                                        {grdTier[grd]
                                          ? grdTier[grd]
                                          : grdTier["FOUNDATION"]
                                          ? grdTier["FOUNDATION"]
                                          : "-"}
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div
                                className="row no-margin certiBlock"
                                style={{ display: "block" }}
                              >
                                <div className="certiContainer">
                                  <div className="emptyCerti">
                                    {!certiEnabled && (
                                      <p>
                                        This course does not have a certificate
                                      </p>
                                    )}
                                    {certiEnabled && (
                                      <div>
                                        {certiAutoPublish ||
                                        (isElec && elecLevelIndex
                                          ? certiElectivePublishStatus[
                                              grd != "FOUNDATION"
                                                ? grd
                                                : "CORE_TRACK"
                                            ]
                                          : certiPublished) ? ( // in db we are save foundation as core track
                                          <>
                                            {grd != "FOUNDATION" && (
                                              //crtfEligible - part of graduation criteria
                                              <div>
                                                {(certiAutoPublish ||
                                                  certiElectivePublishStatus[
                                                    grd
                                                  ]) &&
                                                  !certiGeneratedStatus[grd] &&
                                                  !(gradebookType == 1
                                                    ? crtfEligibleMap[grd]
                                                    : crtfEligible) && (
                                                    <button
                                                      style={{
                                                        cursor: "not-allowed",
                                                      }}
                                                      className="btn btn-primary rvm-v1-part-2-info-btn center-all disabled"
                                                    >
                                                      Get Certificate
                                                    </button>
                                                  )}
                                                {(certiAutoPublish ||
                                                  certiElectivePublishStatus[
                                                    grd
                                                  ]) &&
                                                  (gradebookType == 1
                                                    ? crtfEligibleMap[grd]
                                                    : crtfEligible) &&
                                                  !certiGeneratedStatus[
                                                    grd
                                                  ] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${grd}`}
                                                    >
                                                      <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                        Generate Certificate
                                                      </button>
                                                    </a>
                                                  )}
                                                {(certiAutoPublish ||
                                                  certiElectivePublishStatus[
                                                    grd
                                                  ]) &&
                                                  certiGeneratedStatus[grd] &&
                                                  !crtfFileMap[grd] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${grd}`}
                                                    >
                                                      <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                        Get Certificate
                                                      </button>
                                                    </a>
                                                  )}
                                                {(certiAutoPublish ||
                                                  certiElectivePublishStatus[
                                                    grd
                                                  ]) &&
                                                  certiGeneratedStatus[grd] &&
                                                  crtfFileMap[grd] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificateView/${pid}/${cid}/${grd}`}
                                                    >
                                                      <img
                                                        className="view-cert"
                                                        src="https://cdn.pegasus.imarticus.org/gradebook/certi-sample.svg"
                                                        alt="Sample certificate"
                                                      />
                                                    </a>
                                                  )}
                                              </div>
                                            )}

                                            {grd == "FOUNDATION" && (
                                              <div>
                                                {(certiAutoPublish ||
                                                  (isElec && elecLevelIndex
                                                    ? certiElectivePublishStatus[
                                                        "CORE_TRACK"
                                                      ]
                                                    : certiPublished)) &&
                                                  !certiGeneratedStatus[
                                                    "FOUNDATION"
                                                  ] &&
                                                  !(gradebookType == 1 && isElec
                                                    ? crtfEligibleMap[
                                                        grd.elec_id
                                                      ]
                                                    : crtfEligible) && (
                                                    <button
                                                      style={{
                                                        cursor: "not-allowed",
                                                      }}
                                                      className="btn btn-primary rvm-v1-part-2-info-btn center-all disabled"
                                                    >
                                                      Get Certificate
                                                    </button>
                                                  )}

                                                {(certiAutoPublish ||
                                                  (isElec && elecLevelIndex
                                                    ? certiElectivePublishStatus[
                                                        "CORE_TRACK"
                                                      ]
                                                    : certiPublished)) &&
                                                  (gradebookType == 1 && isElec
                                                    ? crtfEligibleMap[
                                                        grd.elec_id
                                                      ]
                                                    : crtfEligible) &&
                                                  !certiGeneratedStatus[
                                                    "FOUNDATION"
                                                  ] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${undefined}`}
                                                    >
                                                      <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                        Generate Certificate
                                                      </button>
                                                    </a>
                                                  )}
                                                {(certiAutoPublish ||
                                                  (isElec && elecLevelIndex
                                                    ? certiElectivePublishStatus[
                                                        "CORE_TRACK"
                                                      ]
                                                    : certiPublished)) &&
                                                  certiGeneratedStatus[
                                                    "FOUNDATION"
                                                  ] &&
                                                  !crtfFileMap[
                                                    "FOUNDATION"
                                                  ] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${undefined}`}
                                                    >
                                                      <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                        Get Certificate
                                                      </button>
                                                    </a>
                                                  )}
                                                {(certiAutoPublish ||
                                                  (isElec && elecLevelIndex
                                                    ? certiElectivePublishStatus[
                                                        "CORE_TRACK"
                                                      ]
                                                    : certiPublished)) &&
                                                  certiGeneratedStatus[
                                                    "FOUNDATION"
                                                  ] &&
                                                  crtfFileMap["FOUNDATION"] && (
                                                    <a
                                                      href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificateView/${pid}/${cid}/${undefined}`}
                                                    >
                                                      <img
                                                        className="view-cert"
                                                        src="https://cdn.pegasus.imarticus.org/gradebook/certi-sample.svg"
                                                        alt="Sample certificate"
                                                      />
                                                    </a>
                                                  )}
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <p>
                                            The Certificate for this course is
                                            not yet available
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>

        {/* 
        {assignmentData &&
          assignmentData.length === 0 &&
          quizData &&
          quizData.length === 0 && (
            <div className="rvm-v1-cont-ctnr bottomRowGradebook">
              <div
                className="row no-margin gradeBlock"
                style={{
                  display: "block",
                  width: "100vw",
                  paddingTop: "8vh",
                  flex: "0 1 100vw",
                  maxWidth: "100vw",
                }}
              >
                <div className="col-12">
                  <span className="my-grade">My Grades</span>
                </div>
                <hr />
                <div style={{ textAlign: "center" }}>
                  <img
                    src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                    alt="Empty Gradebook"
                  />
                  <br />
                  <br />
                  <span>
                    There are no quizzes and assignments to show at this moment.
                  </span>
                </div>
              </div>
            </div>
          )} */}

        <div style={{ backgroundColor: "#f2f6f9" }}>
          {brand && brand.poweredByEnabled && (
            <div className="powered-by-imarticus">
              {brand.hyperlinkEnabled ? (
                <a
                  href="https://www.imarticus.org/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Powered By Imarticus Learning
                </a>
              ) : (
                <p>Powered By Imarticus Learning</p>
              )}
            </div>
          )}
        </div>
      </div>
      <div></div>
    </div>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  primaryEmail: state.profile.email,
  ppic: state.profile.ppic,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
  getProfileInfoOnly,
  setSelectedCourse,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Gradebook)
);
