import EditProfile from "./EditProfile";
import EditGroup from "./EditGroup";
import AddProfile from "./AddProfile";
import Explore from "./Explore";
import Home from "./Home";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Switch, Redirect, withRouter } from "react-router";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CreateGroup from "./CreateGroup";
import JoinGroup from "./JoinGroup";
import MyCourse from "./MyCourse";
import MyGroup from "./MyGroup";
import Gradebook from "../gradebook/gradebook";
import ComboCourse from "./ComboCourse";
import GroupDetail from "../groupcontents/GroupDetail";
import EmptyState from "./EmptyState";
import GroupDescription from "./GroupDescription";
import ChangePassword from "./ChangePassword";
import BasicDetails from "./ProfileDetails/BasicDetails";
import BasicDetails2 from "./ProfileDetails/BasicDetails2";
import EducationDetails from "./ProfileDetails/EducationDetails";
import EducationDetails2 from "./ProfileDetails/EducationDetails2";
import ProfessionalDetails from "./ProfileDetails/ProfessionalDetails";
import ProfessionalDetails2 from "./ProfileDetails/ProfessionalDetails2";
import ResumeDetails from "./ProfileDetails/ResumeDetails";
import ResumeDetails2 from "./ProfileDetails/ResumeDetails2";
import ProfilePreview from "./ProfileDetails/ProfilePreview";
import ChangeEmail from "./ChangeEmail";
import LevelsView from "./LevelsView/LevelsView";
import EditWindow from "./Edit/EditWindow";
import EditEmailWindow from "./Edit/EditEmailWindow";
import EditMobileWindow from "./Edit/EditMobileWindow";
import EditTimezone from "./Edit/EditTimeZone";
import { NotificationHelper } from "../notifications/Notifications";
import ManageDevices from "../notifications/ManageDevices";
import TroubleshootNotifications from "../notifications/TroubleshootNotifications";
import SocketConnect from "../Discussion/SocketConnect_copy";
import CareerServices from "../../components/placement/placementportal";
class ContentContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var loggedIn = false;
    return (
      <Switch>
        {/* {props.current_tab==} */}
        <Route
          exact
          path={"/dashboard/edit/mobile"}
          component={EditMobileWindow}
        />
        <Route exact path={"/view"} component={LevelsView} />
        <Route exact path={"/dashboard/home"} component={EmptyState} />
        <Route exact path={"/dashboard/edit"} component={EditWindow} />
        <Route exact path={"/dashboard/edit/profile"} component={EditProfile} />
        <Route exact path="/dashboard/edit/email" component={EditEmailWindow} />
        <Route exact path="/dashboard/edit/timezone" component={EditTimezone} />

        <Route exact path={"/dashboard/pwd_edit"} component={ChangePassword} />
        <Route exact path={"/placement-portal"} component={CareerServices} />
        <Route
          exact
          path={"/placement-module/basic_details"}
          component={BasicDetails}
        />
        <Route
          exact
          path={"/placement-module/basic-details"}
          component={BasicDetails2}
        />
        <Route
          exact
          path={"/placement-module/education_details"}
          component={EducationDetails}
        />
        <Route
          exact
          path={"/placement-module/education-details"}
          component={EducationDetails2}
        />
        <Route
          exact
          path={"/placement-module/professional_details"}
          component={ProfessionalDetails}
        />
        <Route
          exact
          path={"/placement-module/professional-details"}
          component={ProfessionalDetails2}
        />
        <Route
          exact
          path={"/placement-module/resume"}
          component={ResumeDetails}
        />
        <Route
          exact
          path={"/placement-module/resume-details"}
          component={ResumeDetails2}
        />
        <Route
          exact
          path={"/placement-module/profile_preview"}
          component={ProfilePreview}
        />
        <Route exact path={"/dashboard/updt_mail"} component={ChangeEmail} />
        <Route exact path={"/dashboard/gedit"} component={EditGroup} />
        <Route exact path={"/dashboard/profile"} component={AddProfile} />
        <Route exact path={"/dashboard/course"} component={MyCourse} />
        <Route exact path={"/dashboard/explore"} component={Explore} />
        <Route exact path={"/dashboard/combo"} component={ComboCourse} />
        <Route exact path={"/dashboard/group/:gid"} component={SocketConnect} />
        <Route exact path={"/dashboard/group"} component={MyGroup} />
        <Route exact path={"/dashboard/cgroup"} component={CreateGroup} />
        <Route exact path={"/dashboard/jgroup"} component={JoinGroup} />
        <Route exact path={"/dashboard/gdesc"} component={GroupDescription} />
        <Route exact path={"/dashboard/group-detail"} component={GroupDetail} />
        <Route exact path={"/gradebook/"} component={Gradebook} />
        <Route
          exact
          path={"/dashboard/notifications"}
          component={NotificationHelper}
        />
        <Route
          exact
          path={"/dashboard/manageDevices"}
          component={ManageDevices}
        />
        <Route exact path={"/dashboard"} component={Home} />
        <Route
          exact
          path={"/dashboard/troubleshootNotifications"}
          component={TroubleshootNotifications}
        />
        <Route path="/dashboard/socketConnect" component={SocketConnect} />
        <Route exact path={"/dashboard"} component={Home} />
        <Route
          exact
          path="*"
          render={() =>
            loggedIn ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/dashboard" />
            )
          }
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentContainer)
);
