import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import "./scorm.scss";
import Cookies from "universal-cookie";
import Loader from "../utils/Loader.js";
import {
  updateQuizStatus,
  pluginIdentifier,
} from "../../actions/quizAction.js";
import {
  getScormData,
  scormCompleted,
  startScorm,
} from "../../actions/scormAction.js";
import { CONFIGS } from "../../constants/constants.js";
import { downloadBase64File } from "../../utility/utils.js";
import { getSignedUrl } from "../../actions/docLectureActions.js";

const cookies = new Cookies();

const Scorm = ({ course, getIndex, prevFid, setContentLoading }) => {
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const gid = query[5];
  const cid = query[6];
  const levelId = query[7] != undefined && query[7] ? query[7] : null;
  const type = query[8];
  const chid = query[9];
  const fid = query[10];

  const [optionSelected, setOptionSelected] = useState(false);
  const [scorm, setScorm] = useState();
  const [showResumePopUp, setShowResumePopUp] = useState(false);
  const [scormHTML, setScormHTML] = useState();
  const [loading, setLoading] = useState();
  const [isLockedDeadline, setIsLockedDeadline] = useState(false);
  const [deadline, setDeadline] = useState();
  const [aboutResources, setAboutResources] = useState("About");
  const [resourceMap, setResourceMap] = useState();
  const [secondaryLoader, setSecondaryLoader] = useState(false);

  useEffect(() => {
    console.log("scorm loaded", { scorm });
    if (scorm) {
      setLoading(false);
      setContentLoading(false);
    }
  }, [scorm]);

  useEffect(() => {
    (async () => {
      if (course) {
        setContentLoading(true);
        setLoading(true);
        getIndex();
        setShowResumePopUp(false);
        setOptionSelected(false);
        await activateScorm();
      }
    })();
  }, [prevFid]);

  const activateScorm = async (isResume) => {
    if (isResume != undefined) {
      setOptionSelected(true);
    }
    var tempScormData = course.scorm[fid];
    if (tempScormData.etim && tempScormData.etim != "") {
      var tempIsLockedDeadline =
        parseInt(tempScormData.etim) < new Date().getTime() ? true : false;
      var tempFormattedDate = new Date(tempScormData.etim).toLocaleString(
        "en-US",
        {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      );
      setIsLockedDeadline(tempIsLockedDeadline);
      setDeadline(tempFormattedDate);
    }
    var scormData = await getScormData(fid);
    if (scormData.success) {
      if (
        scormData.data.ucsScormData &&
        scormData.data.ucsScormData.data.cmi.suspend_data
      ) {
        setShowResumePopUp(false);
      } else {
        setShowResumePopUp(true);
        setOptionSelected(true);
      }
    }
    var startScormData = await startScorm(pid, cid, chid, fid, true);
    if (startScormData.success) {
      var params = new URLSearchParams({
        courseId: cid,
        chapterId: chid,
        scormId: fid,
        pid: pid,
        aid: cookies.get("aid"),
        crs: cookies.get("crs_tkn"),
        isResume: isResume,
      });
      var scormHTML =
        CONFIGS.API_API_ENDPOINT +
        "scorm/" +
        fid +
        "/gt_scorm" +
        "?" +
        params.toString();
      console.log({ scormHTML });
      setScormHTML(scormHTML);
      window.addEventListener(
        "message",
        async function (event) {
          var status = event.data;
          if (status["lesson_status"] != undefined) {
            if (
              status["lesson_status"] == "completed" ||
              status["lesson_status"] == "passed"
            ) {
              await startScorm(status["dump"]);
              console.log(status["lesson_status"]);
              console.log(status["dump"]);
            }
          } else if (status["action"] != undefined) {
            if (
              status["action"] == "exit" &&
              this.document.fullscreenElement != null
            ) {
              this.document.exitFullscreen();
            }
          }
        },
        false
      );
    }
    if (isResume != undefined) {
      console.log("disabling popup", isResume);
      setShowResumePopUp(true);
    }
    if (tempScormData.extras && tempScormData.extras.length > 0) {
      var tempResourceMap = {};
      for (var i = 0; i < tempScormData.extras.length; i++) {
        var tempResource = await getSignedUrl(
          pid,
          gid,
          course.links[tempScormData.extras[i]].url
        );
        if (tempResource.success) {
          tempResourceMap[tempScormData.extras[i]] =
            course.links[tempScormData.extras[i]];
          tempResourceMap[tempScormData.extras[i]]["signed_url"] =
            tempResource.data.signed_url;
        }
      }
      setResourceMap(tempResourceMap);
    }
    setScorm(tempScormData);
  };

  const playerFullScreen = function () {
    var docEl = document.querySelector("iframe");
    docEl.requestFullscreen();
    docEl.webkitRequestFullscreen();
    docEl.mozRequestFullScreen();
    docEl.msRequestFullscreen();
  };

  const downLoadResources = function (url, name) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Convert blob to Base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64Data = reader.result;
          console.log(base64Data); // Base64 representation of the image
          downloadBase64File(base64Data, name);
          setSecondaryLoader(false);
        };
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
        setSecondaryLoader(false);
      });
  };

  return scorm ? (
    !secondaryLoader ? (
      <div className="scormScss">
        {isLockedDeadline ? (
          <div className="assignment">
            <div className="as-dl-msg">The Content deadline is exceeded!</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "200px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  position: "relative",
                  top: "-80px",
                }}
              >
                <img
                  src="https://cdn.pegasus.imarticus.org/chapter-controller/deadlineExpired.svg"
                  alt="Deadline Expired"
                />
              </div>
            </div>
            <div
              style={{
                color: "#e94b35",
                fontSize: "40px",
                fontWeight: "800",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                top: "-24px",
              }}
            >
              Deadline Expired at {deadline}
            </div>
          </div>
        ) : (
          !showResumePopUp && (
            <div className="loaderdiv">
              {!showResumePopUp && (
                <div id="isResume" style={{ color: "black" }}>
                  Would you like to pickup your SCORM Module from where you left
                  off?
                  <div className="button-scorm">
                    <button
                      style={{ borderRadius: "4px" }}
                      onClick={() => activateScorm(false)}
                    >
                      Start New
                    </button>
                    <button
                      onClick={() => activateScorm(true)}
                      className="resumeScorm"
                    >
                      Resume
                    </button>
                  </div>
                </div>
              )}
            </div>
          )
        )}
        {!isLockedDeadline && optionSelected && (
          <div>
            {loading && (
              <div id="scorm-loader">
                <p>Please wait while we are preparing your scorm.</p>

                <div>
                  <img
                    id="loading-image"
                    src="https://cdn.pegasus.imarticus.org/chapter-controller/imaritucs_loader.svg"
                    alt="Loading..."
                  />
                </div>
              </div>
            )}
            <div style={{ padding: "20px 40px" }}>
              <iframe
                style={{ width: "100%", height: "75vh" }}
                id="scorm-iframe"
                className="scorm-area"
                src={scormHTML}
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
                webkitallowtransparency
                mozallowtransparency
                allowtransparency
                onLoadStart={() => {
                  setLoading(true);
                }}
                onLoad={() => {
                  setLoading(false);
                }}
              />
            </div>
            <div className="sc-plr-player">
              <div className="sc-plr-player-fullScr" onClick={playerFullScreen}>
                <i className="fa fa-arrows-alt" />
                <span className="sc-plr-player-text"> Fullscreen </span>
              </div>
            </div>
          </div>
        )}
        {!isLockedDeadline && (
          <div className="video-desc">
            <div
              className="nav-tabs"
              style={{ display: "flex", columnGap: "30px" }}
            >
              <div
                className="eck-tabss"
                onClick={() => {
                  setAboutResources("About");
                }}
                style={{
                  cursor: "pointer",
                  marginLeft: "15px",
                  color: "#055646",
                  padding: "8px 16px 13px 16px",
                  borderBottom:
                    aboutResources == "About" ? "3px solid #055646" : "",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                About
              </div>
              <div
                className="eck-tabss"
                onClick={() => {
                  setAboutResources("Resources");
                }}
                style={{
                  cursor: "pointer",
                  color: "#055646",
                  padding: "8px 16px 13px 16px",
                  borderBottom:
                    aboutResources == "Resources" ? "3px solid #055646" : "",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                Resources
              </div>
            </div>
            <div>
              {aboutResources == "About" && (
                <div
                  style={{
                    color: "#494949",
                    fontSize: "16px",
                    padding: "30px 0px",
                  }}
                >
                  <p dangerouslySetInnerHTML={{ __html: scorm.dsc }}></p>
                </div>
              )}
              {aboutResources == "Resources" && (
                <div id="resources" className="tab-pane">
                  <ul className="docLectureResouceList">
                    {scorm.extras.map((extra, index) => (
                      <li key={index} className="docLectureResouceListItem">
                        {course.links[extra].url && (
                          <div
                            className="docLectureResouceListItemLink"
                            onClick={() => {
                              setSecondaryLoader(true);
                              downLoadResources(
                                resourceMap[extra].signed_url,
                                course.links[extra].nm
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Download{" "}
                            {scorm.nm == course.links[extra].nm
                              ? `Resource ${scorm.nm}`
                              : course.links[extra].nm}
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    ) : (
      <Loader />
    )
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = { dispatchIndividualBrand, getProfileInfoOnly };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Scorm));
