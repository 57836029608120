import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter, useHistory, Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { CONFIGS } from "../../constants/constants";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import moment from "moment-timezone";
import {
  atCrs,
  certStatus,
  chapStatus,
  courseContent,
  courseStatus,
  downloadCert,
  endUserQuiz,
  feedbackUserStatus,
  impartusUserLogin,
  initializeUserQuiz,
  levelContent,
  placementRedirect,
  pluginIdentifier,
  registerUserQuizIdnt,
  testQuestion,
  testToken,
  userLogout,
  zoomSignature,
} from "../../actions/batchAction.js";
import { showFreshDesk } from "../../actions/showFreshDesk.js";
import "./chapter.scss";
import ReactHtmlParser from "react-html-parser";
import Loader from "../utils/Loader.js";
import { ROUTES } from "../../constants/routes.js";
import Quiz, { quiz } from "../quiz/quiz.js";
import DocLecture from "../docLecture/docLecture.js";
import Scorm from "../scorm/scorm.js";
import Assignment from "../assignment/assignment.js";
import Lecture from "../lecture/lecture.js";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

const cookies = new Cookies();
const monthsArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const Chapter = (props) => {
  let query = window.location.href;
  console.log({
    '!props.brand?.textColor ||props.brand?.textColor =="#000000"':
      !props.brand?.textColor || props.brand?.textColor == "#000000",
    "props.brand?.textColor": props.brand?.textColor,
  });
  query = query.split("/");
  const pid = query[4];
  const gid = query[5];
  const cid = query[6];
  const levelId = query[7] != undefined && query[7] ? query[7] : null;
  const type = query[8];
  const chid = query[9];
  const fid = query[10];
  console.log({ query, type });
  const history = useHistory();

  const [batchObj, setBatchObj] = useState({});
  const [levelsArr, setLevelsArr] = useState(null);
  const [atCrsLoaded, setAtCrsLoaded] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [brand, setBrand] = useState({});
  const [course, setCourse] = useState({});
  const [courseCerti, setCourseCerti] = useState(false);
  const [trxnTypeEmi, setTrxnTypeEmi] = useState();
  const [hideCourseContent, setHideCourseContent] = useState();
  const [noOfVisibleChapter, setNoOfVisibleChapter] = useState();
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [status, setStatus] = useState();
  const [gStatus, setGStatus] = useState();
  const [crtfEligible, setCrtfEligible] = useState();
  const [chapterLocking, setChapterLocking] = useState();
  const [contentLocking, setContentLocking] = useState();
  const [statusLoaded, setStatusLoaded] = useState(false);
  const [certiEnabled, setCertiEnabled] = useState();
  const [certiAutoPublish, setCertiAutoPublish] = useState();
  const [certiPublished, setCertiPublished] = useState();
  const [certiGenerated, setCertiGenerated] = useState();
  const [crtfFile, setCrtfFile] = useState();
  const [chapters, setChapters] = useState();
  const [showPopupMap, setShowPopUpMap] = useState({});
  const [showContentPopUpMap, setShowContentPopUpMap] = useState({});
  const [sequence, setSequence] = useState();
  const [activePopUpChapterId, setActivePopUpChapterId] = useState("");
  const [videoLectureType, setVideoLectureType] = useState();
  const [seqTopic, setSeqTopic] = useState([]);
  const [index, setIndex] = useState();
  const [showPopupPrevious, setShowPopupPrevious] = useState(false);
  const [showPopupNext, setShowPopupNext] = useState(false);
  const [prevFid, setPrevFid] = useState("");
  const [contentLoading, setContentLoading] = useState(false);
  const [elecId, setElecId] = useState();
  const [applyCert, setApplyCert] = useState({});
  const [certiGeneratedStatus, setCertiGeneratedStatus] = useState({});

  const [elecLevelIndex, setElecLevelIndex] = useState();
  const [isElec, setIsElec] = useState(false);
  const [certiElectivePublishStatus, setCertiElectivePublishStatus] = useState(
    {}
  );
  const [crtfEligibleMap, setCrtfEligibleMap] = useState({});
  const [gradebookType, setGradebookType] = useState(1);

  useEffect(() => {
    console.log("hello");
    window.FreshworksWidget?.("hide", "launcher");
    authenticateCourse(function () {});
  }, []);

  useEffect(() => {
    if (atCrsLoaded) {
      console.log("hello", atCrsLoaded);

      getLevelContent(function (tempBreadCrumb) {
        getCourseContent(tempBreadCrumb);
      });
    }
  }, [atCrsLoaded]);

  useEffect(() => {
    console.log("hello", courseLoaded);
    if (courseLoaded) {
      getCourseStatus(function () {
        getCertificateStatus(function () {});
        downloadCertificate();
      });
    }
  }, [courseLoaded]);

  useEffect(() => {
    if (statusLoaded) {
      compileAllTopicsInSeq();
    }
  }, [statusLoaded]);

  useEffect(() => {
    if (seqTopic.length > 0) {
      getCurrentIndex();
    }
  }, [seqTopic]);

  const authenticateCourse = async function (cb) {
    if (!cookies.get("isLoggedIn")) {
      return window.open(CONFIGS.WEB_DASHBOARD_BASE_URL, "_self");
    }
    atCrs({
      pid,
      gid,
      cid,
      levelId,
      dispatchIndividualBrand: props.dispatchIndividualBrand,
    })
      .then(function (res) {
        console.log(res);
        setBatchObj(() => {
          return res.data.data.course;
        });
        let grdtype = res.data.data.course.gradebook_type
          ? res.data.data.course.gradebook_type
          : 1;
        setGradebookType(grdtype);
        setIsElec(res.data.data.elec_enable);
        if ("elec_certi_publish_status" in res.data.data.course) {
          setCertiElectivePublishStatus(
            res.data.data.course["elec_certi_publish_status"]
          );
        }
        if ("elec_level_index" in res.data.data.course) {
          setElecLevelIndex(res.data.data.course["elec_level_index"]);
        }
        setLevelsArr(() => {
          return res.data.data.course.level_labels;
        });
        var expirydate = new Date();
        expirydate.setDate(expirydate.getDate() + 180);
        cookies.set("crs_tkn", res.data.data.at, {
          expires: expirydate,
          domain: CONFIGS.DOMAIN,
          path: "/",
        });
        setAtCrsLoaded(true);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getLevelContent = async function (cb) {
    levelContent(pid, gid, cid, levelId)
      .then(function (res) {
        let tempBreadCrumb = res.data.data.breadCrumbs;
        setBreadCrumbs(() => {
          tempBreadCrumb.unshift({
            name: "All Courses",
            link: CONFIGS.DASHBOARD_BASE_URL,
          });
          return tempBreadCrumb;
        }); //set here on redux
        cb(tempBreadCrumb);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getCourseContent = async function (crumb) {
    courseContent(pid, gid, cid, levelId)
      .then(function (res) {
        var ourChap = res.data.data.chapters.filter(function (el) {
          return el._id == chid;
        })[0];
        console.log({ ourChap });
        var tempBreadCrumbs = crumb;
        tempBreadCrumbs.push({
          _id: ourChap._id,
          name: ourChap.nm,
          chap: true,
        });
        console.log({ tempBreadCrumbs });
        setBreadCrumbs(tempBreadCrumbs);
        console.log("COURSE", res.data.data);
        setCourse(() => {
          return res.data.data;
        });
        setSequence(() => {
          return res.data.data.sequence;
        });
        var tempChapters = res.data.data.chapters;
        var chapterMap = {};
        for (var i = 0; i < tempChapters.length; i++) {
          tempChapters[i]["isCollapsed"] = true;
          chapterMap[tempChapters[i]._id] = tempChapters[i];
        }
        setChapters(chapterMap);
        setCourseCerti(() => {
          return res.data.data.crtf_enable;
        });
        if (
          res.data.data.emistatus.trxn_typ_emi &&
          res.data.data.emistatus.emi_paid <
            res.data.data.emistatus.emi_needed_to_pay
        ) {
          setTrxnTypeEmi(() => {
            return res.data.data.emistatus.trxn_typ_emi;
          });
          setHideCourseContent(() => {
            return res.data.data.emistatus.hide_course_content;
          });
          if ("no_of_visible_chapters" in res.data.data.emistatus) {
            setNoOfVisibleChapter(() => {
              return res.data.data.emistatus.no_of_visible_chapters;
            });
          }
        } else {
          setTrxnTypeEmi(() => {
            return false;
          });
          setHideCourseContent(() => {
            return res.data.data.emistatus.hide_course_content;
          });
          setNoOfVisibleChapter(() => {
            return res.data.data.emistatus.no_of_visible_chapters
              ? res.data.data.emistatus.no_of_visible_chapters
              : res.data.data.chapters.length;
          });
        }
        setCourseLoaded(true);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getCourseStatus = async function (cb) {
    courseStatus(pid, gid, cid, levelId)
      .then(function (res) {
        console.log(res);
        setStatus(() => {
          return res.data.data;
        });
        setGStatus(res.data.data.status);
        setCrtfEligible(() => {
          return res.data.data.crtf_eligible;
        });
        setChapterLocking(() => {
          return res.data.data.chapterLocking;
        });
        setContentLocking(() => {
          return res.data.data.contentLocking;
        });
        setCrtfEligibleMap(res.data.data.crtf_eligibleMap);

        setStatusLoaded(true);
        cb();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getCertificateStatus = async function (cb) {
    certStatus(pid, gid, cid, levelId)
      .then(function (res) {
        console.log(res);
        setCertiEnabled(() => {
          return res.data.data.certiEnabled;
        });
        setCertiAutoPublish(() => {
          return res.data.data.auto_publish;
        });
        setCertiPublished(() => {
          return res.data.data.published;
        });
        setCertiGenerated(() => {
          return res.data.data.generated;
        });
        if (res.data.data.levelElecMap && res.data.data.levelElecMap[levelId]) {
          setElecId(res.data.data.levelElecMap[levelId]);
        }

        setCertiGeneratedStatus(res.data.data.certgeneratedMap);
        setCertiPublished(res.data.data.published);

        console.log({
          certiEnabled: certiEnabled,
          certiAutoPublish: certiAutoPublish,
          certiPublished: certiPublished,
          certiGenerated: certiGenerated,
          crtfEligible: crtfEligible,
        });
        cb();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    if (elecId && applyCert[elecId] && applyCert[elecId].status) {
      let obj = applyCert[elecId];
      console.log({ obj });
      setCrtfFile(() => {
        return obj.data.crtf_file;
      });
    }
    if (applyCert["FOUNDATION"] && applyCert["FOUNDATION"].status) {
      let obj = applyCert["FOUNDATION"];
      setCrtfFile(() => {
        return obj.data.crtf_file;
      });
    }
  }, [applyCert, elecId]);

  const downloadCertificate = async function () {
    downloadCert(pid, gid, cid, levelId)
      .then(function (res) {
        console.log(res);
        if (res.data.data) {
          let cert = res.data.data;
          setApplyCert({ ...cert });
          // if (elecId && cert[elecId] && cert[elecId].status) {
          //   let obj = cert[elecId];
          //   console.log({obj})
          //   setCrtfFile(() => {
          //     return obj.data.crtf_file;
          //   });
          // }
          // if (cert["FOUNDATION"] && cert["FOUNDATION"].status) {
          //   let obj = cert["FOUNDATION"];
          //   setCrtfFile(() => {
          //     return obj.data.crtf_file;
          //   });
          // }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const updateChapterStatusAndToggle = async function (chapter, chapter_no) {
    var tempChapter = { ...chapters };
    tempChapter[chapter._id].isCollapsed =
      !tempChapter[chapter._id].isCollapsed;
    setChapters(tempChapter);
    if (hideCourseContent && chapter_no > noOfVisibleChapter) {
      console.log("hidden");
    } else {
      var configs = {};
      var headers = {};
      headers["x-access-crs-token"] = cookies.get("crs_tkn");
      configs.headers = headers;
      axios
        .post(
          ROUTES.CHAPTER_STATUS,
          {
            crs_id: cid,
            cp_id: chapter._id,
            p_id: pid,
            a_id: cookies.get("aid"),
            cl: "W",
          },
          configs
        )
        .then(function (res) {})
        .catch(function (err) {
          console.log(err);
        });
    }
  };

  const compileAllTopicsInSeq = function () {
    let seqTopicTemp = [];
    for (var i = 0; i < course.chapters.length; i++) {
      if (
        course.sequence[course.chapters[i]._id] &&
        course.sequence[course.chapters[i]._id].length > 0
      ) {
        for (
          var j = 0;
          j < course.sequence[course.chapters[i]._id].length;
          j++
        ) {
          var isLockedChapter = false;
          var isLockedContent = false;
          if (
            chapterLocking[course.chapters[i]._id] &&
            (chapterLocking[course.chapters[i]._id].isLocked ||
              chapterLocking[course.chapters[i]._id].isLockedDeadline)
          ) {
            isLockedChapter = true;
          }
          if (
            contentLocking[course.chapters[i]._id] &&
            contentLocking[course.chapters[i]._id][j] &&
            (contentLocking[course.chapters[i]._id][j].isLocked ||
              contentLocking[course.chapters[i]._id][j].isLockedDeadline)
          ) {
            isLockedContent = true;
          }
          if (
            course.sequence[course.chapters[i]._id][j].type != 7 ||
            (course.liv_lec_id_map &&
              course.sequence[course.chapters[i]._id][j].type == 7 &&
              course.liv_lec_id_map[
                course.sequence[course.chapters[i]._id][j].fid
              ] &&
              course.liv_lec_id_map[
                course.sequence[course.chapters[i]._id][j].fid
              ].s3_url &&
              course.liv_lec_id_map[
                course.sequence[course.chapters[i]._id][j].fid
              ].s3_url != "")
          ) {
            //ignoring livelecture as it was before.
            seqTopicTemp.push({
              id: course.sequence[course.chapters[i]._id][j].fid,
              isLocked: isLockedChapter || isLockedContent ? true : false,
              msg:
                chapterLocking[course.chapters[i]._id] &&
                chapterLocking[course.chapters[i]._id].msg != ""
                  ? chapterLocking[course.chapters[i]._id].msg
                  : contentLocking[course.chapters[i]._id] &&
                    contentLocking[course.chapters[i]._id][j] &&
                    contentLocking[course.chapters[i]._id][j].msg != ""
                  ? contentLocking[course.chapters[i]._id][j].msg
                  : "",
              chapter: course.chapters[i]._id,
              type: course.sequence[course.chapters[i]._id][j].type,
            });
          } else {
            if (
              course.liv_lec_id_map[
                course.sequence[course.chapters[i]._id][j].fid
              ].s3_url != null &&
              course.liv_lec_id_map[
                course.sequence[course.chapters[i]._id][j].fid
              ].s3_url != ""
            ) {
              seqTopicTemp.push({
                id: course.sequence[course.chapters[i]._id][j].fid,
                isLocked: isLockedChapter || isLockedContent ? true : false,
                msg:
                  chapterLocking[course.chapters[i]._id] &&
                  chapterLocking[course.chapters[i]._id].msg != ""
                    ? chapterLocking[course.chapters[i]._id].msg
                    : contentLocking[course.chapters[i]._id] &&
                      contentLocking[course.chapters[i]._id][j] &&
                      contentLocking[course.chapters[i]._id][j].msg != ""
                    ? contentLocking[course.chapters[i]._id][j].msg
                    : "",
                chapter: course.chapters[i]._id,
                type: course.sequence[course.chapters[i]._id][j].type,
              });
            }
          }
        }
      }
    }
    console.log("seqTopic1", seqTopicTemp);
    setSeqTopic([...seqTopicTemp]);
  };

  const getCurrentIndex = async function () {
    for (var i = 0; i < seqTopic.length; i++) {
      if (seqTopic[i].id == fid) {
        setIndex(i);
        var chapterMap = { ...chapters };
        chapterMap[seqTopic[i].chapter]["isCollapsed"] = false;
        setChapters({ ...chapterMap });
        var tempBreadCrumbs = [];
        breadCrumbs.forEach((entry) => {
          if (entry.chap) {
            tempBreadCrumbs.push({
              _id: seqTopic[i].chapter,
              name: chapterMap[seqTopic[i].chapter].nm,
              chap: true,
            });
          } else {
            tempBreadCrumbs.push(entry);
          }
        });
        setBreadCrumbs([...tempBreadCrumbs]);
      }
    }
  };

  const previous = function (index) {
    setPrevFid(fid);
    var prevTopic = seqTopic[index - 1];
    if (prevTopic.isLocked) {
      return;
    }
    if (prevTopic.type == 1 || prevTopic.type == 7) {
      window.location.replace(
        `${CONFIGS.WEB_DASHBOARD_BASE_URL}chapter/${pid}/${gid}/${cid}/${levelId}/lecture/${prevTopic.chapter}/${prevTopic.id}`
      );
    } else if (prevTopic.type == 5) {
      history.push(
        `/chapter/${pid}/${gid}/${cid}/${levelId}/docLecture/${prevTopic.chapter}/${prevTopic.id}`
      );
    } else if (prevTopic.type == 2 || prevTopic.type == 6) {
      history.push(
        `/chapter/${pid}/${gid}/${cid}/${levelId}/assignment/${prevTopic.chapter}/${prevTopic.id}`
      );
    } else if (prevTopic.type == 3) {
      history.push(
        `/chapter/${pid}/${gid}/${cid}/${levelId}/quiz/${prevTopic.chapter}/${prevTopic.id}`
      );
    } else if (prevTopic.type == 8) {
      history.push(
        `/chapter/${pid}/${gid}/${cid}/${levelId}/scorm/${prevTopic.chapter}/${prevTopic.id}`
      );
    } else {
      console.log("invalid id");
    }
  };

  const next = function (index) {
    setPrevFid(fid);
    var nextTopic = seqTopic[index + 1];
    if (nextTopic.isLocked) {
      return;
    }
    if (nextTopic.type == 1 || nextTopic.type == 7) {
      window.location.replace(
        `${CONFIGS.WEB_DASHBOARD_BASE_URL}chapter/${pid}/${gid}/${cid}/${levelId}/lecture/${nextTopic.chapter}/${nextTopic.id}`
      );
    } else if (nextTopic.type == 5) {
      history.push(
        `/chapter/${pid}/${gid}/${cid}/${levelId}/docLecture/${nextTopic.chapter}/${nextTopic.id}`
      );
    } else if (nextTopic.type == 2 || nextTopic.type == 6) {
      history.push(
        `/chapter/${pid}/${gid}/${cid}/${levelId}/assignment/${nextTopic.chapter}/${nextTopic.id}`
      );
    } else if (nextTopic.type == 3) {
      history.push(
        `/chapter/${pid}/${gid}/${cid}/${levelId}/quiz/${nextTopic.chapter}/${nextTopic.id}`
      );
    } else if (nextTopic.type == 8) {
      history.push(
        `/chapter/${pid}/${gid}/${cid}/${levelId}/scorm/${nextTopic.chapter}/${nextTopic.id}`
      );
    } else {
      console.log("invalid id");
    }
  };

  if (statusLoaded) {
    return (
      <div
        className="chapterScss"
        style={{ fontFamily: "Source Sans Pro, sans-serif" }}
      >
        <div className="course-chapter">
          <div id="content" style={{ background: "white" }}>
            <nav id="sidebar" className="ch-sb" style={{ height: "100%" }}>
              <div className="sidebar-header" style={{ paddingTop: "30px" }}>
                <a
                  onClick={() => {
                    history.goBack();
                  }}
                  className="btn trans-edge-btn"
                >
                  <i
                    className="icon fa fa-angle-left"
                    style={{ paddingRight: "3px" }}
                  ></i>
                  Back
                </a>
              </div>
              <div className="chapters">
                <div
                  className="panel-group"
                  // style={{ backgroundColor: "#136656" }}
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  {Object.values(chapters).map((chapter, index) => (
                    <div
                      key={chapter._id}
                      className={`panel panel-default ${
                        !chapter.isCollapsed ? "active" : ""
                      }`}
                      style={{
                        marginBottom: chapter.isCollapsed ? "0" : "1px",
                      }}
                    >
                      <div
                        key={chapter._id}
                        style={{ paddingTop: "12px", paddingBottom: "12px" }}
                        className={`panel-heading new-panel-heading`}
                        onClick={() => {
                          if (
                            (chapterLocking[chapter._id] &&
                              !chapterLocking[chapter._id].isLocked &&
                              !chapterLocking[chapter._id].isLockedDeadline) ||
                            !chapterLocking[chapter._id]
                          ) {
                            updateChapterStatusAndToggle(chapter, index + 1);
                          }
                        }}
                        role="tab"
                      >
                        <h4 className="panel-title new-panel-title">
                          <span className="wrap-text-chapter">
                            {index + 1}. {chapter.nm}
                          </span>
                          {((chapterLocking[chapter._id] &&
                            !chapterLocking[chapter._id].isLocked &&
                            !chapterLocking[chapter._id].isLockedDeadline) ||
                            !chapterLocking[chapter._id]) && (
                            <span className="arrow"></span>
                          )}
                          {chapterLocking[chapter._id] &&
                            (chapterLocking[chapter._id].isLocked ||
                              chapterLocking[chapter._id].isLockedDeadline) && (
                              <div className="chapter-lock-icon">
                                <img src="https://cdn.pegasus.imarticus.org/chapter-controller/white-lock.svg" />
                              </div>
                            )}
                        </h4>
                        <p>
                          <span>
                            {chapter.lec.length > 0 &&
                              `${chapter.lec.length} Lecture${
                                chapter.lec.length > 1 ? "s " : " "
                              }`}
                          </span>
                          <span>
                            {chapter.quiz.length > 0 &&
                              `${chapter.lec.length > 0 ? ", " : ""}${
                                chapter.quiz.length
                              } Quiz${chapter.quiz.length > 1 ? "zes " : " "}`}
                          </span>
                          <span>
                            {chapter.asgn.length > 0 &&
                              `${
                                chapter.lec.length > 0 ||
                                chapter.quiz.length > 0
                                  ? ", "
                                  : ""
                              }${chapter.asgn.length} Assignment${
                                chapter.asgn.length > 1 ? "s " : " "
                              }`}
                          </span>
                          <span>
                            {chapter.scorm.length > 0 &&
                              `${
                                chapter.lec.length > 0 ||
                                chapter.quiz.length > 0 ||
                                chapter.asgn.length > 0
                                  ? ", "
                                  : ""
                              }${chapter.scorm.length} SCORM Module${
                                chapter.scorm.length > 1 ? "s " : " "
                              }`}
                          </span>
                          <span>
                            {chapter.doc_lec.length > 0 &&
                              `${
                                chapter.lec.length > 0 ||
                                chapter.quiz.length > 0 ||
                                chapter.asgn.length > 0 ||
                                chapter.scorm.length > 0
                                  ? ", "
                                  : ""
                              }${chapter.doc_lec.length} Document${
                                chapter.doc_lec.length > 1 ? "s " : " "
                              }`}
                          </span>
                          <span>
                            {chapter.liv_lec.length > 0 &&
                              `${
                                chapter.lec.length > 0 ||
                                chapter.quiz.length > 0 ||
                                chapter.asgn.length > 0 ||
                                chapter.scorm.length ||
                                chapter.doc_lec.length > 0 > 0
                                  ? ", "
                                  : ""
                              }${chapter.liv_lec.length} Live Lecture${
                                chapter.liv_lec.length > 1 ? "s " : " "
                              }`}
                          </span>
                        </p>
                        {chapterLocking[chapter._id] &&
                          (chapterLocking[chapter._id].isLocked ||
                            chapterLocking[chapter._id].isLockedDeadline) && (
                            <div key={chapter._id} className="chapterPopup">
                              {chapterLocking[chapter._id].msg}
                            </div>
                          )}
                      </div>
                      {!chapter.isCollapsed &&
                        ((chapterLocking[chapter._id] &&
                          !chapterLocking[chapter._id].isLocked &&
                          !chapterLocking[chapter._id].isLockedDeadline) ||
                          !chapterLocking[chapter._id]) && (
                          <div className="panel-collapse">
                            <div className="panel-body">
                              <ul>
                                {sequence[chapter._id] &&
                                  sequence[chapter._id].map(
                                    (topic, contentIndex) => (
                                      <React.Fragment key={topic.fid}>
                                        {topic.type == 1 &&
                                          course.lectures[topic.fid] && (
                                            <li
                                              className="title-popup"
                                              key={topic.fid}
                                              onClick={() => {
                                                if (
                                                  (contentLocking[
                                                    chapter._id
                                                  ] &&
                                                    contentLocking[chapter._id][
                                                      contentIndex
                                                    ] &&
                                                    !(
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLocked ||
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLockedDeadline
                                                    )) ||
                                                  !contentLocking[chapter._id]
                                                ) {
                                                  window.location.replace(
                                                    `${CONFIGS.WEB_DASHBOARD_BASE_URL}chapter/${pid}/${gid}/${cid}/${levelId}/lecture/${topic.chpid}/${topic.fid}`
                                                  );
                                                  setPrevFid(fid);
                                                }
                                              }}
                                              style={{
                                                opacity:
                                                  topic.fid == fid
                                                    ? "0.9"
                                                    : "1",
                                              }}
                                            >
                                              <div
                                                className="title"
                                                onClick={() => {
                                                  setVideoLectureType(
                                                    "lecture"
                                                  );
                                                }}
                                              >
                                                <a>
                                                  <div>
                                                    <div>
                                                      <div
                                                        className="wrap-text"
                                                        style={{
                                                          // opacity:
                                                          //   topic.fid == fid
                                                          //     ? "0.9"
                                                          //     : "1",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {!props.brand
                                                          ?.textColor ||
                                                        props.brand
                                                          ?.textColor ==
                                                          "#000000" ? (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/index/play_dark.svg"
                                                            className="icon play-button"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/index/play_white.svg"
                                                            className="icon play-button"
                                                          />
                                                        )}
                                                        {
                                                          course.lectures[
                                                            topic.fid
                                                          ].nm
                                                        }
                                                        <span
                                                          style={{
                                                            float: "right",
                                                          }}
                                                        >
                                                          {
                                                            course.lectures[
                                                              topic.fid
                                                            ].vlen
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="statusLock">
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial"
                                                          : "complete"
                                                      }
                                                    >
                                                      <img src="https://cdn.pegasus.imarticus.org/chapter-controller/white-lock.svg" />
                                                    </div>
                                                  </div>
                                                )}
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="popup">
                                                    {
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex].msg
                                                    }
                                                  </div>
                                                )}
                                              {status.status &&
                                                status.status[topic.fid] &&
                                                status.status[topic.fid]
                                                  .stts === 4 &&
                                                ((course.lectures[topic.fid]
                                                  .feedback_compulsory &&
                                                  status.status[
                                                    course.lectures[topic.fid]
                                                      .feedback
                                                  ] &&
                                                  status.status[
                                                    course.lectures[topic.fid]
                                                      .feedback
                                                  ].stts === 4) ||
                                                  !course.lectures[topic.fid]
                                                    .feedback_compulsory) &&
                                                ((contentLocking[chapter._id] &&
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ] &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLocked &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) ||
                                                  !contentLocking[
                                                    chapter._id
                                                  ]) && (
                                                  <div
                                                    className="status"
                                                    style={{
                                                      verticalAlign: "super",
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial s-circle"
                                                          : "complete s-circle"
                                                      }
                                                    >
                                                      <i
                                                        class="icon fa fa-check"
                                                        style={{
                                                          color: "white",
                                                          fontSize: "9px",
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </div>
                                                )}
                                            </li>
                                          )}
                                        {(topic.type == 3 || topic.type == 4) &&
                                          course.quizzes[topic.fid] && (
                                            <li
                                              className="title-popup"
                                              key={topic.fid}
                                              onClick={() => {
                                                if (
                                                  (contentLocking[
                                                    chapter._id
                                                  ] &&
                                                    contentLocking[chapter._id][
                                                      contentIndex
                                                    ] &&
                                                    !(
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLocked ||
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLockedDeadline
                                                    )) ||
                                                  !contentLocking[chapter._id]
                                                ) {
                                                  history.push(
                                                    `/chapter/${pid}/${gid}/${cid}/${levelId}/quiz/${topic.chpid}/${topic.fid}`
                                                  );
                                                  setPrevFid(fid);
                                                }
                                              }}
                                              style={{
                                                opacity:
                                                  topic.fid == fid
                                                    ? "0.9"
                                                    : "1",
                                              }}
                                            >
                                              <div className="title">
                                                <a>
                                                  <div>
                                                    <div>
                                                      <div
                                                        className="wrap-text"
                                                        style={{
                                                          // opacity:
                                                          //   topic.fid == fid
                                                          //     ? "0.9"
                                                          //     : "1",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {!props.brand
                                                          ?.textColor ||
                                                        props.brand
                                                          ?.textColor ==
                                                          "#000000" ? (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/index/quiz_dark.svg"
                                                            className="icon play-button"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/index/quiz_white.svg"
                                                            className="icon play-button"
                                                          />
                                                        )}
                                                        {
                                                          course.quizzes[
                                                            topic.fid
                                                          ].nm
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="statusLock">
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial"
                                                          : "complete"
                                                      }
                                                    >
                                                      <img src="https://cdn.pegasus.imarticus.org/chapter-controller/white-lock.svg" />
                                                    </div>
                                                  </div>
                                                )}
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="popup">
                                                    {
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex].msg
                                                    }
                                                  </div>
                                                )}
                                              {status.status &&
                                                status.status[topic.fid] &&
                                                status.status[topic.fid]
                                                  .stts === 4 &&
                                                ((contentLocking[chapter._id] &&
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ] &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLocked &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) ||
                                                  !contentLocking[
                                                    chapter._id
                                                  ]) && (
                                                  <div
                                                    className="status"
                                                    style={{
                                                      verticalAlign: "super",
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial s-circle"
                                                          : "complete s-circle"
                                                      }
                                                    >
                                                      <i
                                                        class="icon fa fa-check"
                                                        style={{
                                                          color: "white",
                                                          fontSize: "9px",
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </div>
                                                )}
                                            </li>
                                          )}
                                        {(topic.type == 2 || topic.type == 6) &&
                                          course.assignments[topic.fid] && (
                                            <li
                                              className="title-popup"
                                              key={topic.fid}
                                              onClick={() => {
                                                if (
                                                  (contentLocking[
                                                    chapter._id
                                                  ] &&
                                                    contentLocking[chapter._id][
                                                      contentIndex
                                                    ] &&
                                                    !(
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLocked ||
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLockedDeadline
                                                    )) ||
                                                  !contentLocking[chapter._id]
                                                ) {
                                                  history.push(
                                                    `/chapter/${pid}/${gid}/${cid}/${levelId}/assignment/${topic.chpid}/${topic.fid}`
                                                  );
                                                  setPrevFid(fid);
                                                }
                                              }}
                                              style={{
                                                opacity:
                                                  topic.fid == fid
                                                    ? "0.9"
                                                    : "1",
                                              }}
                                            >
                                              <div className="title">
                                                <a>
                                                  <div>
                                                    <div>
                                                      <div
                                                        className="wrap-text"
                                                        style={{
                                                          // opacity:
                                                          //   topic.fid == fid
                                                          //     ? "0.9"
                                                          //     : "1",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {!props.brand
                                                          ?.textColor ||
                                                        props.brand
                                                          ?.textColor ==
                                                          "#000000" ? (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/index/assignment_dark.svg"
                                                            className="icon play-button"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/index/assignment_white.svg"
                                                            className="icon play-button"
                                                          />
                                                        )}
                                                        {
                                                          course.assignments[
                                                            topic.fid
                                                          ].nm
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="statusLock">
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial"
                                                          : "complete"
                                                      }
                                                    >
                                                      <img src="https://cdn.pegasus.imarticus.org/chapter-controller/white-lock.svg" />
                                                    </div>
                                                  </div>
                                                )}
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="popup">
                                                    {
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex].msg
                                                    }
                                                  </div>
                                                )}
                                              {status.status &&
                                                status.status[topic.fid] &&
                                                status.status[topic.fid]
                                                  .stts === 4 &&
                                                ((contentLocking[chapter._id] &&
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ] &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLocked &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) ||
                                                  !contentLocking[
                                                    chapter._id
                                                  ]) && (
                                                  <div
                                                    className="status"
                                                    style={{
                                                      verticalAlign: "super",
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial s-circle"
                                                          : "complete s-circle"
                                                      }
                                                    >
                                                      <i
                                                        class="icon fa fa-check"
                                                        style={{
                                                          color: "white",
                                                          fontSize: "9px",
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </div>
                                                )}
                                            </li>
                                          )}
                                        {topic.type == 5 &&
                                          course.documentAsLectures[
                                            topic.fid
                                          ] && (
                                            <li
                                              className="title-popup"
                                              key={topic.fid}
                                              onClick={() => {
                                                if (
                                                  (contentLocking[
                                                    chapter._id
                                                  ] &&
                                                    contentLocking[chapter._id][
                                                      contentIndex
                                                    ] &&
                                                    !(
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLocked ||
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLockedDeadline
                                                    )) ||
                                                  !contentLocking[chapter._id]
                                                ) {
                                                  history.push(
                                                    `/chapter/${pid}/${gid}/${cid}/${levelId}/docLecture/${topic.chpid}/${topic.fid}`
                                                  );
                                                  setPrevFid(fid);
                                                }
                                              }}
                                              style={{
                                                opacity:
                                                  topic.fid == fid
                                                    ? "0.9"
                                                    : "1",
                                              }}
                                            >
                                              <div className="title">
                                                <a>
                                                  <div>
                                                    <div>
                                                      <div
                                                        className="wrap-text"
                                                        style={{
                                                          // opacity:
                                                          //   topic.fid == fid
                                                          //     ? "0.9"
                                                          //     : "1",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {!props.brand
                                                          ?.textColor ||
                                                        props.brand
                                                          ?.textColor ==
                                                          "#000000" ? (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/chapter-controller/pdficon.svg"
                                                            className="icon play-button"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/index/pdf_white.svg"
                                                            className="icon play-button"
                                                          />
                                                        )}
                                                        {
                                                          course
                                                            .documentAsLectures[
                                                            topic.fid
                                                          ].nm
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="statusLock">
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial"
                                                          : "complete"
                                                      }
                                                    >
                                                      <img src="https://cdn.pegasus.imarticus.org/chapter-controller/white-lock.svg" />
                                                    </div>
                                                  </div>
                                                )}
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="popup">
                                                    {
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex].msg
                                                    }
                                                  </div>
                                                )}
                                              {status.status &&
                                                status.status[topic.fid] &&
                                                status.status[topic.fid]
                                                  .stts === 4 &&
                                                ((contentLocking[chapter._id] &&
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ] &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLocked &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) ||
                                                  !contentLocking[
                                                    chapter._id
                                                  ]) && (
                                                  <div
                                                    className="status"
                                                    style={{
                                                      verticalAlign: "super",
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial s-circle"
                                                          : "complete s-circle"
                                                      }
                                                    >
                                                      <i
                                                        class="icon fa fa-check"
                                                        style={{
                                                          color: "white",
                                                          fontSize: "9px",
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </div>
                                                )}
                                            </li>
                                          )}
                                        {topic.type == 8 &&
                                          course.scorm[topic.fid] && (
                                            <li
                                              className="title-popup"
                                              key={topic.fid}
                                              onClick={() => {
                                                if (
                                                  (contentLocking[
                                                    chapter._id
                                                  ] &&
                                                    contentLocking[chapter._id][
                                                      contentIndex
                                                    ] &&
                                                    !(
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLocked ||
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLockedDeadline
                                                    )) ||
                                                  !contentLocking[chapter._id]
                                                ) {
                                                  history.push(
                                                    `/chapter/${pid}/${gid}/${cid}/${levelId}/scorm/${topic.chpid}/${topic.fid}`
                                                  );
                                                  setPrevFid(fid);
                                                }
                                              }}
                                              style={{
                                                opacity:
                                                  topic.fid == fid
                                                    ? "0.9"
                                                    : "1",
                                              }}
                                            >
                                              <div className="title">
                                                <a>
                                                  <div>
                                                    <div>
                                                      <div
                                                        className="wrap-text"
                                                        style={{
                                                          // opacity:
                                                          //   topic.fid == fid
                                                          //     ? "0.9"
                                                          //     : "1",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {topic.fid == fid ? (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/chapter-controller/scorm-icon-white.svg"
                                                            className="icon play-button"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/chapter-controller/scorm-icon.svg"
                                                            className="icon play-button"
                                                          />
                                                        )}
                                                        {
                                                          course.scorm[
                                                            topic.fid
                                                          ].nm
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="statusLock">
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial"
                                                          : "complete"
                                                      }
                                                    >
                                                      <img src="https://cdn.pegasus.imarticus.org/chapter-controller/white-lock.svg" />
                                                    </div>
                                                  </div>
                                                )}
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="popup">
                                                    {
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex].msg
                                                    }
                                                  </div>
                                                )}
                                              {status.status &&
                                                status.status[topic.fid] &&
                                                status.status[topic.fid]
                                                  .stts === 4 &&
                                                ((contentLocking[chapter._id] &&
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ] &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLocked &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) ||
                                                  !contentLocking[
                                                    chapter._id
                                                  ]) && (
                                                  <div
                                                    className="status"
                                                    style={{
                                                      verticalAlign: "super",
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial s-circle"
                                                          : "complete s-circle"
                                                      }
                                                    >
                                                      <i
                                                        class="icon fa fa-check"
                                                        style={{
                                                          color: "white",
                                                          fontSize: "9px",
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </div>
                                                )}
                                            </li>
                                          )}
                                        {topic.type == 7 &&
                                          course.liv_lec_id_map[topic.fid]
                                            .s3_url != null &&
                                          course.liv_lec_id_map[topic.fid]
                                            .s3_url != "" && (
                                            <li
                                              className="title-popup"
                                              key={topic.fid}
                                              onClick={() => {
                                                if (
                                                  (contentLocking[
                                                    chapter._id
                                                  ] &&
                                                    contentLocking[chapter._id][
                                                      contentIndex
                                                    ] &&
                                                    !(
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLocked ||
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex]
                                                        .isLockedDeadline
                                                    )) ||
                                                  !contentLocking[chapter._id]
                                                ) {
                                                  window.location.replace(
                                                    `${CONFIGS.WEB_DASHBOARD_BASE_URL}chapter/${pid}/${gid}/${cid}/${levelId}/lecture/${topic.chpid}/${topic.fid}`
                                                  );
                                                  setPrevFid(fid);
                                                }
                                              }}
                                              style={{
                                                opacity:
                                                  topic.fid == fid
                                                    ? "0.9"
                                                    : "1",
                                              }}
                                            >
                                              <div
                                                className="title"
                                                onClick={() => {
                                                  setVideoLectureType(
                                                    "liveLecture"
                                                  );
                                                }}
                                              >
                                                <a>
                                                  <div>
                                                    <div>
                                                      <div
                                                        className="wrap-text"
                                                        style={{
                                                          // opacity:
                                                          //   topic.fid == fid
                                                          //     ? "0.9"
                                                          //     : "1",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {!props.brand
                                                          ?.textColor ||
                                                        props.brand
                                                          ?.textColor ==
                                                          "#000000" ? (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/index/play_dark.svg"
                                                            className="icon play-button"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="https://cdn.pegasus.imarticus.org/index/play_white.svg"
                                                            className="icon play-button"
                                                          />
                                                        )}
                                                        {
                                                          course.liv_lec_id_map[
                                                            topic.fid
                                                          ].nm
                                                        }
                                                        <span
                                                          style={{
                                                            float: "right",
                                                          }}
                                                        >
                                                          {
                                                            course
                                                              .liv_lec_id_map[
                                                              topic.fid
                                                            ].vlen
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="statusLock">
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial"
                                                          : "complete"
                                                      }
                                                    >
                                                      <img src="https://cdn.pegasus.imarticus.org/chapter-controller/white-lock.svg" />
                                                    </div>
                                                  </div>
                                                )}
                                              {contentLocking[chapter._id] &&
                                                contentLocking[chapter._id][
                                                  contentIndex
                                                ] &&
                                                (contentLocking[chapter._id][
                                                  contentIndex
                                                ].isLocked ||
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) && (
                                                  <div className="popup">
                                                    {
                                                      contentLocking[
                                                        chapter._id
                                                      ][contentIndex].msg
                                                    }
                                                  </div>
                                                )}
                                              {status.status &&
                                                status.status[topic.fid] &&
                                                status.status[topic.fid]
                                                  .stts === 4 &&
                                                ((course.liv_lec_id_map[
                                                  topic.fid
                                                ].feedback_compulsory &&
                                                  status.status[
                                                    course.liv_lec_id_map[
                                                      topic.fid
                                                    ].feedback
                                                  ] &&
                                                  status.status[
                                                    course.liv_lec_id_map[
                                                      topic.fid
                                                    ].feedback
                                                  ].stts === 4) ||
                                                  !course.liv_lec_id_map[
                                                    topic.fid
                                                  ].feedback_compulsory) &&
                                                ((contentLocking[chapter._id] &&
                                                  contentLocking[chapter._id][
                                                    contentIndex
                                                  ] &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLocked &&
                                                  !contentLocking[chapter._id][
                                                    contentIndex
                                                  ].isLockedDeadline) ||
                                                  !contentLocking[
                                                    chapter._id
                                                  ]) && (
                                                  <div
                                                    className="status"
                                                    style={{
                                                      verticalAlign: "super",
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        status.status[
                                                          topic.fid
                                                        ] &&
                                                        (status.status[
                                                          topic.fid
                                                        ].stts === 2 ||
                                                          status.status[
                                                            topic.fid
                                                          ].stts === 3)
                                                          ? "partial s-circle"
                                                          : "complete s-circle"
                                                      }
                                                    >
                                                      <i
                                                        class="icon fa fa-check"
                                                        style={{
                                                          color: "white",
                                                          fontSize: "9px",
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </div>
                                                )}
                                            </li>
                                          )}
                                      </React.Fragment>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
                </div>
                <div>
                  {certiEnabled &&
                    (gradebookType == 2 && !elecId ? false : true) && (
                      <div>
                        <div>
                          {elecId && ( //crtfEligible - part of graduation criteria
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "1rem",
                              }}
                            >
                              {(certiAutoPublish ||
                                certiElectivePublishStatus[elecId]) &&
                                !certiGeneratedStatus[elecId] &&
                                !(gradebookType == 1
                                  ? crtfEligibleMap[elecId]
                                  : crtfEligible) && (
                                  <button
                                    style={{ cursor: "not-allowed" }}
                                    className="btn btn-primary rvm-v1-part-2-info-btn center-all disabled"
                                  >
                                    Get Certificate
                                  </button>
                                )}
                              {(certiAutoPublish ||
                                certiElectivePublishStatus[elecId]) &&
                                (gradebookType == 1
                                  ? crtfEligibleMap[elecId]
                                  : crtfEligible) &&
                                !certiGeneratedStatus[elecId] && (
                                  <a
                                    href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${elecId}`}
                                  >
                                    <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                      Generate Certificate
                                    </button>
                                  </a>
                                )}
                              {(certiAutoPublish ||
                                certiElectivePublishStatus[elecId]) &&
                                certiGeneratedStatus[elecId] &&
                                !crtfFile && (
                                  <a
                                    href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}certificate/${pid}/${cid}/${elecId}`}
                                  >
                                    <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                      Get Certificate
                                    </button>
                                  </a>
                                )}
                              {(certiAutoPublish ||
                                certiElectivePublishStatus[elecId]) &&
                                certiGeneratedStatus[elecId] &&
                                crtfFile && (
                                  <a
                                    href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificateView/${pid}/${cid}/${elecId}`}
                                    className="float-right btn btn-primary rvm-v1-part-2-info-btn center-all"
                                  >
                                    View Certificate
                                  </a>
                                )}
                            </div>
                          )}

                          {!elecId && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "1rem",
                              }}
                            >
                              {(certiAutoPublish ||
                                (isElec && elecLevelIndex
                                  ? certiElectivePublishStatus["CORE_TRACK"]
                                  : certiPublished)) &&
                                !certiGeneratedStatus["FOUNDATION"] &&
                                !(gradebookType == 1 && isElec
                                  ? crtfEligibleMap["FOUNDATION"]
                                  : crtfEligible) && (
                                  <button
                                    style={{ cursor: "not-allowed" }}
                                    className="btn btn-primary rvm-v1-part-2-info-btn center-all disabled"
                                  >
                                    Get Certificate
                                  </button>
                                )}
                              {(certiAutoPublish ||
                                (isElec && elecLevelIndex
                                  ? certiElectivePublishStatus["CORE_TRACK"]
                                  : certiPublished)) &&
                                (gradebookType == 1 && isElec
                                  ? crtfEligibleMap["FOUNDATION"]
                                  : crtfEligible) &&
                                !certiGeneratedStatus["FOUNDATION"] && (
                                  <a
                                    href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${elecId}`}
                                  >
                                    <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                      Generate Certificate
                                    </button>
                                  </a>
                                )}
                              {(certiAutoPublish ||
                                (isElec && elecLevelIndex
                                  ? certiElectivePublishStatus["CORE_TRACK"]
                                  : certiPublished)) &&
                                certiGeneratedStatus["FOUNDATION"] &&
                                !crtfFile && (
                                  <a
                                    href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}certificate/${pid}/${cid}/${elecId}`}
                                  >
                                    <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                      Get Certificate
                                    </button>
                                  </a>
                                )}
                              {(certiAutoPublish ||
                                (isElec && elecLevelIndex
                                  ? certiElectivePublishStatus["CORE_TRACK"]
                                  : certiPublished)) &&
                                certiGeneratedStatus["FOUNDATION"] &&
                                crtfFile && (
                                  <a
                                    href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificateView/${pid}/${cid}/${elecId}`}
                                    className="float-right btn btn-primary rvm-v1-part-2-info-btn center-all"
                                  >
                                    View Certificate
                                  </a>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </nav>
            <div className="header">
              <div className="row">
                <div className="col-sm-10 col-xs-10 col-md-12 getCertified">
                  <h2 className="textWrap">{course.course_name}</h2>
                </div>
              </div>
            </div>
            <div className="breadCrumbsContainer breadcrumbsChapter">
              {breadCrumbs.length > 0 && (
                <div className="rvm-v1-header-brdsc-ctnr breadCrumbs-row">
                  {breadCrumbs.map((entry, index) => (
                    <div className="breadCrumbs" key={index}>
                      {index === 0 && (
                        <>
                          <a href={entry.link}>
                            <span>{entry.name}</span>
                          </a>
                          <i
                            style={{ fontSize: "small", marginLeft: "10px" }}
                            className="fa fa-chevron-right"
                          ></i>
                        </>
                      )}
                      {index === 1 && (
                        <a
                          href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}view/?cid=${cid}`}
                        >
                          {entry.name}
                        </a>
                      )}
                      {entry._id &&
                        !entry.chap &&
                        index != breadCrumbs.length - 2 && (
                          <>
                            <i
                              style={{
                                fontSize: "small",
                                marginLeft: "10px",
                              }}
                              className="fa fa-chevron-right"
                            ></i>
                            <a
                              href={
                                index > 2
                                  ? `${CONFIGS.WEB_DASHBOARD_BASE_URL}view/?cid=${cid}/&levelId=${entry._id}`
                                  : `${CONFIGS.WEB_DASHBOARD_BASE_URL}view/?cid=${cid}`
                              }
                            >
                              <span>{entry.name}</span>
                            </a>
                          </>
                        )}
                      {entry._id &&
                        !entry.chap &&
                        index === breadCrumbs.length - 2 && (
                          <>
                            <i
                              style={{ fontSize: "small", marginLeft: "10px" }}
                              className="fa fa-chevron-right"
                            ></i>
                            <a
                              href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}batch/${pid}/${gid}/${cid}/${levelId}`}
                            >
                              <span>{entry.name}</span>
                            </a>
                          </>
                        )}
                      {entry._id && entry.chap && (
                        <>
                          <i
                            style={{ fontSize: "small", marginLeft: "10px" }}
                            className="fa fa-chevron-right"
                          ></i>
                          <a className="rvm-v1-header-brdsc-active">
                            <span>{entry.name}</span>
                          </a>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {type == "quiz" && fid && (
              <Quiz
                course={course}
                getIndex={getCurrentIndex}
                prevFid={prevFid}
                setContentLoading={setContentLoading}
                status={status}
              />
            )}
            {type == "docLecture" && fid && (
              <DocLecture
                course={course}
                getIndex={getCurrentIndex}
                prevFid={prevFid}
                setContentLoading={setContentLoading}
              />
            )}
            {type == "scorm" && fid && (
              <Scorm
                course={course}
                getIndex={getCurrentIndex}
                prevFid={prevFid}
                setContentLoading={setContentLoading}
              />
            )}
            {type == "assignment" && (
              <Assignment
                course={course}
                getIndex={getCurrentIndex}
                prevFid={prevFid}
                setPrevFid={setPrevFid}
                setContentLoading={setContentLoading}
                status={status}
              />
            )}
            {type == "lecture" && fid && (
              <Lecture
                course={course}
                status={status}
                type={videoLectureType}
                getIndex={getCurrentIndex}
                prevFid={prevFid}
                setContentLoading={setContentLoading}
              />
            )}
            <div className="topic-head">
              {showPopupNext && (
                <div className="showPopupNext">{seqTopic[index + 1]?.msg}</div>
              )}
              {showPopupPrevious && (
                <div className="showPopupPrevious">
                  The previous content is Locked.
                </div>
              )}
              <ul>
                <li
                  onMouseEnter={() => {
                    setShowPopupPrevious(seqTopic[index - 1]?.isLocked);
                  }}
                  onMouseLeave={() => {
                    setShowPopupPrevious(false);
                  }}
                >
                  <div
                    onClick={() => previous(index)}
                    className={
                      index === 0 ||
                      seqTopic[index - 1]?.isLocked ||
                      contentLoading
                        ? "inactive"
                        : ""
                    }
                    style={{
                      cursor:
                        index === 0 ||
                        seqTopic[index - 1]?.isLocked ||
                        contentLoading
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    Previous
                  </div>
                </li>
                <li>
                  <p>
                    {index + 1}/{seqTopic.length}
                  </p>
                </li>
                <li
                  onMouseEnter={() => {
                    setShowPopupNext(seqTopic[index + 1]?.isLocked);
                  }}
                  onMouseLeave={() => {
                    setShowPopupNext(false);
                  }}
                >
                  <div
                    onClick={() => next(index)}
                    className={
                      index + 1 === seqTopic.length ||
                      seqTopic[index + 1]?.isLocked ||
                      contentLoading
                        ? "inactive"
                        : ""
                    }
                    style={{
                      cursor:
                        index + 1 === seqTopic.length ||
                        seqTopic[index + 1]?.isLocked ||
                        contentLoading
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    Next
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
};

const mapStateToProps = (state) => ({
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = { dispatchIndividualBrand, getProfileInfoOnly };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Chapter)
);
