import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import blankProfile from "./img/blank_profile.png";
import alert from "./img/alert.svg";

export default function GroupMembers({
  currentGMPage,
  handleGroupMemberPage,
  PID,
  allGroupMembers,
  toggleShowSettings,
  toggleShowSettingsHandler,
  currentGroup,
  currentGroupMember,
  makeAdminHandler,
  removeAdminHandler,
  banUserHandler,
  unbanUserHandler,
  deleteUserHandler,
}) {
  let totalPages =
    allGroupMembers.totalMembers < 10
      ? 1
      : Math.ceil(allGroupMembers.totalMembers / 10);

  const [memberToggle, setMemberToggle] = useState(false);
  const manageMemberHandler = (e, member) => {
    console.log("member selected : ", member);
    setMemberToggle(!memberToggle);
    return;
  };

  return (
    <Modal
      isOpen={toggleShowSettings}
      toggle={toggleShowSettingsHandler}
      size={"xl"}
    >
      <ModalHeader toggle={toggleShowSettingsHandler}>
        <h4>{currentGroup.name}</h4>
        <h6>
          Group code: <b>{currentGroup.code}</b>
        </h6>
        <h6>
          Permission:{" "}
          <b>
            {currentGroup.type
              ? "All members can post"
              : "Only admins can post"}
          </b>
        </h6>
      </ModalHeader>
      <ModalBody style={{ maxHeight: "400px", overflowY: "scroll" }}>
        {/* {console.log(
          "allGroupMembers :: ",
          allGroupMembers.members.filter((member) => {
            console.log("Member.pid : ", member.pid, "  and PID : ", PID);
            if (member.pid == PID) {
              //user ko apna profile kyu dikhana
              return false;
            }
            return true;
          })
        )} */}

        {allGroupMembers &&
          allGroupMembers.members.length > 0 &&
          allGroupMembers.members.map((member) => {
            let profileDetails = allGroupMembers.profileMap[member.pid];
            return (
              <div className="row each-member justify-content-between">
                <div className="col">
                  <img
                    src={profileDetails.ppic || blankProfile}
                    width={"30px"}
                    height={"40px"}
                    style={{ paddingTop: "8px" }}
                  />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ paddingTop: "10px", fontSize: "16px" }}>
                    {profileDetails.nam}
                  </span>
                  {member.type === 1 && (
                    <p
                      style={{ float: "right", marginLeft: "25px" }}
                      class="member-status member-status-admin ng-scope member-for-admin"
                    >
                      Group Admin
                    </p>
                  )}
                </div>
                {currentGroupMember.type == 1 &&
                  member.pid !== currentGroupMember.pid && (
                    <div className="float-end">
                      {member.type == 3 && (
                        <>
                          <span
                            style={{ color: "red", verticalAlign: "middle" }}
                          >
                            Banned
                          </span>{" "}
                          <img src={alert} width={"20px"} />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      <UncontrolledDropdown group>
                        <DropdownToggle
                          caret
                          // color="primary"
                        />
                        <DropdownMenu flip={true} style={{ left: "-100px" }}>
                          {member.type !== 1 && member.type !== 3 && (
                            <DropdownItem
                              onClick={(e) => makeAdminHandler(member)}
                            >
                              Make Admin
                            </DropdownItem>
                          )}
                          {member.type == 1 &&
                            member.pid !== currentGroupMember.pid && (
                              <DropdownItem
                                onClick={(e) => removeAdminHandler(member)}
                              >
                                Remove Admin
                              </DropdownItem>
                            )}
                          {member.type !== 1 && member.type !== 3 && (
                            <DropdownItem
                              onClick={(e) => banUserHandler(member)}
                            >
                              Ban User
                            </DropdownItem>
                          )}
                          {member.type !== 1 && member.type == 3 && (
                            <DropdownItem
                              onClick={(e) => unbanUserHandler(member)}
                            >
                              Unban User
                            </DropdownItem>
                          )}
                          {member.type !== 1 && (
                            <DropdownItem
                              onClick={(e) => deleteUserHandler(member)}
                            >
                              Delete User
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  )}
              </div>
            );
          })}
        {/* {memberToggle && 
            <div className="optionsList">
              <div className="options">Make Admin</div>
              <div className="options">Ban User</div>
              <div className="options">Delete User</div>
            </div>
          } */}
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center" }}>
        <Pagination aria-label="">
          {/* <PaginationItem>
            <PaginationLink first href="#" />
          </PaginationItem> */}
          <PaginationItem>
            <PaginationLink first href="#" previous />
          </PaginationItem>
          {console.log(allGroupMembers.totalMembers)}
          {Array.from(Array(totalPages), (e, i) => {
            console.log("i == currentGMPage : ", i == currentGMPage);
            if (i === currentGMPage) {
              return (
                <PaginationItem
                  active={i == currentGMPage ? "true" : "false"}
                  onClick={(e) => handleGroupMemberPage(i)}
                >
                  <PaginationLink>{i + 1}</PaginationLink>
                </PaginationItem>
              );
            } else
              return (
                <PaginationItem onClick={(e) => handleGroupMemberPage(i)}>
                  <PaginationLink>{i + 1}</PaginationLink>
                </PaginationItem>
              );
          })}
          {/* <PaginationItem active>
                <PaginationLink href="#">
                1
                </PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink href="#">
                2
                </PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink href="#">
                3
                </PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink href="#">
                4
                </PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink href="#">
                5
                </PaginationLink>
            </PaginationItem> */}
          <PaginationItem>
            <PaginationLink href="#" next />
          </PaginationItem>
          {/* <PaginationItem>
            <PaginationLink href="#" last />
          </PaginationItem> */}
        </Pagination>
      </ModalFooter>
    </Modal>
  );
}
