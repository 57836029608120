const initialState = {
  individualBrand: {},
  courses: [],
  groups: [],
  combo_courses: [],
  resume_course: null,
  live_lectures: [],
  child_courses: [],
  courses_map: {},
  crs_groups: [],
  crs_pid: null,
  selected_course: {},
  // notification: {},
  pendingInstallments: [],
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MY_COURSES":
      return {
        ...state,
        courses: action.courses,
        groups: action.groups,
        combo_courses: action.combo_courses,
        resume_course: action.resume_course,
        live_lectures: action.live_lectures,
        courses_map: action.courses_map,
        crs_groups: action.crs_groups,
        brand: action.brand,
      };
    case "PENDING_INSTALLMENTS":
      return {
        ...state,
        pendingInstallments: action.pendingInstallments,
      };
    case "CHILD_COURSES":
      return {
        ...state,
        child_courses: action.child_courses,
      };
    case "NULL_GRP_CRS":
      return {
        ...state,
        courses: action.courses,
      };
    case "CRS_PID":
      return {
        ...state,
        crs_pid: action.crs_pid,
      };
    case "INDIVIDUAL_BRAND":
      return {
        ...state,
        individualBrand: action.data,
      };
    case "SET_SELECTED_COURSE":
      return {
        ...state,
        selected_course: action.course,
      };
    // case "IN_APP":
    //   console.log({ action });
    //   return {
    //     ...state,
    //     notification: action.notification,
    //   };
    default:
      return state;
  }
};

export default courseReducer;
